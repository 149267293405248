import { createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/logOnlyInProduction";
import reducer from "./reducer";
import StateLoader from "./StateLoader";

const stateLoader = new StateLoader();

const initializeStore = (
    initialState = {
        AmaxApi: {
            connected: false,
            user: null,
            token: null,
            token_expires_date: null,
        },
        SearchQuery: {
            formName: null,
            query: null,
            result: null,
            status: null,
        },
        Logic: {
            loading: false,
            error: false,
            language: null,
        },
    }
) => {
    const composeEnhancers = composeWithDevTools({
        // options like actionSanitizer, stateSanitizer
    });
    const store = createStore(reducer, stateLoader.loadState(initialState), composeEnhancers());
    // Make reducers hot reloadable
    if (module.hot)
        module.hot.accept("./reducer", () => {
            store.replaceReducer(reducer);
        });
    store.subscribe(() => {
        stateLoader.saveState(store.getState());
    });

    return store;
};

export default initializeStore;
