export default function AmaxApi(
    // Default Initial Store State
    state = {
        connected: false,
        user: null,
        token: null,
        token_expires_date: null,
    },
    action
) {
    switch (action.type) {
        case "SET_API_CONNECTED":
            if (action.status)
                return Object.assign({}, state, {
                    connected: action.status,
                    user: action.user,
                    token: action.token,
                    token_expires_date: action.token_expires_date,
                });
            return Object.assign({}, state, {
                connected: false,
                user: null,
                token: null,
                token_expires_date: null,
            });
        default:
            return state;
    }
}
