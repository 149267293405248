import React, { memo } from "react";
import { useState, useEffect } from "react";
import AmaxApiService from "../services/AmaxApiService";
//Router
import { Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import { store } from "../components/providers/ReduxProvider";
//Antd
import { Typography } from "antd";
import {
    ScheduleFilled,
    IdcardFilled,
    SettingFilled,
    PhoneFilled,
    DollarCircleFilled,
    LikeFilled,
    CloseCircleFilled,
    ShoppingFilled,
    DeleteFilled,
} from "@ant-design/icons";
//Layouts
import LayoutWrapper from "../components/layouts/LayoutWrapper";
//Components
import IconTile, { IconTileContainer } from "../components/common/IconTile";
//Language
import t from "../components/providers/LanguageProvider";

import { setPermisions } from "../store/actions";

const Home = () => {
    const [permisionResponse, setPermisionResponse] = useState(store.getState().Logic.permisions);
    console.log(permisionResponse);

    const getPermision = async () => {
        AmaxApiService.Account.Permission().then((response) => {
            setPermisionResponse(response);
            store.dispatch(setPermisions(response));
        });
    };

    useEffect(() => {
        //loads user permisions if not loaded
        if (!store.getState().Logic.permisions) {
            getPermision();
        }
    }, []);

    return (
        <LayoutWrapper>
            <div
                className="site-layout-mobile"
                style={{
                    height: "100%",
                    justifyContent: "center",
                    textAlign: "center",
                }}
            >
                <Typography.Title>{t("Welcome to our information portal")}</Typography.Title>

                <IconTileContainer className="mobile-height-icon-tile">
                    {permisionResponse && (
                        <>
                            {permisionResponse.ReportMeeting && (
                                <Link to="meetings">
                                    <IconTile
                                        icon={<ScheduleFilled className="ant-color" />}
                                        label={t("Report Meetings")}
                                    />
                                </Link>
                            )}
                            {permisionResponse.Telemarketing && (
                                <Link to="telemarketing">
                                    <IconTile icon={<PhoneFilled className="ant-color" />} label={t("Telemarketing")} />
                                </Link>
                            )}

                            {permisionResponse.Telesale && (
                                <Link to="telesales">
                                    <IconTile icon={<ShoppingFilled className="ant-color" />} label={t("Telesales")} />
                                </Link>
                            )}

                            {permisionResponse.Rating && (
                                <Link to="hodnocenischuzky">
                                    <IconTile
                                        icon={<LikeFilled className="ant-color" />}
                                        label={t("Meetings Evaluation")}
                                    />
                                </Link>
                            )}

                            {permisionResponse.Cancel && (
                                <Link to="cancel">
                                    <IconTile
                                        icon={<CloseCircleFilled className="ant-color" />}
                                        label={t("Cancellation")}
                                    />
                                </Link>
                            )}
                            {/*<Link to="blacklist">
                <IconTile icon={<DeleteFilled className="ant-color" />} label={t("Blacklist")} />
            </Link>*/}
                        </>
                    )}
                    <Link to="user">
                        <IconTile icon={<IdcardFilled className="ant-color" />} label={t("User")} />
                    </Link>
                </IconTileContainer>
            </div>
        </LayoutWrapper>
    );
};

export default connect(mapStateToProps)(memo(Home));

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi };
}
