import React from "react";
// Redux
import { connect } from "react-redux";
import { setLanguage } from "../store/actions";
// Antd
import { PageHeader, Select, Form } from "antd";
// Layouts
import LayoutWrapper from "../components/layouts/LayoutWrapper";
// Language
import t from "../components/providers/LanguageProvider";
// Icons
import czIcon from "../icons/flagIcons/czech-republic.svg";
import enIcon from "../icons/flagIcons/united-kingdom.svg";

const SettingsPage = ({ settings, setLanguage }) => {
    const changeLanguage = (e) => {
        setLanguage(e); // Dispatch the action to update the selected language
    };

    const formItemLayout = {
        style: { display: "block" },
        labelCol: {
            xs: { span: 4 },
            sm: { span: 5 },
            md: { span: 6 },
        },
        wrapperCol: {
            xs: { span: 14 },
            sm: { span: 14 },
            md: { span: 14 },
        },
    };
    const iconStyle = {
        height: "17px",
        display: "inline-block",
        marginRight: "10px",
    };

    return (
        <LayoutWrapper>
            <PageHeader
                onBack={() => {
                    window.location = "/";
                }}
                title={t("Settings")}
                extra={[]}
            >
                <Form layout="horizontal" {...formItemLayout}>
                    <Form.Item label={t("Language")}>
                        <Select
                            {...formItemLayout}
                            defaultValue={settings.language}
                            onChange={changeLanguage}
                            key="Language"
                        >
                            <Select.Option value="cs">
                                <img src={czIcon} style={iconStyle} alt="Czech Flag" />
                                {t("Czech")}
                            </Select.Option>
                            <Select.Option value="en">
                                <img src={enIcon} style={iconStyle} alt="English Flag" />
                                {t("English")}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                </Form>
            </PageHeader>
        </LayoutWrapper>
    );
};

const mapStateToProps = (state) => {
    return { settings: state.Logic };
};

const mapDispatchToProps = (dispatch) => ({
    setLanguage: (language) => dispatch(setLanguage(language)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);
