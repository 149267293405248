//Cheerio
import cheerio from "cheerio";
//Moment
import moment from "moment";
//import ReportMeetingTemplate from "/templates/report_meeting.svg";

//PDF Make
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function exportToPdf(variables = {}) {
    //No input specified
    if (!variables) return false;

    //Load template
    const $ = cheerio.load(_ReportMeetingTemplate);

    let tssId = 0;
    //Parsing the input data into the template by updating the SVG markup
    for (let [key, value] of Object.entries(variables)) {
        const identif = `#${key}`;
        //Empty values
        if (value == null || value === "") {
            value = "-";
        }

        if (key == "Company") {
            if (value.length > 40) {
                value = value.substring(0, 40) + "...";
            }
        } else if (value.length > 82) {
            value = value.substring(0, 82) + "...";
        }

        //Datetime format
        if (key === "MeetingDate" || key === "AgreedDate") {
            value = moment(value).format("DD.MM.YYYY") + " " + moment(value).format("HH:mm");
        }
        //Tss Id
        if (key === "TssId") {
            tssId = value;
        }
        //Replace placeholder with value
        $(identif).text(value);
    }

    //New document
    var docDefinition = {
        content: [{ svg: [$("body").html()], width: 600, margin: -40 }],
    };

    //Download file
    pdfMake.createPdf(docDefinition).download(`ReportSchůzky-${tssId}`);
}

export default exportToPdf;

const _ReportMeetingTemplate = `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   width="210mm"
   height="297mm"
   viewBox="0 0 210 297"
   version="1.1"
   id="svg8"
   inkscape:version="1.1.2 (b8e25be833, 2022-02-05)"
   sodipodi:docname="telem.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:dc="http://purl.org/dc/elements/1.1/">
  <defs
     id="defs2" />
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:zoom="0.49497475"
     inkscape:cx="-97.984796"
     inkscape:cy="385.87827"
     inkscape:document-units="mm"
     inkscape:current-layer="layer1"
     showgrid="false"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     showguides="false"
     borderlayer="true"
     inkscape:pagecheckerboard="true" />
  <metadata
     id="metadata5">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     inkscape:label="Vrstva 1"
     inkscape:groupmode="layer"
     id="layer1">
    <rect
       style="opacity:1;fill:#009fe2;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.82433516;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
       id="rect834"
       width="211.2887"
       height="298.22321"
       x="-0.94493955"
       y="-0.65624988" />
    <rect
       style="opacity:1;fill:#f0f2f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.08481;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
       id="rect812"
       width="212.86545"
       height="219.44165"
       x="-15.874997"
       y="68.531548"
       ry="16.60397" />
    <g
       id="g18657"
       transform="translate(0.61282049,1.557761)">
      <text
         transform="scale(1.0349719,0.96620982)"
         id="text868"
         y="21.439487"
         x="75.113976"
         style="font-weight:bold;font-size:17.6514px;opacity:0.791667;fill:#ffdc00;stroke:none;stroke-width:0.598409">AMAX</text>
      <path
         inkscape:connector-curvature="0"
         id="path881"
         d="M 77.660254,22.231305 H 132.87757"
         style="opacity:0.4;fill:none;stroke:#000000;stroke-width:0.287991;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <text
         transform="scale(1.4227915,0.70284368)"
         id="text885"
         y="36.997055"
         x="54.250431"
         style="font-style:normal;font-weight:normal;font-size:4.59731px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;opacity:0.791667;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.430997"
         xml:space="preserve"><tspan
           dx="0.016332898 0.0081664491"
           style="letter-spacing:0.509893px;word-spacing:0.00459363px;fill:#f0f2f5;fill-opacity:1;stroke-width:0.430997"
           y="36.997055"
           x="54.250431"
           id="tspan883"
           sodipodi:role="line">CALL CENTRUM</tspan></text>
    </g>
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:11.9436px;line-height:1.25;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;letter-spacing:0px;word-spacing:0px;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.307135"
       x="44.882957"
       y="44.652172"
       id="text840"><tspan
         sodipodi:role="line"
         id="tspan838"
         x="60.882957"
         y="44.652172"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:11.9436px;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#f0f2f5;fill-opacity:1;stroke-width:0.307135">DETAIL SCHŮZKY</tspan></text>
         <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:10.2889px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.264583"
       x="8.3154764"
       y="120.48512"
       id="text855" />
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:10.2889px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.264583"
       x="8.3154764"
       y="120.48512"
       id="text855" />
       <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:8.88107px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.20815"
       x="9.755311"
       y="60.854786"
       id="text892"><tspan
         id="Company"
         x="9.755311"
         y="60.854786"
         style="fill:#f0f2f5;fill-opacity:1;stroke-width:0.20815"
         sodipodi:role="line">$Company</tspan><tspan
         x="9.755311"
         y="71.956123"
         style="fill:#f0f2f5;fill-opacity:1;stroke-width:0.20815"
         sodipodi:role="line"
         id="tspan23780" /></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:8.71869px;line-height:1.9;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
       x="14.4553"
       y="89.116096"
       id="text859"><tspan
         sodipodi:role="line"
         id="tspan857"
         x="14.4553"
         y="89.116096"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344">Adresa</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="105.68161"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan873">Místo schůzky</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="122.24712"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan871">Datum Schůzky</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="138.81262"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan877">Firma</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="155.37814"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan863">IČO</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="171.94365"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan879">Telefon</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="188.50916"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan865">Osoba</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="205.07468"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan881">Funkce</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="221.64018"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan867">Datum sjednání</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="238.20569"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan884">Poznámka</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="254.77121"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan886">Poznámka obchodního zástupce</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="271.3367"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan888">Obchodní zástupce</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="287.90222"
         id="tspan869" /></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:8.7187px;line-height:1.9;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
       x="19.43906"
       y="95.461296"
       id="text859-2"><tspan
         sodipodi:role="line"
         id="Address"
         x="19.43906"
         y="95.461296"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344">$Address</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="112.02682"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="MeetingPlace">$MeetingPlace</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="128.59236"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="MeetingDate">$MeetingDate</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="145.15788"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="Company">$Company</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="161.72342"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="TIN">$TIN</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="178.28896"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="Phone">$Phone</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="194.85448"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="Person">$Person</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="211.42001"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="Function">$Function</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="227.98553"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="AgreedDate">$AgreedDate</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="244.55107"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="Note">$Note</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="261.11661"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="SRNote">$SRNote</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="277.68213"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="SR">$SR</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="294.24765"
         id="tspan869-3" /></text>
  </g>
</svg>

`;
