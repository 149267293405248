import IsValidLogin, { isValidLogin } from "../components/common/AccountFunctions";
import { store } from "../components/providers/ReduxProvider";
import { setDisconnected, displayError, resetError } from "../store/actions";
import { getDeepestObject } from "../helpers/helpers";
import t from "../components/providers/LanguageProvider";

/**
 * Sets a message to be displayed in appropriate ErrorView components
 * @param {Integer} status
 * @param {String} message
 * @param {String} display [ main | modal ]
 * @param {*} stackTrace
 */
function displayErrorMessage(status, message, display, stackTrace) {
    store.dispatch(displayError(status, message, display, stackTrace));
}

/**
 * Handles error with appropriate app behavior
 *
 * @function handleError
 * @param {Error} err
 * @param {string} display where do display the error message
 */
export default function handleError(err = { data: { Message: "" }, status: 200 }, display = "all") {
    switch (err.status) {
        // CLIENT SIDE ERROS
        case 401:
            handle401(err.data);
            return;
        case 400:
            displayErrorMessage(err.status, extractError(err.data), "all", err.data);
            return;
        /// SERVER SIDE ERRORS
        case 500:
            handle500(err.data, display);
            return;
        default:
            if (err.status)
                displayErrorMessage(err.status, err.data.Message + " " + extractError(err.data), display, err.data);
            else displayErrorMessage(503, "Připojení není k disposici", display, null);
            return;
    }
}

function handle401(data) {
    let AmaxApi = store.getState().AmaxApi;
    if (AmaxApi.connected) store.dispatch(setDisconnected());
    else displayErrorMessage(401, "Neoprávněný přístup", JSON.stringify(data));
}

function handle500(data, display) {
    displayErrorMessage(500, "Chyba serveru:", display, JSON.stringify(data));
}

/**
 * Tries to extract an error message from many different sources
 * @function extractError
 *
 * @param {any} data
 * @todo Please refactor this to be pretty
 */
function extractError(data) {
    return typeof getDeepestObject(data).obj === "array"
        ? getDeepestObject(data).obj[0]
        : typeof getDeepestObject(data).obj === "object"
        ? getDeepestObject(data).obj[0]
        : data.ModelState.optionsViewModel[0];
}

export function resetErrorView() {
    store.dispatch(resetError());
}
