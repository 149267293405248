import React, { memo, useState, useEffect } from "react";
//Router
import { useHistory, useLocation, Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import AmaxApiService from "../services/AmaxApiService";
import { resetErrorView } from "../services/ErrorHandler";
import { store } from "../components/providers/ReduxProvider";
//Antd
import { Alert, Input, Button, Form, Select } from "antd";
//Layout
import LoginLayout from "../components/layouts/LoginLayout";
//Components
import ErrorView from "../components/common/ErrorView";
//Language
import t from "../components/providers/LanguageProvider";
//Images
import logo from "../images/logo-portal-small.png";
import amax from "../images/amax-small.svg";
//Icons
import czIcon from "../icons/flagIcons/czech-republic.svg";
import enIcon from "../icons/flagIcons/united-kingdom.svg";

import { setLanguage } from "../store/actions";

const Forgotten = ({ loading }) => {
    const [success, setSuccess] = useState(false);
    const [pwdOk, setPwdOk] = useState(false);
    const [pwdSame, setPwdSame] = useState(true);
    const [pwdNew, setPwdNew] = useState("");
    const [pwdNewRepeat, setPwdNewRepeat] = useState("");
    const [lang, setLang] = useState(null);

    const token = useLocation().pathname.substring(11);
    const history = useHistory();

    const changeLanguage = (e) => {
        store.dispatch(setLanguage(e));
        setLang(e);
        console.log(e);
    };

    /*
     * Handle Input Fields Change
     */
    const handleChange = (event) => {
        switch (event.target.id) {
            case "pwdNew":
                setPwdNew(event.target.value);
                break;
            case "pwdNewRepeat":
                setPwdNewRepeat(event.target.value);
                break;
        }
    };

    /*
     * Handle button submit,
     * Send email
     * If success set Logged In
     */
    const handleSubmit = async () => {
        //console.log(pwdNew, pwdNewRepeat, token);
        if (pwdOk) {
            let data = {
                NewPassword: pwdNew,
                Token: token,
            };
            //console.log(data);
            AmaxApiService.Account.NewPassword(data).then((response) => {
                //console.log(response);
                if (response.status === 200) {
                    setPwdNew("");
                    setPwdNewRepeat("");
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 2000);
                    setTimeout(() => {
                        history.push(`/login`);
                    }, 2500);
                }
            });
        } else {
            console.log("Password not ok");
        }
    };

    useEffect(() => {
        setPwdSame(pwdNew === pwdNewRepeat);
    }, [pwdNew, pwdNewRepeat]);

    useEffect(() => {
        //if (pwdSame && pwdNew !== "") {
        resetErrorView();
        var mediumRegex = new RegExp(
            "^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
            "g"
        );
        setPwdOk(mediumRegex.test(pwdNew));
        //} else {
        //setPwdOk(false);
        //}
    }, [pwdSame]);

    useEffect(() => {
        //console.log(pwdOk);
    }, [pwdOk]);

    useEffect(() => {
        resetErrorView();
    }, []);

    const formItemLayout = {
        style: {
            //width: "auto",
            display: "flex",
            flexDirection: "column",
            //justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const iconStyle = {
        height: "17px",
        display: "inline-block",
    };

    const inputControl = {
        style: {
            width: "300px",
            height: "47px",
        },
    };

    const buttonControl = {
        style: {
            width: "300px",
            height: "36px",
        },
    };

    const buttonSubmitControl = {
        style: {
            width: "300px",
            height: "36px",
            backgroundColor: "#fcab06",
        },
    };

    const enterSubmit = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    return (
        <LoginLayout>
            <div className="login-box blue-background">
                <Select
                    defaultValue={store.getState().Logic.language == "cs" ? "cs" : "en"}
                    onChange={changeLanguage}
                    key="Language"
                >
                    <Select.Option value="cs">
                        <img src={czIcon} style={iconStyle} />
                        {" CZ"}
                    </Select.Option>
                    <Select.Option value="en">
                        <img src={enIcon} style={iconStyle} />
                        {" EN"}
                    </Select.Option>
                </Select>

                <div className="email-login login-img">
                    <img className="login-img" src={logo} alt="logo" />
                </div>
                <br />
                <h2 style={{ width: "330px", textAlign: "center" }}>{t("Create your new password")}</h2>

                <Form>
                    <div className="email-login white-background">
                        <Form.Item className="u-form-group" {...formItemLayout} name="pwdNew">
                            <Input.Password
                                className="u-form-group"
                                {...inputControl}
                                type="password"
                                placeholder={t("New Password")}
                                id="pwdNew"
                                value={pwdNew}
                                onChange={handleChange}
                            ></Input.Password>
                        </Form.Item>
                        <Form.Item className="u-form-group" {...formItemLayout} name="pwdNewRepeat">
                            <Input.Password
                                className="u-form-group"
                                {...inputControl}
                                type="password"
                                placeholder={t("New Password Again")}
                                id="pwdNewRepeat"
                                value={pwdNewRepeat}
                                onChange={handleChange}
                                onKeyDown={(e) => enterSubmit(e)}
                            ></Input.Password>
                        </Form.Item>

                        {!loading ? (
                            <>
                                <Button
                                    {...buttonSubmitControl}
                                    className="ant-btn-secondary u-form-group btn-login-form"
                                    onClick={handleSubmit}
                                >
                                    {t("Change")}
                                </Button>
                                <br />
                                <Link to="/login">
                                    <Button {...buttonControl} className="ant-btn-secondary u-form-group">
                                        {t("Back")}
                                    </Button>
                                </Link>
                            </>
                        ) : null}
                    </div>
                    <ErrorView type="main" />
                </Form>

                {!pwdOk && !success && (
                    <Alert
                        style={{ width: "330px", textAlign: "center" }}
                        message={t("Password must contain at least 8 characters with letters and numbers")}
                        type="error"
                        showIcon
                    />
                )}
                {!pwdSame && !success && (
                    <Alert
                        style={{ width: "330px", textAlign: "center" }}
                        message={t("Passwords must be the same")}
                        type="error"
                        showIcon
                    />
                )}
                {success && (
                    <Alert
                        style={{ width: "330px", textAlign: "center" }}
                        message={t("Password changed successfully, now you can log in")}
                        type="success"
                        showIcon
                    />
                )}
                <div className="email-login">
                    <img src={amax} alt="logo" />
                </div>
            </div>
        </LoginLayout>
    );
};

export default connect(mapStateToProps)(memo(Forgotten));

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi, loading: state.Logic.loading };
}
