import React, { useState, useEffect, useRef } from "react";
//Redux
import { connect } from "react-redux";
import SearchAndQueryData from "../../../services/DataQueryController";
import AmaxApiService from "../../../services/AmaxApiService";
//Antd
import { Button, Table, Descriptions, Select, Collapse, Input, Pagination, Checkbox, Radio } from "antd";
import {
    InfoCircleTwoTone,
    FileExcelOutlined,
    FilterFilled,
    CloseCircleOutlined,
    UpOutlined,
    FileExcelFilled,
    MessageFilled,
    SearchOutlined,
    SettingFilled,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import TelesalesDetail from "../Modals/TelesalesDetail";
import exportTelesales, {
    TelesalesParse,
    TelesalesParseColumns,
    printCampaignsTextFile,
} from "../exports/CSVTelesales";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
const CheckboxGroup = Checkbox.Group;

const TelesalesTable = ({ data, loading, query }) => {
    const [storedColumns, setStoredColumns] = useState(
        JSON.parse(localStorage.getItem("telesalesColumns"))
            ? JSON.parse(localStorage.getItem("telesalesColumns"))
            : null
    );
    const [columns, setColumns] = useState(null);

    const [campaigns, setCampaigns] = useState([]);
    const [newColumns, setNewColumns] = useState([]);

    const [last, setLast] = useState(data);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const [displayDetail, setDisplayDetail] = useState(false);
    const [detailId, setDetailId] = useState(null);
    const [detailData, setDetailData] = useState([]);
    const [questions, setQuestions] = useState(null);
    const [layout, setLayout] = useState("vertical");
    const [tableExpandable, setTableExpandable] = useState(false);
    const [columnSettVisible, setColumnSettVisible] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(null);
    const [exportVisible, setExportVisible] = useState(false);
    const [withoutQuestion, setWithoutQuestion] = useState(false);
    const [selectedInformation, setSelectedInformation] = useState(false);

    const [showColumns, setShowColumns] = useState([]);
    const [indexSearchQuestion, setIndexSearchQuestion] = useState(null);
    const [searchValue, setSearchValue] = useState(" ");
    const [filterEqual, setFilterEqual] = useState(false);
    //const [last, setLast] = useState(data);
    const [selectAnswers, setSelectAnswers] = useState([]);
    const [answerID, setAnswerID] = useState(" ");
    const [byAnswersVisible, setByAnswersVisible] = useState(false);
    const [productArray, setProductArray] = useState([]);
    const [allAnswers, setAllAnswers] = useState([]);
    const [questionArray, setQuestionArray] = useState([]);
    //const [newData, setNewData] = useState([])

    const [oldLast, setOldLast] = useState(last);
    const [costSum, setCostSum] = useState(0);
    const [costCancelSum, setCostCancelSum] = useState(0);
    const [cancelSum, setCancelSum] = useState(0);
    const [notCancelSum, setNotCancelSum] = useState(0);
    const [selectedRowKeys, setRowKeys] = useState([]);

    let newData = [];

    ///***FUNKCIONALITY FOR COLUMNS SETTING****
    useEffect(() => {
        if (storedColumns) localStorage.setItem("telesalesColumns", JSON.stringify(storedColumns));
    }, [storedColumns]);

    useEffect(() => {
        //needed for render and sort columns
        if (storedColumns) {
            for (let i = 0; i < storedColumns.length; i++) {
                let newColumn = storedColumns;

                switch (storedColumns[i]["title"]) {
                    case t("Date of Sale"):
                        newColumn[i] = colDate;
                        break;
                    case t("Company"):
                        newColumn[i] = colCompany;
                        break;
                    case t("Date of Sale"):
                        newColumn[i] = colDate;
                        break;
                    case t("TIN"):
                        newColumn[i] = colTIN;
                        break;
                    case t("Address"):
                        newColumn[i] = colAddress;
                        break;
                    case t("Price"):
                        newColumn[i] = colPrice;
                        break;
                    case t("Product"):
                        newColumn[i] = colProduct;
                        break;
                    case t("Campaign"):
                        newColumn[i] = colCampaign;
                        break;
                    case t("Person"):
                        newColumn[i] = colPerson;
                        break;
                    case t(""):
                        newColumn[i] = colStorno;
                        break;
                    case t("Detail"):
                        newColumn[i] = colDetail;
                        break;
                }
                setStoredColumns(newColumn);
            }
        }
    }, [data]);

    /////////////

    const getSumOfCost = () => {
        let rowsToSum = last ? last : data;
        if (selectedRowKeys.length > 0) {
            let dataToSum = [];
            rowsToSum.map((record) => (selectedRowKeys.indexOf(record.pripadID) >= 0 ? dataToSum.push(record) : null));
            rowsToSum = dataToSum;
        }

        if (rowsToSum) {
            let cancelled = 0;
            let notCancelled = 0;
            rowsToSum.map((oneRow) => {
                if (oneRow.stornovano) {
                    cancelled += oneRow.cena;
                } else {
                    notCancelled += oneRow.cena;
                }
            });
            setCostSum(notCancelled);
            setCostCancelSum(cancelled);
        }
    };

    const getCancelSum = () => {
        let rowsToSum = last ? last : data;
        if (selectedRowKeys.length > 0) {
            let dataToSum = [];
            rowsToSum.map((record) => (selectedRowKeys.indexOf(record.pripadID) >= 0 ? dataToSum.push(record) : null));
            rowsToSum = dataToSum;
        }
        if (rowsToSum) {
            let cancelled = 0;
            let notCancelled = 0;
            rowsToSum.map((oneRow) => {
                if (oneRow.stornovano) {
                    cancelled += 1;
                } else {
                    notCancelled += 1;
                }
            });
            setCancelSum(cancelled);
            setNotCancelSum(notCancelled);
        }
    };

    const findCampaigns = () => {
        AmaxApiService.Telesales.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    };

    const getAnswears = () => {
        if (data) {
            //make array allAnswers with format: [all records]==[[one record]]==[[[question, answear, pripad_id]]]
            let oneAnswers = []; //and array questionArray with all unique questions
            let newQuestionArray = [];
            let newAllAnswers = [];
            for (let i = 0; i < data.length; i++) {
                oneAnswers = [];
                {
                    Object.entries(data[i].vyplnenyDotaznik).map(
                        ([key, value]) => (
                            oneAnswers.push([key, value, data[i].pripadID]),
                            newQuestionArray.indexOf(key) < 0 ? newQuestionArray.push(key) : null,
                            key.indexOf()
                        )
                    );
                }
                newAllAnswers.push(oneAnswers);
            }
            setAllAnswers(newAllAnswers);
            setQuestionArray(newQuestionArray);
        }
    };

    const findInAnswers = (valueToFind) => {
        //filter records by search value
        if (data) {
            setPage(1);
            allAnswers.map((oneSet) =>
                oneSet.map((oneQuest) =>
                    //filter records with values which are THE SAME or which CONTAINS search value
                    (filterEqual && oneQuest[0].indexOf(indexSearchQuestion) == 0 && oneQuest[1] == valueToFind) ||
                    (!filterEqual &&
                        oneQuest[0].indexOf(indexSearchQuestion) == 0 &&
                        oneQuest[1].indexOf(valueToFind) >= 0)
                        ? data.map((dt) => (dt.pripadID == oneQuest[2] ? newData.push(dt) : null))
                        : null
                )
            );

            setLast(newData);
            setOldLast(newData);
        }
    };

    const answersForSelect = (e) => {
        let sAnswers = [];
        if (data) {
            //getAnswears()
            allAnswers.map((oneSet) =>
                oneSet.map((oneQuest) =>
                    oneQuest[0].indexOf(e) == 0 && !sAnswers.includes(oneQuest[1]) && oneQuest[1] != ""
                        ? sAnswers.push(oneQuest[1])
                        : null
                )
            );
            setSelectAnswers(sAnswers);
        }
    };

    ///if there is same "volany telefon", show "volany telefon" else show "telefon"
    if (data) {
        for (let i = 0; i < data.length; i++) {
            data[i].telefon = data[i].volanyTelefon ? data[i].volanyTelefon : data[i].telefon;
        }
    }

    useEffect(() => {
        setColumnSettVisible(false);
        setExportVisible(false);
        setByAnswersVisible(false);
        //setLast(data);
        resetQueFilter();
        if (data) {
            console.log(data);
            getAnswears();
        }
        setRowKeys([]);
        setPage(1);
        setSearchText("");
        setOldLast(data);
        findCampaigns();
        getSumOfCost();
        getCancelSum();
    }, [data]);

    useEffect(() => {
        getSumOfCost();
        getCancelSum();
    }, [last]);

    useEffect(() => {
        setAllAnswers([]);
        setQuestionArray([]);
        //Load campaigns to state after component mount
        findCampaigns();
        getSumOfCost();
        getCancelSum();
    }, []);

    const resetQueFilter = () => {
        setSearchValue("");
        setSearchText("");
        setLast(data);
        setOldLast(data);
        newData = [];
        setFilterEqual(false);
        setSelectAnswers([]);
        setAnswerID("");
        setIndexSearchQuestion(null);
    };

    const formItemControl = {
        style: {
            minWidth: "300px",
            width: "auto",
            marginRight: "8px",
            marginBottom: "10px",
        },
    };

    const formItemControlBig = {
        style: {
            width: "auto",
            minWidth: "300px",
            marginRight: "8px",
            marginBottom: "8px",
        },
    };

    const paddingRightLayout = {
        style: {
            paddingRight: "10px",
            color: "#0e151a",
            marginTop: "10px",
        },
    };

    const inputLayout = {
        style: {
            width: "300px",
            height: "32px",
            marginRight: "8px",
            marginBottom: "8px",
        },
    };

    const resizeModal = () => {
        setColumns(
            responsiveWidth({
                xs: xsColumns,
                sm: smColumns,
                md: mdColumns,
                lg: lgColumns,
            })
        );
        setLayout(
            responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            })
        );
    };
    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });
    }, []);

    useEffect(() => {
        if (
            columns != null &&
            columns != undefined &&
            columns.length &&
            columns.length != undefined &&
            columns.length != null
        ) {
            {
                /*if (columns.length < 7) {
        setTableExpandable(true);
      } else {
        setTableExpandable(false);
      */
            }
        }
        if (!isChanged) onResize();
    }, [columns]);

    const onResize = () => {
        if (storedColumns) {
            console.log(storedColumns);
            console.log(columns);
            let actualColumnsToChange = [];
            storedColumns.map((oneColumn) => {
                actualColumnsToChange.push(oneColumn["title"]);
            });
            setSelectedItems(actualColumnsToChange.splice(0, actualColumnsToChange.length - 2));
        } else {
            setSelectedItems(
                responsiveWidth({
                    xs: [t("Date of Sale"), t("Company"), t("Campaign")],
                    sm: [t("Date of Sale"), t("Company"), t("Campaign")],
                    md: [t("Date of Sale"), t("Company"), t("TIN"), t("Phone"), t("Campaign")],
                    lg: [
                        t("Date of Sale"),
                        t("Company"),
                        t("TIN"),
                        t("Phone"),
                        t("Price"),
                        t("Product"),
                        t("Person"),
                        t("Campaign"),
                    ],
                })
            );
        }
    };

    const handleDisplayDetail = (pripadID, record) => {
        setDisplayDetail(true);
        setDetailId(pripadID);
        setDetailData(record);
    };

    const closeDetail = () => {
        setDisplayDetail(false);
        setDetailId(null);
        setDetailData(null);
    };

    const openPrevDetail = () => {
        let allData = last ? last : data;

        allData.map((record) => {
            if (record.pripadID == detailId) {
                if (allData.indexOf(record) != 0) {
                    //setPrev(allData.indexOf(record) - 1)
                    closeDetail();
                    setDetailData(allData[allData.indexOf(record) - 1]);
                    setDetailId(allData[allData.indexOf(record) - 1].pripadID);
                    handleDisplayDetail(
                        allData[allData.indexOf(record) - 1].pripadID,
                        allData[allData.indexOf(record) - 1]
                    );
                }
            }
        });
    };

    const openNextDetail = () => {
        let allData = last ? last : data;

        allData.map((record) => {
            if (record.pripadID == detailId) {
                if (allData.indexOf(record) != allData.length - 1) {
                    //setPrev(allData.indexOf(record) - 1)
                    closeDetail();
                    setDetailData(allData[allData.indexOf(record) + 1]);
                    setDetailId(allData[allData.indexOf(record) + 1].pripadID);
                    handleDisplayDetail(
                        allData[allData.indexOf(record) + 1].pripadID,
                        allData[allData.indexOf(record) + 1]
                    );
                }
            }
        });
    };

    const exportTypeAllRecords = () => {
        if (!selectedInformation) {
            exportAll();
        } else {
            exportColumns();
        }
    };

    const exportTypeSelection = () => {
        if (!selectedInformation) {
            exportSelection();
        } else {
            exportColumnSelection();
        }
    };

    const exportAll = () => {
        const dataToExport = last ? last : data;
        let tssIds = [];
        let JSONDataArray = [];
        let TssArray = [];

        dataToExport.map((record) => {
            if (!tssIds.includes(record.idTss)) tssIds.push(record.idTss);
        });
        tssIds.map((tss) => {
            let oneTssData = [];
            dataToExport.map((record) => {
                if (record.idTss == tss) {
                    oneTssData.push(record);
                }
            });
            if (oneTssData.length > 0) {
                JSONDataArray.push(TelesalesParse(oneTssData, withoutQuestion, campaigns));
                TssArray.push(tss);
                //exportTelesales(TelesalesParse(oneTssData, withQuestion, campaigns), tss, "amax_portal_telesale", query, campaigns);
            }
        });
        exportTelesales(JSONDataArray, TssArray, "amax_portal_telesale", query, campaigns);
    };

    const exportColumns = () => {
        const dataToExport = last ? last : data;
        let tssIds = [];
        let JSONDataArray = [];
        let TssArray = [];

        dataToExport.map((record) => {
            if (!tssIds.includes(record.idTss)) tssIds.push(record.idTss);
        });
        tssIds.map((tss) => {
            let oneTssData = [];
            dataToExport.map((record) => {
                if (record.idTss == tss) {
                    oneTssData.push(record);
                }
            });
            if (oneTssData.length > 0) {
                JSONDataArray.push(TelesalesParseColumns(oneTssData, withoutQuestion, selectedItems, campaigns));
                TssArray.push(tss);
            }
        });
        exportTelesales(JSONDataArray, TssArray, "amax_portal_telesale", query, campaigns);
    };

    const exportSelection = () => {
        if (selectedRowKeys.length > 0) {
            let selectedData = [];
            const dataToExport = last ? last : data;
            let JSONDataArray = [];
            let TssArray = [];

            dataToExport.map((record) =>
                selectedRowKeys.indexOf(record.pripadID) >= 0 ? selectedData.push(record) : null
            );

            let tssIds = [];
            selectedData.map((record) => {
                if (!tssIds.includes(record.idTss)) tssIds.push(record.idTss);
            });

            tssIds.map((tss) => {
                let oneTssData = [];
                selectedData.map((record) => {
                    if (record.idTss == tss) {
                        oneTssData.push(record);
                    }
                });
                if (oneTssData.length > 0) {
                    JSONDataArray.push(TelesalesParse(oneTssData, withoutQuestion, campaigns));
                    TssArray.push(tss);
                }
            });
            exportTelesales(JSONDataArray, TssArray, "amax_portal_telesale", query, campaigns);
        }
    };

    const exportColumnSelection = () => {
        if (selectedRowKeys.length > 0) {
            const selectedData = [];
            let dataToExport = last ? last : data;
            let JSONDataArray = [];
            let TssArray = [];

            dataToExport.map((record) =>
                selectedRowKeys.indexOf(record.pripadID) >= 0 ? selectedData.push(record) : null
            );

            let tssIds = [];
            selectedData.map((record) => {
                if (!tssIds.includes(record.idTss)) tssIds.push(record.idTss);
            });
            tssIds.map((tss) => {
                let oneTssData = [];
                selectedData.map((record) => {
                    if (record.idTss == tss) {
                        oneTssData.push(record);
                    }
                });
                if (oneTssData.length > 0) {
                    JSONDataArray.push(TelesalesParseColumns(oneTssData, withoutQuestion, selectedItems, campaigns));
                    TssArray.push(tss);
                }
            });
            exportTelesales(JSONDataArray, TssArray, "amax_portal_telesale", query, campaigns);
        }
    };

    const resetChangeColumns = () => {
        //setSelectedColumns(null);
        setIsChanged(false);
        setStoredColumns(
            responsiveWidth({
                xs: xsColumns.concat([colCampaign, colStorno, colDetail]),
                sm: smColumns.concat([colCampaign, colStorno, colDetail]),
                md: mdColumns.concat([colCampaign, colStorno, colDetail]),
                lg: lgColumns.concat([colCampaign, colStorno, colDetail]),
            })
        );

        setSelectedItems(
            responsiveWidth({
                xs: [t("Date of Sale"), t("Company"), t("Campaign")],
                sm: [t("Date of Sale"), t("Company"), t("Campaign")],
                md: [t("Date of Sale"), t("Company"), t("TIN"), t("Phone"), t("Campaign")],
                lg: [
                    t("Date of Sale"),
                    t("Company"),
                    t("TIN"),
                    t("Phone"),
                    t("Price"),
                    t("Product"),
                    t("Person"),
                    t("Campaign"),
                ],
            })
        );
    };

    const findProduct = (record) => {
        let retVal = "-";
        {
            Object.entries(record.vyplnenyDotaznik).map(([key, value]) => {
                if (
                    key.toString().indexOf("produkt") > 0 ||
                    key.toString().indexOf("sluzba") > 0 ||
                    key.toString().indexOf("sluzbu") > 0 ||
                    key.toString().indexOf("službu") > 0 ||
                    key.toString().indexOf("služba") > 0
                ) {
                    retVal = value;
                }
            });
        }
        return retVal;
    };

    //////
    const [searchText, setSearchText] = useState("");
    var newLast = [];

    const handleSearch = () => {
        const dataToSearch = oldLast ? oldLast : data;
        if (dataToSearch) {
            dataToSearch.map((one) => {
                if (
                    one.nazev.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.ico.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.osoba.toString().toLowerCase().includes(searchText.toLowerCase())
                )
                    newLast.push(one);
            });
        }
        setLast(newLast);
        setPage(1);
        setPageSize(10);
    };

    const resetSearch = () => {
        setLast(oldLast);
        setSearchText("");
    };
    ////

    const changeColumns = (columns) => {
        let newColumns = [];
        columns.map((column) => {
            switch (column) {
                case t("Date of Sale"):
                    newColumns.push(colDate);
                    break;
                case t("Company"):
                    newColumns.push(colCompany);
                    break;
                case t("TIN"):
                    newColumns.push(colTIN);
                    break;
                case t("ZIP"):
                    newColumns.push(colZIP);
                    break;
                case t("Address"):
                    newColumns.push(colAddress);
                    break;
                case t("Phone"):
                    newColumns.push(colPhone);
                    break;
                case t("Email"):
                    newColumns.push(colEmail);
                    break;
                case t("Web"):
                    newColumns.push(colWeb);
                    break;
                case t("Price"):
                    newColumns.push(colPrice);
                    break;
                case t("Product"):
                    newColumns.push(colProduct);
                    break;
                case t("Campaign"):
                    newColumns.push(colCampaign);
                    break;
                case t("Person"):
                    newColumns.push(colPerson);
                    break;
                case t("Questionnaire Note"):
                    newColumns.push(colQuestionNote);
                    break;
                case t("Contact Note"):
                    newColumns.push(colContactNote);
                    break;
                case t("Customer note"):
                    newColumns.push(colCustomerNote);
                    break;
            }
        });
        newColumns.push(colStorno);
        newColumns.push(colDetail);
        setStoredColumns(newColumns);
    };

    const getCampaignName = (ID) => {
        let CampaignName = "";
        campaigns.map((campaign) => {
            if (campaign["CampaignId"] == ID) {
                CampaignName = campaign["CampaignName"];
            }
        });
        return CampaignName;
    };

    //////////////////////COLUMNS SETTINGS/////////////////////
    ///Columns setting for changing table columns
    const colDate = {
        title: t("Date of Sale"),
        dataIndex: "cas",
        key: "cas",
        sorter: (a, b) => a.cas.localeCompare(b.cas),
        sortDirections: ["descend", "ascend"],
        render: (dateString) => (
            <>
                <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                <span>{moment(dateString).format("HH:mm")}</span>
            </>
        ),
        maxWidth: "200",
    };

    const colCompany = {
        title: t("Company"),
        dataIndex: "nazev",
        key: "nazev",
        sorter: (a, b) => a.nazev.localeCompare(b.nazev),
        sortDirections: ["descend", "ascend"],
        width: "15%",
    };

    const colTIN = {
        title: t("TIN"),
        dataIndex: "ico",
        key: "ico",
        sorter: (a, b) => a.ico - b.ico,
        sortDirections: ["descend", "ascend"],
        width: "5%",
    };

    const colZIP = {
        title: t("ZIP"),
        dataIndex: "psc",
        key: "psc",
        width: "auto",
    };

    const colAddress = {
        title: t("Address"),
        dataIndex: "adresa",
        key: "adresa",
        width: "20%",
        render: (text, record) => (
            <span>
                {record.ulice} {record.obec}, {record.okres}
            </span>
        ),
    };

    const colPhone = {
        title: t("Phone"),
        dataIndex: "telefon",
        key: "telefon",
        width: "auto",
    };

    const colEmail = {
        title: t("Email"),
        dataIndex: "mail",
        key: "mail",
        width: "auto",
    };

    const colWeb = {
        title: t("Web"),
        dataIndex: "web",
        key: "web",
        width: "auto",
    };

    const colPrice = {
        title: t("Price"),
        dataIndex: "cena",
        key: "cena",
        sorter: (a, b) => a.cena - b.cena,
        sortDirections: ["descend", "ascend"],
        width: "auto",
    };

    const colProduct = {
        title: t("Product"),
        dataIndex: "Product",
        //sorter: (a, b) => a.cena - b.cena,
        //sortDirections: ["descend", "ascend"],
        render: (text, record) => <span>{findProduct(record)}</span>,
        maxWidth: "100",
    };

    const colCampaign = {
        title: t("Campaign"),
        dataIndex: "idTss",
        //key: "idTss",
        width: "auto",
        sorter: (a, b) => getCampaignName(a.idTss).localeCompare(getCampaignName(b.idTss)),
        render: (text, record) => <span>{getCampaignName(record.idTss)}</span>,
    };

    const colPerson = {
        title: t("Person"),
        dataIndex: "osoba",
        key: "osoba",
        sorter: (a, b) => a.osoba.localeCompare(b.osoba),
        sortDirections: ["descend", "ascend"],
        width: "auto",
    };

    const colQuestionNote = {
        title: t("Questionnaire Note"),
        dataIndex: "poznamka",
        key: "poznamka",
        width: "auto",
    };

    const colContactNote = {
        title: t("Contact Note"),
        dataIndex: "poznamkaKontakt",
        key: "poznamkaKontakt",
        width: "auto",
    };

    const colCustomerNote = {
        title: t("Customer note"),
        dataIndex: "poznamkaZakaznika",
        key: "poznamkaZakaznika",
        width: "auto",
    };

    const colDetail = {
        title: t("Detail"),
        dataIndex: "details",
        key: "details",
        width: "auto",
        render: (text, record) => (
            <Button title={t("Telesales Detail")} onClick={() => handleDisplayDetail(record.pripadID, record)}>
                <InfoCircleTwoTone />
            </Button>
        ),
        width: "77px",
    };

    const colStorno = {
        title: t(""),
        dataIndex: "stornovano",
        key: "stornovano",
        width: "auto",
        render: (text, record) => (
            <span>
                {!record.stornovano ? (
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                )}
            </span>
        ),
    };

    //////////LARGE SIZE OF TABLE///////////
    const lgColumns = [
        {
            title: t("Date of Sale"),
            dataIndex: "cas",
            key: "cas",
            sorter: (a, b) => a.cas.localeCompare(b.cas),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "10%",
        },
        {
            title: t("Company"),
            dataIndex: "nazev",
            key: "nazev",
            sorter: (a, b) => a.nazev.localeCompare(b.nazev),
            sortDirections: ["descend", "ascend"],
            width: "18%",
        },
        {
            title: t("TIN"),
            dataIndex: "ico",
            key: "ico",
            sorter: (a, b) => a.ico - b.ico,
            sortDirections: ["descend", "ascend"],
            width: "8%",
        },
        {
            title: t("Phone"),
            dataIndex: "telefon",
            key: "telefon",
            width: "8%",
        },
        {
            title: t("Price"),
            dataIndex: "cena",
            key: "cena",
            sorter: (a, b) => a.cena - b.cena,
            sortDirections: ["descend", "ascend"],
            width: "5%",
        },
        {
            title: t("Product"),
            dataIndex: "Product",
            //sorter: (a, b) => a.cena - b.cena,
            //sortDirections: ["descend", "ascend"],
            render: (text, record) => <span>{findProduct(record)}</span>,
            width: "15%",
        },
        {
            title: t("Person"),
            dataIndex: "osoba",
            key: "osoba",
            sorter: (a, b) => a.osoba.localeCompare(b.osoba),
            sortDirections: ["descend", "ascend"],
            width: "12%",
        },
    ];
    //////////MEDIUM SIZE OF TABLE///////////
    const mdColumns = [
        {
            title: t("Date of Sale"),
            dataIndex: "cas",
            key: "cas",
            sorter: (a, b) => a.cas.localeCompare(b.cas),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "20%",
        },
        {
            title: t("Company"),
            dataIndex: "nazev",
            key: "nazev",
            sorter: (a, b) => a.nazev.localeCompare(b.nazev),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("TIN"),
            dataIndex: "ico",
            key: "ico",
            sorter: (a, b) => a.ico - b.ico,
            sortDirections: ["descend", "ascend"],
            width: "5%",
        },
        {
            title: t("Phone"),
            dataIndex: "telefon",
            key: "telefon",
            width: "8%",
        },
    ];
    //////////SMALL SIZE OF TABLE///////////
    const smColumns = [
        {
            title: t("Date of Sale"),
            dataIndex: "cas",
            key: "cas",
            sorter: (a, b) => a.cas.localeCompare(b.cas),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "25%",
        },
        {
            title: t("Company"),
            dataIndex: "nazev",
            key: "nazev",
            sorter: (a, b) => a.nazev.localeCompare(b.nazev),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
    ];
    //////////EXTRA SMALL SIZE OF TABLE///////////
    const xsColumns = [
        {
            title: t("Date of Sale"),
            dataIndex: "cas",
            key: "cas",
            sorter: (a, b) => a.cas.localeCompare(b.cas),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "30%",
        },
        {
            title: t("Company"),
            dataIndex: "nazev",
            key: "nazev",
            sorter: (a, b) => a.nazev.localeCompare(b.nazev),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
    ];

    ///////Change columns options
    const exportOptions = [
        t("Date of Sale"),
        t("TIN"),
        t("Company"),
        t("ZIP"),
        t("Address"),
        t("Phone"),
        t("Campaing"),
        t("Email"),
        t("Web"),
        t("Person"),
        t("Price"),
        t("Product"),
        t("Campaign"),
        t("Questionnaire Note"),
        t("Contact Note"),
        t("Customer note"),
    ];
    const [selectedItems, setSelectedItems] = useState([]);
    const filteredOptions = selectedItems ? exportOptions.filter((o) => !selectedItems.includes(o)) : null;
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        getSumOfCost();
        getCancelSum();
    }, [selectedRowKeys]);

    ///***FUNKCIONALITY FOR SELECTING RECORDS FROM TABLE****
    const onSelectChange = (selectedRowKeys) => {
        setRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            {
                key: "all",
                text: t("All"),
                onSelect: () => {
                    let recordIds = [];
                    const records = last ? last : data ? data : null;
                    if (records) {
                        records.map((one) => {
                            recordIds.push(one.pripadID);
                        });
                    }
                    setRowKeys(recordIds);
                },
            },
            {
                key: "cancel",
                text: t("None"),
                onSelect: () => {
                    setRowKeys([]);
                },
            },
        ],
    };

    const sortForPrevNext = (sorter) => {
        let allData = last ? last : data;
        if (allData) {
            switch (sorter["field"]) {
                case "cas":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.cas.localeCompare(a.cas));
                    else allData.sort((a, b) => a.cas.localeCompare(b.cas));
                    break;
                case "nazev":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.nazev.localeCompare(a.nazev));
                    else allData.sort((a, b) => a.nazev.localeCompare(b.nazev));
                    break;
                case "ico":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.ico.localeCompare(a.ico));
                    else allData.sort((a, b) => a.ico.localeCompare(b.ico));
                    break;
                case "cena":
                    if (sorter["order"] == "descend")
                        allData.sort((a, b) => (a.cena && b.cena ? b.cena - a.cena : null));
                    else allData.sort((a, b) => (a.cena && b.cena ? a.cena - b.cena : null));
                    break;
                case "osoba":
                    if (sorter["order"] == "descend")
                        allData.sort((a, b) => (a.osoba && b.osoba ? b.osoba.localeCompare(a.osoba) : null));
                    else allData.sort((a, b) => (a.osoba && b.osoba ? a.osoba.localeCompare(b.osoba) : null));
                    break;
                case "idTss":
                    if (sorter["order"] == "descend")
                        allData.sort((a, b) => getCampaignName(b.idTss).localeCompare(getCampaignName(a.idTss)));
                    else allData.sort((a, b) => getCampaignName(a.idTss).localeCompare(getCampaignName(b.idTss)));
                    break;
            }
            if (sorter["order"] == undefined) allData.sort((a, b) => a.cas.localeCompare(b.cas));
            setLast(allData);
        }
    };

    return (
        <>
            <div className="big-form-switch-div">
                <div className="big-form-switch-button">
                    <button
                        className="multiple-button"
                        onClick={() => {
                            setByAnswersVisible(!byAnswersVisible);
                            setExportVisible(false);
                            setColumnSettVisible(false);
                        }}
                    >
                        {byAnswersVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Filter answers")} <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Filter answers")} <MessageFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>

                    <button
                        className="multiple-button"
                        onClick={() => {
                            setColumnSettVisible(!columnSettVisible);
                            setExportVisible(false);
                            setByAnswersVisible(false);
                        }}
                    >
                        {columnSettVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Edit columns ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Edit columns ")}
                                <SettingFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>

                    <button
                        className="multiple-button"
                        onClick={() => {
                            setExportVisible(!exportVisible);
                            setByAnswersVisible(false);
                            setColumnSettVisible(false);
                        }}
                    >
                        {exportVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Export ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Export ")}
                                <FileExcelFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>
                </div>

                {columnSettVisible && (
                    <div className="form-question" style={{ paddingTop: "10px" }}>
                        <div style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "30px" }}>
                            <p style={{ marginTop: "10px", color: "#0e151a" }}>
                                {t("To add more columns, click on the white part of the field")}
                            </p>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Choose columns"
                                value={selectedItems}
                                onChange={(e) => {
                                    setSelectedItems(e);
                                    changeColumns(e);
                                    setIsChanged(true);
                                }}
                                style={{
                                    width: "90%",
                                }}
                            >
                                {filteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                            <div style={{ marginTop: "5px" }}>
                                <Button
                                    type="primary"
                                    className="route-button"
                                    icon={<CloseCircleOutlined />}
                                    onClick={resetChangeColumns}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {exportVisible && (
                    <div className="form-question" style={{ paddingTop: "10px" }}>
                        <div style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "30px" }}>
                            <div style={{ marginTop: "5px" }}>
                                <Checkbox
                                    {...paddingRightLayout}
                                    onChange={(e) => setWithoutQuestion(e.target.checked)}
                                >
                                    {t("Export without Questionnaire")}
                                </Checkbox>

                                <Checkbox
                                    {...paddingRightLayout}
                                    onChange={(e) => setSelectedInformation(e.target.checked)}
                                >
                                    {t("Export visible columns")}
                                </Checkbox>
                                <br />
                                <Button
                                    style={{ marginTop: "22px", width: "256px" }}
                                    disabled={data ? false : true}
                                    onClick={exportTypeAllRecords}
                                    className="yellow-button"
                                    icon={<FileExcelOutlined />}
                                >
                                    {t("Export ALL records")}
                                </Button>
                                <Button
                                    style={{ width: "256px" }}
                                    disabled={data && selectedRowKeys.length > 0 ? false : true}
                                    onClick={exportTypeSelection}
                                    className="yellow-button"
                                    icon={<FileExcelOutlined />}
                                >
                                    {t("Export SELECTED records")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {byAnswersVisible && (
                <div className="form-question" style={{ paddingTop: "15px" }}>
                    <div style={{ backgroundColor: "white", paddingRight: "30px" }}>
                        <div style={{ marginTop: "5px" }}>
                            <Select //FORM FOR FILTER BY ANSWERS
                                {...formItemControlBig}
                                showSearch
                                optionFilterProp="children"
                                placeholder={t("Choose Question")}
                                onChange={(e) => {
                                    setIndexSearchQuestion(e);
                                    answersForSelect(e);
                                    setSearchValue("");
                                    setAnswerID("");
                                }}
                                //defaultValue={ indexSearchQuestion!=null ? indexSearchQuestion : t("Choose Question")}
                                value={indexSearchQuestion ? indexSearchQuestion : t("Choose Question")}
                                defaultValue={""}
                            >
                                {questionArray
                                    ? questionArray.map((dt) => (
                                          <Select.Option value={dt} key={dt}>
                                              {dt.substring(dt.indexOf(" "), dt.length - 1) +
                                                  " (" +
                                                  dt.substring(1, dt.indexOf(" ") - 1) +
                                                  ")"}
                                          </Select.Option>
                                      ))
                                    : null}
                            </Select>

                            <Select
                                {...formItemControl}
                                showSearch
                                optionFilterProp="children"
                                placeholder={t("Contains")}
                                onChange={(e) => {
                                    setFilterEqual(e);
                                }}
                                value={filterEqual}
                            >
                                <Select.Option value={false} default>
                                    {t("Contains")}
                                </Select.Option>
                                <Select.Option value={true}>{t("Is equal")}</Select.Option>
                            </Select>
                            {filterEqual ? (
                                <Select
                                    {...formItemControlBig}
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder={"odpoved"}
                                    onChange={(e) => {
                                        setSearchValue(e);
                                        setAnswerID(e);
                                    }}
                                    value={answerID ? answerID : t("Choose Answer")}
                                >
                                    {selectAnswers
                                        ? selectAnswers.map((dt) => (
                                              <Select.Option value={dt} key={dt}>
                                                  {dt}
                                              </Select.Option>
                                          ))
                                        : null}
                                </Select>
                            ) : (
                                <Input
                                    {...inputLayout}
                                    value={searchValue}
                                    type="text"
                                    onChange={(e) => setSearchValue(e.target.value)}
                                />
                            )}
                            <br />
                            <Button
                                className="yellow-button"
                                style={{ width: "140px" }}
                                htmlType="submit"
                                onClick={() => {
                                    findInAnswers(searchValue);
                                }}
                                icon={<FilterFilled />}
                            >
                                {t("Filter ")}
                            </Button>

                            <Button
                                className="ant-btn"
                                style={{ width: "140px" }}
                                type="secondary"
                                onClick={() => {
                                    resetQueFilter();
                                }}
                                icon={<CloseCircleOutlined />}
                            >
                                {t("Reset")}
                            </Button>
                        </div>
                        <br />
                    </div>
                </div>
            )}

            <TelesalesDetail
                closeDialog={() => closeDetail()}
                visible={displayDetail}
                detailId={detailId}
                detailData={detailData}
                questions={questions}
                campaigns={campaigns}
                openPrevDetail={() => openPrevDetail()}
                openNextDetail={() => openNextDetail()}
            />

            <div style={{ backgroundColor: "white" }}>
                <p style={{ marginLeft: "35px", float: "left" }}>
                    {t("Selected")} {selectedRowKeys.length} {t("from")} {last ? last.length : data ? data.length : 0}
                </p>

                <div style={{ float: "right", display: "flex", marginRight: "26px" }}>
                    <Input
                        placeholder={t("Search")}
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        onPressEnter={() => handleSearch()}
                        style={{
                            marginBottom: 8,
                            height: "24.5px",
                            width: "140px",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 25, marginLeft: "5px" }}
                    />
                    <Button
                        icon={<CloseCircleOutlined />}
                        onClick={() => resetSearch()}
                        size="small"
                        style={{ width: 25 }}
                    />
                </div>
            </div>

            <Table
                //scroll={{ x: 1300 }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "30"],
                    size: layout == "horizontal" ? "default" : "small",
                    current: page,
                    onChange: (e) => {
                        setPage(e);
                    },
                    total: last ? last.length : data ? data.length : 0,
                    showTotal: (total) => t("Total: ") + `${total} `,
                    onShowSizeChange: (current, size) => setPage(1),
                }}
                rowSelection={rowSelection}
                className="tableClass"
                tableLayout="auto"
                rowClassName={(record) => (record.stornovano ? "cancelled-row" : "")}
                dataSource={last ? [...last] : data ? [...data] : null}
                columns={
                    storedColumns
                        ? storedColumns
                        : columns
                        ? columns.concat([colCampaign, colStorno, colDetail])
                        : columns
                }
                loading={loading}
                rowKey={(record) => record.pripadID}
                //onChange?: ((pagination: PaginationConfig, filters: Record<string, React.Key[] | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: TableCurrentDataSource<...>) => void) | undefined
                onChange={(pagination, filters, sorter) => {
                    sortForPrevNext(sorter);
                }}
            />
            <br />
            <br />

            <div>
                <Descriptions
                    title={
                        selectedRowKeys.length > 0 ? t("Summary of selected Telesales") : t("Summary of all Telesales")
                    }
                    className="summarization"
                    style={{ marginLeft: "28px", marginRight: "28px" }}
                    bordered
                    layout={layout}
                >
                    <Descriptions.Item className="green" label={t("Number of Not Cancelled")} span={2}>
                        <b>{notCancelSum ? notCancelSum : "0"}</b>
                    </Descriptions.Item>
                    <Descriptions.Item className="green" label={t("Price of Not Cancelled")} span={2}>
                        <b>{costSum.toLocaleString(undefined, { minimumFractionDigits: 0 })}</b>
                    </Descriptions.Item>

                    <Descriptions.Item className="red" label={t("Number of Cancelled")} span={2}>
                        <b>{cancelSum ? cancelSum : "0"}</b>
                    </Descriptions.Item>
                    <Descriptions.Item className="red" label={t("Price of Cancelled")} span={2}>
                        <b>{costCancelSum.toLocaleString(undefined, { minimumFractionDigits: 0 })}</b>
                    </Descriptions.Item>
                </Descriptions>
            </div>

            <div className="btn-group-height"></div>
        </>
    );
};
export default connect(mapStateToProps)(TelesalesTable);

// Will run every time state change is detected
function mapStateToProps(state) {
    // If form issued a queue, and 'QUEUED' is detected in state
    // Search and Query data to state
    if (state.SearchQuery.formName == "TELESALES" && state.SearchQuery.query && state.SearchQuery.status == "QUEUED") {
        SearchAndQueryData(3, state.SearchQuery.query);
        return { loading: true };
    }
    // If data are loaded in state ('READY') detected
    // set data to component's props
    if (
        (state.SearchQuery.formName == "TELESALES") & (state.SearchQuery.status == "READY") &&
        state.SearchQuery.result
    ) {
        var allData = [].concat.apply([], state.SearchQuery.result);
        allData.sort((a, b) => a.cas.localeCompare(b.cas));

        return { data: allData, loading: false, query: state.SearchQuery.query };
    }

    // If no queue detected and no data detected
    // set null to data, and disable loading
    return { data: null, loading: false };
}
