import React from "react";
import Chart from "react-apexcharts";

export default class Bar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [
                {
                    data: this.props.chartSeries,
                },
            ],
            options: {
                chart: {
                    type: "bar",
                    height: 200,
                },
                plotOptions: {
                    bar: {
                        borderRadius: 4,
                        horizontal: true,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    categories: this.props.chartLabels,
                },
            },
        };
    }

    render() {
        return (
            <div>
                <Chart options={this.state.options} series={this.state.series} type="bar" height={100} />
            </div>
        );
    }
}
