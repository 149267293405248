import React, { memo, useState } from "react";
//Antd
import { PageHeader, Button, Tooltip } from "antd";
//Layout
import LayoutWrapper from "../components/layouts/LayoutWrapper";
//Components
import StornoForm from "../components/common/forms/StornoForm";
import StornoTable from "../components/common/tables/StornoTable";
import ErrorView from "../components/common/ErrorView";
//Language
import t from "../components/providers/LanguageProvider";
import HelpStorno from "../components/common/Modals/HelpStorno";

const StornoPage = () => {
    const [displayHelp, setDisplayHelp] = useState(false);

    const closeHelp = () => {
        setDisplayHelp(false);
    };

    const handleHelp = () => {
        setDisplayHelp(true);
    };

    return (
        <>
            <HelpStorno key="Help" closeDialog={() => closeHelp()} visible={displayHelp} />
            <LayoutWrapper>
                <PageHeader
                    onBack={() => {
                        window.location = "/";
                    }}
                    title={
                        <>
                            <Tooltip placement="leftBottom" title={t("Help")}>
                                {t("Cancellation")}
                                <Button
                                    className="mobile-help"
                                    type="secondary"
                                    size="small"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => handleHelp()}
                                >
                                    ?
                                </Button>
                            </Tooltip>
                        </>
                    }
                    className="ant-page-header-half-radius ant-page-header-boxShadow"
                    extra={[
                        <Tooltip placement="leftBottom" title={t("Help")}>
                            <Button className="desktop-help" size="small" onClick={() => handleHelp()}>
                                ?
                            </Button>
                        </Tooltip>,
                    ]}
                >
                    <StornoForm />
                    <ErrorView type="main" />
                </PageHeader>
                <StornoTable />
            </LayoutWrapper>
        </>
    );
};

export default memo(StornoPage);
