import React, { useState, useEffect } from "react";
//Redux
import AmaxApiService from "../../../services/AmaxApiService";
import { resetErrorView } from "../../../services/ErrorHandler";
import { store } from "../../providers/ReduxProvider";
//Antd
import { Button, Form, Select, Input, List, Radio, Tooltip, Collapse } from "antd";
import { UpOutlined, DownOutlined, ArrowUpOutlined, PlusOutlined } from "@ant-design/icons";
//Language
import t from "../../providers/LanguageProvider";
import { read, utils } from "xlsx";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
// locale for czech calendar
import { cs, enGB } from "date-fns/locale";
//time
import { DefinedRange, Calendar } from "react-date-range";
import { addDays, startOfWeek, getDate, getMonth, getYear, isBefore, addYears, addMonths } from "date-fns";
import { push } from "react-burger-menu";

const { TextArea } = Input;

const BlackListImportForm = () => {
    const [form] = Form.useForm();
    const [insertType, setInsertType] = useState("1");
    const [campaigns, setCampaigns] = useState(null);
    const [TssId, setTssId] = useState(null);
    const [TssIds, setTssIds] = useState([]);
    const [MeetingType, setMeetingType] = useState("ContactDate");
    const [selectedCampaign, setSelectedCampaign] = useState(true);
    const [inputText, setInputText] = useState("");

    const [listContent, setListContent] = useState([]);
    const [dataToAdd, setDataToAdd] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [layout, setLayout] = useState("vertical");

    const [fromDate, setFromDate] = useState(addDays(new window.Date(), 1));
    const [untilDate, setUntilDate] = useState("2");
    const [openCalendar, setOpenCalendar] = useState(false); // visibility of popup range
    const inputDate = getDate(fromDate) + ". " + (getMonth(fromDate) + 1) + ". " + getYear(fromDate);
    const [dateIsBefore, setDateIsBefore] = useState(false);

    const [succesfullyAdded, setSuccesfullyAdded] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [succesMessage, setSuccesMessage] = useState(false);
    const [TINToAdd, setTINToAdd] = useState(true);

    const setCampaignsIds = (campaignID) => {
        if ((campaignID == 0 || campaignID.indexOf(0) >= 0) && campaigns) {
            let ids = [];
            campaigns.map((campaign) => {
                ids.push(campaign.CampaignId);
            });
            setTssIds(ids);
        } else {
            setTssIds(campaignID);
        }
    };

    const onCampaignChange = (campaignID) => {
        if (campaignID.indexOf(0) >= 0) {
            let campaignsIds = [];
            campaigns.map((one) => {
                campaignsIds.push(one.CampaignId);
            });
            form.setFieldsValue({ TssId: campaignsIds });
        }
    };

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const formCampaignLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "300px",
        },
    };

    const inputLayout = {
        style: {
            height: "30px",
            maxWidth: "300px",
        },
    };

    const formItemControl = {
        style: {
            maxWidth: "300px",
        },
    };

    const resizeModal = () => {
        setLayout(
            responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            })
        );
    };

    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });
    }, []);

    /**
     * Runs on form submit, evaluates fields and sets SearchQuery
     *
     * @param {Object} values
     */
    const onFinish = (values) => {
        resetErrorView();

        if (TssIds.length < 1 || listContent.length < 1) {
            if (TssIds.length < 1) setSelectedCampaign(false);
            if (listContent.length < 1) setTINToAdd(false);
            //setErrorMessage(true)
        } else {
            let until = null;
            switch (untilDate) {
                case "0":
                    until = addMonths(fromDate, 1);
                    break;
                case "1":
                    until = addYears(fromDate, 1);
                    break;
                case "2":
                    until = addYears(fromDate, 2);
                    break;
            }

            values = {
                ...values,
                Identifiers: listContent,
                From: fromDate,
                Until: until,
            };
            console.log("Received values of form after edit: ", values);

            AmaxApiService.Blacklist.AddToBlacklist(values).then((response) => {
                console.log(response);
                setSuccesMessage(true);
                setErrorMessage(false);
                setListContent([]);

                {
                    /*if (response.status === 200) {
          setSuccesfullyAdded(true);
          setListContent(null);
          setDataToAdd(null);
        */
                }
            });
        }
        //store.dispatch(setSearchQuery("TELESALES", values));
        //store.dispatch(setUserTelesalesCampaigns(campaigns, TssIds));
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    useEffect(() => {
        resetErrorView();
        //Load campaigns to state after component mount
        AmaxApiService.Telesales.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    }, []);

    const changeFromDate = (date) => {
        if (isBefore(date, new window.Date())) {
            setDateIsBefore(true);
        } else {
            setFromDate(date);
            setOpenCalendar(!openCalendar);
            setDateIsBefore(false);
        }
    };

    const inputTextChange = (add) => {
        console.log(inputText);
        const result = inputText.split(/\r?\n/);
        console.log(result);
        let onlyUniqueTINs = [];
        if (add) {
            onlyUniqueTINs = listContent;
        }

        result.map((one) => {
            if (onlyUniqueTINs.indexOf(one) < 0 && !isNaN(one)) {
                onlyUniqueTINs.push(one);
            }
        });
        setListContent(onlyUniqueTINs);
        setInputText("");
        setTINToAdd(true);
    };

    //const [importedData, setImportedData] = useState([])
    const readfile = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    //setImportedData(rows)
                    console.log("tady", rows);
                    let UniqueIdentificator = [];
                    rows.map((oneRow) => {
                        if (
                            oneRow["ico"] &&
                            !isNaN(oneRow["ico"]) &&
                            UniqueIdentificator.indexOf(String(oneRow["ico"])) < 0
                        ) {
                            UniqueIdentificator.push(String(oneRow["ico"]));
                        }
                    });
                    //setListContent(UniqueIdentificator)
                    setDataToAdd(UniqueIdentificator);
                }
            };
            reader.readAsArrayBuffer(file);
        }
    };

    const addFromFile = (add) => {
        console.log("from file old", dataToAdd);
        let newTINs = [];
        if (add) {
            setListContent(Array.from(new Set(listContent.concat(dataToAdd))));
        } else {
            setListContent(dataToAdd);
        }
        setTINToAdd(true);
    };

    const onDeleteClick = (item) => {
        console.log(item);
        let filtered = listContent.filter((id) => id != item);
        console.log(filtered);
        setListContent(filtered);
    };

    const handleDeleteInput = () => {
        setInputText("");
    };

    const handleUntilChange = (value) => {
        setUntilDate(value);
    };

    return (
        <>
            <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                {succesMessage && (
                    <div style={{ background: "#50C878", padding: "10px" }}>ICO byla uspesne vlozena!</div>
                )}
                {errorMessage && <div style={{ background: "#DC143C", padding: "10px" }}>ICO nebyla vlozena!</div>}
                <Form
                    form={form}
                    id="add-to-blacklist"
                    className="form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        Idtsses: TssIds,
                        From: fromDate,
                        Until: untilDate,
                    }}
                >
                    <div className="form-div">
                        <Form.Item
                            validateStatus={selectedCampaign ? "success" : "error"}
                            {...formCampaignLayout}
                            label={t("Campaign")}
                            name="Idtsses"
                            rules={[{ required: true, message: t("Please select a Campaign") }]}
                        >
                            <Select
                                className="campaign-multiple"
                                maxTagCount={4}
                                //showArrow={true}
                                //suffixIcon={<DownCircleTwoTone />}
                                showSearch
                                mode="multiple"
                                allowClear
                                value={TssIds}
                                defaultValue={TssIds}
                                values={TssIds}
                                placeholder={t("Select Campaigns")}
                                optionFilterProp="children"
                                defaultActiveFirstOption
                                loading={campaigns ? false : true}
                                disabled={campaigns ? false : true}
                                onChange={(campaignID) => {
                                    if (campaignID.length == 0) {
                                        setCampaignsIds([]);
                                        setSelectedCampaign(false);
                                    } else {
                                        console.log(campaignID);
                                        setCampaignsIds(campaignID);
                                        setSelectedCampaign(true);
                                        onCampaignChange(campaignID);
                                    }
                                }}
                            >
                                <Select.Option value={0} style={{ background: "Gainsboro" }}>
                                    {t("Select all Campaigns")}
                                </Select.Option>
                                {campaigns
                                    ? campaigns.map((campaign) => (
                                          <Select.Option selected key={campaign.CampaignId} value={campaign.CampaignId}>
                                              {campaign.CampaignName}
                                          </Select.Option>
                                      ))
                                    : null}
                            </Select>
                        </Form.Item>

                        <Form.Item {...formItemLayout} label={t("Insert")}>
                            <Radio.Group
                                buttonStyle="outline"
                                onChange={(e) => {
                                    setInsertType(e.target.value);
                                }}
                                defaultValue={insertType}
                                value={insertType}
                            >
                                <Radio.Button style={{ width: "150px" }} selected value="1">
                                    {t("By text")}
                                </Radio.Button>
                                <Radio.Button style={{ width: "150px" }} value="0">
                                    {t("By file")}
                                </Radio.Button>
                            </Radio.Group>

                            {insertType == "1" ? (
                                <>
                                    <TextArea
                                        rows={4}
                                        //allowClear
                                        placeholder="Napiste jedno ico na jeden radek napr:
                22222222
                33333333"
                                        value={inputText}
                                        style={{ marginTop: "12px", maxWidth: "300px" }}
                                        onChange={(e) => {
                                            setInputText(e.target.value);
                                        }}
                                    />
                                    <br />
                                    <Tooltip placement="bottomLeft" title="Prida zadane unikatni ica k drive pridanym">
                                        <Button onClick={() => inputTextChange(true)} icon={<PlusOutlined />}>
                                            {t("Add")}
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        placement="bottomLeft"
                                        title="Smaze predchozi a nahraje pouze zadana unikatni ica"
                                    >
                                        <Button onClick={() => inputTextChange(false)} icon={<ArrowUpOutlined />}>
                                            {t("Upload")}
                                        </Button>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <input
                                        style={{ marginTop: "12px", maxWidth: "300px" }}
                                        type="file"
                                        onChange={readfile}
                                        accept=".xlsx"
                                        id="fileUpload"
                                    />
                                    <br />
                                    <Tooltip placement="bottomLeft" title="Prida zadane unikatni ica k drive pridanym">
                                        <Button onClick={() => addFromFile(true)} icon={<PlusOutlined />}>
                                            {t("Add")}
                                        </Button>
                                    </Tooltip>
                                    <Tooltip
                                        placement="bottomLeft"
                                        title="Smaze predchozi a nahraje pouze zadana unikatni ica"
                                    >
                                        <Button onClick={() => addFromFile(false)} icon={<ArrowUpOutlined />}>
                                            {t("Upload")}
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </Form.Item>

                        {!TINToAdd && (
                            <p style={{ textAlign: "left", color: "red" }}>
                                Pro vlozeni je treba zadat alespon jedno ICO
                            </p>
                        )}

                        {/*<List style={{maxWidth: "500px", paddingBottom: "20px", marginTop: "50px"}}
          size="small"
          className="left-pagination"
          header={<div>Nahravana IČO</div>}
          //footer={<div>Footer</div>}
          dataSource={listContent}
          renderItem={(item) => 
            <List.Item 
              extra={<Button onClick={() => onDeleteClick(item)} size="small">Odstranit</Button>}
            >
              {item}
            </List.Item>}
          pagination={{ 
            defaultPageSize: 5, 
            showSizeChanger: true, 
            pageSizeOptions: ['5', '10', '20', '30'],
            size: "small",
            current: page,
            onChange:(e)=>{setPage(e)},
            total: listContent ? listContent.length : 0,
            showTotal: (total) => t("Total: ") + `${total} `,
            onShowSizeChange: (current, size) => setPage(1)
          }}
        />
        <Button 
          onClick={() => {
            setListContent([]);
            handleDeleteInput();
          }}
        >
          Smazat vse
        </Button>
        */}
                    </div>

                    <div className="left-padding responsive-advanced-up">
                        <Form.Item
                            className="form-advanced"
                            {...formItemLayout}
                            label={<div>{t("From")}</div>}
                            rules={[{ required: true, message: t("Please enter Company ID") }]}
                        >
                            <Input.Group>
                                <Input
                                    {...inputLayout}
                                    value={inputDate}
                                    onClick={() => setOpenCalendar(!openCalendar)}
                                    addonAfter={
                                        openCalendar ? (
                                            <UpOutlined onClick={() => setOpenCalendar(!openCalendar)} />
                                        ) : (
                                            <DownOutlined onClick={() => setOpenCalendar(!openCalendar)} />
                                        )
                                    }
                                />
                            </Input.Group>
                            {openCalendar && (
                                <Calendar
                                    style={{ width: "300px", marginTop: "0px" }}
                                    onChange={(item) => changeFromDate(item)}
                                    locale={store.getState().Logic.language == "cs" ? cs : enGB}
                                    date={fromDate}
                                />
                            )}
                            {dateIsBefore && (
                                <p style={{ color: "red" }}>{t("TINS can be added tomorrow at the earliest")}</p>
                            )}
                        </Form.Item>
                        <div style={{ height: "9px" }}></div>

                        <Form.Item className="form-advanced" {...formItemLayout} label={<div>{t("Duration")}</div>}>
                            <Select
                                {...formItemControl}
                                onChange={handleUntilChange}
                                defaultValue={"2"}
                                placeholder={t("Campaign")}
                                optionFilterProp="children"
                            >
                                <Select.Option value="0">{t("Month")}</Select.Option>
                                <Select.Option value="1">{t("Year")}</Select.Option>
                                <Select.Option value="2">{t("2 Years")}</Select.Option>
                                <Select.Option value="3">{t("Forever")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </div>

                    <Collapse //COLLAPSE ADVANCED FORM ITEMS FOR MOBILE
                        className="responsive-advanced-down"
                        bordered={false}
                        expandIconPosition={"left"}
                    >
                        <Collapse.Panel header={t("Advanced")} key="1">
                            <Form.Item
                                className="form-advanced"
                                {...formItemLayout}
                                label={<div>{t("From")}</div>}
                                rules={[{ required: true, message: t("Please enter Company ID") }]}
                            >
                                <Input.Group>
                                    <Input
                                        {...inputLayout}
                                        value={inputDate}
                                        onClick={() => setOpenCalendar(!openCalendar)}
                                        addonAfter={
                                            openCalendar ? (
                                                <UpOutlined onClick={() => setOpenCalendar(!openCalendar)} />
                                            ) : (
                                                <DownOutlined onClick={() => setOpenCalendar(!openCalendar)} />
                                            )
                                        }
                                    />
                                </Input.Group>
                                {openCalendar && (
                                    <Calendar
                                        style={{ width: "300px", marginTop: "0px" }}
                                        onChange={(item) => changeFromDate(item)}
                                        locale={store.getState().Logic.language == "cs" ? cs : enGB}
                                        date={fromDate}
                                    />
                                )}
                                {dateIsBefore && (
                                    <p style={{ color: "red" }}>{t("TINS can be added tomorrow at the earliest")}</p>
                                )}
                            </Form.Item>
                            <div style={{ height: "9px" }}></div>

                            <Form.Item className="form-advanced" {...formItemLayout} label={<div>{t("Trvani")}</div>}>
                                <Select
                                    {...formItemControl}
                                    onChange={handleUntilChange}
                                    defaultValue={"2"}
                                    placeholder={t("Campaign")}
                                    optionFilterProp="children"
                                >
                                    <Select.Option value="0">{t("Month")}</Select.Option>
                                    <Select.Option value="1">{t("Year")}</Select.Option>
                                    <Select.Option value="2">{t("2 Years")}</Select.Option>
                                    <Select.Option value="3">{t("Forever")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Collapse.Panel>
                    </Collapse>
                </Form>

                {/*<div style={{display: "flex", paddingTop: "12px"}}>
        <Form.Item {...formItemLayout}>
          <Button 
            type="primary" 
            htmlType="submit"
            icon={<PlusOutlined style={{paddingRight:"10px"}}/>}

          >
            <b>{t("Upload records")}</b>
          </Button>
        </Form.Item>
        <Form.Item {...formItemLayout}>
          <Button 
            type="primary" 
            htmlType="submit"
            icon={<MinusOutlined style={{paddingRight:"10px"}}/>}

          >
            <b>{t("Odebrat záznamy")}</b>
          </Button>
        </Form.Item>
      </div>*/}

                {/*<table style={{marginBottom: "24px", marginTop: "12px"}}>
        <tr>
          <th>ico</th>
        </tr>
        {
          importedData.map(x => {
            return <tr>
              <td>{x.ico}</td>
            </tr>
          })
        }
        {console.log(importedData)}
      </table>*/}

                {listContent.length > 0 && (
                    <List
                        style={{
                            maxWidth: "400px",
                            background: "#ececea",
                            marginBottom: "20px",
                            padding: "20px",
                            marginTop: "40px",
                        }}
                        size="small"
                        className="left-pagination"
                        header={
                            <div>
                                {t("Entered")} {listContent ? listContent.length : 0} {t(" unique TINs")}
                            </div>
                        }
                        //footer={<div>Footer</div>}
                        dataSource={listContent}
                        renderItem={(item) => (
                            <List.Item
                                extra={
                                    <Button onClick={() => onDeleteClick(item)} size="small">
                                        {t("Delete")}
                                    </Button>
                                }
                            >
                                {item}
                            </List.Item>
                        )}
                        pagination={{
                            defaultPageSize: 5,
                            showSizeChanger: true,
                            pageSizeOptions: ["5", "10", "20", "30"],
                            size: "small",
                            current: page,
                            onChange: (e) => {
                                setPage(e);
                            },
                            //total: listContent ? listContent.length : 0,
                            //showTotal: (total) => t("Total: ") + `${total} ` + '\n',
                            onShowSizeChange: (current, size) => setPage(1),
                        }}
                    />
                )}

                <div style={{ marginTop: "12px", float: "left" }}>
                    <Button form="add-to-blacklist" type="primary" htmlType="submit">
                        {t("Intert into Blacklist")}
                    </Button>
                    {listContent.length > 0 && (
                        <Button
                            onClick={() => {
                                setListContent([]);
                                handleDeleteInput();
                            }}
                        >
                            {t("Delete all")}
                        </Button>
                    )}
                </div>

                <br />
                <div className="btn-group-height"></div>
            </div>
        </>
    );
};

export default BlackListImportForm;
