//File saver
import { saveAs } from "file-saver";
//Moment
import moment from "moment";
//const utf8 = require('utf8')
//Language
import t from "../../providers/LanguageProvider";

import * as XLSX from "xlsx";

/**
 * Creates a CSV export file
 *
 * @param {Object | Array} JSONData The report data
 * @param {Number} TssId Campaign ID to export multiple campaigns
 * @param {*} ShowLabel
 */
const exportTelesales = (JSONData, TssId, ShowLabel = null, query, campaigns) => {
    const StartDateQ = moment(query[0]["Date"][0]["startDate"]).format("DD.MM.YYYY");
    const EndDateQ = moment(query[0]["Date"][0]["endDate"]).format("DD.MM.YYYY");

    //const searchedQuery = t("Campaign") + ": " + CampaignName + "\n" + t("Date of Sale") + ": " + StartDateQ + " - " + EndDateQ
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    //let arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

    {
        /*
  //This condition will generate the Label/Header
  if (ShowLabel) {
    let row = "";
    //This loop will extract the label from 1st index of on array
    for (let index in arrData[0]) {
      //Now convert each value to string and semicolon-seprated
      row += index + ";";
    }
    row = row.slice(0, -1);
    //append Label row with line break
    CSV += row + "\r\n";
  }
  //1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = "";
    //2nd loop will extract each column and convert it in string semicolon-seprated
    for (let index in arrData[i]) {
      row += '"' + arrData[i][index] + '";';
    }
    row.slice(0, row.length - 1);
    //add a line break after each row
    CSV += row + "\r\n";
  }
  if (CSV == "") {
    alert("Invalid data");
    return;
  }

  var blob = new Blob(
    [
      //new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
      CSV,
    ],
    {
      //encoding: "UTF-8",
      //type: "text/csv;charset=UTF-8",
      type: "application/vnd.ms-excel;charset=utf-8",
      //type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
    }
  );

  saveAs(blob, TssId + "-" + "(" + CampaignName + ")" +"-telesales" + time +".xls");
  */
    }

    const time = moment().format("DD-MM-YYYY") + "_" + moment().format("HH-mm");
    let filterContent = [];
    let wb = XLSX.utils.book_new();

    for (let i = 0; i < JSONData.length; i++) {
        let filterRow = {};
        filterRow[t("Date of Sale")] = StartDateQ + " - " + EndDateQ;
        filterRow["ID kampaně"] = TssId[i];
        filterRow["Název kampaně"] = getCampaignName(campaigns, TssId[i]);
        filterRow[t("Display")] =
            query[0]["CancellationStatus"] == 0
                ? t("All")
                : query[0]["CancellationStatus"] == 1
                ? t("Not Cancelled")
                : t("Cancelled ");
        filterContent.push(filterRow);

        let ws = XLSX.utils.json_to_sheet(JSONData[i]);
        XLSX.utils.book_append_sheet(
            wb,
            ws,
            (TssId[i] + " - " + getCampaignName(campaigns, TssId[i]).replaceAll("/", "|")).substring(0, 25)
        );
    }

    let filter = XLSX.utils.json_to_sheet(filterContent);
    XLSX.utils.book_append_sheet(wb, filter, "Filtr");

    XLSX.writeFile(wb, "(" + TssId + ")" + "telesale" + time + ".xlsx");
};

export default exportTelesales;

const getCampaignName = (campaigns, ID) => {
    let CampaignName = "";
    campaigns.map((campaign) => {
        if (campaign["CampaignId"] == ID) {
            CampaignName = campaign["CampaignName"];
        }
    });
    return CampaignName;
};

export const printCampaignsTextFile = (tssIds, campaigns) => {
    console.log(tssIds);
    console.log(campaigns);

    let fileContent = "";

    tssIds.map((oneTss) => {
        let campaignName = "";
        campaigns.map((campaign) => {
            if (campaign["CampaignId"] == oneTss) campaignName = campaign["CampaignName"];
        });
        fileContent += oneTss + " - " + campaignName + "\r\n";
    });

    var blob = new Blob([fileContent], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "Nazvy_kampani.txt");
};

/**
 * Parses unforrmated JSON Object to a formatted one
 *
 * @function TelesalesParse
 * @param {Object} unformattedData
 * @returns {Object} Formatted data
 */
export const TelesalesParse = (unformattedData, withoutQuestion, campaigns) => {
    if (!unformattedData) throw "Got no object to parse.";

    return unformattedData.map((telesale) => {
        let set = {};
        set[t("Cancelled")] = telesale.stornovano ? "Ano" : " ";
        set[t("Date of Sale")] = moment(telesale.cas).format("DD.MM.YYYY") + " " + moment(telesale.cas).format("HH:mm");
        set[t("Price")] = telesale.cena;
        set[t("Product")] = findProduct(telesale);
        set[t("Campaign")] = getCampaignName(campaigns, telesale.idTss);
        set[t("TIN")] = telesale.ico;
        set[t("Company")] = telesale.nazev;
        set[t("ZIP")] = telesale.psc;
        set[t("Person")] = telesale.osoba;
        set[t("Phone")] = telesale.volanyTelefon ? telesale.volanyTelefon : telesale.telefon;
        set[t("Email")] = telesale.mail;
        set[t("Web")] = telesale.web;
        set[t("Questionnaire Note")] = telesale.poznamka;
        set[t("Contact Note")] = telesale.poznamkaKontakt;
        set[t("Customer note")] = telesale.poznamkaZakaznika;

        if (!withoutQuestion) {
            {
                Object.entries(telesale.vyplnenyDotaznik).map((question) => {
                    let newKey =
                        question[0].indexOf(" ") > 0
                            ? question[0]
                                  .substring(question[0].indexOf(", ") + 1, question[0].length - 1)
                                  .replace(",", "- ")
                            : question[0];
                    let newValue = question[1] ? question[1].replace(",", "- ") : "-";
                    set[newKey.replace(/\n/g, " ")] = newValue;
                });
            }
        }
        return set;
    });
};

const findProduct = (record) => {
    let retVal = "-";
    {
        Object.entries(record.vyplnenyDotaznik).map(([key, value]) => {
            if (key.toString().indexOf("produkt") > 0 || key.toString().indexOf("sluzba") > 0) {
                retVal = value;
            }
        });
    }
    return retVal;
};

/**
 * Parses unforrmated JSON Object to a formatted one
 *
 * @function TelesalesParseColumns
 * @param {Object} unformattedData
 * @returns {Object} Formatted data
 */
export const TelesalesParseColumns = (unformattedData, withoutQuestion, columns, campaigns) => {
    if (!unformattedData) throw "Got no object to parse.";

    return unformattedData.map((telesale) => {
        let set = {};
        set[t("Cancelled")] = telesale.stornovano ? "Ano" : " ";
        columns.map((column) => {
            switch (column) {
                case t("Date of Sale"):
                    set[t("Date of Sale")] =
                        moment(telesale.cas).format("DD.MM.YYYY") + " " + moment(telesale.cas).format("HH:mm");
                    break;
                case t("Price"):
                    set[t("Price")] = telesale.cena;
                    break;
                case t("Product"):
                    set[t("Product")] = findProduct(telesale);
                    break;
                case t("Campaign"):
                    set[t("Campaign")] = getCampaignName(campaigns, telesale.idTss);
                    break;
                case t("TIN"):
                    set[t("TIN")] = telesale.ico;
                    break;
                case t("Company"):
                    set[t("Company")] = telesale.nazev;
                    break;
                case t("ZIP"):
                    set[t("ZIP")] = telesale.psc;
                    break;
                case t("Person"):
                    set[t("Person")] = telesale.osoba;
                    break;
                case t("Phone"):
                    set[t("Phone")] = telesale.volanyTelefon ? telesale.volanyTelefon : telesale.telefon;
                    break;
                case t("Email"):
                    set[t("Email")] = telesale.mail;
                    break;
                case t("Web"):
                    set[t("Web")] = telesale.web;
                    break;
                case t("Questionnaire Note"):
                    set[t("Questionnaire Note")] = telesale.poznamka;
                    break;
                case t("Contact Note"):
                    set[t("Contact Note")] = telesale.poznamkaKontakt;
                    break;
                case t("Customer note"):
                    set[t("Customer note")] = telesale.poznamkaZakaznika;
                    break;
            }
        });
        if (!withoutQuestion) {
            {
                Object.entries(telesale.vyplnenyDotaznik).map((question) => {
                    let newKey =
                        question[0].indexOf(" ") > 0
                            ? question[0]
                                  .substring(question[0].indexOf(", ") + 1, question[0].length - 1)
                                  .replace(",", "- ")
                            : question[0];
                    let newValue = question[1] ? question[1].replace(",", "- ") : "-";
                    set[newKey.replace(/\n/g, " ")] = newValue;
                });
            }
        }

        return set;
    });
};
