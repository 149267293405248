import React from "react";
//Antd
import { Button, Skeleton, Modal, Typography, Tabs } from "antd";
import { CalendarTwoTone, CloseCircleOutlined } from "@ant-design/icons";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//Images
import Form from "../../../images/help-images/reporthelp-form.PNG";
import FormMobile from "../../../images/help-images/reporthelp-form-mobile.PNG";
import Route from "../../../images/help-images/reporthelp-routeE.png";
import RouteMobile from "../../../images/help-images/route-mobile.PNG";
import ColumnHiding from "../../../images/help-images/columnhiding.png";
import ColumnHidingMobile from "../../../images/help-images/columnhiding-mobile.png";
import Table from "../../../images/help-images/table.png";
import TableMobile from "../../../images/help-images/table-mobileE.png";
import Export from "../../../images/help-images/reporthelp-export.PNG";
import ExportMobile from "../../../images/help-images/reporthelp-export-mobile.PNG";
import Detail from "../../../images/help-images/reporthelp-detailE.png";
import DetailMobile from "../../../images/help-images/reporthelp-detailE-mobile.png";

const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;

const paragraphLayout = {
    style: {
        textAlign: "left",
        color: "black",
        fontSize: "14px",
        padding: "10px",
    },
};

const headdingLayout = {
    style: {
        color: "#48a3d8",
        fontSize: "24px",
    },
};

class HelpMeetings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            data: null,
            layout: "vertical",
            visible: false,
        };
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }
    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.record) {
            this.setState({ data: newProps.record });
        }
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    closeDialog() {
        this.setState({
            ChangeMeetingDateModal: false,
            MeetingReclamationModal: false,
        });
    }

    render() {
        const footer = [
            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={t("Nápověda pro schůzky")}
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        <div className="help-paragraph">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="" key="0"></TabPane>
                                <TabPane tab="Filtrování" key="1">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Filtrování reportů schůzek")}</h2>
                                        <p>
                                            Nejprve je potřeba vyfiltrovat Vámi požadovaná data, která se následně
                                            zobrazí v tabulce a umožní další práci.
                                        </p>
                                        <img src={Form} alt="form" className="help-image-desktop" />
                                        <p>
                                            Formulář pro filtrování je možné zobrazit/skrýt kliknutím na tlačítko
                                            <b> Filtrovat schůzky/Skrýt filtrování</b>. Formulář se skryje automaticky
                                            po úspěšném vyfiltrování.
                                        </p>
                                        <p>
                                            <b>Pro vyhledání dat je třeba:</b>
                                        </p>
                                        <p>
                                            - Zvolit <b> kampaň</b> z nabízeného seznamu. Tím se zpřístupní výběr{" "}
                                            <b> obchodního zástupce</b> dané kampaně, zástupce není nutné vybírat, v
                                            takovém případě se zobrazí schůzky všech zástupců.
                                        </p>
                                        <p>
                                            - Vybrat z kalendáře <b> termín</b> uskutečnění schůzky. To lze provést
                                            kliknutím na počáteční a koncové datum požadovaného termínu v zobrazeném
                                            kalendáři nebo výběrem některého z předdefinovaných termínů. Zvolený termín
                                            musí být kratší než 3 měsíce.
                                        </p>
                                        <img src={FormMobile} alt="form" className="help-image-mobile" />
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Tabulka" key="2">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Práce s tabulkou")}</h2>
                                        <img src={Table} alt="table" className="help-image-desktop" />
                                        <img src={TableMobile} alt="table" className="help-image-mobile" />
                                        <p>
                                            <b>1. </b> Vlevo nad tabulkou je zobrazen počet vybraných záznamů tabulky a
                                            celkový počet vyfiltrovaných záznamů.
                                        </p>
                                        <p>
                                            <b>2.</b> Záznamy z tabulky lze vybrat pro následný export vybraných záznamů
                                            do souboru .xlsx. Data jsou vybrána kliknutím na čtvereček v prvním sloupci
                                            daného záznamu. Všechny záznamy na aktuální stránce tabulky
                                            vybereme/odvybereme kliknutím na čtvereček v záhlaví tabulky. Kliknutím na
                                            šipku vedle čtverečku v záhlaví tabulky lze vybrat všechna data z celé
                                            tabulky nebo zrušit celý výběr.
                                        </p>
                                        <p>
                                            <b>3. </b> Kliknutím na <b>šipečky</b> za názvy sloupců je možné měnit
                                            seřazení dat v tabulce. Defaultně jsou data seřazená podle data schůzky
                                            vzestupně. Záznamy v tabulce je možné řadit podle
                                            <b>
                                                {" "}
                                                Data schůzky, Data sjednání, Názvu firmy, IČO, Osoby a Obchodního
                                                zástupce{" "}
                                            </b>
                                            . Sloupec, podle kterého jsou data ručně seřazena, je podbarven.
                                        </p>
                                        <p>
                                            <b>4. </b> V záznamech tabulky lze hledat pomocí vyhledávače nacházejícího
                                            se na pravé straně nad tabulkou. Do okénka napíšeme požadovaný řetězec a
                                            stisknutím klávesy Enter nebo kliknutím na lupu vyhledáme data. Řetězec je
                                            vyhledáván ve sloupcích <b> Firma, IČO, Osoba a Obchodní zástupce.</b>
                                        </p>
                                        <p>
                                            <b>5. </b>U každého záznamu se nachází tlačítko ve sloupci <b>Detail</b>,
                                            které po kliknutí zobrazí vyskakovací okno s podrobnými informacemi o dané
                                            schůzce.
                                        </p>
                                        <p className="help-image-desktop">
                                            <b>6. </b>Data je možné procházet po stránkách a nastavit počet zobrazených
                                            záznamů na jedné straně.
                                        </p>
                                        <p className="help-image-mobile">
                                            <b>6. </b> Data je možné procházet po stránkách.
                                        </p>

                                        <img
                                            src={ColumnHiding}
                                            alt="column-hiding-button"
                                            className="help-image-desktop"
                                        />
                                        <img
                                            src={ColumnHidingMobile}
                                            alt="column-hiding-button"
                                            className="help-image-mobile"
                                        />
                                        <p>
                                            Sloupce tabulky je možné přizpůsobit v sekci <b>Upravit sloupce</b>{" "}
                                            přidáním/odebráním možných sloupců. Sloupce jsou odebrány kliknutím na
                                            křížek u názvu daného sloupce v oknýnku pro správu sloupců. Přidány jsou
                                            kliknutím na bílou část okénka a vybráním požadovaného sloupce/sloupců.
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Trasa" key="3">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Vyhledání trasy mezi schůzkami")}</h2>
                                        <img src={Route} alt="route-searching" className="help-image-desktop" />
                                        <img src={RouteMobile} alt="route-searching" className="help-image-mobile" />
                                        <p>
                                            Pro vyhledání trasy mezi schůzkami je třeba data nejprve úspěšně vyfiltrovat
                                            a vybrat z tabulky kliknutím na zaškrtávací políčko u požadované schůzky.
                                        </p>
                                        <p>
                                            Trasa bude vyhledána mezi vybranými schůzkami, je navíc možné zadat výchozí
                                            polohu. Pokud chceme hledat trasu od výchozí polohy, zaklikneme
                                            <b> Hledat od výchozí polohy</b> a do zobrazeného pole napíšeme výchozí bod
                                            ve formátu Ulice č.p., PSČ Město.
                                        </p>
                                        <p>
                                            Kliknutím na tlačítko <b>Najít trasu</b> se otevře nové okno s Google Maps s
                                            vyhledanou trasou. Je potřeba mít prohlížeči povolené otevírání nových oken.
                                            Trasa zobrazí místa schůzek v pořadí, v jakém byla vybírána z tabulky. Pokud
                                            chcete toto pořadí změnit, v Google Maps je to možné "přetažením" místa na
                                            jinou pozici
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Export" key="4">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Export")}</h2>
                                        <img src={Export} alt="export" className="help-image-desktop" />
                                        <img src={ExportMobile} alt="export" className="help-image-mobile" />

                                        <p>
                                            Export záznamů z tabulky se provádí v sekci <b>Exportovat schůzky</b>.
                                        </p>
                                        <p>Data budou vyexportována v pořadí nastaveném v tabulce.</p>
                                        <p>
                                            Je možné exportovat: <br />
                                            - Všechny sloupce všech záznamů tabulky
                                            <br />
                                            - Nastavené sloupce všech záznamů tabulky
                                            <br />
                                            - Všechny sloupce vybraných záznamů tabulky
                                            <br />
                                            - Nastavené (viditelné) sloupce vybraných záznamů
                                            <br />
                                        </p>
                                        <p>
                                            V případě, že chcete exportovat data pouze s viditelnými sloupci, zaškrtněte
                                            volbu
                                            <b> Exportovat zobrazené sloupce</b>. Pro exportování je nutné mít data
                                            úspěšně vyfiltrována pomocí formuláře. Tlačítko{" "}
                                            <b>Exportovat VYBRANÉ záznamy</b> se zpřístupní po výběru dat z tabulky.
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Detail" key="5">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Detail schůzky")}</h2>
                                        <p>
                                            Detail schůzky se otevře kliknutím na tlačítko ve sloupci <b>Detail</b> u
                                            příslušného záznamu. Zobrazuje podrobné informace o dané schůzce a nabízí
                                            další funkce.
                                        </p>

                                        <p>
                                            Pomocí <b>šipek</b> po levé a pravé straně od názvu firmy lze přejít na
                                            detail předchozího/následujícího záznamu z tabulky.
                                        </p>
                                        <p>
                                            V horní části se nachází informace o schůzce. V popisu schůzky lze telefonní
                                            čísla volat po kliknutí na modře zvýrazněné telefonní číslo. Emaily lze
                                            rychle napsat kliknutím na modře zvýrazněné emaily. Po kliknutí na modře
                                            zvýrazněný odkaz bude v novém okně otevřen příslušný web.
                                            <br />
                                        </p>
                                        <p>
                                            Je zde možnost <b>Upravit poznámku obchodního zástupce.</b>
                                        </p>

                                        <p>
                                            Pod popisem schůzky se nachází tlačítka pro <b>Export</b> detailu schůzky ve
                                            formátu .pdf, tlačítko pro otevření místa schůzky v <b>Google Maps</b> a
                                            tlačítko pro <b>Přidání schůzky do Google kalendáře.</b>
                                        </p>
                                        <p>
                                            Následuje <b>mapa</b>, její přiblížení a oddálení je možné kolečkem myši při
                                            podržení klávesy Ctrl.
                                        </p>
                                        <p>
                                            Pod mapou je tlačítko pro <b>Reklamaci dle obchodních podmínek</b> a
                                            tlačítko pro <b>Žádost o přesunutí schůzky</b>, to je přístupné pouze v
                                            případě, že je ještě možné schůzku přesunout.
                                        </p>
                                        <br />
                                        <img src={Detail} alt="detail" className="help-image-desktop" />
                                        <img src={DetailMobile} alt="detail" className="help-image-mobile" />
                                    </Paragraph>
                                </TabPane>
                                <TabPane tab="" key="10"></TabPane>
                            </Tabs>
                        </div>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default HelpMeetings;
