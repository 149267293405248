import React from "react";
//Antd
import { Button, Skeleton, Descriptions, Modal, Pagination, List, Typography } from "antd";
import { CloseCircleOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//Charts
import Pie from "../Chart";
import Bar from "../BarChart";

const buttonLayout = {
    style: {
        //marginLeft: "75%",
        width: "200px",
    },
};
const leftMargin = {
    style: {
        marginLeft: "10px",
    },
};
const paddingBottom = {
    style: {
        paddingBottom: "20px",
    },
};

const { Title } = Typography;
let questionArray = [];
let allAnswers = [];
let answerCount = [];
let values = [];
let labels = [];
let counter = 0;

export default class TelemarketingStatistic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            data: this.props.statisticData,
            arrayOfQaA: this.props.statisticArray,
            layout: "vertical",
            listVisible: true,
        };
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }

    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps() {
        this.setState({ data: this.props.statisticData });
    }

    setListVisibility() {
        this.setState({ listVisible: !this.state.listVisible });
    }

    makeArray(dataDotaznik) {
        questionArray = [];
        allAnswers = [];
        answerCount = [];
        if (dataDotaznik) {
            //make array allAnswers with format: [all records]==[[one record]]==[[[question, answear, pripad_id]]]
            let oneAnswers = []; //and array questionArray with all unique questions
            for (let i = 0; i < dataDotaznik.length; i++) {
                oneAnswers = [];
                let dot = dataDotaznik[i].vyplnenyDotaznik;
                {
                    Object.entries(dot).map(
                        ([key, value]) => (
                            oneAnswers.push([key, value]),
                            questionArray.indexOf(key) < 0 ? questionArray.push(key) : null
                        )
                    );
                }
                allAnswers.push(oneAnswers);
            }

            let sAnswers = [];
            questionArray.map((key) => {
                //make array answerCount with format [[array of answers], [array of answers], ...]
                sAnswers = []; //[[[answer, 0], [answer,0]] , [[answer, 0]], ...]
                allAnswers.map((oneSet) =>
                    oneSet.map((oneQuest) =>
                        oneQuest[0].indexOf(key) == 0 && sAnswers.indexOf(oneQuest[1]) < 0 && oneQuest[1] != ""
                            ? sAnswers.push(oneQuest[1])
                            : null
                    )
                );

                let nove = [];
                sAnswers.map((ans) => nove.push([ans, 0]));
                answerCount.push(nove);
            });

            for (let i = 0; i < answerCount.length; i++) {
                //count number of similar answers to answerCount
                for (let j = 0; j < answerCount[i].length; j++) {
                    allAnswers.map((oneSet) =>
                        oneSet.map((oneQuest) =>
                            oneQuest[0].indexOf(questionArray[i]) == 0 && oneQuest[1].indexOf(answerCount[i][j][0]) == 0
                                ? (answerCount[i][j][1] += 1)
                                : null
                        )
                    );
                }
            }
        }
    }

    getGraph(setOfAnswers) {
        /*
      Function to show Graphs
      When there are only one answer - function shows Bar Chart
      When there are 1 < number of answers <= 5 - function shows Pie Chart
      When there are more then 5 answers - Shows List with all answers with Hide/Show Button 
    */
        values = [];
        labels = [];
        counter += 1;

        return (
            <div key={questionArray[counter - 1]}>
                <Title key="titlw" level={4}>
                    {questionArray[counter - 1].substring(
                        questionArray[counter - 1].indexOf(", ") + 1,
                        questionArray[counter - 1].length - 1
                    )}
                </Title>
                {setOfAnswers.length == 0 ? (
                    <div key="noData" {...leftMargin} {...paddingBottom}>
                        {t("No Data")}
                    </div>
                ) : (
                    <div key="answers" {...paddingBottom}>
                        {setOfAnswers.map(([key, value]) => {
                            labels.push(key);
                            values.push(value);
                        })}
                        {labels.length > 5 ? (
                            <div key="listOfAnswers">
                                <Button key="show/hide" {...buttonLayout} onClick={this.setListVisibility.bind(this)}>
                                    {this.state.listVisible ? <CaretUpOutlined /> : <CaretDownOutlined />}
                                    {this.state.listVisible ? t("Hide Answers") : t("Show Answers")}
                                </Button>
                                {this.state.listVisible && (
                                    <List
                                        key="list"
                                        size="small"
                                        bordered
                                        dataSource={labels}
                                        visible={this.state.listVisible ? "true" : "false"}
                                        renderItem={(item) => <List.Item>{item}</List.Item>}
                                    />
                                )}
                            </div>
                        ) : (
                            <div key="graphs">
                                {labels.length > 1 ? (
                                    <Pie key="pie" chartSeries={values} chartLabels={labels} />
                                ) : (
                                    <Bar key="bar" chartSeries={values} chartLabels={labels} />
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }

    render() {
        counter = 0;
        const footer = [
            <Button key="closebtn" type="primary" size="large" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {this.makeArray(this.props.statisticData)}
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={t("Statistic")}
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        <div key="divGraph">
                            {answerCount.map((setOfAnswers) => this.getGraph(setOfAnswers))}

                            {answerCount.length == 0 && (
                                <div style={{ marginTop: "100px", marginBottom: "100px", textAlign: "center" }}>
                                    <h2>{t("No data found")}</h2>
                                </div>
                            )}
                        </div>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
