import React from "react";
import { store } from "../components/providers/ReduxProvider";
//Router
import { Link } from "react-router-dom";
//Antd
import { Button, PageHeader } from "antd";
//Layout
import LayoutWrapper from "../components/layouts/LayoutWrapper";
//Language
import t from "../components/providers/LanguageProvider";
import { setLanguage } from "../store/actions";
//Images
import image from "../images/unauthorized.svg";
import { HomeOutlined } from "@ant-design/icons";

const NotAuthorized = () => {
    return (
        <LayoutWrapper>
            <PageHeader
                onBack={() => {
                    window.location = "/";
                }}
                extra={[]}
            >
                <div style={{ paddingBottom: "30px", paddingTop: "20px" }}>
                    <p style={{ fontSize: "24px", paddingBottom: "10px" }}>
                        {t("You are not authorized for this site!")}
                    </p>
                    <img style={{ paddingBottom: "40px" }} src={image} alt="unauthorized" />
                    <p style={{ fontSize: "24px", paddingBottom: "30px" }}>{t("Try going back to homepage.")}</p>
                    <Button type="primary" size="large">
                        <Link to="/">
                            <HomeOutlined />
                            {t("Home")}
                        </Link>
                    </Button>
                </div>
            </PageHeader>
        </LayoutWrapper>
    );
};

export default NotAuthorized;
