import React from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
//Antd
import { Button, Skeleton, Descriptions, Modal, PageHeader, Input, Tooltip } from "antd";
import {
    FilePdfOutlined,
    FileTextTwoTone,
    FileAddTwoTone,
    StopOutlined,
    SaveOutlined,
    EditOutlined,
    CloseCircleOutlined,
    FileUnknownTwoTone,
    CaretUpOutlined,
    CommentOutlined,
    LeftOutlined,
    RightOutlined,
    EnvironmentOutlined,
    MailOutlined,
    GlobalOutlined,
    UserOutlined,
    PhoneOutlined,
    CalendarOutlined,
    HomeOutlined,
    InfoCircleTwoTone,
} from "@ant-design/icons";
//Redux
import AmaxApiService from "../../../services/AmaxApiService";
//Moment
import moment from "moment";
//Components
import exportToPdfTelemarketing from "../exports/PDFExportTelemarketing";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//Images
import logo from "../../../images/logo-export-amax.png";

const itemHeaderLayout = {
    style: {
        background: "#e1e2e4",
        border: "none",
    },
};

class TelemarketingDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            noteEditable: false,
            noteSaving: false,
            newNote: null,
            data: null,
            questions: null,
            coordinates: [0, 0],
            layout: "vertical",
            visible: true,
            description: true,
        };
        this.exportPDF = this.exportPDF.bind(this);
    }

    printDocument() {
        const input = document.getElementById("divToPrint");
        html2canvas(input, {
            onclone: (document) => {
                document.getElementById("divToPrint").style.display = "block";
            },
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "pt", "a4", false);
            pdf.addImage(imgData, "PNG", 30, 30, 540, 0, undefined, false);
            // pdf.output('dataurlnewwindow');
            pdf.save(`Telemarketing-${this.state.data.pripadID}.pdf`);
        });
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }

    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        this.setState({ visible: true, data: newProps.detailData, noteEditable: false });
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    setDescriptione() {
        this.setState({ description: !this.state.description });
    }

    exportPDF() {
        exportToPdfTelemarketing(this.state.data);
    }

    saveNote() {
        this.setState({ noteSaving: true });
        AmaxApiService.Telemarketing.UpdateNote({
            MeetingId: this.state.data.pripadID,
            Text: this.state.newNote,
        }).then((response) => {
            console.log(response);

            this.props.detailData.poznamkaZakaznika = this.state.newNote;

            this.setState({
                noteSaving: false,
                noteEditable: false,
                data: Object.assign({}, this.state.data, {
                    poznamkaZakaznika: this.state.newNote,
                }),
            });
        });
    }

    isNumeric(value) {
        return /^\d+$/.test(value);
    }

    formatNote(input) {
        const rePhone = /(?:[+() ]*\d){9,13}/gm;
        const reEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
        const reWeb =
            /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

        if (this.state.data) {
            if (input) {
                let parts = input.split(/(?:,|\s)/);
                var text = undefined;

                parts.forEach((element) => {
                    if (!text) {
                        if (reEmail.test(element)) text = this.makeEmailLink(element);
                        else if (reWeb.test(element)) text = this.makeWebLink(element);
                        else text = element;
                    } else {
                        if (reEmail.test(element)) text = text + " " + this.makeEmailLink(element);
                        else if (reWeb.test(element)) text = text + " " + this.makeWebLink(element);
                        else text = text + " " + element;
                    }
                });

                let phoneNumbers = [...text.matchAll(rePhone)];
                let newText = "";
                let i = 0;
                phoneNumbers.forEach((element) => {
                    newText = newText + text.substring(i, element["index"]) + this.makePhoneLink(element[0]);
                    i = element["index"] + element[0].length;
                });
                newText = newText + text.substring(i, text.length);
                return newText;
            }
        }
    }

    findWeb(input) {
        const re =
            /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
        if (this.state.data) {
            if (input) {
                return this.makeWebLink(input.match(re) ? input.match(re)[0] : "http://");
            }
        }
    }

    findEmail(input) {
        const re = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
        if (this.state.data) {
            if (input) {
                return this.makeEmailLink(input.match(re) ? input.match(re)[0] : "-");
            }
        }
    }

    findPhone(input) {
        let splitArray = input.split(",");
        const re = /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/;
        if (this.state.data) {
            if (input) {
                if (splitArray.length > 0) {
                    return this.makePhoneLink(splitArray[0].match(re) ? splitArray[0].match(re)[0] : " ");
                }
                return this.makePhoneLink(input.match(re) ? input.match(re)[0] : " ");
            }
        }
    }

    makePhoneLink(phone) {
        let toCall = phone;
        let formatedPhone = "";
        if (phone[0] == "+") {
            toCall = "tel:" + phone;
            formatedPhone = phone.replaceAll(" ", "").substring(4);
        } else if (phone[0] == "4") {
            toCall = "tel:+" + phone;
            formatedPhone = phone.replaceAll(" ", "").substring(3);
        } else {
            toCall = "tel:+420" + phone.replaceAll(" ", "");
            formatedPhone = phone.replaceAll(" ", "");
        }
        formatedPhone =
            "+420 " +
            formatedPhone.substring(0, 3) +
            " " +
            formatedPhone.substring(3, 6) +
            " " +
            formatedPhone.substring(6);
        return (
            //className="blue-text"
            `<a href=${toCall} target="_blank">
        <span>${formatedPhone}</span>
      </a>`
        );
    }

    makeEmailLink(email) {
        let mailTo = "mailto:" + email;
        return `<a href=${mailTo}>
          <span>${email}</span>
      </a>`;
    }

    makeWebLink(web) {
        let webLink = web;
        if (webLink.indexOf("://") < 1) {
            webLink = "http://" + web;
        }
        let splitWeb = "";
        if (web.indexOf("=") > 0) {
            splitWeb = web.substring(0, web.indexOf("=")) + "...";
            console.log(splitWeb);
            return `<a href=${webLink} target="_blank">
            <span>${splitWeb}</span>
        </a>`;
        }
        let shorterWeb = web;
        if (shorterWeb.length > 95) shorterWeb = web.substring(0, 95) + "...";
        return `<a href=${webLink} target="_blank">
          <span>${shorterWeb}</span>
      </a>`;
    }
    ///////////////////////////////////  ??????????????

    dotaznikWeb = () => {
        let retVal = this.state.data.web;
        {
            Object.entries(this.state.data.vyplnenyDotaznik).map(([key, value]) => {
                if (key.toString().indexOf("web") >= 0 || key.toString().indexOf("zdroj") >= 0) {
                    if (
                        value &&
                        value.match(
                            /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
                        )
                    )
                        retVal = value;
                }
            });
        }
        return retVal;
    };

    dotaznikPhone = () => {
        let retVal = this.state.data.volanyTelefon
            ? this.state.data.volanyTelefon
            : this.state.data.telefon
            ? this.state.data.telefon
            : "-";
        {
            Object.entries(this.state.data.vyplnenyDotaznik).map(([key, value]) => {
                if (key.toString().indexOf("telefon") >= 0 || key.toString().indexOf("kontakt") >= 0) {
                    if (value && value.match(/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/)) retVal = value;
                }
            });
        }
        return retVal;
    };

    dotaznikEmail = () => {
        let retVal = this.state.data.mail;
        {
            Object.entries(this.state.data.vyplnenyDotaznik).map(([key, value]) => {
                if (key.toString().indexOf("email") >= 0) {
                    if (value && value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) retVal = value;
                }
            });
        }
        return retVal;
    };

    headerButtons = [];
    render() {
        const footer = [
            <Button type="secondary" key="export" size="large" onClick={this.printDocument.bind(this)}>
                <FilePdfOutlined key="export-icon" />
                {t("Export")}
            </Button>,
            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <CloseCircleOutlined key="closeA-icon" />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        key="modal"
                        width={this.state.modalWidth}
                        footer={footer}
                        title={
                            <>
                                <InfoCircleTwoTone style={{ paddingRight: "8px" }} />
                                {t("Detail")}
                            </>
                        }
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "24px" }}
                    >
                        {!this.state.data ? (
                            <Skeleton key="skeleton1" active />
                        ) : this.props.detailData.pripadID !== this.state.data.pripadID ? (
                            <Skeleton key="skeleton2" active />
                        ) : (
                            <>
                                <div data-html2canvas-ignore="true">
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            size="small"
                                            key="prev"
                                            shape="circle"
                                            onClick={this.props.openPrevDetail}
                                            style={{ float: "left", marginTop: "12px" }}
                                        >
                                            <LeftOutlined />
                                        </Button>

                                        <h3 style={{ width: "100%", paddingLeft: "10px" }} className="title">
                                            {this.state.data.nazev}
                                        </h3>

                                        <Button
                                            style={{ float: "right", marginTop: "12px" }}
                                            size="small"
                                            key="next"
                                            shape="circle"
                                            onClick={this.props.openNextDetail}
                                        >
                                            <RightOutlined />
                                        </Button>
                                    </div>

                                    <div className="mobile-card">
                                        <div class="column-mobile">
                                            <div style={{ display: "flex", width: "26px" }}>
                                                <Tooltip placement="top" title={t("Company TIN")}>
                                                    <p
                                                        style={{
                                                            paddingRight: "14px",
                                                            paddingTop: "2px",
                                                            fontSize: "12px",
                                                        }}
                                                    >{`${t("TIN")}   `}</p>
                                                </Tooltip>
                                                {this.state.data.ico ? this.state.data.ico : "-"}
                                            </div>

                                            <div style={{ display: "flex" }}>
                                                <Tooltip placement="top" title={t("Company Address")}>
                                                    <HomeOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div style={{ paddingLeft: "4px" }}>
                                                    {this.state.data.ulice && `${this.state.data.ulice}, `}
                                                    {this.state.data.obec && `${this.state.data.obec}`}
                                                    {this.state.data.psc && `, ${this.state.data.psc}`}
                                                    {this.state.data.okres && (
                                                        <>
                                                            <br />
                                                            {`${t("Region")}: ${this.state.data.okres}`}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <br />

                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={"E-mail"}>
                                                    <MailOutlined className="detail-icon" />
                                                </Tooltip>
                                                {this.state.data.mail ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findEmail(
                                                                this.state.data.mail.split(/[\s,]+/)[0]
                                                            ),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>

                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={"Web"}>
                                                    <GlobalOutlined className="detail-icon" />
                                                </Tooltip>
                                                {this.state.data.web ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findWeb(
                                                                this.state.data.web.split(/[\s,]+/)[0]
                                                            ),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>

                                            <br />
                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Person")}>
                                                    <UserOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div>{this.state.data.osoba}</div>
                                            </div>
                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Phone")}>
                                                    <PhoneOutlined className="detail-icon" />
                                                </Tooltip>
                                                {
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findPhone(
                                                                this.state.data.volanyTelefon
                                                                    ? this.state.data.volanyTelefon
                                                                    : this.state.data.telefon
                                                            ),
                                                        }}
                                                    />
                                                }
                                            </div>

                                            <br />
                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Contact date")}>
                                                    <CalendarOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div>
                                                    {moment(this.state.data.cas).format("DD.MM.YYYY") +
                                                        " " +
                                                        moment(this.state.data.cas).format("HH:mm")}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ///// DESKTOP VERSION ////// */}
                                    <div class="desktop-card">
                                        <div className="row">
                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Company TIN")}>
                                                        <p
                                                            style={{
                                                                paddingRight: "14px",
                                                                fontSize: "12px",
                                                                paddingTop: "2px",
                                                            }}
                                                        >{`${t("TIN")}   `}</p>
                                                    </Tooltip>
                                                    {this.state.data.ico ? this.state.data.ico : "-"}
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Company Address")}>
                                                        <HomeOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    <p style={{ paddingLeft: "4px" }}>
                                                        {this.state.data.ulice && `${this.state.data.ulice}, `}
                                                        {this.state.data.obec && `${this.state.data.obec}`}
                                                        {this.state.data.psc && `, ${this.state.data.psc}`}
                                                        {this.state.data.okres && (
                                                            <>
                                                                <br />
                                                                {`${t("Region")}: ${this.state.data.okres}`}
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "2px", paddingLeft: "4px" }}>
                                                <Tooltip placement="top" title={"E-mail"}>
                                                    <MailOutlined className="detail-icon" />
                                                </Tooltip>
                                                {this.dotaznikEmail() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findEmail(this.dotaznikEmail()),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "14px", paddingLeft: "4px" }}>
                                                <Tooltip placement="top" title={"Web"}>
                                                    <GlobalOutlined className="detail-icon" />
                                                </Tooltip>
                                                {this.dotaznikWeb() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findWeb(this.dotaznikWeb()).toString(),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>
                                        </div>

                                        <div class="row" style={{ paddingTop: "8px" }}>
                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Person")}>
                                                        <UserOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    <div style={{ paddingLeft: "4px" }}>{this.state.data.osoba}</div>
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Phone")}>
                                                        <PhoneOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.findPhone(this.dotaznikPhone()).toString(),
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Contact date")}>
                                                        <CalendarOutlined
                                                            className="detail-icon"
                                                            style={{ paddingTop: "2px" }}
                                                        />
                                                    </Tooltip>
                                                    <div>
                                                        {moment(this.state.data.cas).format("DD.MM.YYYY") +
                                                            " " +
                                                            moment(this.state.data.cas).format("HH:mm")}
                                                    </div>
                                                </div>
                                                {/*<div style={{display: "flex", paddingTop: "4px"}}>
                    <DollarOutlined className="detail-icon"  style={{paddingTop:"2px"}}/>
                    <div>{this.state.data.cena + ",-"}</div>
                    </div>*/}
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.data.duvodOdmitnuti !== "" &&
                                        this.state.data.duvodOdmitnuti !== null && (
                                            <div class="big-column" style={{ paddingTop: "18px", display: "flex" }}>
                                                {t("Reason for refusal") + ": "}
                                                {this.state.data.duvodOdmitnuti}
                                                {this.state.data.duvodPopis !== "" &&
                                                    this.state.data.duvodPopis !== null && (
                                                        <>
                                                            <br />
                                                            {`${t("Description")}:  ${this.state.data.duvodPopis}`}
                                                        </>
                                                    )}
                                            </div>
                                        )}
                                    {(this.state.data.poznamka ||
                                        (this.state.data.poznamkaKontakt &&
                                            this.state.data.poznamkaKontakt != "Nahrazeno za jinou firmu") ||
                                        this.state.data.poznamkaZakaznika) && (
                                        <div class="row">
                                            <div class="big-column">
                                                {this.state.data.poznamka && (
                                                    <div style={{ paddingTop: "18px" }}>
                                                        {t("Questionnaire Note") + ": "}
                                                        {
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: this.formatNote(this.state.data.poznamka),
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                )}

                                                {this.state.data.poznamkaKontakt &&
                                                    this.state.data.poznamkaKontakt.indexOf(
                                                        "Nahrazeno za jinou firmu"
                                                    ) < 0 && (
                                                        <div style={{ paddingTop: "18px" }}>
                                                            {t("Contact Note") + ": "}
                                                            {
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: this.formatNote(
                                                                            this.state.data.poznamkaKontakt
                                                                        ),
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    )}

                                    {this.state.noteEditable ? (
                                        <div class="big-column" style={{ paddingTop: "18px" }}>
                                            {t("Customer note") + ": "}
                                            <React.Fragment key="edit-note-input">
                                                <Input.TextArea
                                                    key="textArea"
                                                    onChange={(e) => this.setState({ newNote: e.target.value })}
                                                    rows={3}
                                                    value={this.state.newNote}
                                                />
                                                <div key="editnote-group" className="btn-group">
                                                    <Button
                                                        type="primary"
                                                        key="save-note"
                                                        style={{ float: "right" }}
                                                        onClick={this.saveNote.bind(this)}
                                                        loading={this.state.noteSaving}
                                                    >
                                                        <SaveOutlined key="save" />
                                                        {t("Save")}
                                                    </Button>
                                                    <Button
                                                        type="secondary"
                                                        key="cancel-note"
                                                        style={{ float: "right" }}
                                                        onClick={() => this.setState({ noteEditable: false })}
                                                    >
                                                        <StopOutlined key="cancel-icon" />
                                                        {t("Cancel")}
                                                    </Button>
                                                </div>
                                            </React.Fragment>
                                        </div>
                                    ) : (
                                        <div class="big-column" style={{ paddingTop: "18px" }}>
                                            {t("Customer note") + ": "}
                                            <React.Fragment key="edit-note-button">
                                                {this.state.data.poznamkaZakaznika}
                                                <Button
                                                    type="primary"
                                                    size="default"
                                                    key="edit"
                                                    style={{ float: "right" }}
                                                    onClick={() =>
                                                        this.setState({
                                                            noteEditable: true,
                                                            newNote: this.state.data.poznamkaZakaznika,
                                                        })
                                                    }
                                                >
                                                    <EditOutlined key="edit-icon" />
                                                </Button>
                                            </React.Fragment>
                                        </div>
                                    )}

                                    <br />
                                    <div>
                                        <button className="detail-button" onClick={this.setVisible.bind(this)}>
                                            {this.state.visible ? (
                                                <h3 style={{ fontWeight: "bold" }}>
                                                    {t("Filled Questionnaire ")}
                                                    <CaretUpOutlined />
                                                </h3>
                                            ) : (
                                                <h3>
                                                    {t("Filled Questionnaire ")}
                                                    <CommentOutlined />{" "}
                                                </h3>
                                            )}
                                        </button>

                                        {this.state.visible && (
                                            <Descriptions
                                                key={"questionFilled"}
                                                column={2}
                                                bordered
                                                layout="horizontal"
                                                className="ant-descriptions-half-radius detail-question"
                                            >
                                                {Object.entries(this.state.data.vyplnenyDotaznik).map((question) => (
                                                    <Descriptions.Item {...itemHeaderLayout} span={2} key={question[0]}>
                                                        {question[0].indexOf(" ") > 0
                                                            ? question[0].substring(
                                                                  question[0].indexOf(", ") + 1,
                                                                  question[0].length - 1
                                                              )
                                                            : question[0]}
                                                        {<br />}
                                                        <b>{question[1] ? question[1] : "-"}</b>
                                                    </Descriptions.Item>
                                                ))}
                                            </Descriptions>
                                        )}
                                        <br />
                                    </div>
                                </div>

                                <div
                                    id="divToPrint"
                                    style={{
                                        width: "200mm",
                                        display: "none",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            background: "#48a3d8",
                                            paddingTop: "5px",
                                        }}
                                        className="pdf-img"
                                    >
                                        <h2
                                            style={{
                                                padding: "10px",
                                                background: "#48a3d8",
                                                color: "white",
                                                width: "80%",
                                            }}
                                        >
                                            {t("Telemarketing Detail") + " "}
                                            {this.state.data.pripadID}
                                        </h2>
                                        <img src={logo} />
                                    </div>

                                    <h3 style={{ width: "100%", paddingLeft: "5px" }} className="title">
                                        {this.state.data.nazev}
                                    </h3>
                                    <div class="desktop-card">
                                        <div className="row">
                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <p
                                                        style={{
                                                            paddingRight: "14px",
                                                            fontSize: "12px",
                                                            paddingTop: "2px",
                                                        }}
                                                    >{`${t("TIN")}   `}</p>
                                                    {this.state.data.ico ? this.state.data.ico : "-"}
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <HomeOutlined className="detail-icon" />
                                                    <p style={{ paddingLeft: "4px" }}>
                                                        {this.state.data.ulice && `${this.state.data.ulice}, `}
                                                        {this.state.data.obec && `${this.state.data.obec}`}
                                                        {this.state.data.psc && `, ${this.state.data.psc}`}
                                                        {this.state.data.okres && (
                                                            <>
                                                                <br />
                                                                {`${t("Region")}: ${this.state.data.okres}`}
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "2px", paddingLeft: "4px" }}>
                                                <MailOutlined className="detail-icon" />
                                                {this.dotaznikEmail() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findEmail(this.dotaznikEmail()),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "14px", paddingLeft: "4px" }}>
                                                <GlobalOutlined className="detail-icon" />
                                                {this.dotaznikWeb() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findWeb(this.dotaznikWeb()).toString(),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>
                                        </div>

                                        <div class="row" style={{ paddingTop: "8px" }}>
                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <UserOutlined className="detail-icon" />
                                                    <div style={{ paddingLeft: "4px" }}>{this.state.data.osoba}</div>
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <PhoneOutlined className="detail-icon" />
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.findPhone(this.dotaznikPhone()).toString(),
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <CalendarOutlined
                                                        className="detail-icon"
                                                        style={{ paddingTop: "2px" }}
                                                    />
                                                    <div>
                                                        {moment(this.state.data.cas).format("DD.MM.YYYY") +
                                                            " " +
                                                            moment(this.state.data.cas).format("HH:mm")}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.data.duvodOdmitnuti !== "" &&
                                        this.state.data.duvodOdmitnuti !== null && (
                                            <div class="big-column" style={{ paddingTop: "18px", display: "flex" }}>
                                                {t("Reason for refusal") + ": "}
                                                {this.state.data.duvodOdmitnuti}
                                                {this.state.data.duvodPopis !== "" &&
                                                    this.state.data.duvodPopis !== null && (
                                                        <>
                                                            <br />
                                                            {`${t("Description")}:  ${this.state.data.duvodPopis}`}
                                                        </>
                                                    )}
                                            </div>
                                        )}
                                    {(this.state.data.poznamka ||
                                        (this.state.data.poznamkaKontakt &&
                                            this.state.data.poznamkaKontakt != "Nahrazeno za jinou firmu") ||
                                        this.state.data.poznamkaZakaznika) && (
                                        <div class="row">
                                            <div class="big-column">
                                                {this.state.data.poznamka && (
                                                    <div style={{ paddingTop: "18px" }}>
                                                        {t("Questionnaire Note") + ": "}
                                                        {
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html: this.formatNote(this.state.data.poznamka),
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                )}

                                                {this.state.data.poznamkaKontakt &&
                                                    this.state.data.poznamkaKontakt.indexOf(
                                                        "Nahrazeno za jinou firmu"
                                                    ) < 0 && (
                                                        <div style={{ paddingTop: "18px" }}>
                                                            {t("Contact Note") + ": "}
                                                            {
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: this.formatNote(
                                                                            this.state.data.poznamkaKontakt
                                                                        ),
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    )}
                                            </div>
                                        </div>
                                    )}
                                    <div class="row">
                                        <div class="big-column" style={{ paddingTop: "18px" }}>
                                            {t("Customer note") + ": "}
                                            <br />
                                            {this.state.data.poznamkaZakaznika
                                                ? this.state.data.poznamkaZakaznika
                                                : "-"}
                                        </div>
                                    </div>
                                    <br />
                                    <div>
                                        {this.state.visible && (
                                            <Descriptions
                                                key={"questionFilled"}
                                                column={2}
                                                bordered
                                                layout="horizontal"
                                                className="ant-descriptions-half-radius print-detail-question"
                                            >
                                                {Object.entries(this.state.data.vyplnenyDotaznik).map((question) => (
                                                    <Descriptions.Item {...itemHeaderLayout} span={2} key={question[0]}>
                                                        {question[0].indexOf(" ") > 0
                                                            ? question[0].substring(
                                                                  question[0].indexOf(", ") + 1,
                                                                  question[0].length - 1
                                                              )
                                                            : question[0]}
                                                        {<br />}
                                                        <b>{question[1] ? question[1] : "-"}</b>
                                                    </Descriptions.Item>
                                                ))}
                                            </Descriptions>
                                        )}
                                        <br />
                                    </div>
                                </div>
                            </>
                        )}
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default TelemarketingDetail;
