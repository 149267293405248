import React, { useEffect } from "react";
//Redux
import { store } from "./ReduxProvider";
import { setLanguage } from "../../store/actions";
import { connect } from "react-redux";
//Antd
import { ConfigProvider } from "antd";
//Language
import csCZ from "antd/es/locale-provider/cs_CZ";
import enGB from "antd/es/locale-provider/en_GB";

/**
 * Takes a string a returns from dictionary appropriate language string
 *
 * @function t
 * @param {string} text
 * @returns {string} Translated text
 */
function t(text) {
    let langDict;

    switch (languageSetup()) {
        case "cs":
            langDict = require("../../languages/cs.json");
            break;

        default:
            // en
            return text;
            break;
    }
    if (langDict[text]) return langDict[text];
    return text;
}

export default t;

/**
 * Returns current language setup
 *
 * @function languageSetup
 */
export function languageSetup() {
    return store.getState().Logic.language;
}

/**
 * Sets up language provider for browser use
 *
 * @function tSetup
 */
export function tSetup() {
    if (typeof window !== `undefined`)
        if (!store.getState().Logic.language)
            if (navigator) store.dispatch(setLanguage((navigator.language || navigator.userLanguage).split("-")[0]));
}

/**
 * Provides language setup
 *
 * @func LanguageProvider
 */
const LanguageProvide = ({ children, language }) => {
    useEffect(() => {
        tSetup();
    });
    return <ConfigProvider locale={language}>{children}</ConfigProvider>;
};
export const LanguageProvider = connect(mapStateToProps)(LanguageProvide);

function mapStateToProps(state) {
    let outLang;
    switch (state.Logic.language) {
        case "cs":
            outLang = csCZ;
            break;
        case "en":
            outLang = enGB;
            break;
        default:
            outLang = csCZ;
    }
    return { language: outLang };
}
