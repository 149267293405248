import axios from "axios";
import { store } from "../components/providers/ReduxProvider";
import handleError from "./ErrorHandler";
import "../interfaces/AmaxApiInterface";

class AmaxApiService {
    /**
     * Sets up axios
     */
    static axiosConfig() {
        return {
            headers: {
                Accept: "application/json",
                "x-Auth-Token": store.getState().AmaxApi.token,
                Authorization: "bearer " + store.getState().AmaxApi.token,
                "Content-Type": "application/json",
            },
            Authorization: "bearer " + store.getState().AmaxApi.token,
        };
    }

    static CURRENT = "PROD";

    static HOST = {
        DEV: "http://localhost:50353/",
        PROD: "https://api.amaxsro.cz/",
        TEST: "http://10.11.6.97:5511/",
    };

    /**
     * Authenticates user in API, returns object with username, token, token expire date
     *
     * @static
     * @async
     * @function authenticate
     * @param {string} username
     * @param {string} passwd
     * @returns {Object}
     */
    static async authenticate(username, passwd) {
        //Setup credentials
        let credentials = `grant_type=password&username=${username}&password=${passwd}`;
        //POST request token
        return new Promise((resolve, reject) =>
            axios
                .post(this.HOST[this.CURRENT] + "/Token", credentials)
                .then((response) => {
                    //detect if really connected
                    resolve({
                        user: username,
                        token_expires_date: response.data[".expires"],
                        token: response.data["access_token"],
                    });
                })
                .catch((err) => {
                    console.error("An Error occured.. " + err);
                    console.log(err.response);
                    handleError(err.response);
                    resolve(false);
                })
        );
    }

    /**
     * Sends a GET request to API
     *
     * @static
     * @async
     * @function GET
     * @param {string} path Path after 'api/'
     */
    static async GET(path) {
        return new Promise((resolve, reject) =>
            axios
                .get(this.HOST[this.CURRENT] + "api/" + path, this.axiosConfig())
                .then((response) => {
                    resolve(response.data);
                })
                .catch((err) => {
                    console.log({ err });
                    handleError(err.response);

                    reject(err);
                })
        );
    }

    /**
     * Sends a POST requst to API
     *
     * @static
     * @async
     * @function POST
     * @param {string} path Path after 'api/'
     * @param {Object} data Data to be sent in body to api
     */
    static async POST(path, data = null) {
        return new Promise((resolve, reject) =>
            axios
                .post(this.HOST[this.CURRENT] + "api/" + path, data, this.axiosConfig())
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    console.error({ err });
                    handleError(err.response);

                    reject(err);
                })
        );
    }

    /**
     * Sends a PUT requst to API
     *
     * @static
     * @async
     * @function PUT
     * @param {string} path Path after 'api/'
     * @param {Object} data Data to be sent in body to api
     */
    static async PUT(path, data = null) {
        return new Promise((resolve, reject) =>
            axios
                .put(this.HOST[this.CURRENT] + "api/" + path, data, this.axiosConfig())
                .then((response) => {
                    resolve(response);
                })
                .catch((err) => {
                    console.error({ err });
                    handleError(err.response);

                    reject(err);
                })
        );
    }

    /* Account class */
    static Account = class {
        /**
         * Returns current user info
         *
         * @static
         * @async
         * @function UserInfo
         */
        static async UserInfo() {
            return await AmaxApiService.GET("Account/UserInfo");
        }

        static async ChangePassword(pwds) {
            return await AmaxApiService.POST("Account/ChangePassword/", JSON.stringify(pwds));
        }

        static async ResetPassword(email) {
            return await AmaxApiService.PUT("Account/ResetPassword/", JSON.stringify(email));
        }

        static async NewPassword(data) {
            console.log("odeslana data:", JSON.stringify(data));
            return await AmaxApiService.POST("Account/NewPassword/", JSON.stringify(data));
        }

        static async Permission() {
            return await AmaxApiService.GET("Account/Permission");
        }
    };

    /* Report meetings class */
    static ReportMeetings = class {
        /**
         * Returns campaigns currently available to user
         *
         * @static
         * @async
         * @function CampaignsByUser
         * @returns {Array}
         */
        static async CampaignsByUser() {
            return await AmaxApiService.GET("ReportMeetings/CampaignsByUser");
        }

        /**
         * Returns Sales Representatives by campaign id, currently avaible to user
         *
         * @static
         * @async
         * @function SRsByUser
         * @param {Integer} tssId Id of the TSS
         * @returns {Array}
         */
        static async SRsByUser(tssId) {
            return await AmaxApiService.GET("ReportMeetings/SRsByUser/" + tssId);
        }

        static async ParseAllSRByUser(tssId) {}

        /**
         * Return reports from API
         *
         * @static
         * @async
         * @function Reports
         * @param {FilterOptionsViewModel} filter
         * @returns {Array}
         */
        static async Reports(filter) {
            return await AmaxApiService.POST("ReportMeetings/Reports/", filter);
        }

        /**
         * Returns Meeting  Details by meeting ID
         *
         * @static
         * @async
         * @function Report
         * @param {Integer} meetingId
         * @returns {Object}
         */
        static async Report(meetingId) {
            return await AmaxApiService.GET("ReportMeetings/Report/" + meetingId);
        }

        static async OrderByItems() {}

        /**
         * Updates Sales Rep's note
         *
         * @static
         * @async
         * @function UpdateSRNote
         * @param {UpdateNoteParametersViewModel} req
         */
        static async UpdateSRNote(req) {
            return await AmaxApiService.POST("ReportMeetings/UpdateSRNote/", req);
        }

        /**
         * Sends a reclamation request
         *
         * @static
         * @async
         * @function SendReclamationRequest
         * @param {Integer} meetingId
         * @param {ReclamationRequestModel} req
         */
        static async SendReclamationRequest(req) {
            return await AmaxApiService.POST("ReportMeetings/SendReclamationRequest/", req);
        }

        /**
         * Sends a term moving request
         *
         * @static
         * @async
         * @function SendTermMovingRequest
         * @param {TermMovingRequestViewModel} req
         */
        static async SendTermMovingRequest(req) {
            return await AmaxApiService.POST("ReportMeetings/SendTermMovingRequest/", JSON.stringify(req));
        }
    };

    /* Telemarketing class */
    static Telemarketing = class {
        /**
         * Returns campaigns currently avaible to user
         *
         * @static
         * @async
         * @function CampaignsByUser
         * @returns {Array}
         */
        static async CampaignsByUser() {
            return await AmaxApiService.GET("Telemarketings/CampaignsByUser");
        }

        static async Priorities(tssId) {
            return await AmaxApiService.GET(`Telemarketings/Priorities/${tssId}`);
        }

        static ShowPriorities = (tssId) => {};

        /**
         * Return telemarketings from API
         *
         * @static
         * @async
         * @function Telemarketings
         * @param {FilterOptionsViewModel} filter
         * @returns {Array}
         */
        static async Telemarketings(filter) {
            return await AmaxApiService.POST("Telemarketings/Telemarketings/", filter);
        }

        static async PositiveResponse(filter) {
            return await AmaxApiService.POST("Telemarketings/PositiveResponse/", filter);
        }

        static async NegativeResponse(filter) {
            return await AmaxApiService.POST("Telemarketings/NegativeResponse/", filter);
        }

        static OrderByItems() {}

        //static UpdateNote() {}
        /**
         * Updates Customer's note
         *
         * @static
         * @async
         * @function UpdateNote
         * @param {UpdateNoteParametersViewModel} req
         */
        static async UpdateNote(req) {
            console.log(req);
            return await AmaxApiService.POST("Telemarketings/UpdateNote/", req);
        }

        static async GetForm(idDotazniku) {
            return await AmaxApiService.GET(`Telemarketings/GetForm/${idDotazniku}`);
        }
    };

    /* Telesales class */
    static Telesales = class {
        /**
         * Returns campaigns currently avaible to user
         *
         * @static
         * @async
         * @function CampaignsByUser
         * @returns {Array}
         */
        static async CampaignsByUser() {
            return await AmaxApiService.GET("Telesales/CampaignsByUser");
        }

        static async Priorities(tssId) {
            return await AmaxApiService.GET(`Telesales/Priorities/${tssId}`);
        }

        static ShowPriorities = (tssId) => {};

        /**
         * Return Telesales from API
         *
         * @static
         * @async
         * @function Telesales
         * @param {FilterOptionsViewModel} filter
         * @returns {Array}
         */
        static async Telesales(filter) {
            return await AmaxApiService.POST("Telesales/Telesales/", filter);
        }

        static async TelesalesMultiple(filter) {
            let allData = [];
            if (filter) {
                filter.map((one) => {
                    allData.push(AmaxApiService.POST("Telesales/Telesales/", one));
                });
            }
            return await Promise.all(allData);
        }

        static OrderByItems() {}

        static async GetForm(idDotazniku) {
            return await AmaxApiService.GET(`Telesales/GetForm/${idDotazniku}`);
        }
    };

    /* Blacklist class */
    static Blacklist = class {
        static async Blacklist(filter) {
            console.log(filter);
            return await AmaxApiService.GET("Blacklist/GetBlacklist/", filter);
        }

        static OrderByItems() {}

        static async AddToBlacklist(items) {
            console.log(items);
            //return await AmaxApiService.POST(`Blacklist/AddToBlacklist/`, items);
        }
    };

    /* Cancel class */
    static Cancel = class {
        /**
         * Returns campaigns currently avaible to user
         *
         * @static
         * @async
         * @function CampaignsByUser
         * @returns {Array}
         */
        static async CampaignsByUser() {
            return await AmaxApiService.GET("Cancel/CampaignsByUser");
        }
        /**
         * Return Cancel from API
         *
         * @static
         * @async
         * @function Cancel
         * @param {FilterOptionsViewModel} filter
         * @returns {Array}
         */
        static async Search(filter) {
            return await Promise.all([
                AmaxApiService.POST("Cancel/Search/", filter),
                AmaxApiService.POST("Cancel/SearchCancel/", filter),
            ]);
        }

        static async Cancel(req) {
            return await AmaxApiService.POST("Cancel/Cancel/", req);
        }
    };

    /* Inform class */
    static Inform = class {
        static Campaigns() {}

        static staticKontakt() {}

        static Storno() {}
    };

    /* Form class */
    static Form = class {
        static staticForm = (formId) => {};

        static Partialstatic() {}

        static Fullstatic() {}

        static Information = (idSchuzky) => {};
    };

    /* Calendar class */
    static Calendar = class {
        static Meeting = (meetingId, tssId, srId) => {};

        static UsedCalendarLinks() {}
    };

    /* Ratings class */
    static Ratings = class {
        /**
         * Returns campaigns currently available to user
         *
         * @static
         * @async
         * @function CampaignsByUser
         * @returns {Array}
         */
        static async CampaignsByUser() {
            return await AmaxApiService.GET("Ratings/CampaignsByUser");
        }

        /**
         * Returns Sales Representatives by campaign id, currently avaible to user
         *
         * @static
         * @async
         * @function SRsByUser
         * @param {Integer} tssId Id of the TSS
         * @returns {Array}
         */
        static async SRsByUser(tssId) {
            return await AmaxApiService.GET("Ratings/SRsByUser/" + tssId);
        }

        /**
         * Return ratings from API
         *
         * @static
         * @async
         * @function Ratings
         * @param {FilterOptionsViewModel} filter
         * @returns {Array}
         */
        static async Ratings(filter) {
            return await AmaxApiService.POST("Ratings/Ratings/", filter);
        }

        static OrderByItems() {}

        static async OrderByItems() {
            return await AmaxApiService.GET("Ratings/OrderByItems/");
        }

        static async ParseAllSRByUser(TssId) {
            return await AmaxApiService.GET("Ratings/ParseAllSRByUser/" + TssId);
        }

        /**
         * Returns Meeting Details by meeting ID
         *
         * @static
         * @async
         * @function Rating
         * @param {Integer} meetingId
         * @returns {Object}
         */
        static async Rating(meetingId) {
            return await AmaxApiService.GET("Ratings/Rating/" + meetingId);
        }
    };

    static async test(host = "http://localhost:50353/api", path = "/Inform/Campaigns", data = null) {
        // console.log(await this.authenticate(username, passwd))
        console.log(store.getState().AmaxApi.user);
        console.log(host + path);
        axios
            .get(host + path, this.axiosConfig())
            .then((response) => {
                console.log(response);
            })
            .catch((err) => console.log(err))
            .finally(() => console.log("Request sent"));
        /*
    await connection
      .post('api/Telemarketing/Telemarketings')
      .then(response => {
        console.log(response)
      })
      .catch(err => console.log(err))
      .finally(() => console.log('It worked'))
    /**/
    }
}

export default AmaxApiService;
