import React from "react";
//Redux
import AmaxApiService from "../../../services/AmaxApiService";
//Antd
import { Button, Skeleton, Descriptions, Modal, Input, PageHeader } from "antd";
import {
    FilePdfOutlined,
    CalendarOutlined,
    TagsOutlined,
    ContactsOutlined,
    ShopOutlined,
    CheckOutlined,
    StopOutlined,
    FileTextOutlined,
    RollbackOutlined,
    CloseCircleOutlined,
    ProfileOutlined,
    DollarCircleOutlined,
    ProjectOutlined,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import exportToPdf from "../exports/PDFExport";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";

const { TextArea } = Input;

class StornoDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            data: null,
            layout: "vertical",
            visible: false,
            noteSaving: false,
            newNote: null,
        };
        //this.exportPDF = this.exportPDF.bind(this);
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }

    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.record) {
            this.setState({ data: newProps.record });
        }
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    closeDialog() {
        this.setState({
            ChangeMeetingDateModal: false,
            MeetingReclamationModal: false,
        });
    }

    exportPDF() {
        exportToPdf(this.state.data);
    }

    async canceling() {
        this.setState({ noteSaving: true });
        console.log(this.state.data);
        console.log(this.state.newNote);

        let message = "";
        await AmaxApiService.Cancel.Cancel({
            IdContact: this.state.data.IdContact,
            Note: this.state.newNote,
        }).then((response) => {
            console.log(response);
            message = response.data;
            this.setState({
                noteSaving: false,
                //data: Object.assign({}, this.state.data, {
                //  SRNote: this.state.newNote,
                //}),
            });
        });
        this.props.parentCallback(this.props.record, message);
        this.props.closeDialog();
    }

    render() {
        const footer = [
            <Button
                //type="primary"
                style={{ backgroundColor: "#fcab06", fontWeight: "bold", border: "none" }}
                size="large"
                key="storno"
                onClick={this.canceling.bind(this)}
                //loading={this.state.noteSaving}
            >
                <CloseCircleOutlined />
                {t("Cancel Item")}
            </Button>,
            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <StopOutlined />
                {t("Back")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={t("Cancellation")}
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        {!this.state.data ? (
                            <Skeleton active />
                        ) : this.props.record !== this.state.data ? (
                            <Skeleton active />
                        ) : (
                            <>
                                <h3 style={{ width: "100%", paddingLeft: "24px" }} className="title">
                                    {t("Order num. ") + this.state.data.IdContact}
                                </h3>

                                {this.state.layout == "horizontal" && (
                                    <Descriptions
                                        key="table"
                                        column={2}
                                        layout={this.state.layout}
                                        bordered
                                        className="ant-descriptions-half-radius detail-storno"
                                    >
                                        <Descriptions.Item
                                            key="Company"
                                            span={2}
                                            label={[
                                                <ShopOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("Company"),
                                            ]}
                                        >
                                            {this.state.data.Company}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            key="Sold"
                                            span={2}
                                            label={[
                                                <CalendarOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Conclusion Date"),
                                            ]}
                                        >
                                            {moment(this.state.data.Sold).format("DD.MM.YYYY") +
                                                " " +
                                                moment(this.state.data.Sold).format("HH:mm")}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            key="Price"
                                            span={2}
                                            label={[
                                                <DollarCircleOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Price"),
                                            ]}
                                        >
                                            {this.state.data.Price},-
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            key="UniqueIdentificator"
                                            span={2}
                                            label={[
                                                <TagsOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("TIN"),
                                            ]}
                                        >
                                            {this.state.data.UniqueIdentificator}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            key="Campaign"
                                            span={2}
                                            label={[
                                                <ProjectOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("Campaign"),
                                            ]}
                                        >
                                            {this.state.data.Campaign}
                                        </Descriptions.Item>

                                        <Descriptions.Item
                                            key="note"
                                            span={2}
                                            label={[
                                                <FileTextOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Insert Note"),
                                            ]}
                                        >
                                            <TextArea
                                                onChange={(e) => this.setState({ newNote: e.target.value })}
                                                label="nevim"
                                                rows={4}
                                            />
                                        </Descriptions.Item>
                                    </Descriptions>
                                )}

                                {this.state.layout == "vertical" && (
                                    <Descriptions
                                        key="table"
                                        column={2}
                                        layout={this.state.layout}
                                        bordered
                                        className="ant-descriptions-half-radius detail-storno"
                                    >
                                        <Descriptions.Item
                                            key="Company"
                                            span={2}
                                            label={[
                                                <ShopOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("Company"),
                                                ": ",
                                                this.state.data.Company,
                                            ]}
                                        />
                                        <Descriptions.Item
                                            key="Sold"
                                            span={2}
                                            label={[
                                                <CalendarOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Conclusion Date"),
                                                ": ",
                                                moment(this.state.data.Sold).format("DD.MM.YYYY") +
                                                    " " +
                                                    moment(this.state.data.Sold).format("HH:mm"),
                                            ]}
                                        />
                                        <Descriptions.Item
                                            key="Price"
                                            span={2}
                                            label={[
                                                <DollarCircleOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Price"),
                                                ": ",
                                                this.state.data.Price + ",-",
                                            ]}
                                        />
                                        <Descriptions.Item
                                            key="UniqueIdentificator"
                                            span={2}
                                            label={[
                                                <TagsOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("TIN"),
                                                ": ",
                                                this.state.data.UniqueIdentificator,
                                            ]}
                                        />
                                        <Descriptions.Item
                                            key="Campaign"
                                            span={2}
                                            label={[
                                                <ProjectOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("Campaign"),
                                                ": ",
                                                this.state.data.Campaign,
                                            ]}
                                        />

                                        <Descriptions.Item
                                            key="note"
                                            span={2}
                                            label={[
                                                <FileTextOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Insert Note"),
                                            ]}
                                        >
                                            <TextArea
                                                onChange={(e) => this.setState({ newNote: e.target.value })}
                                                label="nevim"
                                                rows={4}
                                            />
                                        </Descriptions.Item>
                                    </Descriptions>
                                )}
                            </>
                        )}
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default StornoDetail;
