import React, { useState, memo, useEffect } from "react";
import PropTypes from "prop-types";
import { store } from "../../components/providers/ReduxProvider";
//Redux
import { connect } from "react-redux";
//Router
import { Link, useLocation } from "react-router-dom";
//Antd
import { Layout, Menu } from "antd";
import Footer from "rc-footer";
import "rc-footer/assets/index.css"; // import 'rc-footer/asssets/index.less';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ScheduleOutlined,
    HomeOutlined,
    PhoneOutlined,
    CloseCircleOutlined,
    DollarOutlined,
    LikeOutlined,
    InstagramOutlined,
    FacebookOutlined,
    YoutubeOutlined,
    MailOutlined,
    EnvironmentOutlined,
    ShoppingOutlined,
    DeleteOutlined,
} from "@ant-design/icons";

//Components
import Logo from "../../images/logo-portal-small.png";
import amax from "../../images/amax-footer.svg";
import oldPortal from "../../images/oldportal-footer.svg";
import dataPic from "../../images/data-footer.svg";
import AccountInfo from "../common/AccountInfo";
//Helpers
import { responsiveWidth } from "../../helpers/helpers";
//Language
import t from "../providers/LanguageProvider";

import AmaxApiService from "../../services/AmaxApiService";

import { setPermisions } from "../../store/actions";

const DesktopLayout = ({ children }) => {
    let collapsedDefault = responsiveWidth({ sm: true, md: true });
    const [permisionResponse, setPermisionResponse] = useState(null);
    const location = useLocation();

    const [collapsed, setCollapsed] = useState(collapsedDefault);
    const [selectedItem, setSelectedItem] = useState([location.pathname]);

    const { Header, Content, Sider } = Layout;

    const onCollapse = (collapsed) => {
        setCollapsed(collapsed);
    };

    const getPermision = async () => {
        await AmaxApiService.Account.Permission()
            .then((response) => {
                setPermisionResponse(response);
                store.dispatch(setPermisions(response));
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        //loads user permisions if not loaded
        if (store.getState().Logic.permisions) {
            setPermisionResponse(store.getState().Logic.permisions);
        } else {
            getPermision();
        }
    }, []);

    return (
        <>
            <Layout>
                <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} trigger={null}>
                    <div className="logo-container">
                        <Link to="/">
                            <img src={Logo} alt="ax portal logo" />
                        </Link>
                    </div>

                    {permisionResponse ? (
                        <Menu
                            className="nav"
                            mode="block"
                            defaultSelectedKeys={["/"]}
                            selectedKeys={selectedItem}
                            onMouseOver={null}
                        >
                            <Menu.Item className="bottom-size" key="/home">
                                <Link to="/">
                                    <HomeOutlined className="ant-color and-menu-size" />
                                    <span>{t("Home")}</span>
                                </Link>
                            </Menu.Item>

                            {permisionResponse.ReportMeeting && (
                                <Menu.Item key="/reportschuzky">
                                    <Link to="/meetings">
                                        <ScheduleOutlined className="ant-color and-menu-size" />
                                        <span>{t("Report Meetings")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {permisionResponse.Telemarketing && (
                                <Menu.Item>
                                    <Link to="/telemarketing">
                                        <PhoneOutlined className="ant-color and-menu-size" />
                                        <span>{t("Telemarketing")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {permisionResponse.Telesale && (
                                <Menu.Item>
                                    <Link to="/telesales">
                                        <ShoppingOutlined className="ant-color and-menu-size" />
                                        <span>{t("Telesales")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {permisionResponse.Rating && (
                                <Menu.Item>
                                    <Link to="/hodnocenischuzky">
                                        <LikeOutlined className="ant-color and-menu-size" />
                                        <span>{t("Meetings Evaluation")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {permisionResponse.Cancel && (
                                <Menu.Item>
                                    <Link to="/cancel">
                                        <CloseCircleOutlined className="ant-color and-menu-size" />
                                        <span>{t("Cancellation")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                        </Menu>
                    ) : (
                        <Menu
                            className="nav"
                            mode="block"
                            defaultSelectedKeys={["/"]}
                            selectedKeys={selectedItem}
                            onMouseOver={null}
                        >
                            <Menu.Item className="bottom-size" key="/home">
                                <Link to="/">
                                    <HomeOutlined className="ant-color and-menu-size" />
                                    <span>{t("Home")}</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    )}
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-header">
                        {collapsed ? (
                            <MenuUnfoldOutlined className="sider-toggler" onClick={() => setCollapsed(!collapsed)} />
                        ) : (
                            <MenuFoldOutlined className="sider-toggler" onClick={() => setCollapsed(!collapsed)} />
                        )}
                        <div className="account-info-header">
                            <AccountInfo />
                        </div>
                    </Header>
                    <Content className="site-layout-content">{children}</Content>
                </Layout>
            </Layout>

            <Footer
                className="big-desktop-version" //big version of footer
                backgroundColor="#282d32"
                columns={[
                    {
                        items: [
                            {
                                title: <img style={{ height: "14px" }} src={amax} alt="amax" />,
                                url: "http://amaxsro.cz/",
                                openExternal: true,
                            },
                            {
                                title: <img style={{ height: "14px" }} src={oldPortal} alt="old-portal" />,
                                url: "http://portal.amaxsro.cz/",
                                openExternal: true,
                            },
                            {
                                title: <img style={{ height: "14px" }} src={dataPic} alt="data" />,
                                url: "https://data.amaxsro.cz/",
                                openExternal: true,
                            },
                        ],
                    },
                    {
                        description: "Site",
                        items: [
                            {
                                title: " amax_telemarketing",
                                icon: <InstagramOutlined className="ant-color social-icons" />,
                                url: "https://www.instagram.com/amax_telemarketing/",
                                openExternal: true,
                            },
                            {
                                title: " AMAX s.r.o",
                                icon: <FacebookOutlined className="ant-color social-icons" />,
                                url: "https://www.facebook.com/amaxsro/",
                                openExternal: true,
                            },
                            {
                                title: " amaxsro",
                                icon: <YoutubeOutlined className="ant-color social-icons" />,
                                url: "https://www.youtube.com/channel/UCWrSZpw8dwV8fn9uhuhH42Q",
                                openExternal: true,
                            },
                        ],
                    },
                    {
                        title: t("COMPANY HEADQUARTERS"),
                        style: { paddingTop: "10px" },
                        items: [
                            { title: "AMAX, s.r.o.", url: "http://amaxsro.cz/", openExternal: true },
                            {
                                title: "Orlí 17, 602 00 Brno",
                                url: "https://www.google.com/maps/place/Orl%C3%AD+17,+602000+Brno",
                                style: { paddingTop: "5px" },
                                openExternal: true,
                            },
                        ],
                    },
                    {
                        title: t("CONTACT"),
                        style: { paddingTop: "10px" },
                        items: [
                            {
                                title: " amax@amaxsro.cz",
                                url: "mailto:amax@amaxsro.cz",
                                icon: <MailOutlined className="ant-color contact-icons" />,
                            },
                            {
                                title: "+420 544 529 959",
                                url: "tel://+420544529959",
                                style: { paddingTop: "5px" },
                                icon: <PhoneOutlined className="ant-color contact-icons" />,
                            },
                        ],
                    },
                ]}
            />

            <Footer
                className="small-desktop-version" //small version
                backgroundColor="#282d32"
                maxColumnsPerRow={2}
                columns={[
                    {
                        items: [
                            {
                                title: (
                                    <div style={{ height: "16px" }}>
                                        <Link to="http://amaxsro.cz/">
                                            <img
                                                style={{ marginRight: "10px", height: "14px" }}
                                                src={amax}
                                                alt="amax"
                                            />
                                        </Link>
                                        <Link to="http://portal.amaxsro.cz/">
                                            <img
                                                style={{ paddingRight: "10px", height: "14px" }}
                                                src={oldPortal}
                                                alt="old-portal"
                                            />
                                        </Link>
                                        <Link to="https://data.amaxsro.cz/">
                                            <img
                                                style={{ paddingRight: "10px", height: "14px" }}
                                                src={dataPic}
                                                alt="data"
                                            />
                                        </Link>
                                    </div>
                                ),
                            },
                        ],
                    },

                    {
                        items: [
                            {
                                title: (
                                    <div>
                                        <Link to="https://www.instagram.com/amax_telemarketing/">
                                            <InstagramOutlined
                                                style={{ paddingRight: "20px" }}
                                                className="ant-color social-icons"
                                            />
                                        </Link>
                                        <Link to="https://www.facebook.com/amaxsro/">
                                            <FacebookOutlined
                                                style={{ paddingRight: "20px" }}
                                                className="ant-color social-icons"
                                            />
                                        </Link>
                                        <Link to="https://www.youtube.com/channel/UCWrSZpw8dwV8fn9uhuhH42Q">
                                            <YoutubeOutlined
                                                style={{ paddingRight: "20px" }}
                                                className="ant-color social-icons"
                                            />
                                        </Link>
                                    </div>
                                ),
                            },
                        ],
                    },
                    {
                        items: [
                            { title: "AMAX, s.r.o.", url: "http://amaxsro.cz/", openExternal: true },
                            {
                                title: "Orlí 17, 602 00 Brno",
                                url: "https://www.google.com/maps/place/Orl%C3%AD+17,+602000+Brno",
                                openExternal: true,
                            },
                        ],
                    },
                    {
                        items: [
                            {
                                title: " amax@amaxsro.cz",
                                url: "mailto:amax@amaxsro.cz",
                                icon: <MailOutlined className="ant-color contact-icons" />,
                            },
                            {
                                title: "+420 544 529 959",
                                url: "tel://+420544529959",
                                icon: <PhoneOutlined className="ant-color contact-icons" />,
                            },
                        ],
                    },
                ]}
            />

            <Footer
                className="tablet-version" //small version
                backgroundColor="#282d32"
                maxColumnsPerRow={2}
                columns={[
                    {
                        items: [
                            {
                                title: (
                                    <div className="bottom-padding">
                                        <Link to="http://amaxsro.cz/">
                                            <img
                                                style={{ marginRight: "10px", height: "14px" }}
                                                src={amax}
                                                alt="amax"
                                            />
                                        </Link>
                                        <Link to="http://portal.amaxsro.cz/">
                                            <img
                                                style={{ paddingRight: "10px", height: "14px" }}
                                                src={oldPortal}
                                                alt="old-portal"
                                            />
                                        </Link>
                                        <Link to="https://data.amaxsro.cz/">
                                            <img
                                                style={{ paddingRight: "10px", height: "14px" }}
                                                src={dataPic}
                                                alt="data"
                                            />
                                        </Link>
                                    </div>
                                ),
                            },
                        ],
                    },
                    {
                        items: [
                            {
                                title: "+420 544 529 959",
                                url: "tel://+420544529959",
                                icon: <PhoneOutlined className="ant-color contact-icons" />,
                            },
                            {
                                title: " amax@amaxsro.cz",
                                url: "mailto:amax@amaxsro.cz",
                                icon: <MailOutlined className="ant-color contact-icons" />,
                            },
                            {
                                title: "Orlí 17, 602 00 Brno",
                                url: "https://www.google.com/maps/place/Orl%C3%AD+17,+602000+Brno",
                                openExternal: true,
                                icon: <EnvironmentOutlined className="ant-color contact-icons" />,
                            },
                        ],
                    },
                    {
                        items: [
                            {
                                title: (
                                    <div className="top-padding">
                                        <Link to="https://www.instagram.com/amax_telemarketing/">
                                            <InstagramOutlined
                                                style={{ paddingRight: "25px" }}
                                                className="ant-color social-icons"
                                            />
                                        </Link>
                                        <Link to="https://www.facebook.com/amaxsro/">
                                            <FacebookOutlined
                                                style={{ paddingRight: "25px" }}
                                                className="ant-color social-icons"
                                            />
                                        </Link>
                                        <Link to="https://www.youtube.com/channel/UCWrSZpw8dwV8fn9uhuhH42Q">
                                            <YoutubeOutlined className="ant-color social-icons" />
                                        </Link>
                                    </div>
                                ),
                            },
                        ],
                    },
                ]}
            />

            <Footer className="padding" backgroundColor="#282d32" bottom="AMAX, s.r.o. © 2022" />
        </>
    );
};

DesktopLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(memo(DesktopLayout));

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi };
}
