import React, { useState, useEffect } from "react";
//Redux
import { connect } from "react-redux";
import SearchAndQueryData from "../../../services/DataQueryController";
//Antd
import { Button, Table, Descriptions, Select, Pagination, Alert } from "antd";
import {
    CloseCircleTwoTone,
    MinusCircleTwoTone,
    PlusCircleTwoTone,
    TagsTwoTone,
    DollarTwoTone,
    ProjectTwoTone,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import StornoDetail from "../Modals/StornoDetail";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";

const StornoTable = ({ data, loading }) => {
    const [stornoDetail, setStornoDetail] = useState(false);
    const [stornoId, setStornoId] = useState(null);
    const [columns, setColumns] = useState(null);
    const [layout, setLayout] = useState("vertical");
    const [tableExpandable, setTableExpandable] = useState(false);
    const [last, setLast] = useState(data ? (Array.isArray(data[0]) ? data[0] : null) : null);
    const [deleted, setDeleted] = useState(data ? (Array.isArray(data[1]) ? data[1] : null) : null);
    const [alert, setAlert] = useState(null);
    const [showAlert, setShowAlert] = useState(false);

    const [changed, setChanged] = useState(false);

    const [pageSizeNot, setPageSizeNot] = useState(10);
    const [pageNot, setPageNot] = useState(1);
    const [pageSizeCancelled, setPageSizeCancelled] = useState(10);
    const [pageCancelled, setPageCancelled] = useState(1);

    useEffect(() => {
        setLast(data ? data[0] : data);
        setDeleted(deleted ? deleted : data ? data[1] : data); /////zmeneno pro upravu??
        //setDeleted(data? data[1] : data);
        //setRowKeys([]);
        setShowAlert(false);
        setAlert(null);
    }, [data]);

    useEffect(() => {
        setLast(last);
        setShowAlert(false);
        setAlert(null);
    }, [last]);

    {
        useEffect(() => {
            setDeleted(deleted);
            /*console.log("last",last)
    console.log(deleted);
    if (deleted && deleted.length > 0) {
      for (let i = 0; i < deleted.length; i++){
        let index = last.indexOf(deleted[i])
        if (index !== -1) {
          last.splice(index, 1);
        }
      }
      

      if(!last || last.length == 0){
        setLast(null);
      }
      else {
        console.log(last);
        setLast(last);
      }
    }*/
        }, [deleted]);
    }

    const resizeModal = () => {
        setColumns(
            responsiveWidth({
                xs: xsColumns,
                sm: smColumns,
                md: mdColumns,
                lg: lgColumns,
            })
        );
        setLayout(
            responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            })
        );
    };
    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });

        //console.log(data);
    }, []);

    useEffect(() => {
        if (
            columns != null &&
            columns != undefined &&
            columns.length &&
            columns.length != undefined &&
            columns.length != null
        ) {
            if (columns.length < 6) {
                setTableExpandable(true);
            } else {
                setTableExpandable(false);
            }
        }
    }, [columns]);

    const handleStornoDetail = (record) => {
        setStornoDetail(true);
        setStornoId(record);
    };

    const closeDetail = () => {
        setStornoDetail(false);
        setStornoId(null);
    };

    // Columns setup
    const lgColumns = [
        {
            title: t("Conclusion Date"),
            dataIndex: "Sold",
            sorter: (a, b) => a.Sold.localeCompare(b.Sold),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "15%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            sorter: (a, b) => a.Company.localeCompare(b.Company),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("TIN"),
            dataIndex: "UniqueIdentificator",
            sorter: (a, b) => a.UniqueIdentificator - b.UniqueIdentificator,
            sortDirections: ["descend", "ascend"],
            width: "10%",
        },
        {
            title: t("Campaign"),
            dataIndex: "Campaign",
            sorter: (a, b) => a.Campaign - b.Campaign,
            sortDirections: ["descend", "ascend"],
            width: "25%",
        },
        {
            title: t("Price"),
            dataIndex: "Price",
            sorter: (a, b) => a.Price - b.Price,
            sortDirections: ["descend", "ascend"],
            width: "10%",
        },
        {
            title: t("Cancel "),
            dataIndex: "cancel",
            width: "5%",
            render: (text, record) => (
                <Button title={t("Cancel ")} onClick={() => handleStornoDetail(record)}>
                    <CloseCircleTwoTone twoToneColor="#eb2f96" />
                </Button>
            ),
        },
    ];
    const mdColumns = [
        {
            title: t("Conclusion Date"),
            dataIndex: "Sold",
            sorter: (a, b) => a.Sold.localeCompare(b.Sold),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "20%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            sorter: (a, b) => a.Company.localeCompare(b.Company),
            sortDirections: ["descend", "ascend"],
            width: "20%",
        },
        {
            title: t("TIN"),
            dataIndex: "UniqueIdentificator",
            sorter: (a, b) => a.UniqueIdentificator - b.UniqueIdentificator,
            sortDirections: ["descend", "ascend"],
            width: "5%",
        },
        {
            title: t("Campaign"),
            dataIndex: "Campaign",
            sorter: (a, b) => a.Campaign - b.Campaign,
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("Price"),
            dataIndex: "Price",
            sorter: (a, b) => a.Price - b.Price,
            sortDirections: ["descend", "ascend"],
            width: "10%",
        },
        {
            title: t("Cancel "),
            dataIndex: "Cancel",
            width: "5%",
            render: (text, record) => (
                <Button title={t("Cancel ")} onClick={() => handleStornoDetail(record)}>
                    <CloseCircleTwoTone />
                </Button>
            ),
        },
    ];
    const smColumns = [
        {
            title: t("Conclusion Date"),
            dataIndex: "Sold",
            sorter: (a, b) => a.Sold.localeCompare(b.Sold),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "25%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            sorter: (a, b) => a.Company.localeCompare(b.Company),
            sortDirections: ["descend", "ascend"],
            width: "30%",
        },

        {
            title: t("Campaign"),
            dataIndex: "Campaign",
            sorter: (a, b) => a.Campaign - b.Campaign,
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("Cancel "),
            dataIndex: "Cancel",
            render: (text, record) => (
                <Button title={t("Cancel ")} onClick={() => handleStornoDetail(record)}>
                    <CloseCircleTwoTone />
                </Button>
            ),
            width: "5%",
        },
    ];
    const xsColumns = [
        {
            title: t("Conclusion Date"),
            dataIndex: "Sold",
            sorter: (a, b) => a.Sold.localeCompare(b.Sold),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "30%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            sorter: (a, b) => a.Company.localeCompare(b.Company),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("Cancel "),
            dataIndex: "Cancel",
            render: (text, record) => (
                <Button title={t("Cancel ")} onClick={() => handleStornoDetail(record)}>
                    <CloseCircleTwoTone />
                </Button>
            ),
            width: "5%",
        },
    ];

    const deletedColumns = [
        {
            title: t("Conclusion Date"),
            dataIndex: "Sold",
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "15%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            width: "auto",
        },
        {
            title: t("TIN"),
            dataIndex: "UniqueIdentificator",
            width: "10%",
        },
        {
            title: t("Campaign"),
            dataIndex: "Campaign",
            width: "25%",
        },
    ];

    const handleCallback = (e, message) => {
        setShowAlert(true);
        setAlert(message);
        if (message || message.length == 0) {
            if (deleted) {
                setDeleted([...deleted, e]);
            } else {
                setDeleted([e]);
            }
            //setShowAlert(false)
            {
                /*if(last != null) {
        let index = last.indexOf(e)
        if (index !== -1) {
          last.splice(index, 1);
        }
        console.log("stornovano")
      }
      if (last == null || last.length == 0)
    setLast(null);*/
            }
            //}
        } else {
            setDeleted(null);
        }
    };

    return (
        <>
            <StornoDetail
                closeDialog={() => closeDetail()}
                visible={stornoDetail}
                record={stornoId}
                parentCallback={handleCallback}
            />

            {showAlert ? (
                <div
                    style={{
                        display: "flex",
                        marginBottom: "0 !important",
                        background: "white",
                    }}
                >
                    {alert ? (
                        <h2
                            style={{
                                display: "flex",
                                paddingLeft: "40px",
                                color: "#DC143C",
                                background: "white",
                            }}
                        >
                            {(t("Canceling was not successed "), alert)}
                        </h2>
                    ) : (
                        <h2
                            style={{
                                display: "flex",
                                paddingLeft: "40px",
                                marginBottom: "0 !important",
                                color: "green",
                                background: "white",
                            }}
                        >
                            {t("Stornování bylo úspěšné!")}
                        </h2>
                    )}
                </div>
            ) : null}
            <div style={{ backgroundColor: "white", paddingBottom: "5px" }}>
                <p
                    style={{
                        color: "#0e151a",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginTop: "10px",
                        marginRight: "auto",
                        marginLeft: "auto",
                    }}
                >
                    {t("Not canceled")}
                </p>
            </div>
            <Table
                className="tableClass"
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "30"],
                    //current: page,
                    size: layout == "horizontal" ? "default" : "small",
                    onChange: (e) => {
                        setPageNot(e);
                    },
                    total: last ? last.length : data ? data.length : 0,
                    showTotal: (total) => t("Total: ") + `${total} `,
                }}
                //rowSelection={rowSelection}
                dataSource={
                    !last
                        ? data
                            ? Array.isArray(data[0])
                                ? data[0]
                                : null
                            : null
                        : !deleted
                        ? [...last]
                        : last.filter((x) => !deleted.includes(x))
                }
                //dataSource={dataSource}
                columns={columns}
                loading={loading}
                rowKey={(record) => record.IdContact}
                expandable={
                    tableExpandable && {
                        expandedRowRender: (record) => {
                            return (
                                <Descriptions column={1} layout={layout}>
                                    {columns &&
                                        !columns.some((e) => e.dataIndex === "UniqueIdentificator") &&
                                        record.UniqueIdentificator !== null &&
                                        record.UniqueIdentificator !== "" && (
                                            <Descriptions.Item
                                                key={record.UniqueIdentificator}
                                                span={3}
                                                label={[<TagsTwoTone style={{ marginRight: "10px" }} />, t("TIN")]}
                                            >
                                                {record.UniqueIdentificator}
                                            </Descriptions.Item>
                                        )}
                                    {columns &&
                                        !columns.some((e) => e.dataIndex === "Price") &&
                                        record.Price !== null &&
                                        record.Price !== "" && (
                                            <Descriptions.Item
                                                key={record.Price}
                                                span={3}
                                                label={[<DollarTwoTone style={{ marginRight: "10px" }} />, t("Price")]}
                                            >
                                                {record.Price}
                                            </Descriptions.Item>
                                        )}
                                    {columns &&
                                        !columns.some((e) => e.dataIndex === "Campaign") &&
                                        record.Campaign !== null &&
                                        record.Campaign !== "" && (
                                            <Descriptions.Item
                                                key={record.Campaign}
                                                span={3}
                                                label={[
                                                    <ProjectTwoTone style={{ marginRight: "10px" }} />,
                                                    t("Campaign"),
                                                ]}
                                            >
                                                {record.Campaign}
                                            </Descriptions.Item>
                                        )}
                                </Descriptions>
                            );
                        },
                        rowExpandable: (record) => record === record,
                        expandIcon: ({ expanded = true, onExpand, record }) =>
                            expanded ? (
                                <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                            ) : (
                                <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                            ),
                    }
                }
            />

            <div style={{ backgroundColor: "white", paddingBottom: "5px", paddingTop: "10px" }}>
                <p
                    style={{
                        color: "#0e151a",
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginRight: "auto",
                        marginLeft: "auto",
                    }}
                >
                    {t("Canceled")}
                </p>
            </div>

            <Table
                className="tableClass"
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "30"],
                    //current: page,
                    size: layout == "horizontal" ? "default" : "small",
                    onChange: (e) => {
                        setPageCancelled(e);
                    },
                    total: deleted ? deleted.length : 0,
                    showTotal: (total) => t("Total: ") + `${total} `,
                }}
                tableLayout="auto"
                dataSource={deleted && deleted.length > 0 ? deleted : null}
                //dataSource={dataSource}
                columns={deletedColumns}
                loading={loading}
                rowKey={(record) => record}
                expandable={
                    tableExpandable && {
                        expandedRowRender: (record) => {
                            return (
                                <Descriptions column={1} layout={layout}>
                                    {columns &&
                                        !columns.some((e) => e.dataIndex === "UniqueIdentificator") &&
                                        record.UniqueIdentificator !== null &&
                                        record.UniqueIdentificator !== "" && (
                                            <Descriptions.Item
                                                key={record.UniqueIdentificator}
                                                span={3}
                                                label={[<TagsTwoTone style={{ marginRight: "10px" }} />, t("TIN")]}
                                            >
                                                {record.UniqueIdentificator}
                                            </Descriptions.Item>
                                        )}
                                    {columns &&
                                        !columns.some((e) => e.dataIndex === "Price") &&
                                        record.Price !== null &&
                                        record.Price !== "" && (
                                            <Descriptions.Item
                                                key={record.Price}
                                                span={3}
                                                label={[<DollarTwoTone style={{ marginRight: "10px" }} />, t("Price")]}
                                            >
                                                {record.Price}
                                            </Descriptions.Item>
                                        )}
                                    {columns &&
                                        !columns.some((e) => e.dataIndex === "Campaign") &&
                                        record.Campaign !== null &&
                                        record.Campaign !== "" && (
                                            <Descriptions.Item
                                                key={record.Campaign}
                                                span={3}
                                                label={[
                                                    <ProjectTwoTone style={{ marginRight: "10px" }} />,
                                                    t("Campaign"),
                                                ]}
                                            >
                                                {record.Campaign}
                                            </Descriptions.Item>
                                        )}
                                </Descriptions>
                            );
                        },
                        rowExpandable: (record) => record === record,
                        expandIcon: ({ expanded = true, onExpand, record }) =>
                            expanded ? (
                                <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                            ) : (
                                <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
                            ),
                    }
                }
            />
            <div style={{ minHeight: "90px" }}>
                <p></p>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(StornoTable);

// Will run every time state change is detected
function mapStateToProps(state) {
    // If form issued a queue, and 'QUEUED' is detected in state
    // Search and Query data to state
    if (state.SearchQuery.formName == "CANCEL" && state.SearchQuery.query && state.SearchQuery.status == "QUEUED") {
        SearchAndQueryData(5, state.SearchQuery.query);
        return { loading: true };
    }
    // If data are loaded in state ('READY') detected
    // set data to component's props
    if ((state.SearchQuery.formName == "CANCEL") & (state.SearchQuery.status == "READY") && state.SearchQuery.result)
        return { data: state.SearchQuery.result, loading: false };

    // If no queue detected and no data detected
    // set null to data, and disable loading
    return { data: null, loading: false };
}
