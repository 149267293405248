import React from "react";
//Antd
import { Button, Skeleton, Modal, Typography, Tabs } from "antd";
import { CalendarTwoTone, CloseCircleOutlined } from "@ant-design/icons";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//Images
import Form from "../../../images/help-images/ratings-form.PNG";
import FormMobile from "../../../images/help-images/ratings-form-mobile.PNG";
import Table from "../../../images/help-images/table.png";
import TableMobile from "../../../images/help-images/table-mobileE.png";
import Export from "../../../images/help-images/reporthelp-export.PNG";
import ExportMobile from "../../../images/help-images/reporthelp-export-mobile.PNG";
import Detail from "../../../images/help-images/ratings-detailE.png";

const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;

const paragraphLayout = {
    style: {
        textAlign: "left",
        color: "black",
        fontSize: "14px",
        padding: "10px",
    },
};

const headdingLayout = {
    style: {
        color: "#48a3d8",
        fontSize: "24px",
    },
};

class HelpRatings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            data: null,
            layout: "vertical",
            visible: false,
        };
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }
    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.record) {
            this.setState({ data: newProps.record });
        }
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    closeDialog() {
        this.setState({
            ChangeMeetingDateModal: false,
            MeetingReclamationModal: false,
        });
    }

    render() {
        const footer = [
            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={t("Nápověda hodnocení schůzek")}
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        <div className="help-paragraph">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="" key="0"></TabPane>
                                <TabPane tab="Filtrování" key="1">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Filtrování hodnocení schůzek")}</h2>
                                        <p>
                                            Nejprve je potřeba vyfiltrovat Vámi požadovaná data, která se následně
                                            zobrazí v tabulce a umožní další práci.
                                        </p>
                                        <img src={Form} alt="form" className="help-image-desktop" />
                                        <img src={FormMobile} alt="form" className="help-image-mobile" />
                                        <p>
                                            Formulář pro filtrování je možné zobrazit/skrýt kliknutím na tlačítko
                                            <b> Filtrovat/Skrýt filtrování</b>. Formulář se skryje automaticky po
                                            úspěšném vyfiltrování.
                                        </p>
                                        <p>
                                            <b>Pro vyhledání dat je třeba:</b>
                                        </p>
                                        <p>
                                            - Zvolit <b> kampaň</b> z nabízeného seznamu. Tím se zpřístupní výběr{" "}
                                            <b> obchodního zástupce</b> dané kampaně, zástupce není nutné vybírat, v
                                            takovém případě se zobrazí hodnocení schůzek všech zástupců.
                                        </p>
                                        <p>
                                            - Vybrat z kalendáře <b> termín</b> uskutečnění schůzky. To lze provést
                                            kliknutím na počáteční a koncové datum požadovaného termínu v zobrazeném
                                            kalendáři nebo výběrem některého z předdefinovaných termínů. Zvolený termín
                                            musí být kratší než 3 měsíce.
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Tabulka" key="2">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Práce s tabulkou")}</h2>
                                        <img src={Table} alt="table" className="help-image-desktop" />
                                        <img src={TableMobile} alt="table" className="help-image-mobile" />
                                        <p>
                                            <b>1. </b> Vlevo nad tabulkou je zobrazen počet vybraných záznamů tabulky a
                                            celkový počet vyfiltrovaných záznamů.
                                        </p>
                                        <p>
                                            <b>2.</b> Záznamy z tabulky lze vybrat pro následný export vybraných záznamů
                                            do souboru .xlsx. Data jsou vybrána kliknutím na čtvereček v prvním sloupci
                                            daného záznamu. Všechny záznamy na aktuální stránce tabulky
                                            vybereme/odvybereme kliknutím na čtvereček v záhlaví tabulky. Kliknutím na
                                            šipku vedle čtverečku v záhlaví tabulky lze vybrat všechna data z celé
                                            tabulky nebo zrušit celý výběr.
                                        </p>
                                        <p>
                                            <b>3. </b> Kliknutím na <b>šipečky</b> za názvy sloupců je možné měnit
                                            seřazení dat v tabulce. Defaultně jsou data seřazená podle data schůzky
                                            vzestupně. Záznamy v tabulce je možné řadit podle
                                            <b>
                                                {" "}
                                                Data schůzky, Názvu firmy, Data hodnocení, IČO, Osoby a Obchodního
                                                zástupce{" "}
                                            </b>
                                            . Sloupec, podle kterého jsou data ručně seřazena, je podbarven.
                                        </p>
                                        <p>
                                            <b>4. </b> V záznamech tabulky lze hledat pomocí vyhledávače nacházejícího
                                            se na pravé straně nad tabulkou. Do okénka napíšeme požadovaný řetězec a
                                            stisknutím klávesy Enter nebo kliknutím na lupu vyhledáme data. Řetězec je
                                            vyhledáván ve sloupcích <b> Firma, IČO, Osoba a Obchodní zástupce.</b>
                                        </p>
                                        <p>
                                            <b>5. </b>U každého záznamu se nachází tlačítko ve sloupci <b>Detail</b>,
                                            které po kliknutí zobrazí vyskakovací okno s podrobnými informacemi o dané
                                            schůzce.
                                        </p>
                                        <p className="help-image-desktop">
                                            <b>6. </b>Data je možné procházet po stránkách a nastavit počet zobrazených
                                            záznamů na jedné straně.
                                        </p>
                                        <p className="help-image-mobile">
                                            <b>6. </b> Data je možné procházet po stránkách.
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Export" key="3">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Export")}</h2>
                                        <img src={Export} alt="export" className="help-image-desktop" />
                                        <img src={ExportMobile} alt="export" className="help-image-mobile" />

                                        <p>
                                            Export záznamů z tabulky se provádí v sekci <b>Exportovat</b>. Data budou
                                            vyexportována v pořadí nastaveném v tabulce.
                                        </p>
                                        <p>
                                            Je možné exportovat: <br />
                                            - Všechny sloupce všech záznamů tabulky
                                            <br />
                                            - Nastavené sloupce všech záznamů tabulky
                                            <br />
                                            - Všechny sloupce vybraných záznamů tabulky
                                            <br />
                                            - Nastavené (viditelné) sloupce vybraných záznamů
                                            <br />
                                        </p>
                                        <p>
                                            V případě, že chcete exportovat data pouze s viditelnými sloupci, zaškrtněte
                                            volbu
                                            <b> Exportovat zobrazené sloupce</b>. Pro exportování je nutné mít data
                                            úspěšně vyfiltrována pomocí formuláře. Tlačítko{" "}
                                            <b>Exportovat VYBRANÉ záznamy</b> se zpřístupní po výběru dat z tabulky.
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Detail" key="4">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Detail hodnocení")}</h2>
                                        <p>
                                            Detail prodeje se otevře kliknutím na tlačítko ve sloupci <b>Detail</b> u
                                            příslušného záznamu. Zobrazuje podrobné informace o daném prodeji a nabízí
                                            další funkce.
                                        </p>
                                        <img src={Detail} alt="detail-telesale" />
                                        <p>
                                            Pomocí <b>šipek</b> po levé a pravé straně od názvu firmy lze přejít na
                                            detail předchozího/následujícího záznamu z tabulky.
                                        </p>
                                        <p>
                                            V popisu prodeje lze telefonní čísla volat po kliknutí na modře zvýrazněné
                                            telefonní číslo.
                                        </p>
                                    </Paragraph>
                                </TabPane>
                                <TabPane tab="" key="10"></TabPane>
                            </Tabs>
                        </div>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default HelpRatings;
