import React, { useState, useEffect } from "react";
//Redux
import { store } from "../../providers/ReduxProvider";
import { setSearchQuery, setUserMeetingForm } from "../../../store/actions";
import AmaxApiService from "../../../services/AmaxApiService";
import { resetErrorView } from "../../../services/ErrorHandler";
//Antd
import { Button, Form, Select, Collapse, Popover, Checkbox, Input } from "antd";
import { SearchOutlined, CalendarOutlined, UpOutlined, FilterFilled } from "@ant-design/icons";
//Moment
// locale for czech calendar
import { cs, enGB } from "date-fns/locale";
//Language
import t from "../../providers/LanguageProvider";
//DateRange and DefinedRange
import { DefinedRange, DateRange } from "react-date-range";
import { defineds, customRanges } from "../DateRange";
import { addDays, subDays, startOfDay, startOfWeek, getDate, getMonth, getYear, differenceInDays } from "date-fns";

const { Option } = Select;

const ReportSchuzkyForm = () => {
    const [CheckedDateOne, setCheckedDateOne] = useState(true);
    const [CheckedDateTwo, setCheckedDateTwo] = useState(false);

    const [firstDateOne, setFirstDateOne] = useState(addDays(startOfWeek(new window.Date()), 1));
    const [secondDateOne, setSecondDateOne] = useState(addDays(startOfWeek(new window.Date()), 7));

    const [firstDateTwo, setFirstDateTwo] = useState(addDays(startOfWeek(new window.Date()), 1));
    const [secondDateTwo, setSecondDateTwo] = useState(addDays(startOfWeek(new window.Date()), 7));

    const [openCalendarOne, setOpenCalendarOne] = useState(false);
    const [openCalendarTwo, setOpenCalendarTwo] = useState(false);
    const range = customRanges();

    const [dateOne, setDateOne] = useState([
        {
            startDate: addDays(startOfWeek(new window.Date()), 1),
            endDate: addDays(startOfWeek(new window.Date()), 7),
            key: "selection",
        },
    ]);

    const [dateTwo, setDateTwo] = useState([
        {
            startDate: addDays(startOfWeek(new window.Date()), 1),
            endDate: subDays(startOfDay(new Date()), 1),
            key: "selection",
        },
    ]);

    const inputDateOne =
        getDate(dateOne[0].startDate) +
        ". " +
        (getMonth(dateOne[0].startDate) + 1) +
        ". " +
        getYear(dateOne[0].startDate) +
        " ~ " +
        getDate(dateOne[0].endDate) +
        ". " +
        (getMonth(dateOne[0].endDate) + 1) +
        ". " +
        getYear(dateOne[0].endDate);

    const inputDateTwo =
        getDate(dateTwo[0].startDate) +
        ". " +
        (getMonth(dateTwo[0].startDate) + 1) +
        ". " +
        getYear(dateTwo[0].startDate) +
        " ~ " +
        getDate(dateTwo[0].endDate) +
        ". " +
        (getMonth(dateTwo[0].endDate) + 1) +
        ". " +
        getYear(dateTwo[0].endDate);

    const inputLayout = {
        style: {
            height: "30px",
            maxWidth: "300px",
        },
    };

    const onCheckedDateOne = () => {
        setCheckedDateOne(!CheckedDateOne);

        if (CheckedDateOne) {
            setOpenCalendarOne(false);
        } else {
            setOpenCalendarOne(true);
        }
    };

    const onCheckedDateTwo = () => {
        setCheckedDateTwo(!CheckedDateTwo);

        if (CheckedDateTwo) {
            setOpenCalendarTwo(false);
        } else {
            setOpenCalendarTwo(true);
        }
    };

    const [campaigns, setCampaigns] = useState(null);
    const [badMeetingDateRange, setBadMeetingDateRange] = useState(false);
    const [badMeetingDateRangeTwo, setBadMeetingDateRangeTwo] = useState(false);

    const [badAgreedDateRange, setBadAgreedDateRange] = useState(false);
    const [badOptionComb, setBadOptionComb] = useState(false);
    const [chooseCamp, setChooseCamp] = useState(false);
    const [salesRepresentatives, setSalesRepresentatives] = useState(null);
    const [SRId, setSRId] = useState(store.getState().Logic.meetingSR ? store.getState().Logic.meetingSR : "0");

    const [AllSR, setAllSR] = useState(null);

    const [TssId, setTssId] = useState(
        store.getState().Logic.meetingSelectedTss ? store.getState().Logic.meetingSelectedTss : "0"
    );

    const [MeetingType, setMeetingType] = useState("MeetingDates");
    const [ReportedStatus, setReportedStatus] = useState("2");

    const [visibleFilter, setVisibleFilter] = useState(true);
    const [visibleOne, setVisibleOne] = useState(false);
    const [visibleTwo, setVisibleTwo] = useState(false);
    const [visibleThree, setVisibleThree] = useState(false);

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const formItemControl = {
        style: {
            maxWidth: "300px",
        },
    };

    const formRangeControl = {
        style: {
            width: "auto",
        },
    };

    /**
     * Evaluate sales reps on campaign change
     *
     * @param {Integer} campaignID
     */
    const evaluateSR = (campaignID) => {
        if (!campaignID) return null;
        // Load sales reps (available to user) to state
        AmaxApiService.ReportMeetings.SRsByUser(campaignID).then((salesRepresentatives) => {
            setSalesRepresentatives(salesRepresentatives);
            setAllSR(salesRepresentatives.map((item) => item.IdOz));
        });
    };

    const onMobileDateOneChange = (item) => {
        setDateOne([item.selection]);
    };

    const onMobileDateTwoChange = (item) => {
        setDateTwo([item.selection]);
    };

    /**
     * Runs on form submit, evaluates fields and sets SearchQuery
     *
     * @param {Object} values
     */
    const onFinish = (values) => {
        resetErrorView();
        setBadOptionComb(false);
        setChooseCamp(false);
        setBadMeetingDateRange(false);
        setBadAgreedDateRange(false);
        setBadMeetingDateRangeTwo(false);

        if (TssId == "0") {
            setChooseCamp(true);
            return;
        }

        if (CheckedDateOne == false && CheckedDateTwo == false) {
            setBadOptionComb(true);
            return;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() - 1);
        if (differenceInDays(dateOne[0].endDate, dateOne[0].startDate) > 91) {
            setBadMeetingDateRange(true);
        } else if (dateTwo[0].endDate > today) {
            setBadAgreedDateRange(true);
        } else if (differenceInDays(dateTwo[0].endDate, dateTwo[0].startDate) > 91) {
            setBadMeetingDateRangeTwo(true);
        } else {
            values = {
                ...values,
                MeetingDate: dateOne,
                AgreedDate: dateTwo,
                AllSR: AllSR,
            };
            if (!values.MeetingType) {
                values = { ...values, MeetingType: MeetingType };
            }
            if (!values.SRId) {
                values = { ...values, SRId: SRId };
            }
            if (!values.TssId) {
                values = { ...values, TssId: TssId };
            }
            if (!values.ReportedStatus) {
                values = { ...values, ReportedStatus: ReportedStatus };
            }
            store.dispatch(setSearchQuery("REPORT_SCHUZKY", values));
            store.dispatch(setUserMeetingForm(TssId, SRId, ReportedStatus, dateOne, dateTwo));

            setOpenCalendarOne(false);
            setOpenCalendarTwo(false);
            setVisibleFilter(!visibleFilter);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleCampaignChange = (value) => {
        setSalesRepresentatives(null);
        setTssId(value);
        evaluateSR(value);
        setSRId("0");
        setAllSR(null);
    };

    const onSalesChange = (value) => {
        setSRId(value);
        if (value === "0") {
            setAllSR(salesRepresentatives.map((item) => item.IdOz));
        } else {
            setAllSR(null);
        }
    };

    const getUserMeetingCampaigns = async () => {
        await AmaxApiService.ReportMeetings.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        resetErrorView();
        if (!campaigns) {
            getUserMeetingCampaigns();
        }

        if (store.getState().Logic.meetingSelectedTss) {
            evaluateSR(store.getState().Logic.meetingSelectedTss);
        }

        if (store.getState().Logic.meetingDate) {
            if (typeof store.getState().Logic.meetingDate[0].startDate != "string") {
                setDateOne(store.getState().Logic.meetingDate);
            } else {
                const storedDate = new Date(store.getState().Logic.meetingDate[0].startDate);
                const endDate = new Date(store.getState().Logic.meetingDate[0].endDate);
                setDateOne([
                    {
                        startDate: storedDate.getTime(),
                        endDate: endDate.getTime(),
                        key: "selection",
                    },
                ]);
            }
        }

        if (store.getState().Logic.agreedDate) {
            if (typeof store.getState().Logic.agreedDate[0].startDate != "string") {
                setDateTwo(store.getState().Logic.agreedDate);
            } else {
                const storedDate = new Date(store.getState().Logic.agreedDate[0].startDate);
                const endDate = new Date(store.getState().Logic.agreedDate[0].endDate);
                setDateTwo([
                    {
                        startDate: storedDate.getTime(),
                        endDate: endDate.getTime(),
                        key: "selection",
                    },
                ]);
            }
        }
    }, []);

    useEffect(() => {
        if (differenceInDays(dateOne[0].endDate, dateOne[0].startDate) > 91) {
            setBadMeetingDateRange(true);
        } else {
            setBadMeetingDateRange(false);
        }
    }, [dateOne]);

    useEffect(() => {
        if (differenceInDays(dateTwo[0].endDate, dateTwo[0].startDate) > 91) {
            setBadMeetingDateRangeTwo(true);
        } else {
            setBadMeetingDateRangeTwo(false);
        }
    }, [dateTwo]);

    useEffect(() => {
        if (CheckedDateOne == true && CheckedDateTwo == true) {
            setMeetingType("BothDates");
        } else if (CheckedDateOne == true) {
            setMeetingType("MeetingDates");
        } else if (CheckedDateTwo == true) {
            setMeetingType("AgreedDates");
        }
    }, [CheckedDateOne, CheckedDateTwo]);

    useEffect(() => {
        if (openCalendarOne == true) {
            setOpenCalendarTwo(false);
        }
    }, [openCalendarOne]);

    useEffect(() => {
        if (openCalendarTwo == true) {
            setOpenCalendarOne(false);
        }
    }, [openCalendarTwo]);

    useEffect(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        today.setDate(today.getDate() - 1);
        if (dateTwo[0].endDate > today) {
            setBadAgreedDateRange(true);
        } else {
            setBadAgreedDateRange(false);
        }
    }, [dateTwo]);

    return (
        <div className="container">
            <button className="filter-button" onClick={() => setVisibleFilter(!visibleFilter)}>
                {visibleFilter ? (
                    <h3 style={{ fontWeight: "bold" }}>
                        {t("Hide Filter ")}
                        <UpOutlined />
                    </h3>
                ) : (
                    <h3>
                        {t("Filter Meetings ")} <FilterFilled />
                    </h3>
                )}
            </button>

            {visibleFilter && (
                <Form
                    className="form"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        MeetingDate: dateOne,
                        AgreedDate: dateTwo,
                        TssId: TssId,
                        SRId: SRId,
                        AllSR: AllSR,
                        MeetingType: `${MeetingType}`,
                        ReportedStatus: `${ReportedStatus}`,
                    }}
                    key="1"
                >
                    <div className="form-div">
                        <div className="sales-label">
                            <div className="sales-label-text">{t("Campaign")}</div>
                            <Select
                                className="sales-label"
                                // showSearch
                                placeholder={t("Select a campaign")}
                                loading={campaigns ? false : true}
                                disabled={campaigns ? false : true}
                                onChange={handleCampaignChange}
                                value={TssId}
                                defaultValue={TssId}
                            >
                                <Option key={"0"} value={"0"} default>
                                    {t("Not Selected")}
                                </Option>
                                {campaigns &&
                                    campaigns.map((campaign) => (
                                        <Select.Option key={campaign.CampaignId} value={campaign.CampaignId}>
                                            {campaign.CampaignName}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </div>

                        <div className="responsive-advanced-up sales-label">
                            <div className="sales-label-text">{t("Sales Representative:")}</div>
                            <Select
                                className="sales-label"
                                loading={salesRepresentatives ? false : true}
                                disabled={salesRepresentatives ? false : true}
                                //showSearch
                                placeholder={t("Sales Representative")}
                                onChange={onSalesChange}
                                value={SRId}
                                defaultValue={SRId}
                            >
                                <Option key={"0"} value={"0"} default>
                                    {t("Select All")}
                                </Option>

                                {salesRepresentatives
                                    ? salesRepresentatives.map((SR) => (
                                          <Option key={SR.IdOz} value={SR.IdOz}>
                                              {SR.OzName}
                                          </Option>
                                      ))
                                    : null}
                            </Select>
                        </div>
                    </div>

                    <div>
                        <Form.Item className="form-advanced">
                            <Checkbox defaultChecked={CheckedDateOne} onChange={onCheckedDateOne}>
                                {t("Meeting Date")}
                            </Checkbox>
                            <Input.Group>
                                <Input
                                    {...inputLayout}
                                    disabled={CheckedDateOne ? false : true}
                                    value={inputDateOne}
                                    onClick={() => (CheckedDateOne ? setOpenCalendarOne(!openCalendarOne) : null)}
                                    addonAfter={
                                        <CalendarOutlined
                                            onClick={() =>
                                                CheckedDateOne ? setOpenCalendarOne(!openCalendarOne) : null
                                            }
                                        />
                                    }
                                />
                            </Input.Group>
                        </Form.Item>

                        {openCalendarOne && (
                            <div>
                                <Form.Item
                                    className="form-advanced"
                                    {...formItemLayout}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("Please choose a date"),
                                        },
                                    ]}
                                >
                                    <div //**CALENDAR WITH POPOVER DEFINED RANGES FOR MOBILE***
                                        className="left-column-calendar"
                                    >
                                        <div className="date-range-picker-mobile">
                                            <Popover
                                                trigger="click"
                                                placement="bottom"
                                                arrowPointAtCenter={true}
                                                onClick={() => setVisibleOne(!visibleOne)}
                                                visible={visibleOne}
                                                content={
                                                    <div onClick={() => setVisibleOne(false)}>
                                                        <DefinedRange
                                                            onChange={onMobileDateOneChange}
                                                            ranges={dateOne}
                                                            inputRanges={[]}
                                                            staticRanges={range}
                                                            rangeColors={["#4897d8"]}
                                                        />
                                                    </div>
                                                }
                                            >
                                                <button className="popup">{t("Quick Select")}</button>
                                            </Popover>
                                        </div>
                                        <DateRange
                                            className={`${badMeetingDateRange ? "red" : ""}`}
                                            {...formRangeControl}
                                            locale={store.getState().Logic.language === "cs" ? cs : enGB}
                                            value={dateOne}
                                            defaultValue={dateOne}
                                            editableDateInputs={false}
                                            onChange={(item) => {
                                                setDateOne([item.selection]);
                                                setFirstDateOne(item.selection.startDate);
                                                setSecondDateOne(item.selection.endDate);
                                            }}
                                            moveRangeOnFirstSelection={false}
                                            ranges={dateOne}
                                            weekStartsOn={1}
                                            rangeColors={["#48a3d8"]}
                                            dateDisplayFormat="d. MMM, yyy"
                                            minDate={defineds.minDateOfCalendar}
                                            maxDate={defineds.maxDateOfCalendar}
                                        />
                                    </div>

                                    <div //**CALENDAR WITH DEFINED RANGES FOR DESKTOP***
                                        className="right-column-calendar"
                                    >
                                        <DefinedRange
                                            className="date-range-picker-desktop"
                                            {...formRangeControl}
                                            onChange={(item) => {
                                                setDateOne([item.selection]);
                                                setFirstDateOne(item.selection.startDate);
                                                setSecondDateOne(item.selection.endDate);
                                            }}
                                            ranges={dateOne}
                                            inputRanges={[]}
                                            staticRanges={range}
                                            rangeColors={["#4897d8"]}
                                        />
                                    </div>
                                </Form.Item>

                                <Form.Item //WARNING FOR TERM VALIDATION
                                    {...formItemLayout}
                                >
                                    {badMeetingDateRange && (
                                        <h3
                                            style={{
                                                display: "flex",
                                                paddingRight: "5px",
                                                color: "#DC143C",
                                            }}
                                        >
                                            {t("Warning! The term must be less than 3 months!")}
                                        </h3>
                                    )}
                                </Form.Item>
                            </div>
                        )}
                        <div className="responsive-advanced-up form-advanced">
                            <Form.Item className="form-advanced">
                                <Checkbox defaultChecked={CheckedDateTwo} onChange={onCheckedDateTwo}>
                                    {t("Agreed Meeting Date")}
                                </Checkbox>
                                <Input.Group>
                                    <Input
                                        {...inputLayout}
                                        disabled={CheckedDateTwo ? false : true}
                                        value={inputDateTwo}
                                        onClick={() => (CheckedDateTwo ? setOpenCalendarTwo(!openCalendarTwo) : null)}
                                        addonAfter={
                                            <CalendarOutlined
                                                onClick={() =>
                                                    CheckedDateTwo ? setOpenCalendarTwo(!openCalendarTwo) : null
                                                }
                                            />
                                        }
                                    />
                                </Input.Group>
                            </Form.Item>

                            {openCalendarTwo && (
                                <div>
                                    <Form.Item
                                        className="form-advanced"
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please choose a date"),
                                            },
                                        ]}
                                    >
                                        <div //**CALENDAR WITH POPOVER DEFINED RANGES FOR MOBILE***
                                            className="left-column-calendar"
                                        >
                                            <div className="date-range-picker-mobile">
                                                <Popover
                                                    trigger="click"
                                                    placement="bottom"
                                                    arrowPointAtCenter={true}
                                                    onClick={() => setVisibleThree(!visibleThree)}
                                                    visible={visibleThree}
                                                    content={
                                                        <div onClick={() => setVisibleThree(false)}>
                                                            <DefinedRange
                                                                onChange={onMobileDateTwoChange}
                                                                ranges={dateTwo}
                                                                inputRanges={[]}
                                                                staticRanges={range}
                                                                rangeColors={["#4897d8"]}
                                                            />
                                                        </div>
                                                    }
                                                >
                                                    <button className="popup">{t("Quick Select")}</button>
                                                </Popover>
                                            </div>
                                            <DateRange
                                                className={`${badAgreedDateRange ? "red" : ""}`}
                                                {...formRangeControl}
                                                locale={store.getState().Logic.language === "cs" ? cs : enGB}
                                                value={dateTwo}
                                                defaultValue={dateTwo}
                                                editableDateInputs={false}
                                                onChange={(item) => {
                                                    setDateTwo([item.selection]);
                                                    setFirstDateTwo(item.selection.startDate);
                                                    setSecondDateTwo(item.selection.endDate);
                                                }}
                                                moveRangeOnFirstSelection={false}
                                                ranges={dateTwo}
                                                weekStartsOn={1}
                                                rangeColors={["#48a3d8"]}
                                                dateDisplayFormat="d. MMM, yyy"
                                                minDate={defineds.minDateOfCalendar}
                                                maxDate={defineds.maxDateOfCalendar}
                                            />
                                        </div>

                                        <div //**CALENDAR WITH DEFINED RANGES FOR DESKTOP***
                                            className="right-column-calendar"
                                        >
                                            <DefinedRange
                                                className="date-range-picker-desktop"
                                                {...formRangeControl}
                                                onChange={(item) => {
                                                    setDateTwo([item.selection]);
                                                    setFirstDateTwo(item.selection.startDate);
                                                    setSecondDateTwo(item.selection.endDate);
                                                }}
                                                ranges={dateTwo}
                                                inputRanges={[]}
                                                staticRanges={range}
                                                rangeColors={["#4897d8"]}
                                            />
                                        </div>
                                    </Form.Item>

                                    <Form.Item //WARNING FOR TERM VALIDATION
                                        {...formItemLayout}
                                    >
                                        {badAgreedDateRange ? (
                                            <h3
                                                style={{
                                                    display: "flex",
                                                    paddingRight: "5px",
                                                    color: "#DC143C",
                                                }}
                                            >
                                                {t(
                                                    "Warning! The appointment period must be lower than the current date!"
                                                )}
                                            </h3>
                                        ) : badMeetingDateRangeTwo ? (
                                            <h3
                                                style={{
                                                    display: "flex",
                                                    paddingRight: "5px",
                                                    color: "#DC143C",
                                                }}
                                            >
                                                {t("Warning! The term must be less than 3 months!")}
                                            </h3>
                                        ) : null}
                                    </Form.Item>
                                </div>
                            )}
                        </div>
                    </div>
                    <Collapse //COLLAPSE ADVANCED FORM ITEMS FOR MOBILE
                        className="responsive-advanced-down"
                        bordered={false}
                        expandIconPosition={"left"}
                    >
                        <Collapse.Panel header={t("Advanced")} key="1">
                            <div className="sales-label">
                                <div className="sales-label-text">{t("Sales Representative")}</div>
                                <Select
                                    className="sales-label"
                                    loading={salesRepresentatives ? false : true}
                                    disabled={salesRepresentatives ? false : true}
                                    //showSearch
                                    placeholder={t("Sales Representative")}
                                    onChange={onSalesChange}
                                    value={SRId}
                                >
                                    <Option key={"0"} value={"0"} default>
                                        {t("Select All")}
                                    </Option>

                                    {salesRepresentatives
                                        ? salesRepresentatives.map((SR) => (
                                              <Option key={SR.IdOz} value={SR.IdOz}>
                                                  {SR.OzName}
                                              </Option>
                                          ))
                                        : null}
                                </Select>
                            </div>
                            <Form.Item className="form-advanced">
                                <Checkbox defaultChecked={CheckedDateTwo} onChange={onCheckedDateTwo}>
                                    {t("Agreed Meeting Date")}
                                </Checkbox>
                                <Input.Group>
                                    <Input
                                        {...inputLayout}
                                        disabled={CheckedDateTwo ? false : true}
                                        value={inputDateTwo}
                                        onClick={() => (CheckedDateTwo ? setOpenCalendarTwo(!openCalendarTwo) : null)}
                                        addonAfter={
                                            <CalendarOutlined
                                                onClick={() =>
                                                    CheckedDateTwo ? setOpenCalendarTwo(!openCalendarTwo) : null
                                                }
                                            />
                                        }
                                    />
                                </Input.Group>
                            </Form.Item>

                            {openCalendarTwo && (
                                <div>
                                    <Form.Item
                                        className="form-advanced"
                                        {...formItemLayout}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("Please choose a date"),
                                            },
                                        ]}
                                    >
                                        <div //**CALENDAR WITH POPOVER DEFINED RANGES FOR MOBILE***
                                            className="left-column-calendar"
                                        >
                                            <div className="date-range-picker-mobile">
                                                <Popover
                                                    trigger="click"
                                                    placement="bottom"
                                                    arrowPointAtCenter={true}
                                                    onClick={() => setVisibleTwo(!visibleTwo)}
                                                    visible={visibleTwo}
                                                    content={
                                                        <div onClick={() => setVisibleTwo(false)}>
                                                            <DefinedRange
                                                                onChange={onMobileDateTwoChange}
                                                                ranges={dateTwo}
                                                                inputRanges={[]}
                                                                staticRanges={range}
                                                                rangeColors={["#4897d8"]}
                                                            />
                                                        </div>
                                                    }
                                                >
                                                    <button className="popup">{t("Quick Select")}</button>
                                                </Popover>
                                            </div>
                                            <DateRange
                                                className={`${badAgreedDateRange ? "red" : ""}`}
                                                {...formRangeControl}
                                                locale={store.getState().Logic.language === "cs" ? cs : enGB}
                                                value={dateTwo}
                                                defaultValue={dateTwo}
                                                editableDateInputs={false}
                                                onChange={(item) => {
                                                    setDateTwo([item.selection]);
                                                    setFirstDateTwo(item.selection.startDate);
                                                    setSecondDateTwo(item.selection.endDate);
                                                }}
                                                moveRangeOnFirstSelection={false}
                                                ranges={dateTwo}
                                                weekStartsOn={1}
                                                rangeColors={["#48a3d8"]}
                                                dateDisplayFormat="d. MMM, yyy"
                                                minDate={defineds.minDateOfCalendar}
                                                maxDate={defineds.maxDateOfCalendar}
                                            />
                                        </div>
                                    </Form.Item>

                                    <Form.Item //WARNING FOR TERM VALIDATION
                                        {...formItemLayout}
                                    >
                                        {badAgreedDateRange ? (
                                            <h3
                                                style={{
                                                    display: "flex",
                                                    paddingRight: "5px",
                                                    color: "#DC143C",
                                                }}
                                            >
                                                {t(
                                                    "Warning! The appointment period must be lower than the current date!"
                                                )}
                                            </h3>
                                        ) : badMeetingDateRangeTwo ? (
                                            <h3
                                                style={{
                                                    display: "flex",
                                                    paddingRight: "5px",
                                                    color: "#DC143C",
                                                }}
                                            >
                                                {t("Warning! The term must be less than 3 months!")}
                                            </h3>
                                        ) : null}
                                    </Form.Item>
                                </div>
                            )}
                        </Collapse.Panel>
                    </Collapse>
                    <Form.Item {...formItemLayout}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SearchOutlined style={{ paddingRight: "5px" }} />}
                            >
                                <b>{t("Display")}</b>
                            </Button>
                            {badOptionComb && (
                                <span style={{ marginLeft: "5px", color: "#DC143C" }}>
                                    {t("Warning! Select at least one datum!")}
                                </span>
                            )}
                            {chooseCamp && (
                                <span style={{ marginLeft: "5px", color: "#DC143C" }}>
                                    {t("Please select a Campaign!")}
                                </span>
                            )}
                        </div>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
};

export default ReportSchuzkyForm;
