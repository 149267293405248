import { addDays, startOfWeek, differenceInDays } from "date-fns";

export default function Logic(
    // Default Initial Store State
    state = {
        loading: false,
        error: false,
        language: null,
        permisions: null,

        meetingCampaigns: null,
        meetingSelectedTss: null,
        meetingSR: null,
        meetingDisplay: null,
        meetingDate: null,
        agreedDate: null,
        meetingColumns: null,

        telemarketingSelectedTss: null,
        telemarketingPriority: null,
        telemarketingQuestion: null,
        telemarketingDisplay: null,
        telemarketingDate: null,

        telesalesSelectedTss: null,
        telesalesDisplay: null,
        telesalesDate: null,

        ratingSelectedTss: null,
        ratingSR: null,
        ratingDate: null,

        cancelFilter: null,
        cancelTIN: null,
        cancelCompanyID: null,
        cancelSelectedTss: null,
        cancelDate: null,
    },
    action
) {
    switch (action.type) {
        case "SET_LOADING":
            return Object.assign({}, state, {
                loading: action.bool,
            });

        case "DISPLAY_ERROR":
            return Object.assign({}, state, {
                error: {
                    display: action.display,
                    status: action.status,
                    message: action.message,
                    trace: action.stackTrace,
                },
            });

        case "RESET_ERROR":
            return Object.assign({}, state, {
                error: null,
            });

        case "SET_LANGUAGE":
            return Object.assign({}, state, { language: action.language });

        case "SET_PERMISIONS":
            return Object.assign({}, state, { permisions: action.permisions });

            /////////////////////////////////////////////////
            {
                /*case "SET_MEETING_CAMPAIGNS":
      return Object.assign({}, state, { 
        meetingCampaigns: action.meetingCampaigns, 
      });*/
            }

        case "SET_MEETING_FORM":
            return Object.assign({}, state, {
                meetingSelectedTss: action.meetingSelectedTss,
                meetingSR: action.meetingSR,
                meetingDisplay: action.meetingDisplay,
                meetingDate: action.meetingDate,
                agreedDate: action.agreedDate,
            });

        /////////////////////////////////////////////////

        case "SET_TELEMARKETING_FORM":
            return Object.assign({}, state, {
                telemarketingSelectedTss: action.telemarketingSelectedTss,
                telemarketingPriority: action.telemarketingPriority,
                telemarketingQuestion: action.telemarketingQuestion,
                telemarketingDisplay: action.telemarketingDisplay,
                telemarketingDate: action.telemarketingDate,
            });

        /////////////////////////////////////////////////

        case "SET_TELESALES_FORM":
            return Object.assign({}, state, {
                telesalesSelectedTss: action.telesalesSelectedTss,
                telesalesDisplay: action.telesalesDisplay,
                telesalesDate: action.telesalesDate,
            });

        /////////////////////////////////////////////////
        case "SET_RATINGS_CAMPAIGNS":
            return Object.assign({}, state, {
                ratingsCampaigns: action.ratingsCampaigns,
            });

        case "SET_RATINGS_FORM":
            return Object.assign({}, state, {
                ratingSelectedTss: action.ratingSelectedTss,
                ratingSR: action.ratingSR,
                ratingsDate: action.ratingsDate,
            });

        /////////////////////////////////////////////////

        case "SET_TELESALES_FORM":
            return Object.assign({}, state, {
                telesalesSelectedTss: action.telesalesSelectedTss,
                telesalesDisplay: action.telesalesDisplay,
                telesalesDate: action.telesalesDate,
            });
        /////////////////////////////////////////////////

        case "SET_CANCEL_FORM":
            return Object.assign({}, state, {
                cancelFilter: action.cancelFilter,
                cancelTIN: action.cancelTIN,
                cancelCompanyID: action.cancelCompanyID,
                cancelSelectedTss: action.cancelSelectedTss,
                cancelDate: action.cancelDate,
            });

        default:
            return state;
    }
}
