import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfMonth,
    addMonths,
    startOfWeek,
    endOfWeek,
    isSameDay,
    addYears,
    startOfYear,
    addHours,
} from "date-fns";

//Language
import t from "../providers/LanguageProvider";

export const defineds = {
    //this week
    startOfWeek: startOfDay(addDays(startOfWeek(new Date()), 1)),
    endOfWeek: startOfDay(addDays(endOfWeek(new Date()), 1)),
    //last week
    startOfLastWeek: startOfDay(addDays(startOfWeek(new Date()), -6)),
    endOfLastWeek: startOfDay(addDays(endOfWeek(new Date()), -6)),
    //next week
    startOfNextWeek: startOfDay(addDays(startOfWeek(new Date()), 8)),
    endOfNextWeek: startOfDay(addDays(endOfWeek(new Date()), 8)),
    //today
    startOfToday: startOfDay(new Date()),
    endOfToday: endOfDay(new Date()),
    //this month
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: addHours(startOfMonth(addMonths(new Date(), 1)), -23),
    //last month
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: addHours(startOfMonth(new Date()), -24),
    //next month
    startOfNextMonth: addHours(startOfMonth(addMonths(new Date(), 1)), 1),
    endOfNextMonth: addHours(startOfMonth(addMonths(new Date(), 2)), -23),
    //three months
    startThreeMonths: addHours(startOfMonth(addMonths(new Date(), 1)), 1),
    endOfThreeMonths: addHours(startOfMonth(addMonths(new Date(), 4)), -23),
    //min and max values of calendar
    minDateOfCalendar: startOfYear(new Date(2005, 1, 1)),
    maxDateOfCalendar: startOfYear(addYears(new Date(), 2)),
};

const staticRangeHandler = {
    range: {},
    isSelected(range) {
        const definedRange = this.range();
        return isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate);
    },
};

export function createStaticRanges2(ranges) {
    return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const customRanges = () =>
    createStaticRanges2([
        {
            label: t("Today"),
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.startOfToday,
            }),
        },
        {
            label: t("This week"),
            range: () => ({
                startDate: defineds.startOfWeek,
                endDate: defineds.endOfWeek,
            }),
        },
        {
            label: t("Last week"),
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek,
            }),
        },
        {
            label: t("Next week"),
            range: () => ({
                startDate: defineds.startOfNextWeek,
                endDate: defineds.endOfNextWeek,
            }),
        },
        {
            label: t("This month"),
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfMonth,
            }),
        },
        {
            label: t("Last month"),
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth,
            }),
        },
        {
            label: t("Next month"),
            range: () => ({
                startDate: defineds.startOfNextMonth,
                endDate: defineds.endOfNextMonth,
            }),
        },
        {
            label: t("Next three months"),
            range: () => ({
                startDate: defineds.startThreeMonths,
                endDate: defineds.endOfThreeMonths,
            }),
        },
    ]);

export const range = customRanges();
