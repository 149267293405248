import React from "react";
//Antd
import { Button, Descriptions, PageHeader, Typography, Divider } from "antd";
//Layout
import LayoutWrapper from "../components/layouts/LayoutWrapper";
//Language
import t from "../components/providers/LanguageProvider";
//Icons
import { FilePdfOutlined } from "@ant-design/icons";
import exportToPdf from "../components/common/exports/PDFobchodniPodminky";

const { Paragraph, Text } = Typography;

const ObchodniPodminky = () => {
    const headingLayout = {
        style: {
            fontWeight: "600",
            color: "#48a3d8",
            fontSize: "24px",
            paddingTop: "10px",
        },
    };

    const paragraphLayout = {
        style: {
            textAlign: "left",
            color: "black",
            fontSize: "14px",
            paddingBottom: "10px",
        },
    };
    const exportPDF = () => {
        exportToPdf();
    };

    return (
        <LayoutWrapper>
            <PageHeader
                onBack={() => {
                    window.location = "/";
                }}
                title={t("Obchodní podmínky pro sjednávání schůzek")}
                extra={[]}
            >
                <div style={{ padding: "20px", paddingBottom: "50px" }}>
                    <p {...headingLayout}>{t("Řádně sjednaná schůzka")}</p>
                    <Paragraph {...paragraphLayout}>
                        <p>
                            Je taková schůzka, která je dohodnutá s kompetentní osobou dle písemně odsouhlaseného
                            scénáře rozhovoru, který je Přílohou Smlouvy o zajištění hlasových služeb.
                        </p>
                        <p>
                            Je taková schůzka, kde souhlasí datum, hodina a lokalita s uzavřenou Technickou a servisní
                            specifikací, která je nedílnou součástí Smlouvy. Zhotovitel je povinen dodržet naplánovanou
                            hodinu, datum a lokalitu – úpravy jsou možné pouze po předcházejícím písemném odsouhlasení{" "}
                        </p>
                        <p>
                            Je taková schůzka, kde je dodržen čas na přejezdy uvedený v Technické a servisní
                            specifikaci, výjimku lze s Objednatelem sjednat pouze po písemném odsouhlasení.
                        </p>
                        <p>Je taková schůzka, kde byla Zhotovitelem ověřena adresa místa schůzky. </p>
                        <p>
                            Je taková schůzka, která je zrušena z důvodu „vyšší moci“, jako je například nemoc,
                            nepředvídatelný odjezd klienta z pracoviště apod.{" "}
                        </p>
                        <p>
                            Je taková schůzka, která je v případě dodání databáze subjektů Zhotovitelem, sjednána u
                            firem odpovídajících výběrovým kriteriím uvedených v Technické a servisní specifikaci
                            (oborové zatřídění, počet zaměstnanců, právní forma…)
                        </p>
                    </Paragraph>

                    <p {...headingLayout}>{t("Špatně sjednaná schůzka")}</p>
                    <Paragraph {...paragraphLayout}>
                        <p>
                            Je taková schůzka, která je dohodnutá s nekompetentní osobou. Vymezení pojmu kompetentní
                            osoba je stanoveno scénářem rozhovoru, který je Přílohou smlouvy o zajištění hlasových
                            služeb.
                        </p>
                        <p>Je taková schůzka, kde nedošlo k ověření adresy místa schůzky.</p>
                        <p>
                            Je taková schůzka, kde nesouhlasí datum, čas a lokalita s údaji uvedenými v Technické a
                            servisní specifikaci.
                        </p>
                        <p>
                            Je taková schůzka, kde není dodržen čas na přejezdy uvedený v Technické a servisní
                            specifikaci.
                        </p>
                        <p>
                            Je taková schůzka, která je klientem telefonicky zrušena před termínem realizace schůzky z
                            důvodu, že klient daný produkt nevyužije a nemá o něj zájem. Tato schůzka je považována za
                            špatně sjednanou pouze, když se klient ozve telefonicky sám před realizací schůzky a nebude
                            předem kontaktován Objednatelem.
                        </p>
                        <p>
                            Je taková schůzka, která není, v případě dodání databáze subjektů Zhotovitelem, sjednána u
                            firem odpovídajících výběrovým kriteriím uvedených v Technické a servisní specifikaci
                            (oborové zatřídění, počet zaměstnanců, právní forma…)
                        </p>
                    </Paragraph>

                    <p {...headingLayout}>{t("Ostatní ujednání")}</p>
                    <Paragraph {...paragraphLayout}>
                        <p>
                            Objednatel má právo špatně sjednanou schůzku reklamovat. Zhotovitel je povinen každou
                            reklamovanou schůzku prošetřit a písemně informovat Objednatele o výsledku reklamace. V
                            případě, že se prokáže, že má schůzka závady uvedené v bodu 2., nebude tato schůzka
                            objednateli fakturována nebo bude nahrazena.
                        </p>
                        <p>
                            Reklamace schůzek bude probíhat průběžně, tak jak budou schůzky Objednatelem realizovány u
                            klientů. Reklamace bude Zhotoviteli doručena písemně nejpozději do 2 (dvou) pracovních dnů
                            po zjištění závady. Na pozdější reklamace nebude brán zřetel.{" "}
                        </p>
                    </Paragraph>

                    <Button key="export" type="primary" size="large" onClick={() => exportPDF()}>
                        <FilePdfOutlined />
                        {t("Export")}
                    </Button>
                </div>
            </PageHeader>
            <div style={{ minHeight: "40px" }}>
                <p></p>
            </div>
        </LayoutWrapper>
    );
};
export default ObchodniPodminky;
