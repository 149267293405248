import React, { useEffect, useState } from "react";
//Redux
import { connect } from "react-redux";
//Antd
import { Alert } from "antd";

const ErrorView = (props) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        setError(props);
    }, [props]);

    const clearError = () => {
        setError(null);
    };

    return (
        <>
            {error ? (
                error.display === "all" || error.display === props.type ? (
                    <Alert
                        message={`${error.message}`}
                        // description={this.props.error.message}
                        type="error"
                        showIcon
                        onClose={clearError}
                    />
                ) : null
            ) : null}
        </>
    );
};

export default connect(mapStateToProps)(ErrorView);
function mapStateToProps(state) {
    if (state.Logic.error) return { error: state.Logic.error };
    else
        return {
            error: null,
        };
}
