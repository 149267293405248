import React from "react";

const IconTile = ({ label, icon }) => {
    return (
        <div className="iconTile">
            {icon}
            <h4>{label}</h4>
        </div>
    );
};

export default IconTile;

export const IconTileContainer = ({ children }) => <div className="iconTileContainer">{children}</div>;
