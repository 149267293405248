//Cheerio
import cheerio from "cheerio";
//Moment
import moment from "moment";

//PDF Make
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function exportToPdfTelemarketing(variables = {}) {
    //No input specified
    if (!variables) return false;

    let detailSVG = _TelemarketingTemplate;

    if (variables.idDuvodOdmitnuti != -1) {
        detailSVG += `<tspan
   sodipodi:role="line"
   x="19.43906"
   y="282.6601"
   style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
   id="duvodOdmitnuti">$duvodOdmitnuti</tspan>`;
    }

    detailSVG += `
<tspan
      sodipodi:role="line"
      x="19.43906"
      y="282.6601"
      style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
      id="tspan14525" /><tspan
      sodipodi:role="line"
      x="19.43906"
      y="299.22565"
      id="tspan869-3" /></text>
      <text
      xml:space="preserve"
      style="font-style:normal;font-weight:normal;font-size:5.64444px;line-height:2.3;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#e6e6e6;fill-opacity:0.8;stroke:none;stroke-width:0.204344"
      x="155.21042"
      y="6.7060542"
      id="text25106"><tspan
        style="font-weight:bold;font-size:5.64444px;fill:#e6e6e6;stroke-width:0.204344"
        sodipodi:role="line"
        id="pripadID"
        x="155.21042"
        y="6.7060542">$pripadID</tspan></text>
        
`;

    if (variables.idDuvodOdmitnuti != -1) {
        detailSVG += `<text
   xml:space="preserve"
   style="font-style:normal;font-weight:normal;font-size:8.71869px;line-height:1.9;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
   x="14.4553"
   y="77.528542"
   id="text859"><tspan
     sodipodi:role="line"
     x="14.4553"
     y="276.31467"
     style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
     id="tspan1270">Důvod odmítnutí</tspan><tspan
     sodipodi:role="line"
     x="14.4553"
     y="292.88019"
     style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
     id="tspan5074" /><tspan
     sodipodi:role="line"
     x="14.4553"
     y="309.44568"
     id="tspan869" /></text>`;
    }

    detailSVG += `
</g>
</svg>

`;

    //Load template
    let $ = cheerio.load(detailSVG);

    let pripadID = 0;
    let pripadid = 0;
    let telef = variables.volanyTelefon;

    let fullAdresa = "";
    if (variables.ulice) {
        fullAdresa = variables.ulice + ", ";
    }
    if (variables.obec) {
        fullAdresa += variables.obec;
    }
    if (variables.psc) {
        fullAdresa += ", " + variables.psc;
    }

    //Parsing the input data into the template by updating the SVG markup
    for (let [key, value] of Object.entries(variables)) {
        const identif = `#${key}`;
        //Empty values
        if (value == null || value === "") {
            value = "-";
        }

        if (key == "nazev") {
            if (value.length > 44) {
                value = value.substring(0, 44) + "...";
            }
        } else if (value.length > 82) {
            value = value.substring(0, 80) + "...";
        }
        //Datetime format
        if (key === "cas") {
            value = moment(value).format("DD.MM.YYYY") + " " + moment(value).format("HH:mm");
        }
        //telefon
        if (key === "telefon") {
            if (telef) {
                value = telef;
            }
        }
        //adresa
        if (key === "ulice") {
            value = fullAdresa;
        }
        //pripadID
        if (key === "pripadID") {
            pripadID = value;
            value = value + "";
            pripadid = value;
        }

        //Replace placeholder with value
        $(identif).text(value);
    }

    //New document
    var docDefinitionFirst = {
        content: [{ svg: [$("body").html()], width: 600, margin: -40 }],
    };

    if (variables.idDuvodOdmitnuti != -1) {
        //Download file
        pdfMake.createPdf(docDefinitionFirst).download(`Telemarketing-${pripadID}`);
    } else {
        /////////////////Page with filled questionare
        let dotaznikSVG = _TelemarketingDotaznik;
        if (variables.vyplnenyDotaznik) {
            dotaznikSVG += `
      <text
      xml:space="preserve"
      style="font-style:normal;font-weight:normal;font-size:8.71869px;line-height:2.3;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
      x="19.5"
      y="72"
      id="text859">`;
            let y = 72;

            Object.entries(variables.vyplnenyDotaznik).map(([key, value]) => {
                const index = key.indexOf(" ");
                if (index > 0) {
                    key = key.substring(index, key.length - 1);
                }
                dotaznikSVG +=
                    `
      <tspan
         sodipodi:role="line"
         x="19.5"
         y="` +
                    y +
                    `"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="` +
                    value +
                    `">` +
                    key +
                    `
      </tspan>`;
                y += 20;
            });
            dotaznikSVG += `
      </text>
      <text
         xml:space="preserve"
         style="font-style:normal;font-weight:normal;font-size:8.7187px;line-height:2.3;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
         x="23.0"
         y="79.0"
         id="text859-2">`;

            y = 79;
            Object.entries(variables.vyplnenyDotaznik).map(([key, value]) => {
                if (value.length > 91) {
                    value = value.substring(0, 91) + "...";
                }
                let exportValue = value ? value : "-";
                dotaznikSVG +=
                    `
      <tspan
         sodipodi:role="line"
         x="23.0"
         y="` +
                    y +
                    `"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="` +
                    value +
                    `">` +
                    exportValue +
                    `
      </tspan>`;
                y += 20;
            });
            dotaznikSVG += `</text>`;
        }
        dotaznikSVG +=
            `
   <text
      xml:space="preserve"
      style="font-style:normal;font-weight:normal;font-size:5.64444px;line-height:2.3;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#e6e6e6;fill-opacity:0.8;stroke:none;stroke-width:0.204344"
      x="155.21042"
      y="6.7060542"
      id="text25106"><tspan
         style="font-weight:bold;font-size:5.64444px;fill:#e6e6e6;stroke-width:0.204344"
         sodipodi:role="line"
         id="` +
            pripadID +
            `"
         x="155.21042"
         y="6.7060542">` +
            pripadID +
            `</tspan></text>
   </g></svg>`;

        $ = cheerio.load(dotaznikSVG);
        var docDefinitionSecond = {
            content: [{ svg: [$("body").html()], width: 600, margin: -40 }],
        };

        var docDefinitionAll = {
            content: [docDefinitionFirst.content, docDefinitionSecond.content],
        };

        //Download file
        pdfMake.createPdf(docDefinitionAll).download(`Telemarketing-${pripadID}`);
    }
}
export default exportToPdfTelemarketing;

let _TelemarketingTemplate = `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   width="210mm"
   height="297mm"
   viewBox="0 0 210 297"
   version="1.1"
   id="svg8"
   inkscape:version="1.1.2 (b8e25be833, 2022-02-05)"
   sodipodi:docname="telem.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:dc="http://purl.org/dc/elements/1.1/">
  <defs
     id="defs2" />
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:zoom="0.49497475"
     inkscape:cx="-97.984796"
     inkscape:cy="385.87827"
     inkscape:document-units="mm"
     inkscape:current-layer="layer1"
     showgrid="false"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     showguides="false"
     borderlayer="true"
     inkscape:pagecheckerboard="true" />
  <metadata
     id="metadata5">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     inkscape:label="Vrstva 1"
     inkscape:groupmode="layer"
     id="layer1">
    <rect
       style="opacity:1;fill:#009fe2;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.82433516;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
       id="rect834"
       width="211.2887"
       height="298.22321"
       x="-0.94493955"
       y="-0.65624988" />
    <rect
       style="opacity:1;fill:#f0f2f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.08481;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
       id="rect812"
       width="212.86545"
       height="219.44165"
       x="-15.874997"
       y="68.531548"
       ry="16.60397" />
    <g
       id="g18657"
       transform="translate(0.61282049,1.557761)">
      <text
         transform="scale(1.0349719,0.96620982)"
         id="text868"
         y="21.439487"
         x="75.113976"
         style="font-weight:bold;font-size:17.6514px;opacity:0.791667;fill:#ffdc00;stroke:none;stroke-width:0.598409">AMAX</text>
      <path
         inkscape:connector-curvature="0"
         id="path881"
         d="M 77.660254,22.231305 H 132.87757"
         style="opacity:0.4;fill:none;stroke:#000000;stroke-width:0.287991;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <text
         transform="scale(1.4227915,0.70284368)"
         id="text885"
         y="36.997055"
         x="54.250431"
         style="font-style:normal;font-weight:normal;font-size:4.59731px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;opacity:0.791667;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.430997"
         xml:space="preserve"><tspan
           dx="0.016332898 0.0081664491"
           style="letter-spacing:0.509893px;word-spacing:0.00459363px;fill:#f0f2f5;fill-opacity:1;stroke-width:0.430997"
           y="36.997055"
           x="54.250431"
           id="tspan883"
           sodipodi:role="line">CALL CENTRUM</tspan></text>
    </g>
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:11.9436px;line-height:1.25;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;letter-spacing:0px;word-spacing:0px;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.307135"
       x="44.882957"
       y="44.652172"
       id="text840"><tspan
         sodipodi:role="line"
         id="tspan838"
         x="38.882957"
         y="44.652172"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:11.9436px;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#f0f2f5;fill-opacity:1;stroke-width:0.307135">DETAIL TELEMARKETINGU</tspan></text>
         <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:10.2889px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.264583"
       x="8.3154764"
       y="120.48512"
       id="text855" />
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:10.2889px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.264583"
       x="8.3154764"
       y="120.48512"
       id="text855" />
       <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:8.88107px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.20815"
       x="9.755311"
       y="60.854786"
       id="text892"><tspan
         id="nazev"
         x="9.755311"
         y="60.854786"
         style="fill:#f0f2f5;fill-opacity:1;stroke-width:0.20815"
         sodipodi:role="line">$Company</tspan><tspan
         x="9.755311"
         y="71.956123"
         style="fill:#f0f2f5;fill-opacity:1;stroke-width:0.20815"
         sodipodi:role="line"
         id="tspan23780" /></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:8.71869px;line-height:1.9;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
       x="14.4553"
       y="77.528542"
       id="text859"><tspan
         sodipodi:role="line"
         x="14.4553"
         y="77.528542"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan888">Datum Schůzky</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="94.094055"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5050">IČO</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="110.65956"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5052">Firma</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="127.22507"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5054">Adresa</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="143.79059"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5056">Status</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="160.35609"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5058">Telefon</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="176.9216"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5060">Email</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="193.48712"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5062">Web</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="210.05263"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5064">Osoba</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="226.61813"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5068">Poznámka zákazníka</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="243.18365"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5070">Poznámka kontaktu</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="259.74915"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5072">Poznámka dotazníku</tspan><tspan
         sodipodi:role="line"
         x="14.4553"
         y="276.31467"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5074" /><tspan
         sodipodi:role="line"
         x="14.4553"
         y="292.88019"
         id="tspan869" /></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:8.7187px;line-height:1.9;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
       x="19.43906"
       y="83.873741"
       id="text859-2"><tspan
         sodipodi:role="line"
         x="19.43906"
         y="83.873741"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="cas">$cas</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="100.43927"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="ico">$ico</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="117.0048"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="nazev">$nazev</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="133.57033"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="ulice">$ulice</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="150.13586"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="statusFirmy">$statusFirmy</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="166.7014"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="telefon">$telefon</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="183.26692"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="mail">$mail</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="199.83246"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="web">$web</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="216.39798"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="osoba">$osoba</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="232.96352"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="poznamkaZakaznika">$poznamkaZakaznika</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="249.52905"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="poznamka">$poznamka</tspan><tspan
         sodipodi:role="line"
         x="19.43906"
         y="266.09457"
         style="font-weight:normal;font-size:4.23333px;stroke-width:0.204344"
         id="poznamkaKontakt">$poznamkaKontakt</tspan>
`;

let _TelemarketingDotaznik = `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   width="210mm"
   height="297mm"
   viewBox="0 0 210 297"
   version="1.1"
   id="svg8"
   inkscape:version="1.1.2 (b8e25be833, 2022-02-05)"
   sodipodi:docname="dotaznik.svg.2022_04_01_15_30_53.0.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:dc="http://purl.org/dc/elements/1.1/">
  <defs
     id="defs2" />
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:zoom="0.7"
     inkscape:cx="226.42857"
     inkscape:cy="425"
     inkscape:document-units="mm"
     inkscape:current-layer="layer1"
     showgrid="false"
     inkscape:window-width="1684"
     inkscape:window-height="1040"
     inkscape:window-x="111"
     inkscape:window-y="0"
     inkscape:window-maximized="0"
     showguides="false"
     borderlayer="true"
     inkscape:pagecheckerboard="true" />
  <metadata
     id="metadata5">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     inkscape:label="Vrstva 1"
     inkscape:groupmode="layer"
     id="layer1">
    <rect
       style="opacity:1;fill:#009fe2;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.82433516;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
       id="rect834"
       width="211.2887"
       height="297.22321"
       x="-0.94493955"
       y="0.0" />
    <rect
       style="opacity:1;fill:#f0f2f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.12099;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
       id="rect812"
       width="212.86545"
       height="234.32397"
       x="13.092014"
       y="53.649227"
       ry="17.730034" />
    <g
       id="g18657"
       transform="translate(0.61282049,1.557761)">
      <text
         transform="scale(1.0349719,0.96620982)"
         id="text868"
         y="21.439487"
         x="75.113976"
         style="font-weight:bold;font-size:17.6514px;opacity:0.791667;fill:#ffdc00;stroke:none;stroke-width:0.598409">AMAX</text>
      <path
         inkscape:connector-curvature="0"
         id="path881"
         d="M 77.660254,22.231305 H 132.87757"
         style="opacity:0.791667;fill:none;stroke:#000000;stroke-width:0.287991;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <text
         transform="scale(1.4227915,0.70284368)"
         id="text885"
         y="36.997055"
         x="54.250431"
         style="font-style:normal;font-weight:normal;font-size:4.59731px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;opacity:0.791667;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.430997"
         xml:space="preserve"><tspan
           dx="0.016332898 0.0081664491"
           style="letter-spacing:0.509893px;word-spacing:0.00459363px;fill:#f0f2f5;fill-opacity:1;stroke-width:0.430997"
           y="36.997055"
           x="54.250431"
           id="tspan883"
           sodipodi:role="line">CALL CENTRUM</tspan></text>
    </g>
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:11.9436px;line-height:1.25;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;letter-spacing:0px;word-spacing:0px;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.307135"
       x="55.0"
       y="44.5"
       id="text840"><tspan
         sodipodi:role="line"
         id="tspan838"
         x="50.0"
         y="44.5"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:11.9436px;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#f0f2f5;fill-opacity:1;stroke-width:0.307135">VYPLNĚNÝ DOTAZNÍK</tspan></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:10.2889px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.264583"
       x="8.3154764"
       y="120.48512"
       id="text855" />
`;
