import React, { memo } from "react";
//Types
import PropTypes from "prop-types";
//Antd
import { Layout } from "antd";
//Redux
import { connect } from "react-redux";
//Components
import Loading from "../common/Loading";

const LoginLayout = ({ children }) => {
    const { Content } = Layout;

    return (
        <div className="landing-layout">
            <Layout>
                <Loading />
                <Layout>
                    <Content className="site-layout-content ">{children}</Content>
                </Layout>
            </Layout>
        </div>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(memo(LoginLayout));

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi };
}
