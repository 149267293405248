import AmaxApiService from "./AmaxApiService";
import { store } from "../components/providers/ReduxProvider";
import { setSearchResult } from "../store/actions";
import moment from "moment";
import "../interfaces/AmaxApiInterface";
import "../interfaces/FormInterface";
import { addHours } from "date-fns";
/**
 * Accepts formID and search filter
 * and switches between queries and serves them parsed model
 * @param {Integer} formID Id of form that is to be selected
 * @param {ReportMeetingFormFilter} search Filter from Form to be parsed later
 * @returns {Boolean} Depending of success
 */
export default function SearchAndQueryData(formID, search) {
    switch (formID) {
        //Account
        case 0:
            queryAccountInfo();
            return true;
        //Schůzky podle data sjednani i domluveni
        case 1:
            console.log(parseFilterOptionsViewModelForBothDates(search));
            queryReportSchuzky(parseFilterOptionsViewModelForBothDates(search));
            return true;
        //Telemarketing
        case 2:
            // console.log(parseFilterOptionsViewModelForMarketing(search));
            queryTelemarketing(parseFilterOptionsViewModelForMarketing(search));
            return true;
        //Telesales
        case 3:
            // console.log(parseFilterOptionsViewModelForTelesales(search));
            queryTelesales(parseFilterOptionsViewModelForTelesales(search));
            return true;
        //Ratings
        case 4:
            // console.log(parseFilterOptionsViewModelForRatings(search));
            queryRatings(parseFilterOptionsViewModelForRatings(search));
            return true;
        //Cancellation
        case 5:
            // console.log(parseFilterOptionsViewModelForCancel(search));
            queryCancel(parseFilterOptionsViewModelForCancel(search));
            return true;
        //Blacklist
        case 6:
            // console.log(parseFilterOptionsViewModelForBlacklist(search));
            //console.log(parseFilterOptionsViewModelForRejected(search));
            queryBlacklist(parseFilterOptionsViewModelForBlacklist(search));
            return true;
        //Schuzky podle data sjednani
        case 7:
            console.log(parseFilterOptionsViewModelForMeetingDates(search));
            queryReportSchuzky(parseFilterOptionsViewModelForMeetingDates(search));
            return true;
        //Schuzky podle data domluveni
        case 8:
            console.log(parseFilterOptionsViewModelForAgreedDates(search));
            queryReportSchuzky(parseFilterOptionsViewModelForAgreedDates(search));
            return true;
        default:
            return false;
    }
}

/**
 * Account
 *
 * @function
 * @param
 */
const queryAccountInfo = () => {
    //AmaxApiService
};

/**
 * Report Meetings Query accepts search filter and queries reports
 *
 * @function queryReportSchuzky
 * @param {FilterOptionsViewModel} search
 */
const queryReportSchuzky = (search) => {
    // AmaxApiService
    AmaxApiService.ReportMeetings.Reports(JSON.stringify(search))
        .then((result) => {
            store.dispatch(setSearchResult("REPORT_SCHUZKY", "READY", result.data));
        })
        .catch((e) => {
            /*store.dispatch(
        setError(e.response.data, e.response.message),
      )*/
            store.dispatch(setSearchResult("REPORT_SCHUZKY", null, null));
            console.error(e);
        });
};

/**
 * Telemarketing Query accepts search filter and queries reports
 *
 * @function queryTelemarketing
 * @param {FilterOptionsViewModel} search
 */
const queryTelemarketing = (search) => {
    if (search.ReportedStatus > 0 && search.ContactResultIds == 4) {
        // AmaxApiService pro nereportovane s pozitivni
        AmaxApiService.Telemarketing.PositiveResponse(JSON.stringify(search))
            .then((result) => {
                store.dispatch(setSearchResult("TELEMARKETING", "READY", result.data));
            })
            .catch((e) => {
                store.dispatch(setSearchResult("TELEMARKETING", null, null));
                console.error(e);
            });
    } else if (search.ReportedStatus > 0 && search.ContactResultIds == 7) {
        // AmaxApiService pro nereportovane s negativni
        AmaxApiService.Telemarketing.NegativeResponse(JSON.stringify(search))
            .then((result) => {
                store.dispatch(setSearchResult("TELEMARKETING", "READY", result.data));
            })
            .catch((e) => {
                store.dispatch(setSearchResult("TELEMARKETING", null, null));
                console.error(e);
            });
    } else {
        // AmaxApiService
        AmaxApiService.Telemarketing.Telemarketings(JSON.stringify(search))
            .then((result) => {
                store.dispatch(setSearchResult("TELEMARKETING", "READY", result.data));
            })
            .catch((e) => {
                /*store.dispatch(
          setError(e.response.data, e.response.message),
        )*/
                store.dispatch(setSearchResult("TELEMARKETING", null, null));
                console.error(e);
            });
    }
};

/**
 * Telesales Query accepts search filter and queries reports
 *
 * @function queryTelesales
 * @param {FilterOptionsViewModel} search
 */
const queryTelesales = (search) => {
    // AmaxApiService
    AmaxApiService.Telesales.TelesalesMultiple(search.map((x) => JSON.stringify(x)))
        .then((result) => {
            store.dispatch(
                setSearchResult(
                    "TELESALES",
                    "READY",
                    result.map((x) => x.data)
                )
            );
        })
        .catch((e) => {
            /*store.dispatch(
        setError(e.response.data, e.response.message),
      )*/
            store.dispatch(setSearchResult("TELESALES", null, null));
            console.error(e);
        });
};

const queryTelesalesOld = (search) => {
    // AmaxApiService
    AmaxApiService.Telesales.Telesales(JSON.stringify(search))
        .then((result) => {
            store.dispatch(setSearchResult("TELESALES", "READY", result.data));
        })
        .catch((e) => {
            /*store.dispatch(
        setError(e.response.data, e.response.message),
      )*/
            store.dispatch(setSearchResult("TELESALES", null, null));
            console.error(e);
        });
};

/**
 * Ratings Query accepts search filter and queries reports
 *
 * @function queryRatings
 * @param {FilterOptionsViewModel} search
 */
const queryRatings = (search) => {
    // AmaxApiService
    AmaxApiService.Ratings.Ratings(JSON.stringify(search))
        .then((result) => {
            store.dispatch(setSearchResult("RATINGS", "READY", result.data));
        })
        .catch((e) => {
            /*store.dispatch(
        setError(e.response.data, e.response.message),
      )*/
            store.dispatch(setSearchResult("RATINGS", null, null));
            console.error(e);
        });
};

/**
 * Storno Query accepts search filter and queries reports
 *
 * @function queryCancel
 * @param {FilterOptionsViewModel} search
 */
const queryCancel = (search) => {
    // AmaxApiService
    AmaxApiService.Cancel.Search(JSON.stringify(search))
        .then((result) => {
            store.dispatch(
                setSearchResult(
                    "CANCEL",
                    "READY",
                    result.map((x) => x.data)
                )
            );
        })
        .catch((e) => {
            /*store.dispatch(
        setError(e.response.data, e.response.message),
      )*/
            store.dispatch(setSearchResult("CANCEL", null, null));
            console.error(e);
        });
};

/**
 * Report Meetings Query accepts search filter and queries reports
 *
 * @function queryBlacklist
 * @param {FilterOptionsViewModel} search
 */
const queryBlacklist = (search) => {
    AmaxApiService.Blacklist.Blacklist(JSON.stringify(search))
        .then((result) => {
            store.dispatch(setSearchResult("BLACKLIST", "READY", result.data));
        })
        .catch((e) => {
            store.dispatch(setSearchResult("BLACKLIST", null, null));
            console.error(e);
        });
};

/**
 *  C#.net default time for null values
 *  C#.net can't parse null to datetime *smh*
 */
export const defaultAPITime = "0001-01-01T00:00:00+01:00";

/**
 * Parse FilterOptionViewModel
 *
 * @function parseFilterOptionsViewModel
 * @param {ReportMeetingFormFilter} search
 * @returns {FilterOptionsViewModel}
 */
const parseFilterOptionsViewModel = (search) => {
    return {
        PriorityId: search.PriorityId ? search.PriorityId : 0,
        //integer
        TssId: search.TssId ? search.TssId : 0,
        //integer
        SRId: search.SRId ? search.SRId : 0,
        //integer
        CompanyId: search.companyId ? search.companyId : 0,
        //integer
        AgreedMeetingDateFrom:
            search.MeetingType == "BothDates" && search.AgreedDate
                ? search.AgreedDate[0].startDate.toJSON()
                : defaultAPITime,
        //date
        TimeOfClosing: search.timeOfClosing ? search.timeOfClosing.endDate.toJSON() : defaultAPITime,
        //date
        AgreedMeetingDateTo:
            search.MeetingType == "BothDates" && search.AgreedDate
                ? search.AgreedDate[0].endDate.toJSON()
                : defaultAPITime,
        //date
        MeetingDateFrom:
            search.MeetingType == "BothDates" && search.MeetingDate
                ? search.MeetingDate[0].startDate.toJSON()
                : defaultAPITime,
        //date
        MeetingDateTo:
            search.MeetingType == "BothDates" && search.MeetingDate
                ? search.MeetingDate[0].endDate.toJSON()
                : defaultAPITime,
        //date
        ContactDateFrom:
            search.MeetingType == "contactDate" && search.MeetingDate ? search.MeetingDate[0].format() : defaultAPITime,
        //date
        ContactDateTo:
            search.MeetingType == "contactDate" && search.MeetingDate ? search.MeetingDate[1].format() : defaultAPITime,
        //date
        CancelDateFrom: search.cancelDate ? search.cancelDate[0].format() : defaultAPITime,
        //date
        CancelDateTo: search.cancelDate ? search.cancelDate[1].format() : defaultAPITime,
        //date
        ContactResultId: search.ContactResultIds ? search.ContactResultIds : 0,
        //integer
        MultipleIdTss: search.MultipleIdTss ? search.MultipleIdTss : [],
        //Collection of integer
        OrderBy: { PropertyName: search.MeetingType, DisplayName: "" },
        //OrderByItemViewModel
        Desc: false,
        //boolean
        ReportedStatus: search.ReportedStatus ? search.ReportedStatus : null,
        //ReportedFilterStatus
        AllSR: search.AllSR ? search.AllSR : null,
        //Collection of integers
    };
};

/**
 * Parse FilterOptionViewModel for Both Dates
 *
 */
const parseFilterOptionsViewModelForBothDates = (search) => {
    const MeetingStart = addHours(search.MeetingDate[0].startDate, 1);
    const MeetingEnd = addHours(search.MeetingDate[0].endDate, 25);
    const AgreedStart = addHours(search.AgreedDate[0].startDate, 1);
    const AgreedEnd = addHours(search.AgreedDate[0].endDate, 25);

    return {
        //integer
        TssId: search.TssId ? search.TssId : 0,
        //integer
        SRId: search.SRId ? search.SRId : null,
        //list
        AllSR: search.AllSR ? search.AllSR : null,
        //date
        MeetingDateFrom:
            search.MeetingType == "BothDates" && search.MeetingDate ? MeetingStart.toJSON() : defaultAPITime,
        //date
        MeetingDateTo: search.MeetingType == "BothDates" && search.MeetingDate ? MeetingEnd.toJSON() : defaultAPITime,
        //date
        AgreedMeetingDateFrom:
            search.MeetingType == "BothDates" && search.AgreedDate ? AgreedStart.toJSON() : defaultAPITime,
        //date
        AgreedMeetingDateTo:
            search.MeetingType == "BothDates" && search.AgreedDate ? AgreedEnd.toJSON() : defaultAPITime,
        //date
        OrderBy: { PropertyName: search.MeetingType, DisplayName: "" },
        //OrderByItemViewModel
        Desc: false,
        //boolean
        ReportedStatus: "0",
        //ReportedFilterStatus
    };
};

/**
 * Parse FilterOptionViewModel for Meeting Dates
 *
 */
const parseFilterOptionsViewModelForMeetingDates = (search) => {
    const MeetingStart = addHours(search.MeetingDate[0].startDate, 1);
    const MeetingEnd = addHours(search.MeetingDate[0].endDate, 25);

    return {
        //integer
        TssId: search.TssId ? search.TssId : 0,
        //integer
        SRId: search.SRId ? search.SRId : null,
        AllSR: search.AllSR ? search.AllSR : null,
        MeetingDateFrom:
            search.MeetingType == "MeetingDates" && search.MeetingDate ? MeetingStart.toJSON() : defaultAPITime,
        //date
        MeetingDateTo:
            search.MeetingType == "MeetingDates" && search.MeetingDate ? MeetingEnd.toJSON() : defaultAPITime,
        //date
        OrderBy: { PropertyName: search.MeetingType, DisplayName: "" },
        //OrderByItemViewModel
        Desc: false,
        //boolean
        ReportedStatus: "0",
        //ReportedFilterStatus
    };
};

/**
 * Parse FilterOptionViewModel for Agreed Dates
 *
 */
const parseFilterOptionsViewModelForAgreedDates = (search) => {
    const AgreedStart = addHours(search.AgreedDate[0].startDate, 1);
    const AgreedEnd = addHours(search.AgreedDate[0].endDate, 25);

    return {
        //integer
        TssId: search.TssId ? search.TssId : 0,
        //integer
        SRId: search.SRId ? search.SRId : null,
        AllSR: search.AllSR ? search.AllSR : null,
        AgreedMeetingDateFrom:
            search.MeetingType == "AgreedDates" && search.AgreedDate ? AgreedStart.toJSON() : defaultAPITime,
        //date
        AgreedMeetingDateTo:
            search.MeetingType == "AgreedDates" && search.AgreedDate ? AgreedEnd.toJSON() : defaultAPITime,
        //date
        OrderBy: { PropertyName: search.MeetingType, DisplayName: "" },
        //OrderByItemViewModel
        Desc: false,
        //boolean
        ReportedStatus: "0",
        //ReportedFilterStatus
    };
};

const parseFilterOptionsViewModelForMarketing = (search) => {
    const localeStart = addHours(search.Date[0].startDate, 1);
    const localeEnd = addHours(search.Date[0].endDate, 25);
    return {
        PriorityId: search.PriorityId ? search.PriorityId : 0,
        //date
        ContactDateFrom: search.MeetingType == "contactDate" && search.Date ? localeStart.toJSON() : defaultAPITime,
        //date
        ContactDateTo: search.MeetingType == "contactDate" && search.Date ? localeEnd.toJSON() : defaultAPITime,

        //ReportedStatus: search.ReportedStatus ? search.ReportedStatus : null,
        ReportedStatus: "0",
        //date
        ContactResultIds: search.ContactResultIds ? [search.ContactResultIds] : [],
        //ContactResultIds: search.Response ? (search.Response == "PossitiveResponse" ? ["7"] : [1]) : [0],
        //integer
        TssId: search.TssId ? search.TssId : 0,
        //Collection of integer
        OrderBy: { PropertyName: "cas", DisplayName: "" },
        //OrderByItemViewModel
        Desc: false,
    };
};

const parseFilterOptionsViewModelForTelesales = (search) => {
    const localeStart = addHours(search[0].Date[0].startDate, 1);
    const localeEnd = addHours(search[0].Date[0].endDate, 25);
    let array = [];

    search.map((one) => {
        array.push({
            //date
            ContactDateFrom: one.MeetingType == "ContactDate" && one.Date ? localeStart.toJSON() : defaultAPITime,
            //date
            ContactDateTo: one.MeetingType == "ContactDate" && one.Date ? localeEnd.toJSON() : defaultAPITime,

            //ReportedStatus: search.ReportedStatus ? search.ReportedStatus : null,
            //date
            ContactResultIds: one.ContactResultIds ? one.ContactResultIds : [],
            CancellationStatus: one.CancellationStatus,
            //integer
            TssId: one.TssId ? one.TssId : 0,
            //Collection of integer
            OrderBy: { PropertyName: "cas", DisplayName: "" },
            //OrderByItemViewModel
            Desc: false,
        });
    });
    return array;
};

const parseFilterOptionsViewModelForTelesalesOld = (search) => {
    const localeStart = addHours(search.Date[0].startDate, 1);
    const localeEnd = addHours(search.Date[0].endDate, 25);
    return {
        //date
        ContactDateFrom: search.MeetingType == "ContactDate" && search.Date ? localeStart.toJSON() : defaultAPITime,
        //date
        ContactDateTo: search.MeetingType == "ContactDate" && search.Date ? localeEnd.toJSON() : defaultAPITime,

        //ReportedStatus: search.ReportedStatus ? search.ReportedStatus : null,
        //date
        ContactResultIds: search.ContactResultIds ? search.ContactResultIds : [],
        //integer
        TssId: search.TssId ? search.TssId : 0,
        //Collection of integer
        OrderBy: { PropertyName: "cas", DisplayName: "" },
        //OrderByItemViewModel
        Desc: false,
    };
};

/**
 * Parse FilterOptionViewModel for ReportMeeting
 *
 */
const parseFilterOptionsViewModelForRatings = (search) => {
    const localeStart = addHours(search.Date[0].startDate, 1);
    const localeEnd = addHours(search.Date[0].endDate, 25);
    return {
        //integer
        TssId: search.TssId ? search.TssId : 0,
        //integer
        SRId: search.SRId ? search.SRId : 0,
        //date
        MeetingDateFrom: search.MeetingType == "ratingTime" && search.Date ? localeStart.toJSON() : defaultAPITime,
        //date
        MeetingDateTo: search.MeetingType == "ratingTime" && search.Date ? localeEnd.toJSON() : defaultAPITime,
        //Collection of integer
        OrderBy: { PropertyName: "meetingTime", DisplayName: "" },
        //OrderBy: { PropertyName: "", DisplayName: "" },
        //OrderByItemViewModel
        Desc: false,
        //AllSR: search.AllSR ? [search.AllSR] : null,
        AllSR: null,
    };
};

/**
 * Parse FilterOptionViewModel for Cancel
 *
 */
const parseFilterOptionsViewModelForCancel = (search) => {
    const localeStart = addHours(search.Date[0].startDate, 1);
    const localeEnd = addHours(search.Date[0].endDate, 25);
    return {
        IdCompany: search.IdCompany ? search.IdCompany : null,

        UniqueIdentificator: search.UniqueIdentificator ? search.UniqueIdentificator : null,

        //date
        SoldFrom: search.FilterDate && search.Date ? localeStart.toJSON() : null,
        //date
        SoldTo: search.FilterDate && search.Date ? localeEnd.toJSON() : null,

        IdTss: search.TssId ? search.TssId : null,
        OrderBy: { PropertyName: "Sold", DisplayName: "" },
        Desc: false,
    };
};

/**
 * Parse FilterOptionViewModel for ReportMeeting
 *
 */
const parseFilterOptionsViewModelForRejected = (search) => {
    const localeStart = addHours(search.Date[0].startDate, 1);
    const localeEnd = addHours(search.Date[0].endDate, 25);
    return {
        //integer
        TssId: search.TssId ? search.TssId : 0,
        //integer
        SRId: search.SRId ? search.SRId : 0,
        //date
        MeetingDateFrom: search.MeetingType == "rejectedTime" && search.Date ? localeStart.toJSON() : defaultAPITime,
        //date
        MeetingDateTo: search.MeetingType == "rejectedTime" && search.Date ? localeEnd.toJSON() : defaultAPITime,
        //Collection of integer
        OrderBy: { PropertyName: "meetingTime", DisplayName: "" },
        //OrderBy: { PropertyName: "", DisplayName: "" },
        //OrderByItemViewModel
        Desc: false,
        //AllSR: search.AllSR ? [search.AllSR] : null,
        AllSR: null,
    };
};

/**
 * Parse FilterOptionViewModel for Blacklist
 *
 */
const parseFilterOptionsViewModelForBlacklist = (search) => {
    return {
        Identifier: search.UniqueIdentificator ? search.UniqueIdentificator : null,
        OrderBy: { PropertyName: "From", DisplayName: "" },
        Desc: false,
    };
};
