import React from "react";
//Antd
import { Button, Skeleton, Descriptions, Modal, Typography, Collapse, Tabs } from "antd";
import { CalendarTwoTone, CloseCircleOutlined } from "@ant-design/icons";
//Layout
import LayoutWrapper from "../../layouts/LayoutWrapper";
//Moment
import moment from "moment";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//Images
import Form from "../../../images/help-images/telemarketinghelp-form.PNG";
import FormMobile from "../../../images/help-images/reporthelp-form-mobile.PNG";
import Answers from "../../../images/help-images/answers.PNG";
import AnswersMobile from "../../../images/help-images/answers-mobile.PNG";
import ColumnHiding from "../../../images/help-images/columnhiding.png";
import ColumnHidingMobile from "../../../images/help-images/columnhiding-mobile.png";
import Table from "../../../images/help-images/table.png";
import TableMobile from "../../../images/help-images/table-mobileE.png";
import Detail from "../../../images/help-images/telemarketinghelp-detailE.png";
import DetailMobile from "../../../images/help-images/telemarketinghelp-detail-mobileE.png";
import StatistikaDetail from "../../../images/help-images/statistika-detail.PNG";
import StatistikaDetailMobile from "../../../images/help-images/statistika-detail-mobile.PNG";
import Export from "../../../images/help-images/telemarketinghelp-export.PNG";
import ExportMobile from "../../../images/help-images/telemarketinghelp-export-mobile.PNG";

const { Paragraph, Text } = Typography;
const { TabPane } = Tabs;

const paragraphLayout = {
    style: {
        textAlign: "left",
        color: "black",
        fontSize: "14px",
        padding: "10px",
    },
};

const headdingLayout = {
    style: {
        color: "#48a3d8",
        fontSize: "24px",
    },
};

class HelpTelemarketing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            data: null,
            layout: "vertical",
            visible: false,
        };
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }
    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.record) {
            this.setState({ data: newProps.record });
        }
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    closeDialog() {
        this.setState({
            ChangeMeetingDateModal: false,
            MeetingReclamationModal: false,
        });
    }

    render() {
        const footer = [
            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={t("Nápověda pro telemarketing")}
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        <div className="help-paragraph">
                            <Tabs
                                defaultActiveKey="1" //</div>tabPosition="left">
                            >
                                <TabPane tab="" key="0"></TabPane>
                                <TabPane tab="Filtrování" key="1">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Filtrování telemarketingů")}</h2>
                                        <p>
                                            Nejprve je potřeba vyfiltrovat Vámi požadovaná data, která se následně
                                            zobrazí v tabulce a umožní další práci.
                                        </p>
                                        <img src={Form} alt="form" className="help-image-desktop" />

                                        <p>
                                            Formulář pro filtrování je možné zobrazit/skrýt kliknutím na tlačítko
                                            <b> Filtrovat telemarketingy/Skrýt filtrování</b>. Formulář se skryje
                                            automaticky po úspěšném vyfiltrování.
                                        </p>
                                        <p>
                                            <b>Pro vyhledání dat je třeba:</b>
                                        </p>
                                        <p>
                                            - Zvolit <b> Kampaň</b> z nabízeného seznamu. Tím se zpřístupní výběr{" "}
                                            <b> Priority</b> dané kampaně - není nutné vybírat, v takovém případě se
                                            zobrazí telemarketingy všech priorit.
                                        </p>
                                        <p>
                                            - Vybrat, jestli mají být zobrazeny telemarketingy s dotazníkem s
                                            <b> Kladnou odezvou nebo Zápornou odezvou</b>.
                                        </p>
                                        <p>
                                            - Vybrat z kalendáře termín <b>Uskutečnění telemarketingu</b> . To lze
                                            provést kliknutím na počáteční a koncové datum požadovaného termínu v
                                            zobrazeném kalendáři nebo výběrem některého z předdefinovaných termínů.
                                            Zvolený termín musí být kratší než 3 měsíce.
                                        </p>
                                        <img src={FormMobile} alt="form" className="help-image-mobile" />
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Tabulka" key="2">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Práce s tabulkou")}</h2>
                                        <img src={Table} alt="table" className="help-image-desktop" />
                                        <img src={TableMobile} alt="table" className="help-image-mobile" />
                                        <p>
                                            <b>1. </b> Vlevo nad tabulkou je zobrazen počet vybraných záznamů tabulky a
                                            celkový počet vyfiltrovaných záznamů.
                                        </p>
                                        <p>
                                            <b>2.</b> Záznamy z tabulky lze vybrat pro následný export vybraných záznamů
                                            do souboru .xlsx. Data jsou vybrána kliknutím na čtvereček v prvním sloupci
                                            daného záznamu. Všechny záznamy na aktuální stránce tabulky
                                            vybereme/odvybereme kliknutím na čtvereček v záhlaví tabulky. Kliknutím na
                                            šipku vedle čtverečku v záhlaví tabulky lze vybrat všechna data z celé
                                            tabulky nebo zrušit celý výběr.
                                        </p>
                                        <p>
                                            <b>3. </b> Kliknutím na <b>šipečky</b> za názvy sloupců je možné měnit
                                            seřazení dat v tabulce. Defaultně jsou data seřazená podle data schůzky
                                            vzestupně. Záznamy v tabulce je možné řadit podle
                                            <b> Data kontaktu, Názvu firmy, IČO a Osoby</b>. Sloupec, podle kterého jsou
                                            data ručně seřazena, je podbarven.
                                        </p>
                                        <p>
                                            <b>4. </b> V záznamech tabulky lze hledat pomocí vyhledávače nacházejícího
                                            se na pravé straně nad tabulkou. Do okénka napíšeme požadovaný řetězec a
                                            stisknutím klávesy Enter nebo kliknutím na lupu vyhledáme data. Řetězec je
                                            vyhledáván ve sloupcích <b> Firma, IČO, Osoba a Obchodní zástupce.</b>
                                        </p>
                                        <p>
                                            <b>5. </b>U každého záznamu se nachází tlačítko ve sloupci <b>Detail</b>,
                                            které po kliknutí zobrazí vyskakovací okno s podrobnými informacemi o dané
                                            schůzce.
                                        </p>
                                        <p className="help-image-desktop">
                                            <b>6. </b>Data je možné procházet po stránkách a nastavit počet zobrazených
                                            záznamů na jedné straně.
                                        </p>
                                        <p className="help-image-mobile">
                                            <b>6. </b> Data je možné procházet po stránkách.
                                        </p>

                                        <img
                                            src={ColumnHiding}
                                            alt="column-hiding-button"
                                            className="help-image-desktop"
                                        />
                                        <img
                                            src={ColumnHidingMobile}
                                            alt="column-hiding-button"
                                            className="help-image-mobile"
                                        />
                                        <p>
                                            Sloupce tabulky je možné přizpůsobit v sekci <b>Upravit sloupce</b>{" "}
                                            přidáním/odebráním možných sloupců. Sloupce jsou odebrány kliknutím na
                                            křížek u názvu daného sloupce v oknýnku pro správu sloupců. Přidány jsou
                                            kliknutím na bílou část okénka a vybráním požadovaného sloupce/sloupců.
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Filtr odpovědí" key="3">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Filtrování podle odpovědí")}</h2>
                                        <img src={Answers} alt="fiter-by-answers" className="help-image-desktop" />
                                        <img src={AnswersMobile} alt="fiter-by-answers" className="help-image-mobile" />
                                        <p>
                                            Zde je možné z již vyfiltrovaných dat v tabulce vyhledávat záznamy podle
                                            odpovědí dotazníku. Tlačítko reset zruší vyhledání podle odpovědí a vrátí do
                                            tabulky původně vyfiltrovaná data.
                                        </p>
                                        <p>
                                            Pro vyhledání nejprve vyberte otázku. Následně zvolte, zda chcete hledat
                                            konkrétní odpověď (<b>Je rovno</b>), nebo odpověď, která obsahuje učitý text
                                            (<b>Obsahuje</b>).
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Export" key="4">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Export")}</h2>
                                        <img src={Export} alt="export" className="help-image-desktop" />
                                        <img src={ExportMobile} alt="export" className="help-image-mobile" />
                                        <p>Pro exportování je nutné mít data úspěšně vyfiltrována pomocí formuláře. </p>
                                        <p>
                                            Export záznamů se provádí v sekci <b>Exportovat</b>.
                                        </p>
                                        <p>
                                            Je možné exportovat: <br />
                                            - Všechny sloupce všech záznamů tabulky
                                            <br />
                                            - Nastavené sloupce všech záznamů tabulky
                                            <br />
                                            - Všechny sloupce vybraných záznamů tabulky
                                            <br />
                                            - Nastavené (viditelné) sloupce vybraných záznamů
                                            <br />
                                        </p>
                                        <p>
                                            V případě, že chcete exportovat data pouze s viditelnými sloupci, zaškrtněte
                                            volbu
                                            <b> Exportovat zobrazené sloupce</b>.
                                        </p>
                                        <p>
                                            Pokud chcete exportovat data bez dotazníku, zaškrtněte volbu{" "}
                                            <b>Exportovat bez dotazníku.</b>
                                        </p>
                                        <p>
                                            Tlačítko <b>Exportovat VYBRANÉ záznamy</b> se zpřístupní až po výběru
                                            záznamů z tabulky.
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Statistika" key="5">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Statistika")}</h2>
                                        <p>
                                            Tlačítko <b>Statistika</b> nad tabulkou zobrazí okno se statistikou odpovědí
                                            na dotazník. Data jsou zobrazena ve formě grafů, případně tabulky, pro lepší
                                            orientaci.
                                        </p>
                                        <img
                                            src={StatistikaDetail}
                                            alt="statistika-telemarketingu"
                                            className="help-image-desktop"
                                        />
                                        <img
                                            src={StatistikaDetailMobile}
                                            alt="statistika-telemarketingu"
                                            className="help-image-mobile"
                                        />
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Detail " key="6">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Detail telemarketingu")}</h2>
                                        <p>
                                            Detail telemarketingu se otevře kliknutím na tlačítko ve sloupci{" "}
                                            <b>Detail</b> u příslušného záznamu. Zobrazuje podrobné informace o daném
                                            telemarketingu a nabízí další funkce.
                                        </p>
                                        <img src={Detail} alt="detail-telemarketing" className="help-image-desktop" />
                                        <img
                                            src={DetailMobile}
                                            alt="detail-telemarketing"
                                            className="help-image-mobile"
                                        />
                                        <p>
                                            Pomocí <b>šipek</b> po levé a pravé straně od názvu firmy lze přejít na
                                            detail předchozího/následujícího záznamu z tabulky.
                                        </p>
                                        <p>
                                            V horní části se nachází informace o telemarketingu. Následuje tlačítko
                                            umožňující skrytí/zobrazení dotazníku.
                                        </p>
                                        <p>
                                            V popisu telemarketingu lze telefonní čísla volat po kliknutí na modře
                                            zvýrazněné telefonní číslo. Emaily lze rychle napsat kliknutím na modře
                                            zvýrazněné emaily. Po kliknutí na modře zvýrazněný odkaz bude v novém okně
                                            otevřen příslušný web.
                                            <br />
                                        </p>
                                        <p>
                                            Ve spodní části okna se nachází tlačítko <b>Export</b> pro exportování
                                            detailu telemarketingu do souboru .pdf.
                                        </p>
                                    </Paragraph>
                                </TabPane>
                                <TabPane tab="" key="10"></TabPane>
                            </Tabs>
                        </div>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default HelpTelemarketing;
