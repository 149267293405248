//File saver
import { saveAs } from "file-saver";
//Moment
import moment from "moment";
//const utf8 = require('utf8')
//Language
import t from "../../providers/LanguageProvider";

import * as XLSX from "xlsx";

/**
 * Creates a CSV export file
 *
 * @param {Object | Array} JSONData The report data
 * @param {String} ReportTitle Name of the report
 * @param {*} ShowLabel
 */
const exportReport = (JSONData, ReportTitle, ShowLabel = null, query, campaigns, priorities) => {
    let CSV = {};

    const StartDateQ = moment(query["MeetingType"][0]["startDate"]).format("DD.MM.YYYY");
    const EndDateQ = moment(query["MeetingType"][0]["endDate"]).format("DD.MM.YYYY");

    let CampaignName = "";
    campaigns.map((campaign) => {
        if (campaign["CampaignId"] == query["TssId"]) {
            CampaignName = campaign["CampaignName"];
        }
    });

    switch (ReportTitle) {
        case "telemarketing":
            console.log(query["ContactResultIds"] == 4);
            let priorityName = query["PriorityId"] == 0 ? t("Not Selected") : "";

            priorities.map((priority) => {
                if (priority["PriorityId"] == query["PriorityId"]) {
                    priorityName = priority["PriorityName"];
                }
            });

            CSV[t("Campaign")] = CampaignName;
            CSV[t("Priority")] = priorityName;
            CSV[t("Questionnaire")] = query["ContactResultIds"] == 4 ? t("Positive response") : t("Negative response");
            CSV[t("Display")] =
                query["ReportedStatus"] == "0"
                    ? t("Reported")
                    : query["ReportedStatus"] == "1"
                    ? t("Not-Reported")
                    : t("All");
            CSV[t("Date of Sale")] = StartDateQ + " - " + EndDateQ;
            break;

        case "report":
            CSV[t("Campaign")] = CampaignName;
            CSV[t("Sales Representative")] =
                query["SRId"] == 0 ? t("Not Selected") : JSONData.length > 0 ? JSONData[0]["Obch. zástupce"] : "";
            CSV[t("Display")] =
                query["PriorityId"] == "0"
                    ? t("Reported")
                    : query["ReportedStatus"] == "1"
                    ? t("Not-Reported")
                    : t("All");
            CSV[t("Date of Sale")] = StartDateQ + " - " + EndDateQ;
            break;

        case "ratings":
            CSV[t("Campaign")] = CampaignName;
            CSV[t("Sales Representative")] =
                query["SRId"] == 0 ? t("Not Selected") : JSONData.length > 0 ? JSONData[0]["Obchodní Zástupce"] : "";
            CSV[t("Date of Sale")] = StartDateQ + " - " + EndDateQ;

            break;
    }

    {
        /*//If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  let arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;

  //This condition will generate the Label/Header
  if (ShowLabel) {
    let row = "";

    //This loop will extract the label from 1st index of on array
    for (let index in arrData[0]) {
      //Now convert each value to string and semicolon-seprated
      row += index + ";";
    }

    row = row.slice(0, -1);

    //append Label row with line break
    CSV += row + "\r\n";
  }

  //1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = "";

    //2nd loop will extract each column and convert it in string semicolon-seprated
    for (let index in arrData[i]) {
      row += '"' + arrData[i][index] + '";';
    }

    row.slice(0, row.length - 1);

    //add a line break after each row
    CSV += row + "\r\n";
  }

  if (CSV == "") {
    alert("Invalid data");
    return;
  }

  //Generate a file name
  let fileName = "MyReport_";
  //this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, "_");

  var blob = new Blob(
    [
      new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
      CSV,
    ],
    {
      encoding: "UTF-8",
      //type: "text/csv;charset=UTF-8",
      type: "application/vnd.ms-excel;charset=utf-8",
    }
  );

  const time = moment().format("DD-MM-YYYY") + "_" + moment().format("HH-mm");
  saveAs(blob, ReportTitle + time +".xls");
  */
    }

    const time = moment().format("DD-MM-YYYY") + "_" + moment().format("HH-mm");

    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.json_to_sheet(JSONData);

    XLSX.utils.book_append_sheet(wb, ws, "Vyfiltrovaná data");

    let filter = XLSX.utils.json_to_sheet([CSV]);
    XLSX.utils.book_append_sheet(wb, filter, "Filtr");

    XLSX.writeFile(wb, ReportTitle + time + ".xlsx");
};

export default exportReport;

/**
 * Parses unforrmated JSON Object to a formatted one
 *
 * @function MeetingReportsParse
 * @param {Object} unformattedData
 * @returns {Object} Formatted data
 */
export const MeetingReportsParse = (unformattedData) => {
    if (!unformattedData) throw "Got no object to parse.";

    return unformattedData.map((meeting) => {
        let set = {};
        set[t("Meeting Date")] =
            moment(meeting.MeetingDate).format("DD.MM.YYYY") + " " + moment(meeting.MeetingDate).format("HH:mm");
        set[t("Meeting Agreed Date")] =
            moment(meeting.AgreedDate).format("DD.MM.YYYY") + " " + moment(meeting.AgreedDate).format("HH:mm");
        set[t("Sales Representative")] = meeting.SR;
        set[t("TIN")] = meeting.TIN;
        set[t("Person")] = meeting.Person;
        set[t("Function")] = meeting.Function;
        set[t("Company")] = meeting.Company;
        set[t("Phone")] = meeting.PersonPhone;
        set[t("Address")] = meeting.Address + ", " + meeting.Town;
        set[t("ZIP")] = meeting.ZIP;
        set[t("Note")] = meeting.Note;
        set[t("Original Id")] = meeting.OriginalId;
        set[t("Sales Representative's Note")] = meeting.SRNote;

        return set;
    });
};

/**
 * Parses unforrmated JSON Object to a formatted one
 *
 * @function MeetingReportsParse
 * @param {Object} unformattedData
 * @returns {Object} Formatted data
 */
export const MeetingReportsParseColumns = (unformattedData, columns) => {
    if (!unformattedData) throw "Got no object to parse.";

    return unformattedData.map((meeting) => {
        let set = {};
        columns.map((column) => {
            switch (column) {
                case t("Meeting Date"):
                    set[t("Meeting Date")] =
                        moment(meeting.MeetingDate).format("DD.MM.YYYY") +
                        " " +
                        moment(meeting.MeetingDate).format("HH:mm");
                    break;
                case t("Meeting Agreed Date"):
                    set[t("Meeting Agreed Date")] =
                        moment(meeting.AgreedDate).format("DD.MM.YYYY") +
                        " " +
                        moment(meeting.AgreedDate).format("HH:mm");
                    break;
                case t("Sales Representative"):
                    set[t("Sales Representative")] = meeting.SR;
                    break;
                case t("TIN"):
                    set[t("TIN")] = meeting.TIN;
                    break;
                case t("Person"):
                    set[t("Person")] = meeting.Person;
                    break;
                case t("Function"):
                    set[t("Function")] = meeting.Function;
                    break;
                case t("Company"):
                    set[t("Company")] = meeting.Company;
                    break;
                case t("Phone"):
                    set[t("Phone")] = meeting.PersonPhone;
                    break;
                case t("Address"):
                    set[t("Address")] = meeting.Address + ", " + meeting.Town;
                    break;
                case t("ZIP"):
                    set[t("ZIP")] = meeting.ZIP;
                    break;
                case t("Note"):
                    set[t("Note")] = meeting.Note;
                    break;
                case "OriginalId":
                    set[t("Original Id")] = meeting.OriginalId;
                    break;
                case t("Sales Representative's Note"):
                    set[t("Sales Representative's Note")] = meeting.SRNote;
                    break;
            }
        });
        return set;
    });
};

/**
 * Parses unforrmated JSON Object to a formatted one
 *
 * @function TelemarketingParse
 * @param {Object} unformattedData
 * @returns {Object} Formatted data
 */
export const TelemarketingParse = (unformattedData, withoutQuestion) => {
    if (!unformattedData) throw "Got no object to parse.";

    return unformattedData.map((telemarketing) => {
        let set = {};
        set[t("Contact date")] =
            moment(telemarketing.cas).format("DD.MM.YYYY") + " " + moment(telemarketing.cas).format("HH:mm");
        set[t("Company")] = telemarketing.nazev;
        set[t("TIN")] = telemarketing.ico;
        set[t("ZIP")] = telemarketing.psc;
        set[t("Phone numbers")] = telemarketing.telefon;
        set[t("Email")] = telemarketing.mail;
        set[t("Web")] = telemarketing.web;
        set[t("Person")] = telemarketing.osoba;
        set[t("Reason for refusal")] = telemarketing.duvodOdmitnuti;
        set[t("Refusal Description")] = telemarketing.duvodPopis;
        set[t("Customer note")] = telemarketing.poznamkaZakaznika;
        set[t("Questionnaire Note")] = telemarketing.poznamka;
        set[t("Contact Note")] = telemarketing.poznamkaKontakt;

        if (!withoutQuestion) {
            {
                Object.entries(telemarketing.vyplnenyDotaznik).map((question) => {
                    let newKey =
                        question[0].indexOf(" ") > 0
                            ? question[0]
                                  .substring(question[0].indexOf(", ") + 1, question[0].length - 1)
                                  .replace(",", "- ")
                            : question[0];
                    let newValue = question[1] ? question[1].replace(",", "- ") : "-";
                    set[newKey.replace(/\n/g, " ")] = newValue;
                });
            }
        }

        return set;
    });
};

/**
 * Parses unforrmated JSON Object to a formatted one
 *
 * @function TelemarketingParseColumns
 * @param {Object} unformattedData
 * @returns {Object} Formatted data
 */
export const TelemarketingParseColumns = (unformattedData, withoutQuestion, columns) => {
    if (!unformattedData) throw "Got no object to parse.";

    return unformattedData.map((telemarketing) => {
        let set = {};
        columns.map((column) => {
            switch (column) {
                case t("Contact date"):
                    set[t("Contact date")] =
                        moment(telemarketing.cas).format("DD.MM.YYYY") +
                        " " +
                        moment(telemarketing.cas).format("HH:mm");
                    break;
                case t("Company"):
                    set[t("Company")] = telemarketing.nazev;
                    break;
                case t("ZIP"):
                    set[t("ZIP")] = telemarketing.psc;
                    break;
                case t("TIN"):
                    set[t("TIN")] = telemarketing.ico;
                    break;
                case t("Phone numbers"):
                    set[t("Phone numbers")] = telemarketing.telefon;
                    break;
                case t("Email"):
                    set[t("Email")] = telemarketing.mail;
                    break;
                case t("Web"):
                    set[t("Web")] = telemarketing.web;
                    break;
                case t("Person"):
                    set[t("Person")] = telemarketing.osoba;
                    break;
                case t("Reason for refusal"):
                    set[t("Reason for refusal")] = telemarketing.duvodOdmitnuti;
                    break;
                case t("Refusal Description"):
                    set[t("Refusal Description")] = telemarketing.duvodPopis;
                    break;
                case t("Customer note"):
                    set[t("Customer note")] = telemarketing.poznamkaZakaznika;
                    break;
                case t("Questionnaire Note"):
                    set[t("Questionnaire Note")] = telemarketing.poznamka;
                    break;
                case t("Contact Note"):
                    set[t("Contact Note")] = telemarketing.poznamkaKontakt;
                    break;
            }
        });

        if (!withoutQuestion) {
            {
                Object.entries(telemarketing.vyplnenyDotaznik).map((question) => {
                    let newKey =
                        question[0].indexOf(" ") > 0
                            ? question[0]
                                  .substring(question[0].indexOf(", ") + 1, question[0].length - 1)
                                  .replace(",", "- ")
                            : question[0];
                    let newValue = question[1] ? question[1].replace(",", "- ") : "-";
                    set[newKey.replace(/\n/g, " ")] = newValue;
                });
            }
        }
        return set;
    });
};
////////////////////////////////////////////////////////////////////////////////

/**
 * Parses unforrmated JSON Object to a formatted one
 *
 * @function RatingsParse
 * @param {Object} unformattedData
 * @returns {Object} Formatted data
 */
export const RatingsParse = (unformattedData) => {
    if (!unformattedData) throw "Got no object to parse.";

    return unformattedData.map((rating) => {
        let ratingTime = "";
        if (rating.ratingTime.indexOf("0001-01-01T00:00:00") < 0) {
            ratingTime =
                moment(rating.ratingTime).format("DD.MM.YYYY") + " " + moment(rating.ratingTime).format("HH:mm");
        } else {
            ratingTime = "-";
        }
        return {
            "Datum Schůzky":
                moment(rating.meetingTime).format("DD.MM.YYYY") + " " + moment(rating.meetingTime).format("HH:mm"),
            Firma: rating.firm,
            "Datum Hodnocení": rating.ratingTime,
            IČO: rating.ico,
            Kontakt: rating.kontakt,
            "Obchodní Zástupce": rating.SR,
        };
    });
};

/**
 * Parses unforrmated JSON Object to a formatted one
 *
 * @function RatingingParseColumns
 * @param {Object} unformattedData
 * @returns {Object} Formatted data
 */
export const RatingingParseColumns = (unformattedData, columns) => {
    console.log(columns);
    if (!unformattedData) throw "Got no object to parse.";

    return unformattedData.map((rating) => {
        let set = {};
        columns.map((column) => {
            switch (column) {
                case t("Meeting Date"):
                    set[t("Meeting Date")] =
                        moment(rating.meetingTime).format("DD.MM.YYYY") +
                        " " +
                        moment(rating.meetingTime).format("HH:mm");
                    break;
                case t("Company"):
                    set[t("Company")] = rating.firm;
                    break;
                case t("Rating Date"):
                    set[t("Rating Date")] = rating.ratingTime;
                    break;
                case t("TIN"):
                    set[t("TIN")] = rating.ico;
                    break;
                case t("Contact"):
                    set[t("Contact")] = rating.kontakt;
                    break;
                case t("Sales Representative"):
                    set[t("Sales Representative")] = rating.SR;
                    break;
            }
        });
        return set;
    });
};
////////////////////////////////////////////////////////////////////////////////
