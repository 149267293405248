import React, { useState, useEffect } from "react";
//Redux
import { store } from "../../providers/ReduxProvider";
import { setSearchQuery, setUserRatingsForm } from "../../../store/actions";
import AmaxApiService from "../../../services/AmaxApiService";
import { resetErrorView } from "../../../services/ErrorHandler";
//Antd
import { Button, Form, Select, Radio, Collapse, Popover, Input } from "antd";
import { SearchOutlined, DownOutlined, UpOutlined, FilterFilled } from "@ant-design/icons";
//Moment
import moment from "moment";
// locale for czech calendar
import { cs, enGB } from "date-fns/locale";
//Language
import t from "../../providers/LanguageProvider";
//DateRange and DefinedRange
import { DefinedRange, DateRange } from "react-date-range";
import { defineds, customRanges } from "../DateRange";
import { addDays, startOfWeek, getDate, getMonth, getYear, differenceInDays } from "date-fns";

const { Option } = Select;

const HodnoceniSchuzkyForm = () => {
    const [campaigns, setCampaigns] = useState(null);
    const [salesRepresentatives, setSalesRepresentatives] = useState(null);
    const [SRId, setSRId] = useState(store.getState().Logic.ratingSR ? store.getState().Logic.ratingSR : 0);
    const [TssId, setTssId] = useState(
        store.getState().Logic.ratingSelectedTss ? store.getState().Logic.ratingSelectedTss : null
    );

    const [MeetingType, setMeetingType] = useState("ratingTime");

    const [badDateRange, setBadDateRange] = useState(false);
    const [date, setDate] = useState([
        {
            startDate: addDays(startOfWeek(new window.Date()), 1),
            endDate: addDays(startOfWeek(new window.Date()), 7),
            key: "selection",
        },
    ]);

    const inputLayout = {
        style: {
            height: "50px",
            maxWidth: "300px",
        },
    };

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const formItemControl = {
        style: {
            maxWidth: "300px",
        },
    };

    const formRangeControl = {
        style: {
            width: "auto",
        },
    };

    /**
     * Evaluate sales reps on campaign change
     *
     * @param {Integer} campaignID
     */
    const evaluateSR = (campaignID) => {
        if (!campaignID) return null;

        // Load sales reps (available to user) to state
        AmaxApiService.Ratings.SRsByUser(campaignID).then((salesRepresentatives) => {
            setSalesRepresentatives(salesRepresentatives);
        });
    };

    /**
     * Runs on form submit, evaluates fields and sets SearchQuery
     *
     * @param {Object} values
     */
    const onFinish = (values) => {
        resetErrorView();
        console.log("Received values from form: ", values);

        setBadDateRange(false);

        if (differenceInDays(date[0].endDate, date[0].startDate) > 91) {
            setBadDateRange(true);
        } else {
            values = {
                ...values,
                Date: date,
            };
            if (!values.MeetingType) {
                values = { ...values, MeetingType: MeetingType };
            }
            if (!values.SRId) {
                values = { ...values, SRId: SRId };
            }
            console.log("Received values from form after edit: ", values);
            store.dispatch(setSearchQuery("RATINGS", values));

            setVisibleFilter(!visibleFilter);
            setOpenCalendar(false);
            store.dispatch(setUserRatingsForm(TssId, SRId, date));
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const handleCampaignChange = (value) => {
        setSalesRepresentatives(null);
        setTssId(value);
        evaluateSR(value);
        setSRId(0);
    };

    const onSalesChange = (value) => {
        setSRId(value);
    };

    const getUserRatingCampaigns = async () => {
        await AmaxApiService.Ratings.CampaignsByUser()
            .then((ratingsCampaigns) => {
                setCampaigns(ratingsCampaigns);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        resetErrorView();
        if (!campaigns) {
            getUserRatingCampaigns();
        }

        if (store.getState().Logic.ratingSelectedTss) {
            evaluateSR(store.getState().Logic.ratingSelectedTss);
        }

        if (store.getState().Logic.ratingsDate) {
            if (typeof store.getState().Logic.ratingsDate[0].startDate != "string") {
                setDate(store.getState().Logic.ratingsDate);
            } else {
                const storedDate = new Date(store.getState().Logic.ratingsDate[0].startDate);
                const endDate = new Date(store.getState().Logic.ratingsDate[0].endDate);
                setDate([
                    {
                        startDate: storedDate.getTime(),
                        endDate: endDate.getTime(),
                        key: "selection",
                    },
                ]);
            }
        }
    }, []);

    useEffect(() => {
        if (differenceInDays(date[0].endDate, date[0].startDate) > 91) {
            setBadDateRange(true);
        } else {
            setBadDateRange(false);
        }
    }, [date]);

    const [visibleFilter, setVisibleFilter] = useState(true);
    const [visible, setVisible] = useState(false); // visibility of popup range
    const [openCalendar, setOpenCalendar] = useState(false); // visibility of popup range
    const range = customRanges();

    return (
        <>
            <div>
                <button className="filter-button" onClick={() => setVisibleFilter(!visibleFilter)}>
                    {visibleFilter ? (
                        <h3>
                            {t("Hide Filter ")}
                            <UpOutlined />
                        </h3>
                    ) : (
                        <h3>
                            {t("Filter ")} <FilterFilled />
                        </h3>
                    )}
                </button>

                {visibleFilter && (
                    <Form
                        className="form"
                        onChange={(event) => {
                            console.log(event.target.name + ":" + event.target.value);
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            Date: [moment().subtract(3, "months"), moment()],
                            TssId: TssId,
                            SRId: SRId,
                            MeetingType: `${MeetingType}`,
                        }}
                        key="1"
                    >
                        <div className="form-div">
                            <Form.Item
                                {...formItemLayout}
                                label={t("Campaign")}
                                name="TssId"
                                rules={[{ required: true, message: t("Please select a Campaign") }]}
                            >
                                <Select
                                    {...formItemControl}
                                    showSearch
                                    placeholder={t("Select a campaign")}
                                    optionFilterProp="children"
                                    loading={campaigns ? false : true}
                                    disabled={campaigns ? false : true}
                                    onChange={handleCampaignChange}
                                    defaultValue={TssId}
                                    value={TssId}
                                >
                                    {campaigns
                                        ? campaigns.map((campaign) => (
                                              <Select.Option key={campaign.CampaignId} value={campaign.CampaignId}>
                                                  {campaign.CampaignName}
                                              </Select.Option>
                                          ))
                                        : null}
                                </Select>
                            </Form.Item>
                            <br />

                            <div className="sales-label">
                                <div className="sales-label-text">{t("Sales Representative")}</div>
                                <Select
                                    className="sales-label"
                                    loading={salesRepresentatives ? false : true}
                                    disabled={salesRepresentatives ? false : true}
                                    //showSearch
                                    placeholder={t("Sales Representative")}
                                    onChange={onSalesChange}
                                    value={SRId}
                                    defaultValue={SRId}
                                >
                                    <Option key={"0"} value={0} default>
                                        {t("Not Selected")}
                                    </Option>

                                    {salesRepresentatives
                                        ? salesRepresentatives.map((SR) => (
                                              <Option key={SR.IdOz} value={SR.IdOz}>
                                                  {SR.OzName}
                                              </Option>
                                          ))
                                        : null}
                                </Select>
                            </div>
                        </div>

                        <div className="left-padding">
                            <Form.Item
                                className="form-advanced"
                                {...formItemLayout}
                                label={t("Contact date")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please choose a date"),
                                    },
                                ]}
                            >
                                <div className="left-column-calendar">
                                    <div className="date-range-picker-mobile">
                                        <Popover
                                            trigger="click"
                                            placement="bottom"
                                            arrowPointAtCenter={true}
                                            onClick={() => setVisible(!visible)}
                                            visible={visible}
                                            content={
                                                <div onClick={() => setVisible(!visible)}>
                                                    <DefinedRange
                                                        onChange={(item) => setDate([item.selection])}
                                                        ranges={date}
                                                        inputRanges={[]}
                                                        staticRanges={range}
                                                        rangeColors={["#4897d8"]}
                                                    />
                                                </div>
                                            }
                                        >
                                            <button className="popup">{t("Quick Select")}</button>
                                        </Popover>
                                    </div>

                                    <DateRange
                                        className={badDateRange ? "red" : ""}
                                        {...formRangeControl}
                                        locale={store.getState().Logic.language == "cs" ? cs : enGB}
                                        value={date}
                                        defaultValue={date}
                                        editableDateInputs={false}
                                        onChange={(item) => setDate([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={date}
                                        weekStartsOn={1}
                                        rangeColors={["#48a3d8"]}
                                        dateDisplayFormat="d. MMM, yyy"
                                        minDate={defineds.minDateOfCalendar}
                                        maxDate={defineds.maxDateOfCalendar}
                                    />
                                </div>

                                <div className="right-column-calendar">
                                    <DefinedRange
                                        className="date-range-picker-desktop"
                                        {...formRangeControl}
                                        onChange={(item) => setDate([item.selection])}
                                        ranges={date}
                                        inputRanges={[]}
                                        staticRanges={range}
                                        rangeColors={["#4897d8"]}
                                    />
                                </div>
                            </Form.Item>
                        </div>

                        <Form.Item {...formItemLayout}>
                            {badDateRange && (
                                <h3 style={{ display: "flex", paddingRight: "5px", color: "#DC143C" }}>
                                    {t("Warning! The term must be less than 3 months!")}
                                </h3>
                            )}
                            <Button type="primary" htmlType="submit">
                                <SearchOutlined />
                                <b>{t("Display")}</b>
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </>
    );
};

export default HodnoceniSchuzkyForm;
