import React, { memo, useState, useEffect } from "react";
//Router
import { Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import AmaxApiService from "../services/AmaxApiService";
import { resetErrorView } from "../services/ErrorHandler";
import { store } from "../components/providers/ReduxProvider";
//Antd
import { Alert, Input, Button, Form, Select } from "antd";
import { MailOutlined } from "@ant-design/icons";
//Layout
import LoginLayout from "../components/layouts/LoginLayout";
//Components
import ErrorView from "../components/common/ErrorView";
//Language
import t from "../components/providers/LanguageProvider";
//Images
import logo from "../images/logo-portal-small.png";
import amax from "../images/amax-small.svg";
//Icons
import czIcon from "../icons/flagIcons/czech-republic.svg";
import enIcon from "../icons/flagIcons/united-kingdom.svg";

import { setLanguage } from "../store/actions";

const ResetPassword = ({ loading }) => {
    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [lang, setLang] = useState(null);

    /*
     * Handle Input Fields Change
     */
    const handleChange = (event) => {
        switch (event.target.id) {
            case "email":
                setEmail(event.target.value);
                break;
        }
    };

    /*
     * Handle button submit,
     * Send email with link to reset
     */
    const handleSubmit = async () => {
        resetErrorView();
        console.log("Reset password");
        console.log(email);
        AmaxApiService.Account.ResetPassword(email).then((response) => {
            //console.log(response);
            if (response.status === 204) {
                setEmail("");
                setSuccess(true);
                console.log("Email sent");
            } else {
                console.log("Email not sent");
            }
        });
    };

    const changeLanguage = (e) => {
        store.dispatch(setLanguage(e));
        setLang(e);
        console.log(e);
    };

    useEffect(() => {
        resetErrorView();
    }, []);

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const formItemControl = {
        style: {
            width: "300px",
        },
    };

    const buttonSubmitControl = {
        style: {
            width: "300px",
            height: "36px",
            backgroundColor: "#fcab06",
        },
    };

    const buttonControl = {
        style: {
            width: "300px",
            height: "36px",
        },
    };

    const iconStyle = {
        height: "17px",
        display: "inline-block",
    };

    const enterSubmit = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    return (
        <>
            <LoginLayout>
                <div className="login-box">
                    <Select
                        style={{ float: "right" }}
                        defaultValue={store.getState().Logic.language == "cs" ? "cs" : "en"}
                        onChange={changeLanguage}
                        key="Language"
                    >
                        <Select.Option value="cs">
                            <img src={czIcon} style={iconStyle} />
                            {" CZ"}
                        </Select.Option>
                        <Select.Option value="en">
                            <img src={enIcon} style={iconStyle} />
                            {" EN"}
                        </Select.Option>
                    </Select>
                    <br />

                    <div className="blue-background login-box">
                        <div className="email-login login-img">
                            <img className="login-img" src={logo} alt="logo" />
                        </div>
                        <br />
                        <h3 style={{ textAlign: "center", width: "330px" }}>
                            {t(
                                "Insert email you are registered with and we will send you link for reseting your password"
                            )}
                        </h3>

                        <Form>
                            <div className="email-login white-background">
                                <Form.Item className="u-form-group" {...formItemLayout} name="email">
                                    <Input
                                        className="u-form-group"
                                        {...formItemControl}
                                        type="email"
                                        placeholder={t("Email")}
                                        id="email"
                                        value={email}
                                        onChange={handleChange}
                                        onKeyDown={(e) => enterSubmit(e)}
                                        prefix={<MailOutlined className="form-input-icon" />}
                                    ></Input>
                                </Form.Item>

                                {!loading ? (
                                    <>
                                        <Button
                                            {...buttonSubmitControl}
                                            className="ant-btn-secondary u-form-group btn-login-form"
                                            onClick={handleSubmit}
                                        >
                                            {t("Send")}
                                        </Button>
                                        <br />
                                        <Link to="/login">
                                            <Button {...buttonControl} className="ant-btn-secondary u-form-group">
                                                {t("Back")}
                                            </Button>
                                        </Link>
                                    </>
                                ) : null}
                            </div>
                            <ErrorView type="main" />
                        </Form>

                        {success && (
                            <Alert
                                style={{ width: "330px", textAlign: "center" }}
                                message={t("Email sent. You can create new password at the link in the email")}
                                type="success"
                                showIcon
                            />
                        )}
                        <div className="email-login">
                            <img src={amax} alt="logo" />
                        </div>
                    </div>
                </div>

                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                    <p style={{ textAlign: "center", paddingTop: "10vh" }}>
                        {t("In case of problems, please contact your sales representative or write to e-mail ")}
                        <a href="mailto:obchod@amaxsro.cz">obchod@amaxsro.cz</a>
                    </p>
                </div>
            </LoginLayout>

            <div style={{ backgroundColor: "#282d32" }}>
                <p
                    style={{
                        textAlign: "center",
                        fontFamily: "Poppins",
                        color: "#FFFFFF66",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                    }}
                >
                    AMAX, s.r.o. © 2022
                </p>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(memo(ResetPassword));

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi, loading: state.Logic.loading };
}
