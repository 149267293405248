import React from "react";
//Types
import PropTypes from "prop-types";
//Providers
import ReduxProvider from "./ReduxProvider";
import { LanguageProvider } from "./LanguageProvider";

const MainProvider = ({ element }) => (
    <ReduxProvider>
        <LanguageProvider>{element}</LanguageProvider>
    </ReduxProvider>
);

MainProvider.propTypes = {
    element: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default MainProvider;
