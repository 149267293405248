import React from "react";
//Types
import PropTypes from "prop-types";
//Router
import { useLocation } from "react-router-dom";
//Components
import LoginGate from "../common/LoginGate";

const MainLayout = ({ element }) => {
    return <LoginGate location={useLocation()}>{element}</LoginGate>;
};

MainLayout.propTypes = {
    element: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default MainLayout;
