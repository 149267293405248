import React, { memo, useState, useEffect } from "react";
import AmaxApiService from "../services/AmaxApiService";
import { store } from "../components/providers/ReduxProvider";
//Antd
import { PageHeader, Button, Tooltip } from "antd";
//Layout
import LayoutWrapper from "../components/layouts/LayoutWrapper";
//Components
import ReportSchuzkyForm from "../components/common/forms/ReportSchuzkyForm";
import ReportSchuzkyTable from "../components/common/tables/ReportSchuzkyTable";
import ErrorView from "../components/common/ErrorView";
//Language
import t from "../components/providers/LanguageProvider";

import HelpMeetings from "../components/common/Modals/HelpMeetings";

import { setUserMeetingCampaigns } from "../store/actions";

const ReportSchuzkyPage = () => {
    const [displayHelp, setDisplayHelp] = useState(false);

    const closeHelp = () => {
        setDisplayHelp(false);
    };

    const handleHelp = () => {
        setDisplayHelp(true);
    };

    const [switchOption, setSwitchOption] = useState(null);

    const handleSwitchChange = (date) => {
        setSwitchOption(date);
        console.log("DEBUG 2: ", switchOption);
    };

    {
        /*const getUserMeetingCampaigns = async () => {
    await AmaxApiService.ReportMeetings.CampaignsByUser()
      .then((campaigns) => {
        store.dispatch(setUserMeetingCampaigns(campaigns))
      })
      .catch((err) => console.log(err));
  };*/
    }

    useEffect(() => {
        //loads user permisions if not loaded
        {
            /*if (!store.getState().Logic.meetingCampaigns) {
      getUserMeetingCampaigns();
    */
        }
    }, []);

    return (
        <>
            <HelpMeetings key="Help" closeDialog={() => closeHelp()} visible={displayHelp} />
            <LayoutWrapper>
                <PageHeader
                    onBack={() => {
                        window.location = "/";
                    }}
                    title={
                        <>
                            {t("Report Meetings")}
                            <Tooltip placement="leftBottom" title={t("Help")}>
                                <Button
                                    className="mobile-help"
                                    size="small"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => handleHelp()}
                                >
                                    ?
                                </Button>
                            </Tooltip>
                        </>
                    }
                    className="ant-page-header-half-radius ant-page-header-boxShadow"
                    extra={[
                        <Tooltip placement="leftBottom" title={t("Help")}>
                            <Button className="desktop-help" size="small" onClick={() => handleHelp()}>
                                ?
                            </Button>
                        </Tooltip>,
                    ]}
                >
                    <ReportSchuzkyForm switchOption={handleSwitchChange} />
                    <ErrorView type="main" />
                </PageHeader>
                <ReportSchuzkyTable selectedSwitch={switchOption} />
            </LayoutWrapper>
        </>
    );
};

export default memo(ReportSchuzkyPage);
