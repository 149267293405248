import React, { memo } from "react";
//Redux
import { connect } from "react-redux";

const Loading = (props) =>
    props.loading ? (
        <div className="loading-container">
            <div className="loading"></div>
        </div>
    ) : null;

export default connect(mapStateToProps)(memo(Loading));

function mapStateToProps(state) {
    return { loading: state.Logic.loading };
}
