import React, { Component, PropTypes } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
//Antd
import { Button, Skeleton, Descriptions, Modal, Tooltip, Input } from "antd";
import {
    FilePdfOutlined,
    StopOutlined,
    SaveOutlined,
    GoogleOutlined,
    ShopTwoTone,
    EditOutlined,
    CloseCircleOutlined,
    FieldTimeOutlined,
    FrownOutlined,
    UserOutlined,
    MailOutlined,
    EnvironmentOutlined,
    GlobalOutlined,
    DollarOutlined,
    CalendarOutlined,
    InfoCircleTwoTone,
    LeftOutlined,
    RightOutlined,
    PhoneOutlined,
    SkinOutlined,
    HomeOutlined,
    CommentOutlined,
    MobileOutlined,
} from "@ant-design/icons";
//Redux
import AmaxApiService from "../../../services/AmaxApiService";
//Moment
import moment from "moment";
import { addDays } from "date-fns";
//Map
import LeafletMap from "../LeafletMap";
import { Marker } from "react-leaflet";
//Components
import ChangeMeetingDateModal from "./ChangeMeetingDateModal";
import MeetingReclamationModal from "./MeetingReclamationModal";
import { addToGCal } from "../Calendar";
import exportToPdf from "../exports/PDFExport";
//Helpers
import { responsiveWidth, getCoordinates } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
import { number } from "prop-types";
import { map } from "leaflet";
//Images
import logo from "../../../images/logo-export-amax.png";

const mapDivStyle = {
    style: {
        height: "250px",
        width: "calc(100%)",
        overflow: "hidden",
    },
};

const buttonStyle = {
    style: {
        width: "150px",
        paddingBottom: "5px",
    },
};

let googleAddress = "";

class SchuzkaDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            noteEditable: false,
            noteSaving: false,
            newNote: null,
            data: null,
            ChangeMeetingDateModal: false,
            MeetingReclamationModal: false,
            coordinates: [0, 0],
            layout: "vertical",
            mapErr: false,
            mapLoaded: false,
        };
        this.addToCal = this.addToCal.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
    }

    printDocument() {
        const input = document.getElementById("divToPrint");
        html2canvas(input, {
            onclone: (document) => {
                document.getElementById("divToPrint").style.display = "block";
            },
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "pt", "a4", false);
            pdf.addImage(imgData, "PNG", 30, 30, 540, 0, undefined, false);
            // pdf.output('dataurlnewwindow');
            pdf.save(`Report-${this.state.data.MeetingId}.pdf`);
        });
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }

    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.meetingId) {
            AmaxApiService.ReportMeetings.Report(newProps.meetingId).then((data) => {
                this.setState({
                    data: data,
                    mapErr: true,
                });
                googleAddress = "";

                let houseNumber = data.Address.match(/\d+/)[0];
                const streetWithNumber = data.Address.replace("č.p. ", "");
                let street = streetWithNumber.replace(" " + houseNumber, "");

                const twoNumbers = data.Address.match("/");
                if (twoNumbers) {
                    houseNumber = data.Address.match(/\/\d+/)[0].match(/\d+/)[0];
                    street = street.replace("/", "").replace(houseNumber, "");
                }

                const again = street.match("/");
                if (again) {
                    let num = street.match(/\/\d+/)[0].match(/\d+/)[0];
                    street = street.replace("/", "").replace(num, "");
                    street = street.replace("/", "").replace(houseNumber, "");
                }

                const wsStreet = street.replaceAll(" ", "");
                const wsTown = data.Town.replaceAll(" ", "");
                const numberAndStreet =
                    wsStreet == wsTown || houseNumber == wsStreet ? houseNumber : houseNumber + "%2F" + street;

                const gnumberAndStreet =
                    houseNumber == wsStreet ? data.Town + "+" + houseNumber : street + "+" + houseNumber;
                googleAddress = (
                    "https://www.google.com/maps/place/" +
                    gnumberAndStreet +
                    "," +
                    this.state.data.ZIP +
                    "+" +
                    this.state.data.Town
                ).replaceAll(" ", "+");

                getCoordinates(
                    "street=" + numberAndStreet.replaceAll(" ", "+") + "&city=" + data.Town + "&postcode=" + data.ZIP
                ).then((coordinates) => {
                    this.setState({
                        coordinates: coordinates,
                        data: data,
                        noteEditable: false,
                        mapErr: false,
                        mapLoaded: true,
                    });
                    if (coordinates[0] == "0" && coordinates[1] == "0") {
                        getCoordinates(
                            "street=" +
                                numberAndStreet.replaceAll(" ", "+") +
                                "&city=" +
                                data.Town +
                                "&postalcode=" +
                                data.ZIP
                        ).then((coordinates) => {
                            this.setState({
                                coordinates: coordinates,
                                data: data,
                                noteEditable: false,
                                mapErr: false,
                                mapLoaded: true,
                            });
                            if (coordinates[0] == "0" && coordinates[1] == "0") {
                                getCoordinates("street=" + numberAndStreet.replaceAll(" ", "+")).then((coordinates) =>
                                    this.setState({
                                        coordinates: coordinates,
                                        data: data,
                                        noteEditable: false,
                                        mapErr: false,
                                        mapLoaded: true,
                                    })
                                );
                            }
                        });
                    }
                });
            });
        }
    }

    closeDialog() {
        this.setState({
            ChangeMeetingDateModal: false,
            MeetingReclamationModal: false,
            mapLoaded: false,
        });
    }

    addToCal() {
        addToGCal({
            title: this.state.data.Company,
            date: [this.state.data.MeetingDate, moment(this.state.MeetingDate).add(1, "hour")],
            location: this.state.data.MeetingPlace,
            details: `${t("Meeting with ")} ${this.state.data.Person}, ${this.state.data.Function}, ${
                this.state.data.Phone
            }, ${t("Note")}: ${this.state.data.Note} `,
        });
    }

    exportPDF() {
        exportToPdf(this.state.data);
    }

    saveNote() {
        this.setState({ noteSaving: true });
        console.log(this.state.newNote);
        AmaxApiService.ReportMeetings.UpdateSRNote({
            MeetingId: this.state.data.MeetingId,
            Text: this.state.newNote,
        }).then((response) => {
            console.log(response);
            this.setState({
                noteSaving: false,
                noteEditable: false,
                data: Object.assign({}, this.state.data, {
                    SRNote: this.state.newNote,
                }),
            });
        });
    }

    isNumeric(value) {
        return /^\d+$/.test(value);
    }

    formatNote(input) {
        const rePhone = /(?:[+() ]*\d){9,13}/gm;
        const reEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
        const reWeb =
            /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

        if (this.state.data) {
            if (input) {
                let parts = input.split(/(?:,|\s)/);
                var text = undefined;

                parts.forEach((element) => {
                    if (!text) {
                        if (reEmail.test(element)) text = this.makeEmailLink(element);
                        else if (reWeb.test(element)) text = this.makeWebLink(element);
                        else text = element;
                    } else {
                        if (reEmail.test(element)) text = text + " " + this.makeEmailLink(element);
                        else if (reWeb.test(element)) text = text + " " + this.makeWebLink(element);
                        else text = text + " " + element;
                    }
                });

                let phoneNumbers = [...text.matchAll(rePhone)];
                let newText = "";
                let i = 0;
                phoneNumbers.forEach((element) => {
                    newText = newText + text.substring(i, element["index"]) + this.makePhoneLink(element[0]);
                    i = element["index"] + element[0].length;
                });
                newText = newText + text.substring(i, text.length);
                return newText;
            }
        }
    }

    findWeb(input) {
        const re =
            /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
        if (this.state.data) {
            if (input) {
                return this.makeWebLink(input.match(re) ? input.match(re)[0] : "http://");
            }
        }
    }

    findEmail(input) {
        const re = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
        if (this.state.data) {
            if (input) {
                return this.makeEmailLink(input.match(re) ? input.match(re)[0] : "-");
            }
        }
    }

    findPhone(input) {
        const re = /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/;

        if (this.state.data) {
            if (input) {
                let splitArray = input.split(",");
                if (splitArray.length > 0) {
                    return this.makePhoneLink(splitArray[0].match(re) ? splitArray[0].match(re)[0] : " ");
                }
                return this.makePhoneLink(input.match(re) ? input.match(re)[0] : " ");
            }
        }
    }

    makePhoneLink(phone) {
        let toCall = phone;
        let formatedPhone = "";
        if (phone[0] == "+") {
            toCall = "tel:" + phone;
            formatedPhone = phone.replaceAll(" ", "").substring(4);
        } else if (phone.indexOf("420") == 0) {
            toCall = "tel:+" + phone;
            formatedPhone = phone.replaceAll(" ", "").substring(3);
        } else {
            toCall = "tel:+420" + phone.replaceAll(" ", "");
            formatedPhone = phone.replaceAll(" ", "");
        }
        formatedPhone =
            "+420 " +
            formatedPhone.substring(0, 3) +
            " " +
            formatedPhone.substring(3, 6) +
            " " +
            formatedPhone.substring(6);
        return (
            //className="blue-text"
            `<a href=${toCall} target="_blank">
        <span>${formatedPhone}</span>
      </a>`
        );
    }
    makeEmailLink(email) {
        let mailTo = "mailto:" + email;
        return `<a href=${mailTo}>
          <span>${email}</span>
      </a>`;
    }

    makeWebLink(web) {
        let webLink = web;
        if (webLink.indexOf("://") < 1) {
            webLink = "http://" + web;
        }
        return `<a href=${webLink} target="_blank">
          <span>${web}</span>
      </a>`;
    }

    /////////////////////

    isChangeable() {
        if (!this.state.data) return false;
        return moment(this.state.data.MeetingDate).isBefore(moment());
    }

    render() {
        const footer = [
            <Button
                {...buttonStyle}
                type="secondary"
                size="large"
                key="refund"
                onClick={() => {
                    this.props.closeDialog();
                    this.setState({ MeetingReclamationModal: true });
                }}
            >
                <FrownOutlined />
                {t("Refund")}
            </Button>,
            <Button
                {...buttonStyle}
                disabled={this.isChangeable() ? true : false}
                type="secondary"
                size="large"
                key="changeTime"
                onClick={() => {
                    this.props.closeDialog();
                    this.setState({ ChangeMeetingDateModal: true });
                }}
            >
                <FieldTimeOutlined />
                {t("Move")}
            </Button>,
            <Button {...buttonStyle} type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        key="modal"
                        width={this.state.modalWidth}
                        footer={footer}
                        title={
                            <>
                                <InfoCircleTwoTone style={{ paddingRight: "8px" }} />
                                {t("Meeting Detail")}
                            </>
                        }
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        {!this.state.data ? (
                            //<></>
                            <Skeleton active />
                        ) : this.props.meetingId !== this.state.data.MeetingId ? (
                            <Skeleton active />
                        ) : (
                            <>
                                <div data-html2canvas-ignore="true">
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            size="small"
                                            key="prev"
                                            shape="circle"
                                            onClick={this.props.openPrevDetail}
                                            style={{ float: "left", marginTop: "12px" }}
                                        >
                                            <LeftOutlined />
                                        </Button>

                                        <h3 style={{ width: "100%", paddingLeft: "10px" }} className="title">
                                            {this.state.data.Company}
                                        </h3>

                                        <Button
                                            style={{ float: "right", marginTop: "12px" }}
                                            size="small"
                                            key="next"
                                            shape="circle"
                                            onClick={this.props.openNextDetail}
                                        >
                                            <RightOutlined />
                                        </Button>
                                    </div>

                                    <div className="mobile-card">
                                        <div class="column-mobile">
                                            <div style={{ display: "flex", width: "26px" }}>
                                                <Tooltip placement="top" title={t("Company TIN")}>
                                                    <p
                                                        style={{
                                                            paddingRight: "14px",
                                                            paddingTop: "2px",
                                                            fontSize: "12px",
                                                        }}
                                                    >
                                                        {`${t("TIN")}  `}
                                                    </p>
                                                </Tooltip>
                                                <p style={{ paddingLeft: "2px" }}>
                                                    {this.state.data.TIN ? this.state.data.TIN : "-"}
                                                </p>
                                            </div>

                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Person")}>
                                                    <UserOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div style={{ paddingTop: "2px" }}>
                                                    {this.state.data.Person && `${this.state.data.Person}`}
                                                    {this.state.data.Function && `, ${this.state.data.Function}`}
                                                </div>
                                            </div>

                                            <div className="mobile-card-div" style={{ paddingTop: "18px" }}>
                                                <Tooltip placement="top" title={t("Person Phone")}>
                                                    <MobileOutlined className="detail-icon" />
                                                </Tooltip>
                                                {
                                                    <div
                                                        style={{ paddingTop: "2px" }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findPhone(this.state.data.PersonPhone),
                                                        }}
                                                    />
                                                }
                                            </div>

                                            <br />
                                            <br />
                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Meeting Date")}>
                                                    <CalendarOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div style={{ paddingTop: "2px" }}>
                                                    {moment(this.state.data.MeetingDate).format("DD.MM.YYYY") +
                                                        " " +
                                                        moment(this.state.data.MeetingDate).format("HH:mm")}
                                                </div>
                                            </div>

                                            <div className="mobile-card-div" style={{ paddingTop: "18px" }}>
                                                <Tooltip placement="top" title={t("Meeting Place")}>
                                                    <EnvironmentOutlined className="detail-icon" />
                                                </Tooltip>
                                                <p style={{ paddingLeft: "4px", paddingTop: "4px" }}>
                                                    {this.state.data.Address && `${this.state.data.Address}, `}
                                                    {this.state.data.Town && `${this.state.data.Town}`}
                                                    {this.state.data.ZIP && `, ${this.state.data.ZIP}`}
                                                    {this.state.data.Township && (
                                                        <p style={{ paddingTop: "10px" }}>{`${t("Region")}: ${
                                                            this.state.data.Township
                                                        }`}</p>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ///// DESKTOP VERSION ////// */}
                                    <div class="desktop-card">
                                        <div class="row">
                                            <div class="column">
                                                <div style={{ display: "flex", paddingTop: "6px" }}>
                                                    <Tooltip placement="top" title={t("Company TIN")}>
                                                        <p
                                                            style={{
                                                                paddingRight: "14px",
                                                                fontSize: "12px",
                                                                paddingTop: "2px",
                                                            }}
                                                        >{`${t("TIN")}  `}</p>
                                                    </Tooltip>
                                                    {this.state.data.TIN ? this.state.data.TIN : "-"}
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Person")}>
                                                        <UserOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    <div style={{ paddingLeft: "4px" }}>
                                                        {this.state.data.Person && `${this.state.data.Person}`}
                                                        {this.state.data.Function && `, ${this.state.data.Function}`}
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", paddingTop: "12px" }}>
                                                    <Tooltip placement="top" title={t("Person Phone")}>
                                                        <MobileOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.findPhone(this.state.data.PersonPhone),
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Meeting Date")}>
                                                        <CalendarOutlined
                                                            className="detail-icon"
                                                            style={{ paddingTop: "2px" }}
                                                        />
                                                    </Tooltip>
                                                    <div>
                                                        {moment(this.state.data.MeetingDate).format("DD.MM.YYYY") +
                                                            " " +
                                                            moment(this.state.data.MeetingDate).format("HH:mm")}
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", paddingTop: "10px" }}>
                                                    <Tooltip placement="top" title={t("Meeting Place")}>
                                                        <EnvironmentOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    <p style={{ paddingLeft: "4px", paddingTop: "4px" }}>
                                                        {this.state.data.Address && `${this.state.data.Address}, `}
                                                        {this.state.data.Town && `${this.state.data.Town}`}
                                                        {this.state.data.ZIP && `, ${this.state.data.ZIP}`}
                                                        {this.state.data.Township && (
                                                            <p style={{ paddingTop: "6px" }}>{`${t("Region")}: ${
                                                                this.state.data.Township
                                                            }`}</p>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.data.Note && (
                                        <div class="row">
                                            <div class="big-column">
                                                <div style={{ paddingTop: "12px" }}>
                                                    <b>{t("Note") + ": "}</b>
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.formatNote(
                                                                    this.state.data.Note
                                                                ).toString(),
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div class="row">
                                        <div class="big-column">
                                            <div style={{ paddingTop: "12px" }}>
                                                <b>{t("Sales Representative")}</b> {": " + this.state.data.SR}
                                            </div>
                                        </div>
                                        {this.state.noteEditable ? (
                                            <div class="big-column">
                                                <b>{t("SR Note") + ": "}</b>
                                                <React.Fragment key="edit-note-input">
                                                    <Input.TextArea
                                                        key="textArea"
                                                        onChange={(e) => this.setState({ newNote: e.target.value })}
                                                        rows={3}
                                                        value={this.state.newNote}
                                                    />
                                                    <div key="editnote-group" className="btn-group">
                                                        <Button
                                                            type="primary"
                                                            key="save-note"
                                                            style={{ float: "right" }}
                                                            onClick={this.saveNote.bind(this)}
                                                            loading={this.state.noteSaving}
                                                        >
                                                            <SaveOutlined key="save" />
                                                            {t("Save")}
                                                        </Button>
                                                        <Button
                                                            type="secondary"
                                                            key="cancel-note"
                                                            style={{ float: "right" }}
                                                            onClick={() => this.setState({ noteEditable: false })}
                                                        >
                                                            <StopOutlined key="cancel-icon" />
                                                            {t("Cancel")}
                                                        </Button>
                                                    </div>
                                                </React.Fragment>
                                            </div>
                                        ) : (
                                            <div class="big-column">
                                                <b>{t("SR Note") + ": "}</b>
                                                <React.Fragment key="edit-note-button">
                                                    {this.state.data.SRNote}
                                                    <Button
                                                        type="primary"
                                                        key="edit"
                                                        style={{ float: "right" }}
                                                        icon={<EditOutlined />}
                                                        onClick={() =>
                                                            this.setState({
                                                                noteEditable: true,
                                                                newNote: this.state.data.SRNote,
                                                            })
                                                        }
                                                    >
                                                        {this.state.layout == "horizontal" ? t("Edit") : null}
                                                    </Button>
                                                </React.Fragment>
                                            </div>
                                        )}
                                    </div>

                                    <div className="btn-group" key="groupOfBtn">
                                        <Button
                                            key="export"
                                            type="primary"
                                            size="large"
                                            onClick={this.printDocument.bind(this)}
                                        >
                                            <FilePdfOutlined />
                                            {t("Export")}
                                        </Button>
                                        <a href={googleAddress} target="_blank">
                                            <Button key="calendar" type="secondary" size="large">
                                                <EnvironmentOutlined />
                                                {t("Google Maps")}
                                            </Button>
                                        </a>

                                        <Button
                                            onClick={this.addToCal.bind(this)}
                                            key="calendar"
                                            type="secondary"
                                            size="large"
                                        >
                                            <GoogleOutlined />
                                            {t("Add to Calendar")}
                                        </Button>
                                    </div>

                                    <div {...mapDivStyle} key="map" id="map" className="lf-container">
                                        <LeafletMap
                                            key="map-div"
                                            width="100%"
                                            height="250px"
                                            position={this.state.coordinates}
                                            text={this.state.data.MeetingPlace}
                                        />
                                    </div>
                                    <br />
                                </div>

                                <div
                                    id="divToPrint"
                                    style={{
                                        width: "200mm",
                                        display: "none",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            background: "#48a3d8",
                                            paddingTop: "5px",
                                        }}
                                        className="pdf-img"
                                    >
                                        <h2
                                            style={{
                                                padding: "10px",
                                                background: "#48a3d8",
                                                color: "white",
                                                width: "80%",
                                            }}
                                        >
                                            {t("Meeting Detail") + " "}
                                            {this.state.data.MeetingId}
                                        </h2>
                                        <img src={logo} />
                                    </div>

                                    <h3 style={{ width: "100%", paddingLeft: "5px" }} className="title">
                                        {this.state.data.Company}
                                    </h3>

                                    <div class="desktop-card">
                                        <div class="row">
                                            <div class="column">
                                                <div style={{ display: "flex", paddingTop: "6px" }}>
                                                    <Tooltip placement="top" title={t("Company TIN")}>
                                                        <p
                                                            style={{
                                                                paddingRight: "14px",
                                                                fontSize: "12px",
                                                                paddingTop: "2px",
                                                            }}
                                                        >{`${t("TIN")}  `}</p>
                                                    </Tooltip>
                                                    {this.state.data.TIN ? this.state.data.TIN : "-"}
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Person")}>
                                                        <UserOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    <div style={{ paddingLeft: "4px" }}>
                                                        {this.state.data.Person && `${this.state.data.Person}`}
                                                        {this.state.data.Function && `, ${this.state.data.Function}`}
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", paddingTop: "12px" }}>
                                                    <Tooltip placement="top" title={t("Person Phone")}>
                                                        <MobileOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.findPhone(this.state.data.PersonPhone),
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Meeting Date")}>
                                                        <CalendarOutlined
                                                            className="detail-icon"
                                                            style={{ paddingTop: "2px" }}
                                                        />
                                                    </Tooltip>
                                                    <div>
                                                        {moment(this.state.data.MeetingDate).format("DD.MM.YYYY") +
                                                            " " +
                                                            moment(this.state.data.MeetingDate).format("HH:mm")}
                                                    </div>
                                                </div>

                                                <div style={{ display: "flex", paddingTop: "10px" }}>
                                                    <Tooltip placement="top" title={t("Meeting Place")}>
                                                        <EnvironmentOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    <p style={{ paddingLeft: "4px", paddingTop: "4px" }}>
                                                        {this.state.data.Address && `${this.state.data.Address}, `}
                                                        {this.state.data.Town && `${this.state.data.Town}`}
                                                        {this.state.data.ZIP && `, ${this.state.data.ZIP}`}
                                                        {this.state.data.Township && (
                                                            <p style={{ paddingTop: "6px" }}>{`${t("Region")}: ${
                                                                this.state.data.Township
                                                            }`}</p>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.data.Note && (
                                        <div class="row">
                                            <div class="big-column">
                                                <div style={{ paddingTop: "12px" }}>
                                                    <b>{t("Note") + ": "}</b>
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.formatNote(
                                                                    this.state.data.Note
                                                                ).toString(),
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div class="row">
                                        <div class="big-column">
                                            <div style={{ paddingTop: "12px" }}>
                                                <b>{t("Sales Representative")}</b> {": " + this.state.data.SR}
                                            </div>
                                        </div>
                                        <div class="big-column">
                                            <b>{t("SR Note") + ": "}</b>
                                            {this.state.data.SRNote}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </Modal>
                )}
                <ChangeMeetingDateModal
                    key="ChangeMeetingDateModal"
                    visible={this.state.ChangeMeetingDateModal}
                    closeDialog={this.closeDialog.bind(this)}
                    meetingDetails={this.state.data}
                />

                <MeetingReclamationModal
                    key="MeetingReclamationModal"
                    visible={this.state.MeetingReclamationModal}
                    closeDialog={this.closeDialog.bind(this)}
                    meetingDetails={this.state.data}
                />
            </React.Fragment>
        );
    }
}
export default SchuzkaDetail;
