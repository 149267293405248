import React from "react";
//Antd
import { Button, Skeleton, Descriptions, Modal, PageHeader, Input } from "antd";
import {
    FilePdfOutlined,
    CalendarTwoTone,
    TagsTwoTone,
    ContactsTwoTone,
    ShopTwoTone,
    CloseCircleOutlined,
    CaretUpOutlined,
    CommentOutlined,
    PhoneTwoTone,
    ScheduleTwoTone,
    LeftOutlined,
    RightOutlined,
    EnvironmentOutlined,
    MailOutlined,
    GlobalOutlined,
    UserOutlined,
    PhoneOutlined,
    CalendarOutlined,
    InfoCircleTwoTone,
    StarOutlined,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import exportToPdf from "../exports/PDFExport";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";

const queButtonLayout = {
    style: {
        marginTop: "5px",
        marginBottom: "5px",
        marginRight: "0",
        width: "200px",
        float: "right",
    },
};

const itemHeaderLayout = {
    style: {
        background: "#eeeeee",
        border: "1px solid #d6d6d6",
    },
};

class HodnoceniSchuzkyDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            data: null,
            layout: "vertical",
            visible: false,
        };
        this.exportPDF = this.exportPDF.bind(this);
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }

    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.record) {
            this.setState({ data: newProps.record });
        }
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    closeDialog() {
        this.setState({
            ChangeMeetingDateModal: false,
            MeetingReclamationModal: false,
        });
    }

    exportPDF() {
        exportToPdf(this.state.data);
    }

    headerButtons = [];

    render() {
        const footer = [
            /*<Button
        type="secondary"
        key="export" 
        size="large"
        onClick={this.exportPDF.bind(this)}
      >
        <FilePdfOutlined />
        {t("Export")}
      </Button>,*/

            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={
                            <>
                                <InfoCircleTwoTone style={{ paddingRight: "8px" }} />
                                {t("Detail")}
                            </>
                        }
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        {!this.state.data ? (
                            <Skeleton active />
                        ) : this.props.record !== this.state.data ? (
                            <Skeleton active />
                        ) : (
                            <>
                                <div style={{ display: "flex" }}>
                                    <Button
                                        //type="primary"
                                        size="small"
                                        key="prev"
                                        shape="circle"
                                        onClick={this.props.openPrevDetail}
                                        style={{ float: "left", marginTop: "12px" }}
                                    >
                                        <LeftOutlined />
                                    </Button>

                                    <h3 style={{ width: "100%", paddingLeft: "10px" }} className="title">
                                        {this.state.data.firm}
                                    </h3>

                                    <Button
                                        style={{ float: "right", marginTop: "12px" }}
                                        //type="primary"
                                        size="small"
                                        key="next"
                                        shape="circle"
                                        onClick={this.props.openNextDetail}
                                    >
                                        <RightOutlined />
                                    </Button>
                                </div>

                                <div className="mobile-card">
                                    <div class="column-mobile">
                                        <div style={{ display: "flex", width: "26px" }}>
                                            <p
                                                style={{ paddingRight: "14px", paddingTop: "2px", fontSize: "12px" }}
                                            >{`${t("TIN")}: `}</p>
                                            {this.state.data.ico}
                                        </div>

                                        <br />
                                        <div className="mobile-card-div">
                                            <UserOutlined className="detail-icon" />
                                            <div>{this.state.data.SR}</div>
                                        </div>
                                        <div className="mobile-card-div">
                                            <PhoneOutlined className="detail-icon" />
                                            <div>{this.state.data.kontakt}</div>
                                        </div>

                                        <br />
                                        <div className="mobile-card-div">
                                            <CalendarOutlined className="detail-icon" />
                                            <div>
                                                {moment(this.state.data.meetingTime).format("DD.MM.YYYY") +
                                                    " " +
                                                    moment(this.state.data.meetingTime).format("HH:mm")}
                                            </div>
                                        </div>
                                        <div className="mobile-card-div">
                                            <StarOutlined className="detail-icon" />
                                            {this.state.data.ratingTime.indexOf("0001-01-01T00:00:00") < 0 ? (
                                                <div>
                                                    {moment(this.state.data.ratingTime).format("DD.MM.YYYY") +
                                                        " " +
                                                        moment(this.state.data.ratingTime).format("HH:mm")}
                                                </div>
                                            ) : (
                                                <div>-</div>
                                            )}
                                        </div>

                                        {/*<div className="mobile-card-div">
                    <DollarOutlined className="detail-icon"/>
                    <div>{this.state.data.cena + ",-"}</div>
                    </div>*/}
                                    </div>
                                </div>

                                {/* ///// DESKTOP VERSION ////// */}
                                <div class="desktop-card">
                                    <div className="row">
                                        <div class="column">
                                            <div style={{ display: "flex" }}>
                                                <p
                                                    style={{
                                                        paddingRight: "14px",
                                                        fontSize: "12px",
                                                        paddingTop: "2px",
                                                    }}
                                                >{`${t("TIN")}: `}</p>
                                                {this.state.data.ico}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" style={{ paddingTop: "8px" }}>
                                        <div class="column">
                                            <div style={{ display: "flex" }}>
                                                <UserOutlined className="detail-icon" />
                                                <div style={{ paddingLeft: "4px" }}>{this.state.data.SR}</div>
                                            </div>

                                            <div style={{ display: "flex" }}>
                                                <PhoneOutlined className="detail-icon" />
                                                <div style={{ paddingLeft: "4px" }}>{this.state.data.kontakt}</div>
                                            </div>
                                        </div>

                                        <div class="column">
                                            <div style={{ display: "flex" }}>
                                                <CalendarOutlined
                                                    className="detail-icon"
                                                    style={{ paddingTop: "2px" }}
                                                />
                                                <div>
                                                    {moment(this.state.data.meetingTime).format("DD.MM.YYYY") +
                                                        " " +
                                                        moment(this.state.data.meetingTime).format("HH:mm")}
                                                </div>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <StarOutlined className="detail-icon" style={{ paddingTop: "2px" }} />
                                                {this.state.data.ratingTime.indexOf("0001-01-01T00:00:00") < 0 ? (
                                                    <div>
                                                        {moment(this.state.data.ratingTime).format("DD.MM.YYYY") +
                                                            " " +
                                                            moment(this.state.data.ratingTime).format("HH:mm")}
                                                    </div>
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br />
                                <br />
                            </>
                        )}
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default HodnoceniSchuzkyDetail;
