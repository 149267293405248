/**
 * Check if user already logged in
 */
export function isValidLogin(AmaxApi) {
    if (!AmaxApi) return false;
    return AmaxApi.connected && new Date(AmaxApi.token_expires_date) > new Date();
}

// TODO: ...
export function isLoginExpired(AmaxApi) {
    if (!AmaxApi.token_expires_date) return false;
    return new Date(AmaxApi.token_expires_date) < new Date(); // or null..
}
