import React, { useState, useEffect } from "react";
//Redux
import { connect } from "react-redux";
import SearchAndQueryData from "../../../services/DataQueryController";
import AmaxApiService from "../../../services/AmaxApiService";
//Antd
import { Button, Table, Select, Input, Checkbox, Pagination } from "antd";
import {
    InfoCircleTwoTone,
    FileExcelOutlined,
    FilterFilled,
    CloseCircleOutlined,
    SignalFilled,
    MessageFilled,
    UpOutlined,
    EditFilled,
    FileExcelFilled,
    SearchOutlined,
    SettingFilled,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import TelemarketingDetail from "../Modals/TelemarketingDetail";
import TelemarketingStatistic from "../Modals/TelemarketingStatistic";
import exportReport, { TelemarketingParse, TelemarketingParseColumns } from "../exports/CSVExport";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";

const CheckboxGroup = Checkbox.Group;

const TelemarketingTable = ({ data, loading, query }) => {
    const [storedColumns, setStoredColumns] = useState(
        JSON.parse(localStorage.getItem("telemarketingColumns"))
            ? JSON.parse(localStorage.getItem("telemarketingColumns"))
            : null
    );
    const [columns, setColumns] = useState(null);

    const [campaigns, setCampaigns] = useState([]);
    const [priorities, setPriorities] = useState([]);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const [columnSettVisible, setColumnSettVisible] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState(null);
    const [exportVisible, setExportVisible] = useState(false);

    const [displayDetail, setDisplayDetail] = useState(false);
    const [detailId, setDetailId] = useState(null);
    const [questions, setQuestions] = useState(null);
    const [detailData, setDetailData] = useState([]);
    const [layout, setLayout] = useState("vertical");
    const [tableExpandable, setTableExpandable] = useState(false);
    const [withoutQuestion, setWithoutQuestion] = useState(false);
    const [selectedInformation, setSelectedInformation] = useState(false);

    const [displayStatistic, setDisplayStatistic] = useState(false); ////
    const [statisticData, setStatisticData] = useState([]); /////
    const [showColumns, setShowColumns] = useState([]);
    const [byAnswersVisible, setByAnswersVisible] = useState(false);

    const [indexSearchQuestion, setIndexSearchQuestion] = useState(null);
    const [searchValue, setSearchValue] = useState(" ");
    const [filterEqual, setFilterEqual] = useState(false);
    const [last, setLast] = useState(data);
    const [selectAnswers, setSelectAnswers] = useState([]);
    const [answerID, setAnswerID] = useState(" ");

    const [allAnswers, setAllAnswers] = useState([]);
    const [questionArray, setQuestionArray] = useState([]);

    const [oldLast, setOldLast] = useState(last);

    let newData = [];

    ///***FUNKCIONALITY FOR COLUMNS SETTING****
    useEffect(() => {
        if (storedColumns) localStorage.setItem("telemarketingColumns", JSON.stringify(storedColumns));
    }, [storedColumns]);

    useEffect(() => {
        if (storedColumns) {
            for (let i = 0; i < storedColumns.length; i++) {
                let newColumn = storedColumns;

                switch (storedColumns[i]["title"]) {
                    case t("Contact date"):
                        newColumn[i] = colDate;
                        break;
                    case t("Company"):
                        newColumn[i] = colCompany;
                        break;
                    case t("TIN"):
                        newColumn[i] = colTIN;
                        break;
                    case t("Person"):
                        newColumn[i] = colPerson;
                        break;
                    case t("Detail"):
                        newColumn[i] = colDetail;
                        break;
                }
                setStoredColumns(newColumn);
            }
        }
    }, [data]);

    /////////////
    const findCampaigns = async () => {
        await AmaxApiService.Telemarketing.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    };

    const getAnswears = () => {
        if (data) {
            //make array allAnswers with format: [all records]==[[one record]]==[[[question, answear, pripad_id]]]
            let oneAnswers = []; //and array questionArray with all unique questions
            let newQuestionArray = [];
            let newAllAnswers = [];
            for (let i = 0; i < data.length; i++) {
                oneAnswers = [];
                {
                    Object.entries(data[i].vyplnenyDotaznik).map(
                        ([key, value]) => (
                            oneAnswers.push([key, value, data[i].pripadID]),
                            newQuestionArray.indexOf(key) < 0 ? newQuestionArray.push(key) : null,
                            key.indexOf()
                        )
                    );
                }
                newAllAnswers.push(oneAnswers);
            }
            setAllAnswers(newAllAnswers);
            setQuestionArray(newQuestionArray);
        }
    };

    const findInAnswers = (valueToFind) => {
        //filter records by search value
        if (data) {
            setPage(1);
            allAnswers.map((oneSet) =>
                oneSet.map((oneQuest) =>
                    //filter records with values which are THE SAME or which CONTAINS search value
                    (filterEqual && oneQuest[0].indexOf(indexSearchQuestion) == 0 && oneQuest[1] == valueToFind) ||
                    (!filterEqual &&
                        oneQuest[0].indexOf(indexSearchQuestion) == 0 &&
                        oneQuest[1].indexOf(valueToFind) >= 0)
                        ? data.map((dt) => (dt.pripadID == oneQuest[2] ? newData.push(dt) : null))
                        : null
                )
            );

            setLast(newData);
            setOldLast(newData);
        }
    };

    const answersForSelect = (e) => {
        let sAnswers = [];
        if (data) {
            allAnswers.map((oneSet) =>
                oneSet.map((oneQuest) =>
                    oneQuest[0].indexOf(e) == 0 && !sAnswers.includes(oneQuest[1]) && oneQuest[1] != ""
                        ? sAnswers.push(oneQuest[1])
                        : null
                )
            );
            setSelectAnswers(sAnswers);
        }
    };

    ///if there is same "volany telefon", show "volany telefon" else show "telefon"
    if (data) {
        for (let i = 0; i < data.length; i++) {
            data[i].telefon = data[i].volanyTelefon ? data[i].volanyTelefon : data[i].telefon;
        }
    }

    useEffect(() => {
        setColumnSettVisible(false);
        setExportVisible(false);
        setByAnswersVisible(false);
        //setLast(data);
        resetQueFilter();
        if (data) {
            getAnswears();
        }
        setRowKeys([]);
        setPage(1);
        setSearchText("");
        setOldLast(data);
        findCampaigns();

        if (query)
            AmaxApiService.Telemarketing.Priorities(query["TssId"]).then((priorities) => {
                setPriorities(priorities);
            });
    }, [data]);

    useEffect(() => {
        setAllAnswers([]);
        setQuestionArray([]);
        console.log(data);
    }, []);

    useEffect(() => {
        setRowKeys([]);
    }, [last]);

    const resetQueFilter = () => {
        setSearchValue("");
        setSearchText("");
        setLast(data);
        setOldLast(data);
        newData = [];
        setFilterEqual(false);
        setSelectAnswers([]);
        setAnswerID("");
        setIndexSearchQuestion(null);
    };

    const formItemControl = {
        style: {
            minWidth: "300px",
            width: "auto",
            marginRight: "8px",
            marginBottom: "10px",
        },
    };

    const paddingRightLayout = {
        style: {
            paddingRight: "10px",
            color: "#0e151a",
            marginTop: "10px",
        },
    };

    const formItemControlBig = {
        style: {
            width: "auto",
            minWidth: "300px",
            marginRight: "8px",
            marginBottom: "8px",
        },
    };

    const inputLayout = {
        style: {
            width: "300px",
            height: "32px",
            marginRight: "8px",
            marginBottom: "8px",
        },
    };

    const resizeModal = () => {
        setColumns(
            responsiveWidth({
                xs: xsColumns,
                sm: smColumns,
                md: mdColumns,
                lg: lgColumns,
            })
        );
        setLayout(
            responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            })
        );
    };

    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });
    }, []);

    const onResize = () => {
        if (storedColumns) {
            let actualColumnsToChange = [];
            storedColumns.map((oneColumn) => {
                actualColumnsToChange.push(oneColumn["title"]);
            });
            setSelectedItems(actualColumnsToChange.splice(0, actualColumnsToChange.length - 1));
        } else {
            setSelectedItems(
                responsiveWidth({
                    xs: [t("Contact date"), t("Company")],
                    sm: [t("Contact date"), t("Company"), t("Phone numbers")],
                    md: [t("Contact date"), t("Company"), t("TIN"), t("Phone numbers")],
                    lg: [t("Contact date"), t("Company"), t("TIN"), t("ZIP"), t("Phone numbers"), t("Person")],
                })
            );
        }
    };

    useEffect(() => {
        if (
            columns != null &&
            columns != undefined &&
            columns.length &&
            columns.length != undefined &&
            columns.length != null
        ) {
            {
                /*if (columns.length < 7) {
        setTableExpandable(true);
      } else {
        setTableExpandable(false);
      */
            }
        }
        if (showColumns && showColumns.length > 0 && columns && columns.length > 0) {
            if (showColumns.length > columns.length) setColumns(showColumns);
        }
        if (!isChanged) onResize();
    }, [columns]);

    const handleDisplayDetail = (pripadID, record) => {
        setDisplayDetail(true);
        setDetailId(pripadID);
        setDetailData(record);
    };

    const closeDetail = () => {
        setDisplayDetail(false);
        setDetailId(null);
        setDetailData(null);
    };

    const openPrevDetail = () => {
        let allData = last ? last : data;

        allData.map((record) => {
            if (record.pripadID == detailId) {
                if (allData.indexOf(record) != 0) {
                    //setPrev(allData.indexOf(record) - 1)
                    closeDetail();
                    setDetailData(allData[allData.indexOf(record) - 1]);
                    setDetailId(allData[allData.indexOf(record) - 1].pripadID);
                    handleDisplayDetail(
                        allData[allData.indexOf(record) - 1].pripadID,
                        allData[allData.indexOf(record) - 1]
                    );
                }
            }
        });
    };

    const openNextDetail = () => {
        let allData = last ? last : data;

        allData.map((record) => {
            if (record.pripadID == detailId) {
                if (allData.indexOf(record) != allData.length - 1) {
                    //setPrev(allData.indexOf(record) - 1)
                    closeDetail();
                    setDetailData(allData[allData.indexOf(record) + 1]);
                    setDetailId(allData[allData.indexOf(record) + 1].pripadID);
                    handleDisplayDetail(
                        allData[allData.indexOf(record) + 1].pripadID,
                        allData[allData.indexOf(record) + 1]
                    );
                }
            }
        });
    };

    const exportTypeAllRecords = () => {
        if (!selectedInformation) {
            exportAll();
        } else {
            exportColumns();
        }
    };

    const exportAll = () => {
        const dataToExport = last ? last : data;
        exportReport(
            TelemarketingParse(dataToExport, withoutQuestion),
            "telemarketing",
            "amax_portal_telemarketing",
            query,
            campaigns,
            priorities
        );
    };

    const exportColumns = () => {
        const dataToExport = last ? last : data;
        exportReport(
            TelemarketingParseColumns(dataToExport, withoutQuestion, selectedItems),
            "telemarketing",
            "amax_portal_telemarketing",
            query,
            campaigns,
            priorities
        );
        //setColumns(columns)
    };

    const exportTypeSelection = () => {
        if (!selectedInformation) {
            exportSelection();
        } else {
            exportColumnSelection();
        }
    };

    const exportSelection = () => {
        if (selectedRowKeys.length > 0) {
            let selectedData = [];
            const dataToExport = last ? last : data;
            if (dataToExport) {
                dataToExport.map((record) =>
                    selectedRowKeys.indexOf(record.pripadID) >= 0 ? selectedData.push(record) : null
                );
            }
            exportReport(
                TelemarketingParse(selectedData, withoutQuestion),
                "telemarketing",
                "amax_portal_telemarketing",
                query,
                campaigns,
                priorities
            );
        }
    };

    const exportColumnSelection = () => {
        let selectedData = [];
        if (selectedRowKeys.length > 0) {
            const dataToExport = last ? last : data;
            if (dataToExport) {
                dataToExport.map((record) =>
                    selectedRowKeys.indexOf(record.pripadID) >= 0 ? selectedData.push(record) : null
                );
            }
            exportReport(
                TelemarketingParseColumns(selectedData, withoutQuestion, selectedItems),
                "telemarketing",
                "amax_portal_telemarketing",
                query,
                campaigns,
                priorities
            );
        }
    };

    //////
    const [searchText, setSearchText] = useState("");
    var newLast = [];

    const handleSearch = () => {
        const dataToSearch = oldLast ? oldLast : data;
        if (dataToSearch) {
            dataToSearch.map((one) => {
                if (
                    one.nazev.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.ico.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.osoba.toString().toLowerCase().includes(searchText.toLowerCase())
                )
                    newLast.push(one);
            });
        }
        setLast(newLast);
        setPage(1);
        setPageSize(10);
    };

    const resetSearch = () => {
        setLast(oldLast);
        setSearchText("");
    };
    ////

    const handleDisplayStatistic = (record) => {
        setDisplayStatistic(true);
        setStatisticData(record);
    };

    const closeStatistic = () => {
        setDisplayStatistic(false);
        setStatisticData(null);
    };

    const resetChangeColumns = () => {
        //setSelectedItems([])
        //setSelectedColumns(null)
        setStoredColumns(
            responsiveWidth({
                xs: xsColumns,
                sm: smColumns,
                md: mdColumns,
                lg: lgColumns,
            })
        );

        setSelectedItems(
            responsiveWidth({
                xs: [t("Contact date"), t("Company")],
                sm: [t("Contact date"), t("Company"), t("Phone numbers")],
                md: [t("Contact date"), t("Company"), t("TIN"), t("Phone numbers")],
                lg: [t("Contact date"), t("Company"), t("TIN"), t("ZIP"), t("Phone numbers"), t("Person")],
            })
        );
    };

    const changeColumns = (columns) => {
        let newColumns = [];
        columns.map((column) => {
            switch (column) {
                case t("Contact date"):
                    newColumns.push(colDate);
                    break;
                case t("TIN"):
                    newColumns.push(colTIN);
                    break;
                case t("ZIP"):
                    newColumns.push(colZIP);
                    break;
                case t("Company"):
                    newColumns.push(colCompany);
                    break;
                case t("Phone numbers"):
                    newColumns.push(colPhone);
                    break;
                case t("Email"):
                    newColumns.push(colEmail);
                    break;
                case t("Web"):
                    newColumns.push(colWeb);
                    break;
                case t("Person"):
                    newColumns.push(colPerson);
                    break;
                case t("Reason for refusal"):
                    newColumns.push(colRefusal);
                    break;
                case t("Refusal Description"):
                    newColumns.push(colRefusalDescript);
                    break;
                case t("Customer note"):
                    newColumns.push(colNote);
                    break;
                case "Questionnaire Note":
                    newColumns.push(colQuestionNote);
                    break;
                case t("Contact Note"):
                    newColumns.push(colContactNote);
                    break;
            }
        });
        newColumns.push(colDetail);
        setStoredColumns(newColumns);
        //console.log(newColumns)
        //setSelectedColumns(newColumns)
        //console.log(newColumns)
    };

    //////////////////////COLUMNS SETTINGS/////////////////////
    ///Columns setting for changing table columns
    const colDate = {
        title: t("Contact date"),
        dataIndex: "cas",
        key: "cas",
        sorter: (a, b) => a.cas.localeCompare(b.cas),
        sortDirections: ["descend", "ascend"],
        render: (dateString) => (
            <>
                <span>{moment(dateString).format("DD.MM.YYYY") + "  "}</span>
                <span>{moment(dateString).format("HH:mm")}</span>
            </>
        ),
        maxWidth: "200",
    };

    const colCompany = {
        title: t("Company"),
        dataIndex: "nazev",
        key: "nazev",
        sorter: (a, b) => a.nazev.localeCompare(b.nazev),
        sortDirections: ["descend", "ascend"],
        maxWidth: "70",
    };

    const colTIN = {
        title: t("TIN"),
        dataIndex: "ico",
        key: "ico",
        sorter: (a, b) => a.ico - b.ico,
        sortDirections: ["descend", "ascend"],
        width: "5%",
    };

    const colZIP = {
        title: t("ZIP"),
        dataIndex: "psc",
        key: "psc",
        width: "auto",
    };

    const colPhone = {
        title: t("Phone numbers"),
        dataIndex: "telefon",
        key: "telefon",
        width: "auto",
    };

    const colEmail = {
        title: t("Email"),
        dataIndex: "mail",
        key: "mail",
        width: "auto",
    };

    const colWeb = {
        title: t("Web"),
        dataIndex: "web",
        key: "web",
        width: "auto",
    };

    const colPerson = {
        title: t("Person"),
        dataIndex: "osoba",
        key: "osoba",
        sorter: (a, b) => a.osoba.localeCompare(b.osoba),
        sortDirections: ["descend", "ascend"],
        width: "auto",
    };

    const colRefusal = {
        title: t("Reason for refusal"),
        dataIndex: "duvodOdmitnuti",
        key: "duvodOdmitnuti",
        width: "auto",
    };

    const colRefusalDescript = {
        title: t("Refusal Description"),
        dataIndex: "duvodPopis",
        key: "duvodPopis",
        width: "auto",
    };

    const colNote = {
        title: t("Customer note"),
        dataIndex: "poznamkaZakaznika",
        key: "poznamkaZakaznika",
        width: "auto",
    };

    const colQuestionNote = {
        title: t("Questionnaire Note"),
        dataIndex: "poznamka",
        key: "poznamka",
        width: "auto",
    };

    const colContactNote = {
        title: t("Contact Note"),
        dataIndex: "poznamkaKontakt",
        key: "poznamkaKontakt",
        width: "auto",
    };

    const colDetail = {
        title: t("Detail"),
        dataIndex: "details",
        key: "details",
        width: "5%",
        render: (text, record) => (
            <Button title={t("Telemarketing Detail")} onClick={() => handleDisplayDetail(record.pripadID, record)}>
                <InfoCircleTwoTone />
            </Button>
        ),
        width: "77px",
    };

    //////////LARGE SIZE OF TABLE///////////
    {
        /*const lgColumns = [
    {
      title: t("Contact date"),
    dataIndex: "cas",
    key: "cas",
    sorter: (a, b) => a.cas.localeCompare(b.cas),
    sortDirections: ["descend", "ascend"],
    render: (dateString) => (
      <>
        <span>{moment(dateString).format("DD.MM.YYYY") + "  "}</span>
        <span>{moment(dateString).format("HH:mm")}</span>
      </>
    ),
      width: "12%",
    },
    {
      title: t("Company"),
      dataIndex: "nazev",
      key: "nazev",
      sorter: (a, b) => a.nazev.localeCompare(b.nazev),
      sortDirections: ["descend", "ascend"],
      width: "auto",
    },
    {
      title: t("TIN"),
      dataIndex: "ico",
      key: "ico",
      sorter: (a, b) => a.ico - b.ico,
      sortDirections: ["descend", "ascend"],
      width: "8%",
    },
    {
      title: t("ZIP"),
      dataIndex: "psc",
      key: "psc",
      width: "8%",
    },
    {
      title: t("Phone"),
      dataIndex: "telefon",
      key: "telefon",
      width: "20%",
    },
    {
      title: t("Person"), 
      dataIndex: "osoba",
      key: "osoba", 
      sorter: (a, b) => a.osoba.localeCompare(b.osoba),
      sortDirections: ["descend", "ascend"],
      width: "auto",
    },
    colDetail
  ];*/
    }
    const lgColumns = [
        {
            title: t("Contact date"),
            dataIndex: "cas",
            key: "cas",
            sorter: (a, b) => a.cas.localeCompare(b.cas),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + "  "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "12%",
        },
        {
            title: t("Company"),
            dataIndex: "nazev",
            key: "nazev",
            sorter: (a, b) => a.nazev.localeCompare(b.nazev),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("TIN"),
            dataIndex: "ico",
            key: "ico",
            sorter: (a, b) => a.ico - b.ico,
            sortDirections: ["descend", "ascend"],
            width: "8%",
        },
        {
            title: t("ZIP"),
            dataIndex: "psc",
            key: "psc",
            width: "8%",
        },
        {
            title: t("Phone"),
            dataIndex: "telefon",
            key: "telefon",
            width: "20%",
        },
        {
            title: t("Person"),
            dataIndex: "osoba",
            key: "osoba",
            sorter: (a, b) => a.osoba.localeCompare(b.osoba),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        colDetail,
    ];
    //////////MEDIUM SIZE OF TABLE///////////
    const mdColumns = [
        {
            title: t("Contact date"),
            dataIndex: "cas",
            key: "cas",
            sorter: (a, b) => a.cas.localeCompare(b.cas),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + "  "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "20%",
        },
        {
            title: t("Company"),
            dataIndex: "nazev",
            key: "nazev",
            sorter: (a, b) => a.nazev.localeCompare(b.nazev),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("TIN"),
            dataIndex: "ico",
            key: "ico",
            sorter: (a, b) => a.ico - b.ico,
            sortDirections: ["descend", "ascend"],
            width: "8%",
        },
        {
            title: t("Phone"),
            dataIndex: "telefon",
            key: "telefon",
            width: "20%",
        },
        colDetail,
    ];
    //////////SMALL SIZE OF TABLE///////////
    const smColumns = [
        {
            title: t("Contact date"),
            dataIndex: "cas",
            key: "cas",
            sorter: (a, b) => a.cas.localeCompare(b.cas),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + "  "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "25%",
        },
        {
            title: t("Company"),
            dataIndex: "nazev",
            key: "nazev",
            sorter: (a, b) => a.nazev.localeCompare(b.nazev),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("Phone"),
            dataIndex: "telefon",
            key: "telefon",
            width: "20%",
        },
        colDetail,
    ];
    //////////EXTRA SMALL SIZE OF TABLE///////////
    const xsColumns = [
        {
            title: t("Contact date"),
            dataIndex: "cas",
            key: "cas",
            sorter: (a, b) => a.cas.localeCompare(b.cas),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + "  "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "30%",
        },
        {
            title: t("Company"),
            dataIndex: "nazev",
            key: "nazev",
            sorter: (a, b) => a.nazev.localeCompare(b.nazev),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        colDetail,
    ];

    ///////Change columns options
    const exportOptions = [
        t("Contact date"),
        t("Company"),
        t("TIN"),
        t("ZIP"),
        t("Phone numbers"),
        t("Email"),
        t("Web"),
        t("Person"),
        t("Reason for refusal"),
        t("Refusal Description"),
        t("Customer note"),
        t("Questionnaire Note"),
        t("Contact Note"),
    ];
    const [selectedItems, setSelectedItems] = useState([]);
    const filteredOptions = selectedItems ? exportOptions.filter((o) => !selectedItems.includes(o)) : null;
    const [isChanged, setIsChanged] = useState(false);

    ///***FUNKCIONALITY FOR SELECTING RECORDS FROM TABLE****
    const [selectedRowKeys, setRowKeys] = useState([]);
    const onSelectChange = (selectedRowKeys) => {
        setRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            {
                key: "all",
                text: t("All"),
                onSelect: () => {
                    let recordIds = [];
                    const records = last ? last : data ? data : null;
                    if (records) {
                        records.map((one) => {
                            recordIds.push(one.pripadID);
                        });
                    }
                    setRowKeys(recordIds);
                },
            },
            {
                key: "cancel",
                text: t("None"),
                onSelect: () => {
                    setRowKeys([]);
                },
            },
        ],
    };

    const sortForPrevNext = (sorter) => {
        let allData = last ? last : data;
        if (allData) {
            switch (sorter["field"]) {
                case "cas":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.cas.localeCompare(a.cas));
                    else allData.sort((a, b) => a.cas.localeCompare(b.cas));
                    break;
                case "nazev":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.nazev.localeCompare(a.nazev));
                    else allData.sort((a, b) => a.nazev.localeCompare(b.nazev));
                    break;
                case "ico":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.ico - a.ico);
                    else allData.sort((a, b) => a.ico - b.ico);
                    break;
                case "osoba":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.osoba.localeCompare(a.osoba));
                    else allData.sort((a, b) => a.osoba.localeCompare(b.osoba));
                    break;
            }
            if (sorter["order"] == undefined) allData.sort((a, b) => a.cas.localeCompare(b.cas));
            setLast(allData);
        }
    };

    return (
        <>
            <div className="big-form-switch-div">
                <div className="big-form-switch-button">
                    <button
                        className="multiple-button"
                        onClick={() => {
                            setByAnswersVisible(!byAnswersVisible);
                            setExportVisible(false);
                            setColumnSettVisible(false);
                        }}
                    >
                        {byAnswersVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Filter answers")} <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Filter answers")} <MessageFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>

                    <button
                        className="multiple-button"
                        onClick={() => {
                            setColumnSettVisible(!columnSettVisible);
                            setExportVisible(false);
                            setByAnswersVisible(false);
                        }}
                    >
                        {columnSettVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Edit columns ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Edit columns ")}
                                <SettingFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>

                    <button
                        className="multiple-button"
                        onClick={() => {
                            setExportVisible(!exportVisible);
                            setByAnswersVisible(false);
                            setColumnSettVisible(false);
                        }}
                    >
                        {exportVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Export ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Export ")}
                                <FileExcelFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>

                    <button className="multiple-button" onClick={() => handleDisplayStatistic(last)}>
                        {
                            <h3>
                                {t("Statistic ")}
                                <SignalFilled />
                            </h3>
                        }
                    </button>
                </div>

                {byAnswersVisible && (
                    <div className="form-question" style={{ paddingTop: "15px" }}>
                        <div style={{ backgroundColor: "white", paddingRight: "30px" }}>
                            <div style={{ marginTop: "5px" }}>
                                <Select //FORM FOR FILTER BY ANSWERS
                                    {...formItemControlBig}
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder={t("Choose Question")}
                                    onChange={(e) => {
                                        setIndexSearchQuestion(e);
                                        answersForSelect(e);
                                        setSearchValue("");
                                        setAnswerID("");
                                    }}
                                    //defaultValue={ indexSearchQuestion!=null ? indexSearchQuestion : t("Choose Question")}
                                    value={indexSearchQuestion ? indexSearchQuestion : t("Choose Question")}
                                    defaultValue={""}
                                >
                                    {questionArray
                                        ? questionArray.map((dt) => (
                                              <Select.Option value={dt} key={dt}>
                                                  {dt.substring(dt.indexOf(" "), dt.length - 1)}
                                              </Select.Option>
                                          ))
                                        : null}
                                </Select>

                                <Select
                                    {...formItemControl}
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder={t("Contains")}
                                    onChange={(e) => {
                                        setFilterEqual(e);
                                    }}
                                    value={filterEqual}
                                >
                                    <Select.Option value={false} default>
                                        {t("Contains")}
                                    </Select.Option>
                                    <Select.Option value={true}>{t("Is equal")}</Select.Option>
                                </Select>
                                {filterEqual ? (
                                    <Select
                                        {...formItemControlBig}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder={"odpoved"}
                                        onChange={(e) => {
                                            setSearchValue(e);
                                            setAnswerID(e);
                                        }}
                                        value={answerID ? answerID : t("Choose Answer")}
                                    >
                                        {selectAnswers
                                            ? selectAnswers.map((dt) => (
                                                  <Select.Option value={dt} key={dt}>
                                                      {dt}
                                                  </Select.Option>
                                              ))
                                            : null}
                                    </Select>
                                ) : (
                                    <Input
                                        {...inputLayout}
                                        value={searchValue}
                                        type="text"
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                )}
                                <br />
                                <Button
                                    className="yellow-button"
                                    style={{ width: "140px" }}
                                    htmlType="submit"
                                    icon={<FilterFilled />}
                                    onClick={() => {
                                        findInAnswers(searchValue);
                                        setSearchText("");
                                    }}
                                >
                                    {t("Filter ")}
                                </Button>

                                <Button
                                    className="ant-btn"
                                    style={{ width: "140px" }}
                                    type="secondary"
                                    icon={<CloseCircleOutlined />}
                                    onClick={() => {
                                        resetQueFilter();
                                    }}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {columnSettVisible && (
                    <div className="form-question" style={{ paddingTop: "10px" }}>
                        <div style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "30px" }}>
                            <p style={{ marginTop: "10px", color: "#0e151a" }}>
                                {t("To add more columns, click on the white part of the field")}
                            </p>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Choose columns"
                                value={selectedItems}
                                onChange={(e) => {
                                    setSelectedItems(e);
                                    changeColumns(e);
                                    setIsChanged(true);
                                }}
                                style={{
                                    width: "90%",
                                }}
                            >
                                {filteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                            <div style={{ marginTop: "5px" }}>
                                <Button
                                    type="primary"
                                    className="route-button"
                                    icon={<CloseCircleOutlined />}
                                    onClick={resetChangeColumns}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {exportVisible && (
                    <div className="form-question" style={{ paddingTop: "10px" }}>
                        <div style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "30px" }}>
                            <div style={{ marginTop: "5px" }}>
                                <Checkbox
                                    {...paddingRightLayout}
                                    onChange={(e) => setWithoutQuestion(e.target.checked)}
                                >
                                    {t("Export without Questionnaire")}
                                </Checkbox>

                                <Checkbox
                                    {...paddingRightLayout}
                                    onChange={(e) => setSelectedInformation(e.target.checked)}
                                >
                                    {t("Export visible columns")}
                                </Checkbox>
                                <br />
                                <Button
                                    style={{ marginTop: "22px", width: "256px" }}
                                    disabled={data ? false : true}
                                    className="yellow-button"
                                    icon={<FileExcelOutlined />}
                                    onClick={exportTypeAllRecords}
                                >
                                    {t("Export ALL records")}
                                </Button>
                                <Button
                                    style={{ width: "256px" }}
                                    disabled={data && selectedRowKeys.length > 0 ? false : true}
                                    className="yellow-button"
                                    icon={<FileExcelOutlined />}
                                    onClick={exportTypeSelection}
                                >
                                    {t("Export SELECTED records")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <TelemarketingDetail
                closeDialog={() => closeDetail()}
                visible={displayDetail}
                detailId={detailId}
                detailData={detailData}
                questions={questions}
                openPrevDetail={() => openPrevDetail()}
                openNextDetail={() => openNextDetail()}
            />

            <div style={{ backgroundColor: "white" }}>
                <p style={{ marginLeft: "35px", float: "left" }}>
                    {t("Selected")} {selectedRowKeys.length} {t("from")} {last ? last.length : data ? data.length : 0}
                </p>

                <div style={{ float: "right", display: "flex", marginRight: "26px" }}>
                    <Input
                        placeholder={t("Search")}
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onPressEnter={() => handleSearch()}
                        style={{
                            marginBottom: 8,
                            height: "24.5px",
                            width: "140px",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 25, marginLeft: "5px" }}
                    />
                    <Button
                        icon={<CloseCircleOutlined />}
                        onClick={() => resetSearch()}
                        size="small"
                        style={{ width: 25 }}
                    />
                </div>
            </div>

            {/*<Table
        pagination={{ 
          defaultPageSize: 10, 
          showSizeChanger: true, 
          pageSizeOptions: ['5', '10', '20', '30'],
          size: layout == "horizontal" ? "default" : "small",
          current: page,
          onChange:(e)=>{setPage(e)},
          total: last ? last.length : data ? data.length : 0,
          showTotal: (total) => t("Total: ") + `${total} `,
          onShowSizeChange: (current, size) => setPage(1)
        }}
        rowSelection={rowSelection}
        className="tableClass"
        tableLayout="auto"
        dataSource={last ? last : data}
        columns={selectedColumns ? selectedColumns : columns}
        loading={loading}
        rowKey={(record) => record.pripadID}
        onChange={(pagination, filters, sorter) => {sortForPrevNext(sorter)}}
      />*/}

            <Table
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "30"],
                    size: layout == "horizontal" ? "default" : "small",
                    current: page,
                    onChange: (e) => {
                        setPage(e);
                    },
                    total: last ? last.length : data ? data.length : 0,
                    showTotal: (total) => t("Total: ") + `${total} `,
                    onShowSizeChange: (current, size) => setPage(1),
                }}
                rowSelection={rowSelection}
                className="tableClass"
                tableLayout="auto"
                dataSource={last ? last : data}
                columns={storedColumns ? storedColumns : columns}
                loading={loading}
                rowKey={(record) => record.pripadID}
                onChange={(pagination, filters, sorter) => {
                    sortForPrevNext(sorter);
                }}
            />

            <TelemarketingStatistic
                closeDialog={() => closeStatistic()}
                visible={displayStatistic}
                statisticData={statisticData ? statisticData : data}
                statisticArray={allAnswers}
            />

            <div className="btn-group-height"></div>
        </>
    );
};

export default connect(mapStateToProps)(TelemarketingTable);

// Will run every time state change is detected
function mapStateToProps(state) {
    // If form issued a queue, and 'QUEUED' is detected in state
    // Search and Query data to state
    if (
        state.SearchQuery.formName == "TELEMARKETING" &&
        state.SearchQuery.query &&
        state.SearchQuery.status == "QUEUED"
    ) {
        SearchAndQueryData(2, state.SearchQuery.query);
        return { loading: true };
    }
    // If data are loaded in state ('READY') detected
    // set data to component's props
    if (
        (state.SearchQuery.formName == "TELEMARKETING") & (state.SearchQuery.status == "READY") &&
        state.SearchQuery.result
    )
        return {
            data: state.SearchQuery.result.sort((a, b) => a.cas.localeCompare(b.cas)),
            loading: false,
            query: state.SearchQuery.query,
        };

    // If no queue detected and no data detected
    // set null to data, and disable loading
    return { data: null, loading: false };
}
