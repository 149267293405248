import axios from "axios";
//Language
import { languageSetup } from "../components/providers/LanguageProvider";

//  parameters = object of sizes
// xs < 768px
// sm > 768px
// md > 992px
// lg > 1200px
/**
 * Reads windows size and returns appropriate field value
 *
 * @function responsiveWidth
 * @param {SizeParametersModel} parameters
 */
export function responsiveWidth(parameters = {}) {
    if (!parameters.sm) parameters.sm = parameters.md;
    if (!parameters.xs) parameters.xs = parameters.sm;
    if (!parameters.lg) parameters.lg = parameters.md;

    let width = typeof window !== "undefined" && window.innerWidth;
    if (width < 768) return parameters.xs;
    if (width > 768 && width < 992) return parameters.sm;
    if (width > 992 && width < 1200) return parameters.md;
    if (width > 1200) return parameters.lg;
}

/**
 * Returns coordinates from OSM from address
 *
 * @function getCoordinates
 * @param {String} address
 * @returns {[lat,lon]}
 */
export function getCoordinates(address) {
    console.log(
        "https://nominatim.openstreetmap.org/search.php?" +
            address.replaceAll(" ", "+") +
            "&polygon_geojson=1&format=jsonv2"
    );
    return new Promise((resolve, reject) => {
        axios
            .get(
                //"//nominatim.openstreetmap.org/search?format=json&q=" +
                //  encodeURI(address)
                //"https://nominatim.openstreetmap.org/search?" +

                "https://nominatim.openstreetmap.org/search.php?" +
                    address.replaceAll(" ", "+") +
                    "&polygon_geojson=1&format=jsonv2"
            )
            .then((data) => {
                if (data.data) {
                    if (data.data == "") {
                        try {
                            resolve(["0", "0"]);
                        } catch (error) {}
                    } else {
                        try {
                            resolve([data.data[0].lat, data.data[0].lon]);
                        } catch (error) {}
                    }
                }
            });
    });
}

/**
 * Finds deepest var in object a returns value from it
 *
 * @param {Object} obj
 * @returns {*} value from deepest variable in object
 */
export function getDeepestObject(obj) {
    var children = [];
    Object.keys(obj).forEach(function (key) {
        if (typeof obj[key] === "object" && obj[key]) children.push(getDeepestObject(obj[key]));
    });
    if (!children.length) {
        return { obj: obj, level: 0 };
        //return obj
    } else {
        var deepestChild = { level: -1 };
        children.forEach(function (child) {
            if (child.level > deepestChild.level) deepestChild = child;
        });
        return deepestChild;
    }
}

/**
 * @typedef {Object} SizeParametersModel
 * @property {*} xs
 * @property {*} sm Required
 * @property {*} md Required
 * @property {*} lg
 */

/**
 * @typedef {Double} lat
 */

/**
 * @typedef {Double} lon
 */
