import { useEffect, useState } from "react";
//Types
import PropTypes from "prop-types";
//Layouts
import DesktopLayout from "./DesktopLayout";
import MobileLayout from "./MobileLayout";

const LayoutWrapper = ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [windowWidth]);

    if (windowWidth > 525) return <DesktopLayout>{children}</DesktopLayout>;
    return <MobileLayout>{children}</MobileLayout>;
};

LayoutWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default LayoutWrapper;
