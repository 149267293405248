import React from "react";
//Redux
import AmaxApiService from "../../../services/AmaxApiService";
//Antd
import { Button, Skeleton, Descriptions, Modal, Input, Checkbox } from "antd";
import {
    CalendarOutlined,
    TagsOutlined,
    ShopOutlined,
    StopOutlined,
    FileTextOutlined,
    CloseCircleOutlined,
    DollarCircleOutlined,
    ProjectOutlined,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";

const { TextArea } = Input;

class DeleteFromBlacklist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            data: null,
            layout: "vertical",
            visible: false,
        };
        //this.exportPDF = this.exportPDF.bind(this);
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }

    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.blacklistData) {
            this.setState({ data: newProps.blacklistData });
            console.log(newProps.blacklistData);
        }
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    render() {
        const footer = [
            <Button
                //type="primary"
                style={{ backgroundColor: "#fcab06", fontWeight: "bold", border: "none" }}
                size="large"
                key="storno"
                //onClick={this.canceling.bind(this)}
                //loading={this.state.noteSaving}
            >
                <CloseCircleOutlined />
                {t("Odstranit záznam")}
            </Button>,
            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <StopOutlined />
                {t("Back")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={t("Odstranění firmy z blacklistu")}
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        {!this.state.data ? (
                            <Skeleton active />
                        ) : this.props.blacklistData.id !== this.state.data.id ? (
                            <Skeleton active />
                        ) : (
                            <>
                                <h3 style={{ width: "100%", paddingLeft: "24px" }} className="title">
                                    {t("Order num. ") + this.state.data.IdContact}
                                </h3>

                                {this.state.layout == "horizontal" && (
                                    <Descriptions
                                        key="table"
                                        column={2}
                                        layout={this.state.layout}
                                        bordered
                                        className="ant-descriptions-half-radius detail-storno"
                                    >
                                        <Descriptions.Item
                                            key="UniqueIdentificator"
                                            span={2}
                                            label={[
                                                <TagsOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("TIN"),
                                            ]}
                                        >
                                            {this.state.data.ico}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            key="Date"
                                            span={2}
                                            label={[
                                                <CalendarOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Datum vložení"),
                                            ]}
                                        >
                                            {moment(this.state.data.Sold).format("DD.MM.YYYY") +
                                                " " +
                                                moment(this.state.data.Sold).format("HH:mm")}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            key="Campaign"
                                            span={2}
                                            label={[
                                                <ProjectOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("Campaign"),
                                            ]}
                                        >
                                            {this.state.data.campaign.map((oneCampaing) => (
                                                <>
                                                    <Checkbox defaultChecked={true}>{oneCampaing}</Checkbox>
                                                    <br />
                                                </>
                                            ))}
                                        </Descriptions.Item>
                                    </Descriptions>
                                )}

                                {this.state.layout == "vertical" && (
                                    <Descriptions
                                        key="table"
                                        column={2}
                                        layout={this.state.layout}
                                        bordered
                                        className="ant-descriptions-half-radius detail-storno"
                                    >
                                        <Descriptions.Item
                                            key="Company"
                                            span={2}
                                            label={[
                                                <ShopOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("Company"),
                                                ": ",
                                                this.state.data.Company,
                                            ]}
                                        />
                                        <Descriptions.Item
                                            key="Sold"
                                            span={2}
                                            label={[
                                                <CalendarOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Conclusion Date"),
                                                ": ",
                                                moment(this.state.data.Sold).format("DD.MM.YYYY") +
                                                    " " +
                                                    moment(this.state.data.Sold).format("HH:mm"),
                                            ]}
                                        />
                                        <Descriptions.Item
                                            key="Price"
                                            span={2}
                                            label={[
                                                <DollarCircleOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Price"),
                                                ": ",
                                                this.state.data.Price + ",-",
                                            ]}
                                        />
                                        <Descriptions.Item
                                            key="UniqueIdentificator"
                                            span={2}
                                            label={[
                                                <TagsOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("TIN"),
                                                ": ",
                                                this.state.data.UniqueIdentificator,
                                            ]}
                                        />
                                        <Descriptions.Item
                                            key="Campaign"
                                            span={2}
                                            label={[
                                                <ProjectOutlined key="calendar-icon" style={{ marginRight: "10px" }} />,
                                                t("Campaign"),
                                                ": ",
                                                this.state.data.Campaign,
                                            ]}
                                        />

                                        <Descriptions.Item
                                            key="note"
                                            span={2}
                                            label={[
                                                <FileTextOutlined
                                                    key="calendar-icon"
                                                    style={{ marginRight: "10px" }}
                                                />,
                                                t("Insert Note"),
                                            ]}
                                        >
                                            <TextArea
                                                onChange={(e) => this.setState({ newNote: e.target.value })}
                                                label="nevim"
                                                rows={4}
                                            />
                                        </Descriptions.Item>
                                    </Descriptions>
                                )}
                            </>
                        )}
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default DeleteFromBlacklist;
