import React, { useState, useEffect } from "react";
//Redux
import { connect } from "react-redux";
import SearchAndQueryData from "../../../services/DataQueryController";
import AmaxApiService from "../../../services/AmaxApiService";
//Antd
import { Button, Table, Descriptions, Select, Pagination, Input } from "antd";
import { CloseCircleTwoTone } from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import DeleteFromBlacklist from "../Modals/DeleteFromBlacklist";
import exportReport, { RatingsParse } from "../exports/CSVExport";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";

const BlackListTable = ({ data, loading }) => {
    const [campaigns, setCampaigns] = useState([]);
    const [page, setPage] = useState(1);
    const [columns, setColumns] = useState(null);
    const [layout, setLayout] = useState("vertical");
    const [last, setLast] = useState(data);
    const [displayDelete, setDisplayDelete] = useState(false);
    const [detailId, setDetailId] = useState(null);
    const [detailData, setDetailData] = useState([]);

    const paddingRightLayout = {
        style: {
            paddingRight: "10px",
            color: "#0e151a",
            marginTop: "10px",
        },
    };

    const closeDetail = () => {
        setDisplayDelete(false);
        setDetailId(null);
        setDetailData(null);
    };

    const handleDisplayDetail = (id, record) => {
        setDisplayDelete(true);
        setDetailId(id);
        setDetailData(record);
    };

    const findCampaigns = async () => {
        await AmaxApiService.Ratings.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        setLast(data);
        if (data) console.log(data);
        findCampaigns();
    }, [data]);

    const resizeModal = () => {
        setLayout(
            responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            })
        );
    };
    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });

        console.log(data);
    }, []);

    const [selectedRowKeys, setRowKeys] = useState([]);
    const onSelectChange = (selectedRowKeys) => {
        setRowKeys(selectedRowKeys);
    };

    ///****COLUMNS SETUP*****
    const lgColumns = [
        {
            title: t("Tss Id"),
            dataIndex: "tssId",
            sorter: (a, b) => a.IdTss - b.IdTss,
            sortDirections: ["descend", "ascend"],
            width: "10%",
        },
        {
            title: t("Kampaň"),
            dataIndex: "campaign",
            sorter: (a, b) => a.Tss.localeCompare(b.Tss),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("User"),
            dataIndex: "user",
            sorter: (a, b) => a.User.localeCompare(b.User),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        {
            title: t("Valid From"),
            dataIndex: "dateFrom",
            sorter: (a, b) => a.From.localeCompare(b.From),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "} </span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "20%",
        },
        {
            title: t("Valid To"),
            dataIndex: "dateUntil",
            sorter: (a, b) => a.Until.localeCompare(b.Until),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "} </span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "20%",
        },

        {
            /*
      title: t("Odstranit záznam"),
      dataIndex: "details",
      width: "6%",
      render: (text, record) => (
        <Button
          title={t("Meeting Detail")}
          onClick={() => handleDisplayDetail(record.id, record)}
        >
        <CloseCircleTwoTone twoToneColor="#eb2f96"/>
      </Button>
      ),
      */
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            {
                key: "all",
                text: t("All"),
                onSelect: () => {
                    let recordIds = [];
                    if (data) {
                        data.map((one) => {
                            recordIds.push(one.id);
                        });
                    }
                    setRowKeys(recordIds);
                },
            },
            {
                key: "cancel",
                text: t("None"),
                onSelect: () => {
                    setRowKeys([]);
                },
            },
        ],
    };

    return (
        <>
            <DeleteFromBlacklist
                closeDialog={() => closeDetail()}
                visible={displayDelete}
                blacklistRecordId={detailId}
                blacklistData={detailData}
            />

            <Table
                className="tableClass"
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "30"],
                    size: layout == "horizontal" ? "default" : "small",
                    current: page,
                    onChange: (e) => {
                        setPage(e);
                    },
                    total: last ? last.length : data ? data.length : 0,
                    showTotal: (total) => t("Total: ") + `${total} `,
                    onShowSizeChange: (current, size) => setPage(1),
                }}
                rowSelection={rowSelection}
                tableLayout="auto"
                dataSource={data}
                columns={lgColumns}
                loading={loading}
                rowKey={(record) => record.id}
            />
            {/*<div>
        {selectedRowKeys.length > 0 ?
        <Button className="yellow-button">Odstranit vybrané záznamy</Button>
        : <Button disabled>Odstranit vybrané záznamy</Button>
        }
      </div>*/}

            <div className="btn-group-height"></div>
        </>
    );
};

export default connect(mapStateToProps)(BlackListTable);

// Will run every time when state change is detected
function mapStateToProps(state) {
    // If form issued a queue, and 'QUEUED' is detected in state
    // Search and Query data to state
    if (state.SearchQuery.formName == "BLACKLIST" && state.SearchQuery.query && state.SearchQuery.status == "QUEUED") {
        SearchAndQueryData(6, state.SearchQuery.query);
        return { loading: true };
    }

    // If data are loaded in state ('READY') detected
    // set data to component's props
    if ((state.SearchQuery.formName == "BLACKLIST") & (state.SearchQuery.status == "READY") && state.SearchQuery.result)
        return { data: state.SearchQuery.result, loading: false };

    // If no queue detected and no data detected
    // set null to data, and disable loading
    return { data: null, loading: false };
}
