import React, { Component, PropTypes } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
//Antd
import { Button, Skeleton, Descriptions, Modal, Tooltip } from "antd";
import {
    FilePdfOutlined,
    PhoneOutlined,
    CloseCircleOutlined,
    CommentOutlined,
    CaretUpOutlined,
    UserOutlined,
    MailOutlined,
    EnvironmentOutlined,
    GlobalOutlined,
    DollarOutlined,
    CalendarOutlined,
    InfoCircleTwoTone,
    LeftOutlined,
    RightOutlined,
    HomeOutlined,
    CloseCircleTwoTone,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import exportToPdfTelesales from "../exports/PDFExportTelesales";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//import ReactHtmlParser from 'react-html-parser';
//Images
import logo from "../../../images/logo-export-amax.png";

const itemHeaderLayout = {
    style: {
        background: "#e1e2e4",
        border: "none",
    },
};

class TelesalesDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            noteEditable: false,
            noteSaving: false,
            newNote: null,
            data: null,
            layout: "vertical",
            visible: true,
            description: true,
            campaign: "",
        };
        this.exportPDF = this.exportPDF.bind(this);
    }

    printDocument() {
        const input = document.getElementById("divToPrint");
        html2canvas(input, {
            onclone: (document) => {
                document.getElementById("divToPrint").style.display = "block";
            },
        }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF("p", "pt", "a4", false);
            pdf.addImage(imgData, "PNG", 30, 30, 540, 0, undefined, false);
            // pdf.output('dataurlnewwindow');
            pdf.save(`Telesale-${this.state.data.pripadID}.pdf`);
        });
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }

    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.detailData) {
            this.setState({
                visible: true,
                description: true,
                data: newProps.detailData,
                questions: newProps.questions,
                noteEditable: false,
            });

            newProps.campaigns.map((campaign) => {
                if (campaign["CampaignId"] == newProps.detailData.idTss) {
                    this.setState({ campaign: campaign["CampaignName"] });
                }
            });
        }
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    setDescriptione() {
        this.setState({ description: !this.state.description });
    }

    exportPDF() {
        exportToPdfTelesales(this.state.data);
    }

    isNumeric(value) {
        return /^\d+$/.test(value);
    }

    formatNote(input) {
        const rePhone = /(?:[+() ]*\d){9,13}/gm;
        const reEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
        const reWeb =
            /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;

        if (this.state.data) {
            if (input) {
                let parts = input.split(/(?:,|\s)/);
                var text = undefined;

                parts.forEach((element) => {
                    if (!text) {
                        if (reEmail.test(element)) text = this.makeEmailLink(element);
                        else if (reWeb.test(element)) text = this.makeWebLink(element);
                        else text = element;
                    } else {
                        if (reEmail.test(element)) text = text + " " + this.makeEmailLink(element);
                        else if (reWeb.test(element)) text = text + " " + this.makeWebLink(element);
                        else text = text + " " + element;
                    }
                });

                let phoneNumbers = [...text.matchAll(rePhone)];
                let newText = "";
                let i = 0;
                phoneNumbers.forEach((element) => {
                    newText = newText + text.substring(i, element["index"]) + this.makePhoneLink(element[0]);
                    i = element["index"] + element[0].length;
                });
                newText = newText + text.substring(i, text.length);
                return newText;
            }
        }
    }

    findWeb(input) {
        const re =
            /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim;
        if (this.state.data) {
            if (input) {
                return this.makeWebLink(input.match(re) ? input.match(re)[0] : "http://");
            }
        }
    }

    findEmail(input) {
        const re = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
        if (this.state.data) {
            if (input) {
                return this.makeEmailLink(input.match(re) ? input.match(re)[0] : "-");
            }
        }
    }

    findPhone(input) {
        const re = /^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/;
        if (this.state.data) {
            if (input) {
                return this.makePhoneLink(input.match(re) ? input.match(re)[0] : " ");
            }
        }
    }

    makePhoneLink(phone) {
        let toCall = phone;
        let formatedPhone = "";
        if (phone[0] == "+") {
            toCall = "tel:" + phone;
            formatedPhone = phone.replaceAll(" ", "").substring(4);
        } else if (phone[0] == "4") {
            toCall = "tel:+" + phone;
            formatedPhone = phone.replaceAll(" ", "").substring(3);
        } else {
            toCall = "tel:+420" + phone.replaceAll(" ", "");
            formatedPhone = phone.replaceAll(" ", "");
        }
        formatedPhone =
            "+420 " +
            formatedPhone.substring(0, 3) +
            " " +
            formatedPhone.substring(3, 6) +
            " " +
            formatedPhone.substring(6);
        return (
            //className="blue-text"
            `<a href=${toCall} target="_blank">
        <span>${formatedPhone}</span>
      </a>`
        );
    }

    makeEmailLink(email) {
        let mailTo = "mailto:" + email;
        return `<a href=${mailTo}>
          <span>${email}</span>
      </a>`;
    }

    makeWebLink(web) {
        let webLink = web;
        if (webLink.indexOf("://") < 1) {
            webLink = "http://" + web;
        }
        let splitWeb = "";
        if (web.indexOf("=") > 0) {
            splitWeb = web.substring(0, web.indexOf("=")) + "...";
            console.log(splitWeb);
            return `<a href=${webLink} target="_blank">
            <span>${splitWeb}</span>
        </a>`;
        }
        let shorterWeb = web;
        if (shorterWeb.length > 95) shorterWeb = web.substring(0, 95) + "...";
        return `<a href=${webLink} target="_blank">
          <span>${shorterWeb}</span>
      </a>`;
    }
    /////////////////////////#

    dotaznikWeb = () => {
        let retVal = this.state.data.web;
        let isSet = false;
        {
            Object.entries(this.state.data.vyplnenyDotaznik).map(([key, value]) => {
                if ((key.toString().indexOf("web") >= 0 || key.toString().indexOf("zdroj") >= 0) && !isSet) {
                    if (
                        value &&
                        value.match(
                            /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
                        )
                    ) {
                        retVal = value;
                        isSet = true;
                    }
                }
            });
        }
        return retVal;
    };

    dotaznikPhone = () => {
        let retVal = this.state.data.volanyTelefon
            ? this.state.data.volanyTelefon
            : this.state.data.telefon
            ? this.state.data.telefon
            : "-";
        {
            Object.entries(this.state.data.vyplnenyDotaznik).map(([key, value]) => {
                if (key.toString().indexOf("telefon") >= 0 || key.toString().indexOf("kontakt") >= 0) {
                    if (value && value.match(/^(\+420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/)) retVal = value;
                }
            });
        }
        return retVal;
    };

    dotaznikEmail = () => {
        let retVal = this.state.data.mail;
        {
            Object.entries(this.state.data.vyplnenyDotaznik).map(([key, value]) => {
                if (key.toString().indexOf("email") >= 0) {
                    if (value && value.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)) retVal = value;
                }
            });
        }
        return retVal;
    };

    addressFormat = (answear) => {
        const parts = answear.split("|");
        console.log(parts);
        let formatedAddress = answear;

        if (parts.length == 8) {
            formatedAddress = parts[0] + " " + parts[1] + ", ";

            if (parts[2].length > 1) formatedAddress += parts[2] + ", ";
            formatedAddress += parts[3] + ", " + t("ZIP") + ": " + parts[6];

            if (parts[5].length > 1) formatedAddress += ", " + t("Region") + ": " + parts[5];
            formatedAddress += ", " + t("Country") + ": " + parts[7];
        }

        return formatedAddress;
    };

    headerButtons = [];

    render() {
        const footer = [
            <Button type="secondary" key="export" size="large" onClick={this.printDocument.bind(this)}>
                <FilePdfOutlined />
                {t("Export")}
            </Button>,

            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={
                            <>
                                <InfoCircleTwoTone style={{ paddingRight: "8px" }} />
                                {t("Detail")}
                            </>
                        }
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "24px" }}
                    >
                        {!this.state.data ? (
                            <Skeleton active />
                        ) : this.props.detailData.pripadID !== this.state.data.pripadID ? (
                            <Skeleton active />
                        ) : (
                            <>
                                <div data-html2canvas-ignore="true">
                                    <div style={{ display: "flex" }}>
                                        <Button
                                            size="small"
                                            key="prev"
                                            shape="circle"
                                            onClick={this.props.openPrevDetail}
                                            style={{ float: "left", marginTop: "12px" }}
                                        >
                                            <LeftOutlined />
                                        </Button>

                                        <h3 style={{ width: "100%", paddingLeft: "10px" }} className="title">
                                            {this.state.data.nazev}
                                        </h3>

                                        <Button
                                            style={{ float: "right", marginTop: "12px" }}
                                            size="small"
                                            key="next"
                                            shape="circle"
                                            onClick={this.props.openNextDetail}
                                        >
                                            <RightOutlined />
                                        </Button>
                                    </div>

                                    {this.state.data.stornovano ? (
                                        <div>
                                            <div style={{ display: "flex", color: "#eb2f96" }}>
                                                <CloseCircleTwoTone
                                                    twoToneColor="#eb2f96"
                                                    style={{ paddingRight: "20px" }}
                                                />
                                                <p>{t("CANCELLED")}</p>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div className="mobile-card" id="mobile">
                                        <div class="column-mobile">
                                            <div style={{ display: "flex", width: "26px" }}>
                                                <Tooltip placement="top" title={t("Company TIN")}>
                                                    <p
                                                        style={{
                                                            paddingRight: "16px",
                                                            paddingTop: "2px",
                                                            fontSize: "12px",
                                                        }}
                                                    >{`${t("TIN")} `}</p>
                                                </Tooltip>
                                                {this.state.data.ico}
                                            </div>

                                            <div style={{ display: "flex" }}>
                                                <Tooltip placement="top" title={t("Company Address")}>
                                                    <HomeOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div style={{ paddingLeft: "4px" }}>
                                                    {this.state.data.ulice && `${this.state.data.ulice}, `}
                                                    {this.state.data.obec && `${this.state.data.obec}`}
                                                    {this.state.data.psc && `, ${this.state.data.psc}`}
                                                    {this.state.data.okres && (
                                                        <>
                                                            <br />
                                                            {`${t("Region")}: ${this.state.data.okres}`}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <br />

                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={"E-mail"}>
                                                    <MailOutlined className="detail-icon" />
                                                </Tooltip>
                                                {this.dotaznikEmail() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findEmail(this.dotaznikEmail()),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>

                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={"Web"}>
                                                    <GlobalOutlined className="detail-icon" />
                                                </Tooltip>
                                                {this.dotaznikWeb() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findWeb(this.dotaznikWeb()).toString(),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>

                                            <br />
                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Person")}>
                                                    <UserOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div>{this.state.data.osoba}</div>
                                            </div>
                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Phone")}>
                                                    <PhoneOutlined className="detail-icon" />
                                                </Tooltip>
                                                {
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findPhone(this.dotaznikPhone()).toString(),
                                                        }}
                                                    />
                                                }
                                            </div>

                                            <br />
                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Date of Sale")}>
                                                    <CalendarOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div>
                                                    {moment(this.state.data.cas).format("DD.MM.YYYY") +
                                                        " " +
                                                        moment(this.state.data.cas).format("HH:mm")}
                                                </div>
                                            </div>

                                            <div className="mobile-card-div">
                                                <Tooltip placement="top" title={t("Price")}>
                                                    <DollarOutlined className="detail-icon" />
                                                </Tooltip>
                                                <div>{this.state.data.cena + ",-"}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* ///// DESKTOP VERSION ////// */}
                                    <div class="desktop-card" id="desktop">
                                        <div className="row">
                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Company TIN")}>
                                                        <p
                                                            style={{
                                                                paddingRight: "14px",
                                                                fontSize: "12px",
                                                                paddingTop: "2px",
                                                            }}
                                                        >{`${t("TIN")}  `}</p>
                                                    </Tooltip>
                                                    {this.state.data.ico}
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Company Address")}>
                                                        <HomeOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    <p style={{ paddingLeft: "4px" }}>
                                                        {this.state.data.ulice && `${this.state.data.ulice}, `}
                                                        {this.state.data.obec && `${this.state.data.obec}`}
                                                        {this.state.data.psc && `, ${this.state.data.psc}`}
                                                        {this.state.data.okres && (
                                                            <>
                                                                <br />
                                                                {`${t("Region")}: ${this.state.data.okres}`}
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "2px", paddingLeft: "4px" }}>
                                                <Tooltip placement="top" title={"E-mail"}>
                                                    <MailOutlined className="detail-icon" />
                                                </Tooltip>
                                                {this.dotaznikEmail() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findEmail(this.dotaznikEmail()),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "14px", paddingLeft: "4px" }}>
                                                <Tooltip placement="top" title={"Web"}>
                                                    <GlobalOutlined className="detail-icon" />
                                                </Tooltip>
                                                {this.dotaznikWeb() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findWeb(this.dotaznikWeb()).toString(),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>
                                        </div>

                                        <div class="row" style={{ paddingTop: "8px" }}>
                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Person")}>
                                                        <UserOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    <div style={{ paddingLeft: "4px" }}>{this.state.data.osoba}</div>
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Phone")}>
                                                        <PhoneOutlined className="detail-icon" />
                                                    </Tooltip>
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.findPhone(this.dotaznikPhone()).toString(),
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip placement="top" title={t("Date of Sale")}>
                                                        <CalendarOutlined
                                                            className="detail-icon"
                                                            style={{ paddingTop: "2px" }}
                                                        />
                                                    </Tooltip>
                                                    <div>
                                                        {moment(this.state.data.cas).format("DD.MM.YYYY") +
                                                            " " +
                                                            moment(this.state.data.cas).format("HH:mm")}
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", paddingTop: "4px" }}>
                                                    <Tooltip placement="top" title={t("Price")}>
                                                        <DollarOutlined
                                                            className="detail-icon"
                                                            style={{ paddingTop: "2px" }}
                                                        />
                                                    </Tooltip>
                                                    <div>{this.state.data.cena + ",-"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="big-column">
                                            <div style={{ paddingTop: "18px" }}>
                                                {t("Campaign") + ": "}
                                                <div>{this.state.campaign}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {(this.state.data.poznamka ||
                                        (this.state.data.poznamkaKontakt &&
                                            this.state.data.poznamkaKontakt.indexOf("Nahrazeno za jinou firmu") < 0) ||
                                        this.state.data.poznamkaZakaznika) && (
                                        <div class="row">
                                            <div class="big-column">
                                                {this.state.data.poznamka && (
                                                    <div style={{ paddingTop: "18px" }}>
                                                        {t("Questionnaire Note") + ": "}
                                                        <div>{this.state.data.poznamka}</div>
                                                    </div>
                                                )}
                                                <br />
                                                {this.state.data.poznamkaKontakt &&
                                                    this.state.data.poznamkaKontakt != "Nahrazeno za jinou firmu" && (
                                                        <div>
                                                            {t("Contact Note") + ": "}
                                                            {
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: this.formatNote(
                                                                            this.state.data.poznamkaKontakt
                                                                        ).toString(),
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    )}
                                                {this.state.data.poznamkaZakaznika && (
                                                    <div>
                                                        {t("Customer note") + ": " + this.state.data.poznamkaZakaznika}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <br />
                                    <div>
                                        <button className="detail-button" onClick={this.setVisible.bind(this)}>
                                            {this.state.visible ? (
                                                <h3 style={{ fontWeight: "bold" }}>
                                                    {t("Filled Questionnaire ")}
                                                    <CaretUpOutlined />
                                                </h3>
                                            ) : (
                                                <h3>
                                                    {t("Filled Questionnaire ")}
                                                    <CommentOutlined />{" "}
                                                </h3>
                                            )}
                                        </button>

                                        {this.state.visible && (
                                            <Descriptions
                                                key={"questionFilled"}
                                                column={2}
                                                bordered
                                                layout="horizontal"
                                                className="ant-descriptions-half-radius detail-question"
                                            >
                                                {Object.entries(this.state.data.vyplnenyDotaznik).map((question) => (
                                                    <Descriptions.Item {...itemHeaderLayout} span={2} key={question[0]}>
                                                        {question[0].indexOf(" ") > 0
                                                            ? question[0].substring(
                                                                  question[0].indexOf(", ") + 1,
                                                                  question[0].length - 1
                                                              )
                                                            : question[0]}
                                                        {<br />}

                                                        {question[0].indexOf("Prezentační adresa") > 0 ? (
                                                            <b>{question[1] ? this.addressFormat(question[1]) : "-"}</b>
                                                        ) : (
                                                            <b>{question[1] ? question[1] : "-"}</b>
                                                        )}
                                                    </Descriptions.Item>
                                                ))}
                                            </Descriptions>
                                        )}
                                    </div>
                                </div>

                                <div
                                    id="divToPrint"
                                    style={{
                                        width: "200mm",
                                        display: "none",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            width: "100%",
                                            background: "#48a3d8",
                                            paddingTop: "5px",
                                        }}
                                        className="pdf-img"
                                    >
                                        <h2
                                            style={{
                                                padding: "10px",
                                                background: "#48a3d8",
                                                color: "white",
                                                width: "80%",
                                            }}
                                        >
                                            {t("Telesales Detail") + " "}
                                            {this.state.data.pripadID}
                                        </h2>
                                        <img src={logo} />
                                    </div>

                                    <h3 style={{ width: "100%", paddingLeft: "5px" }} className="title">
                                        {this.state.data.nazev}
                                    </h3>
                                    {this.state.data.stornovano ? (
                                        <div>
                                            <div style={{ display: "flex", color: "#eb2f96" }}>
                                                <CloseCircleTwoTone
                                                    twoToneColor="#eb2f96"
                                                    style={{ paddingRight: "20px" }}
                                                />
                                                <p>{t("CANCELLED")}</p>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div>
                                        <div className="row">
                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <p
                                                        style={{
                                                            paddingRight: "14px",
                                                            fontSize: "12px",
                                                            paddingTop: "2px",
                                                        }}
                                                    >{`${t("TIN")}  `}</p>
                                                    {this.state.data.ico}
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <HomeOutlined className="detail-icon" />
                                                    <p style={{ paddingLeft: "4px" }}>
                                                        {this.state.data.ulice && `${this.state.data.ulice}, `}
                                                        {this.state.data.obec && `${this.state.data.obec}`}
                                                        {this.state.data.psc && `, ${this.state.data.psc}`}
                                                        {this.state.data.okres && (
                                                            <>
                                                                <br />
                                                                {`${t("Region")}: ${this.state.data.okres}`}
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "2px", paddingLeft: "4px" }}>
                                                <MailOutlined className="detail-icon" />
                                                {this.dotaznikEmail() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findEmail(this.dotaznikEmail()),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>

                                            <div style={{ display: "flex", paddingTop: "14px", paddingLeft: "4px" }}>
                                                <GlobalOutlined className="detail-icon" />
                                                {this.dotaznikWeb() ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.findWeb(this.dotaznikWeb()).toString(),
                                                        }}
                                                    />
                                                ) : (
                                                    <div>-</div>
                                                )}
                                            </div>
                                        </div>

                                        <div class="row" style={{ paddingTop: "8px" }}>
                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <UserOutlined className="detail-icon" />
                                                    <div style={{ paddingLeft: "4px" }}>{this.state.data.osoba}</div>
                                                </div>

                                                <div style={{ display: "flex" }}>
                                                    <PhoneOutlined className="detail-icon" />
                                                    {
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: this.findPhone(this.dotaznikPhone()).toString(),
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>

                                            <div class="column">
                                                <div style={{ display: "flex" }}>
                                                    <CalendarOutlined
                                                        className="detail-icon"
                                                        style={{ paddingTop: "2px" }}
                                                    />
                                                    <div>
                                                        {moment(this.state.data.cas).format("DD.MM.YYYY") +
                                                            " " +
                                                            moment(this.state.data.cas).format("HH:mm")}
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", paddingTop: "4px" }}>
                                                    <Tooltip placement="top" title={t("Price")}>
                                                        <DollarOutlined
                                                            className="detail-icon"
                                                            style={{ paddingTop: "2px" }}
                                                        />
                                                    </Tooltip>
                                                    <div>{this.state.data.cena + ",-"}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="big-column">
                                            <div style={{ paddingTop: "18px" }}>
                                                {t("Campaign") + ": "}
                                                <div>{this.state.campaign}</div>
                                            </div>
                                        </div>
                                    </div>

                                    {(this.state.data.poznamka ||
                                        (this.state.data.poznamkaKontakt &&
                                            this.state.data.poznamkaKontakt.indexOf("Nahrazeno za jinou firmu") < 0) ||
                                        this.state.data.poznamkaZakaznika) && (
                                        <div class="row">
                                            <div class="big-column">
                                                {this.state.data.poznamka && (
                                                    <div style={{ paddingTop: "18px" }}>
                                                        {t("Questionnaire Note") + ": "}
                                                        <div>{this.state.data.poznamka}</div>
                                                    </div>
                                                )}
                                                <br />
                                                {this.state.data.poznamkaKontakt &&
                                                    this.state.data.poznamkaKontakt != "Nahrazeno za jinou firmu" && (
                                                        <div>
                                                            {t("Contact Note") + ": "}
                                                            {
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: this.formatNote(
                                                                            this.state.data.poznamkaKontakt
                                                                        ).toString(),
                                                                    }}
                                                                />
                                                            }
                                                        </div>
                                                    )}
                                                {this.state.data.poznamkaZakaznika && (
                                                    <div>
                                                        {t("Customer note") + ": " + this.state.data.poznamkaZakaznika}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    <br />
                                    <Descriptions
                                        key={"questionFilled"}
                                        column={2}
                                        bordered
                                        layout="horizontal"
                                        className="ant-descriptions-half-radius print-detail-question"
                                    >
                                        {Object.entries(this.state.data.vyplnenyDotaznik).map((question) => (
                                            <Descriptions.Item {...itemHeaderLayout} span={2} key={question[0]}>
                                                {question[0].indexOf(" ") > 0
                                                    ? question[0].substring(
                                                          question[0].indexOf(", ") + 1,
                                                          question[0].length - 1
                                                      )
                                                    : question[0]}
                                                {<br />}

                                                {question[0].indexOf("Prezentační adresa") > 0 ? (
                                                    <b>{question[1] ? this.addressFormat(question[1]) : "-"}</b>
                                                ) : (
                                                    <b>{question[1] ? question[1] : "-"}</b>
                                                )}
                                            </Descriptions.Item>
                                        ))}
                                    </Descriptions>
                                </div>
                            </>
                        )}
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default TelesalesDetail;
