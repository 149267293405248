import React from "react";
//Antd
import { Button, Skeleton, Modal, Typography, Tabs } from "antd";
import { CalendarTwoTone, CloseCircleOutlined } from "@ant-design/icons";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//Images
import Form from "../../../images/help-images/storno-form.PNG";
import FormMobile from "../../../images/help-images/storno-form-mobile.PNG";
import Detail from "../../../images/help-images/storno-detailE.png";
import DetailMobile from "../../../images/help-images/storno-mobile-detail.png";

const { TabPane } = Tabs;
const { Paragraph, Text } = Typography;

const paragraphLayout = {
    style: {
        textAlign: "left",
        color: "black",
        fontSize: "14px",
        padding: "10px",
    },
};

const headdingLayout = {
    style: {
        color: "#48a3d8",
        fontSize: "24px",
    },
};

class HelpStorno extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modalWidth: "100%",
            data: null,
            layout: "vertical",
            visible: false,
        };
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }
    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.record) {
            this.setState({ data: newProps.record });
        }
    }

    setVisible() {
        this.setState({ visible: !this.state.visible });
    }

    closeDialog() {
        this.setState({
            ChangeMeetingDateModal: false,
            MeetingReclamationModal: false,
        });
    }

    render() {
        const footer = [
            <Button type="primary" size="large" key="back" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,
        ];

        return (
            <React.Fragment>
                {!this.props.visible ? null : (
                    <Modal
                        width={this.state.modalWidth}
                        footer={footer}
                        title={t("Nápověda stornování")}
                        visible={this.props.visible}
                        onCancel={this.props.closeDialog}
                        style={{ top: "30px" }}
                    >
                        <div className="help-paragraph">
                            <Tabs defaultActiveKey="1">
                                <TabPane tab="" key="0"></TabPane>
                                <TabPane tab="Filtrování" key="1">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Filtrování storování")}</h2>
                                        <p>
                                            Nejprve je potřeba vyfiltrovat Vámi požadovaná data, která se následně
                                            zobrazí v tabulkách <b>Nevystornováno</b> a <b>Vystornováno</b> a umožní
                                            další práci.
                                        </p>
                                        <img src={Form} alt="form" className="help-image-desktop" />
                                        <img src={FormMobile} alt="form" className="help-image-mobile" />
                                        <p>
                                            Formulář pro filtrování je možné zobrazit/skrýt kliknutím na tlačítko
                                            <b> Filtrovat/Skrýt filtrování</b>. Formulář se skryje automaticky po
                                            úspěšném vyfiltrování.
                                        </p>
                                        <p>
                                            <b>Pro vyhledání dat je třeba:</b>
                                        </p>
                                        <p>
                                            - Zvolit <b> ID Firmy</b> nebo IČO a následně tuto hodnotu vyplnit.
                                        </p>
                                        <p>
                                            Dále je možné (ne nutné) nastavit požadovanou <b>Kampaň</b> nebo{" "}
                                            <b>Datum uzavření smlouvy.</b>
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Tabulky" key="2">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Práce s tabulkami")}</h2>
                                        <p className="help-image-desktop">
                                            Data v obou tabulkách je možné procházet po stránkách a nastavit počet
                                            zobrazených záznamů na jedné straně.
                                        </p>
                                        <p className="help-image-mobile">
                                            Data v obou tabulkách je možné procházet po stránkách.
                                        </p>
                                        <p style={{ color: "#48a3d8", fontSize: "18px" }}>
                                            <b>Nevystornováno</b>
                                        </p>
                                        <p>
                                            První tabulka zobrazuje vyfiltrované záznamy smluv, které je možné{" "}
                                            <b>vystornovat.</b>
                                            <br />
                                            Kliknutím na <b>šipečky</b> za názvy sloupců je možné měnit seřazení dat v
                                            tabulce. Defaultně jsou data seřazená podle data uzavření vzestupně.
                                        </p>
                                        <p>
                                            U každého záznamu se ve sloupci <b>Storovat</b> nachází tlačítko, které
                                            otevře okno, kde je možné smlouvu vystornovat.
                                        </p>
                                        <br />
                                        <p style={{ color: "#48a3d8", fontSize: "18px" }}>
                                            <b>Vystornováno</b>
                                        </p>
                                        <p>
                                            Druhá tabulka zobrazuje vyfiltrované záznamy již <b>vystornovaných</b>{" "}
                                            smluv.
                                        </p>
                                    </Paragraph>
                                </TabPane>

                                <TabPane tab="Stornování" key="3">
                                    <Paragraph {...paragraphLayout}>
                                        <h2 {...headdingLayout}>{t("Stornování")}</h2>
                                        <p>
                                            Okno se stornováním se otevře kliknutím na tlačítko ve sloupci{" "}
                                            <b>Stornovat</b> u příslušného záznamu.
                                        </p>
                                        <p>
                                            Kliknutím na tlačítko <b>Stornovat položku</b> dojde ke stornování. Objeví
                                            se zpráva, jestli bylo stornování úspěšné nebo nikoliv.
                                        </p>
                                        <p style={{ color: "red" }}>
                                            <b>
                                                POZOR: po kliknutí na tlačítko <b>"Stornovat položku"</b> akci nelze
                                                zrušit!
                                            </b>
                                        </p>
                                        <img src={Detail} alt="detail-telesale" className="help-image-desktop" />
                                        <img src={DetailMobile} alt="detail-telesale" className="help-image-mobile" />
                                    </Paragraph>
                                </TabPane>
                                <TabPane tab="" key="10"></TabPane>
                            </Tabs>
                        </div>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}
export default HelpStorno;
