//Cheerio
import cheerio from "cheerio";
//Moment
import moment from "moment";
//import ReportMeetingTemplate from "/templates/report_meeting.svg";

//PDF Make
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function exportToPdf() {
    //Load template
    const $ = cheerio.load(_ObchodniPodminkyTemplate);

    //New document
    var docDefinition = {
        content: [{ svg: [$("body").html()], width: 600, margin: -40 }],
    };

    //Download file
    pdfMake.createPdf(docDefinition).download(`Obchodni-podminky`);
}

export default exportToPdf;

const _ObchodniPodminkyTemplate = `
<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Created with Inkscape (http://www.inkscape.org/) -->

<svg
   width="210mm"
   height="297mm"
   viewBox="0 0 210 297"
   version="1.1"
   id="svg8"
   inkscape:version="1.1.2 (b8e25be833, 2022-02-05)"
   sodipodi:docname="obchodni-podminky.svg"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:dc="http://purl.org/dc/elements/1.1/">
  <defs
     id="defs2" />
  <sodipodi:namedview
     id="base"
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1.0"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:zoom="0.49497475"
     inkscape:cx="158.59395"
     inkscape:cy="652.55854"
     inkscape:document-units="mm"
     inkscape:current-layer="layer1"
     showgrid="false"
     inkscape:window-width="1920"
     inkscape:window-height="1017"
     inkscape:window-x="-8"
     inkscape:window-y="-8"
     inkscape:window-maximized="1"
     showguides="false"
     borderlayer="true"
     inkscape:pagecheckerboard="true" />
  <metadata
     id="metadata5">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <g
     inkscape:label="Vrstva 1"
     inkscape:groupmode="layer"
     id="layer1">
    <rect
       style="opacity:1;fill:#009fe2;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:0.82433516;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
       id="rect834"
       width="211.2887"
       height="298.22321"
       x="-0.94493955"
       y="-0.65624988" />
    <rect
       style="opacity:1;fill:#f0f2f5;fill-opacity:1;fill-rule:nonzero;stroke:none;stroke-width:1.20306;stroke-linecap:butt;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke"
       id="rect812"
       width="222.81599"
       height="257.84027"
       x="3.7033432"
       y="31.107035"
       ry="7.4258943" />
    <g
       id="g18657"
       transform="matrix(0.61152273,0,0,0.61152273,114.82404,4.7691985)">
      <text
         transform="scale(1.0349719,0.96620982)"
         id="text868"
         y="21.439487"
         x="75.113976"
         style="font-weight:bold;font-size:17.6514px;opacity:0.791667;fill:#ffdc00;stroke:none;stroke-width:0.598409">AMAX</text>
      <path
         inkscape:connector-curvature="0"
         id="path881"
         d="M 77.660254,22.231305 H 132.87757"
         style="opacity:0.791667;fill:none;stroke:#000000;stroke-width:0.287991;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" />
      <text
         transform="scale(1.4227915,0.70284368)"
         id="text885"
         y="36.997055"
         x="54.250431"
         style="font-style:normal;font-weight:normal;font-size:4.59731px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;opacity:0.791667;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.430997"
         xml:space="preserve"><tspan
           dx="0.016332898 0.0081664491"
           style="letter-spacing:0.509893px;word-spacing:0.00459363px;fill:#f0f2f5;fill-opacity:1;stroke-width:0.430997"
           y="36.997055"
           x="54.250431"
           id="tspan883"
           sodipodi:role="line">CALL CENTRUM</tspan></text>
    </g>
    <text
       xml:space="preserve"
       style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.2306px;line-height:0;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;letter-spacing:0px;word-spacing:0px;fill:#f0f2f5;fill-opacity:1;stroke:none;stroke-width:0.307135"
       x="7.0446539"
       y="14.56929"
       id="text840"><tspan
         sodipodi:role="line"
         id="tspan838"
         x="7.0446539"
         y="14.56929"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.2306px;line-height:1.05;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#f0f2f5;fill-opacity:1;stroke-width:0.307135">Obchodní podmínky </tspan><tspan
         sodipodi:role="line"
         x="7.0446539"
         y="25.31142"
         style="font-style:normal;font-variant:normal;font-weight:normal;font-stretch:normal;font-size:10.2306px;line-height:1.05;font-family:Calibri;-inkscape-font-specification:'Calibri, Normal';font-variant-ligatures:normal;font-variant-caps:normal;font-variant-numeric:normal;font-variant-east-asian:normal;fill:#f0f2f5;fill-opacity:1;stroke-width:0.307135"
         id="tspan81165">pro sjednávání schůzek</tspan></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:10.2889px;line-height:1.25;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.264583"
       x="8.3154764"
       y="120.48512"
       id="text855" />
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:8.71869px;line-height:11;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
       x="13.103218"
       y="44.047131"
       id="text859"><tspan
         style="font-weight:bold;font-size:5.64444px;stroke-width:0.204344"
         sodipodi:role="line"
         id="tspan10006"
         x="13.103218"
         y="44.047131">Řádně sjednaná schůzka</tspan><tspan
         style="font-weight:bold;font-size:5.64444px;stroke-width:0.204344"
         sodipodi:role="line"
         id="tspan10008"
         x="13.103218"
         y="139.95271">Špatně sjednaná schůzka</tspan><tspan
         style="font-weight:bold;font-size:5.64444px;stroke-width:0.204344"
         sodipodi:role="line"
         id="tspan10010"
         x="13.103218"
         y="235.85831">Ostatní ujednání</tspan><tspan
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         sodipodi:role="line"
         id="tspan10014"
         x="13.103218"
         y="331.76392" /><tspan
         sodipodi:role="line"
         x="13.103218"
         y="427.66949"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5072" /><tspan
         sodipodi:role="line"
         x="13.103218"
         y="523.57507"
         style="font-weight:bold;font-size:4.23333px;stroke-width:0.204344"
         id="tspan5074" /><tspan
         sodipodi:role="line"
         x="13.103218"
         y="619.48065"
         id="tspan869" /></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:3.88056px;line-height:1.4;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
       x="11.684987"
       y="53.292397"
       id="text859-2"><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         id="tspan45685"
         x="11.684987"
         y="53.292397">- Je taková schůzka, která je dohodnutá s kompetentní osobou dle písemně odsouhlaseného scénáře </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="58.725182"
         id="tspan12318">  rozhovoru, který je Přílohou Smlouvy o zajištění hlasových služeb.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="64.157959"
         id="tspan1474">- Je taková schůzka, kde souhlasí datum, hodina a lokalita s uzavřenou Technickou a servisní specifikací, </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="69.590744"
         id="tspan13568">  která je nedílnou součástí Smlouvy. Zhotovitel je povinen dodržet naplánovanou hodinu, datum </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="75.023529"
         id="tspan14776">  a lokalitu – úpravy jsou možné pouze po předcházejícím písemném odsouhlasení</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="80.456314"
         id="tspan1476">- Je taková schůzka, kde je dodržen čas na přejezdy uvedený v Technické a servisní specifikaci, výjimku </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="85.889099"
         id="tspan17268">  lze s Objednatelem sjednat pouze po písemném odsouhlasení.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="91.321877"
         id="tspan1478">- Je taková schůzka, kde byla Zhotovitelem ověřena adresa místa schůzky.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="96.754662"
         id="tspan1480">- Je taková schůzka, která je zrušena z důvodu „vyšší moci“, jako je například nemoc, nepředvídatelný </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="102.18745"
         id="tspan19076">  odjezd klienta z pracoviště apod.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="107.62023"
         id="tspan1482">- Je taková schůzka, která je v případě dodání databáze subjektů Zhotovitelem, sjednána u firem </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="113.05302"
         id="tspan21436">  odpovídajících výběrovým kriteriím uvedených v Technické a servisní specifikaci (oborové zatřídění, </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="118.4858"
         id="tspan22854">  počet zaměstnanců, právní forma…)</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         id="tspan45689"
         x="11.684987"
         y="123.91858" /><tspan
         sodipodi:role="line"
         x="11.684987"
         y="129.35136"
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         id="tspan14525" /><tspan
         sodipodi:role="line"
         x="11.684987"
         y="134.78415"
         id="tspan869-3"
         style="font-size:3.88056px" /></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:3.88056px;line-height:1.4;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
       x="11.684987"
       y="149.24214"
       id="text27440"><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="149.24214"
         id="tspan27432">- Je taková schůzka, která je dohodnutá s nekompetentní osobou. Vymezení pojmu kompetentní osoba </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="154.67493"
         id="tspan31925">  je stanoveno scénářem rozhovoru, který je Přílohou smlouvy o zajištění hlasových služeb.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="160.10771"
         id="tspan29568">- Je taková schůzka, kde nedošlo k ověření adresy místa schůzky.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="165.5405"
         id="tspan29570">- Je taková schůzka, kde nesouhlasí datum, čas a lokalita s údaji uvedenými v Technické a servisní </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="170.97328"
         id="tspan39525">  specifikaci.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="176.40607"
         id="tspan29572">- Je taková schůzka, kde není dodržen čas na přejezdy uvedený v Technické a servisní specifikaci.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="181.83885"
         id="tspan29574">- Je taková schůzka, která je klientem telefonicky zrušena před termínem realizace schůzky z důvodu, </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="187.27162"
         id="tspan32905">  že klient daný produkt nevyužije a nemá o něj zájem. Tato schůzka je považována za špatně sjednanou </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="192.70441"
         id="tspan33999">  pouze, když se klient ozve telefonicky sám před realizací schůzky a nebude předem kontaktován </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="198.13719"
         id="tspan41099">  Objednatelem.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="203.56998"
         id="tspan29576">- Je taková schůzka, která není, v případě dodání databáze subjektů Zhotovitelem, sjednána u firem </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="209.00276"
         id="tspan37097">  odpovídajících výběrovým kriteriím uvedených v Technické a servisní specifikaci (oborové zatřídění, </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="214.43555"
         id="tspan37759">  počet zaměstnanců, právní forma…)</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         id="tspan27434"
         x="11.684987"
         y="219.86833" /><tspan
         sodipodi:role="line"
         x="11.684987"
         y="225.30112"
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         id="tspan27436" /><tspan
         sodipodi:role="line"
         x="11.684987"
         y="230.7339"
         id="tspan27438"
         style="font-size:3.88056px" /></text>
    <text
       xml:space="preserve"
       style="font-style:normal;font-weight:normal;font-size:3.88056px;line-height:1.4;font-family:sans-serif;letter-spacing:0px;word-spacing:0px;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.204344"
       x="11.684987"
       y="245.19189"
       id="text27474"><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="245.19189"
         id="tspan27466">- Objednatel má právo špatně sjednanou schůzku reklamovat. Zhotovitel je povinen každou </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="250.62468"
         id="tspan50187">  reklamovanou schůzku prošetřit a písemně informovat Objednatele o výsledku reklamace. V případě, </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="256.05746"
         id="tspan51035">  že se prokáže, že má schůzka závady uvedené v bodu 2., nebude tato schůzka objednateli fakturována </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="261.49026"
         id="tspan53089">  nebo bude nahrazena.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="266.92303"
         id="tspan47587">- Reklamace schůzek bude probíhat průběžně, tak jak budou schůzky Objednatelem realizovány u klientů. </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="272.35583"
         id="tspan54993">  Reklamace bude Zhotoviteli doručena písemně nejpozději do 2 (dvou) pracovních dnů po zjištění závady. </tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         x="11.684987"
         y="277.7886"
         id="tspan55451">  Na pozdější reklamace nebude brán zřetel.</tspan><tspan
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         sodipodi:role="line"
         id="tspan27468"
         x="11.684987"
         y="283.22141" /><tspan
         sodipodi:role="line"
         x="11.684987"
         y="288.65417"
         style="font-weight:normal;font-size:3.88056px;stroke-width:0.204344"
         id="tspan27470" /><tspan
         sodipodi:role="line"
         x="11.684987"
         y="294.08698"
         id="tspan27472"
         style="font-size:3.88056px" /></text>
  </g>
</svg>

`;
