//Moment
import moment from "moment";

/**
 * Add event to google calendar, create event
 *
 * @function addToGCal
 * @param {EventModel} event
 */
export function addToGCal(
    event = {
        title: null,
        date: null,
        location: null,
        detail: null,
    }
) {
    window.open(
        "http://www.google.com/calendar/event?action=TEMPLATE&trp=false" +
            "&text=" +
            encodeURI(event.title) +
            "&dates=" +
            moment(event.date[0]).format("YYYYMMDD[T]hhmmss") +
            "/" +
            moment(event.date[1]).format("YYYYMMDD[T]hhmmss") +
            "&location=" +
            encodeURI(event.location) +
            "&details=" +
            encodeURI(event.details)
    );
}

/**
 * @typedef {Object} EventModel
 * @property {String} title Title in gcal
 * @property {[date,date]} date Array of event dates, from to
 * @property {String} location Event location
 * @property {String} detail Text in event detail
 */
