import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
//Router
import { BrowserRouter as Router } from "react-router-dom";
//Provider
import MainProvider from "./components/providers";
//Layout
import MainLayout from "./components/layouts";
//Styles
import "./styles/styles.scss";
import "antd/dist/antd.css";
//Cookies
import { CookiesProvider } from "react-cookie";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <CookiesProvider>
                <MainProvider element={<MainLayout element={<App />}></MainLayout>}></MainProvider>
            </CookiesProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
