import { memo, useState, useEffect } from "react";
//Router
import { Link } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import { store } from "../components/providers/ReduxProvider";
import AmaxApiService from "../services/AmaxApiService";
import { setConnected, setLoading } from "../store/actions";
import { isLoginExpired } from "../components/common/AccountFunctions";
import { resetErrorView } from "../services/ErrorHandler";
//Antd
import { Alert, Input, Button, Form, Select } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Footer from "rc-footer";
//Layout
import LoginLayout from "../components/layouts/LoginLayout";
//Language
import t from "../components/providers/LanguageProvider";
//Images
import logo from "../images/logo-portal-small.png";
import amax from "../images/amax-small.svg";
//Icons
import czIcon from "../icons/flagIcons/czech-republic.svg";
import enIcon from "../icons/flagIcons/united-kingdom.svg";
//Languague
import { setLanguage } from "../store/actions";

import {
    setPermisions,
    setUserTelesalesForm,
    setUserRatingsForm,
    setUserMeetingForm,
    setUserCancelForm,
    setUserTelemarketingForm,
} from "../store/actions";

const LoginPage = ({ AmaxApi, loading }) => {
    store.dispatch(setPermisions(null));

    const [name, setName] = useState("");
    const [passwd, setPasswd] = useState("");
    const [wrongCredentials, setWrongCredentials] = useState(false);
    const [lang, setLang] = useState(null);

    const getPermision = async () => {
        await AmaxApiService.Account.Permission()
            .then((response) => {
                store.dispatch(setPermisions(response));
            })
            .catch((err) => console.log(err));
    };

    /*
     * Handle Input Fields Change -- Set State with appropriate value
     */
    const handleChange = (event) => {
        switch (event.target.id) {
            case "name":
                setName(event.target.value);
                break;
            case "passwd":
                setPasswd(event.target.value);
                break;
        }
    };

    /*
     * Handle button submit,
     * Send request to API
     * If success set Logged In
     */
    const handleSubmit = async () => {
        resetErrorView();
        setWrongCredentials(false);

        store.dispatch(setLoading(true));
        let loginSuccess = await AmaxApiService.authenticate(name, passwd);

        if (loginSuccess) {
            store.dispatch(setConnected(loginSuccess.user, loginSuccess.token_expires_date, loginSuccess.token));
            store.dispatch(setLoading(false));
            store.dispatch(setUserMeetingForm(null, null, null, null, null));
            store.dispatch(setUserTelemarketingForm(null, null, null, null, null));
            store.dispatch(setUserTelesalesForm(null, null, null));
            store.dispatch(setUserRatingsForm(null, null, null));
            store.dispatch(setUserCancelForm(null, null, null, null, null));
        } else {
            store.dispatch(setLoading(false));
            setWrongCredentials(true);
        }
    };

    const enterSubmit = (event) => {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };

    const changeLanguage = (e) => {
        store.dispatch(setLanguage(e));
        setLang(e);
        console.log(e);
    };

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const inputControl = {
        style: {
            width: "300px",
            height: "47px",
        },
    };

    const buttonControl = {
        style: {
            width: "300px",
            height: "36px",
        },
    };

    const buttonSubmitControl = {
        style: {
            width: "300px",
            height: "36px",
            backgroundColor: "#fcab06",
        },
    };

    const iconStyle = {
        height: "17px",
        display: "inline-block",
    };

    return (
        <>
            <LoginLayout>
                <div className="login-box">
                    <Select
                        style={{ float: "right" }}
                        defaultValue={store.getState().Logic.language == "cs" ? "cs" : "en"}
                        onChange={changeLanguage}
                        key="Language"
                    >
                        <Select.Option value="cs">
                            <img src={czIcon} style={iconStyle} />
                            {" CZ"}
                        </Select.Option>
                        <Select.Option value="en">
                            <img src={enIcon} style={iconStyle} />
                            {" EN"}
                        </Select.Option>
                    </Select>
                    <br />

                    <div className="blue-background login-box">
                        <div className="email-login login-img">
                            <img className="login-img" src={logo} alt="logo" />
                        </div>
                        <br />
                        <h3 style={{ textAlign: "center" }}>{t("You need to be signed in to continue.")}</h3>

                        <Form onSubmit={handleSubmit}>
                            <div className="email-login white-background">
                                <Form.Item className="u-form-group" {...formItemLayout} name="name">
                                    <Input
                                        className="u-form-group"
                                        {...inputControl}
                                        type="text"
                                        placeholder={t("Name")}
                                        id="name"
                                        value={name}
                                        onChange={handleChange}
                                        prefix={<UserOutlined className="form-input-icon" />}
                                    ></Input>
                                </Form.Item>
                                <Form.Item className="u-form-group" {...formItemLayout} name="passwd">
                                    <Input.Password
                                        className="u-form-group"
                                        {...inputControl}
                                        type="password"
                                        placeholder={t("Password")}
                                        id="passwd"
                                        value={passwd}
                                        onChange={handleChange}
                                        onKeyDown={(e) => enterSubmit(e)}
                                        prefix={<LockOutlined className="form-input-icon" />}
                                    ></Input.Password>
                                </Form.Item>

                                {!loading ? (
                                    <>
                                        <Button
                                            {...buttonSubmitControl}
                                            type="submit"
                                            className="ant-btn-secondary u-form-group btn-login-form"
                                            onClick={handleSubmit}
                                        >
                                            {t("Log in")}
                                        </Button>
                                        <br />
                                        <Link to="/reset">
                                            <Button {...buttonControl} className="ant-btn-secondary u-form-group">
                                                {t("Forgotten Password")}
                                            </Button>
                                        </Link>
                                    </>
                                ) : null}
                            </div>
                        </Form>
                        {wrongCredentials && (
                            <Alert
                                style={{ width: "330px", textAlign: "center" }}
                                message={t("Incorrect username or password")}
                                type="error"
                                showIcon
                            />
                        )}
                        {isLoginExpired(AmaxApi) && (
                            <Alert
                                style={{ width: "330px", textAlign: "center" }}
                                message={t("Your session has expired")}
                                type="error"
                                showIcon
                            />
                        )}
                        <div className="email-login">
                            <img src={amax} alt="logo" />
                        </div>
                    </div>
                </div>

                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                    <p style={{ textAlign: "center", paddingTop: "10vh" }}>
                        {t("In case of problems, please contact your sales representative or write to e-mail ")}
                        <a href="mailto:obchod@amaxsro.cz">obchod@amaxsro.cz</a>
                    </p>
                </div>
            </LoginLayout>
            <div style={{ backgroundColor: "#282d32" }}>
                <p
                    style={{
                        textAlign: "center",
                        fontFamily: "Poppins",
                        color: "#FFFFFF66",
                        paddingTop: "16px",
                        paddingBottom: "16px",
                    }}
                >
                    AMAX, s.r.o. © 2022
                </p>
            </div>
        </>
    );
};

export default connect(mapStateToProps)(memo(LoginPage));

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi, loading: state.Logic.loading };
}
