/**
 * *
 * *
 * *  Action Creators
 * *
 * *
 */

/**
 *  AmaxApi Action Creators
 */

export function setConnected(user, token_expires_date, token) {
    return {
        type: "SET_API_CONNECTED",
        status: "true",
        user: user,
        token: token,
        token_expires_date: token_expires_date,
    };
}

export function setDisconnected() {
    return {
        type: "SET_API_CONNECTED",
        status: "false",
        user: null,
        token: null,
        token_expires_date: null,
    };
}

/**
 *  Query Action Creators
 */

export function setSearchQuery(formTable, query) {
    return {
        type: "SET_SEARCH_QUERY",
        formName: formTable,
        query: query,
    };
}

export function setSearchResult(formTable, status, data = null) {
    return {
        type: "SET_SEARCH_RESULT",
        formName: formTable,
        status: status,
        data: data,
    };
}

/**
 *  Logic Action Creators
 */

export function setLoading(bool) {
    return {
        type: "SET_LOADING",
        bool: bool,
    };
}
export function displayError(status, message = null, display = "main", staceTrace = null) {
    return {
        type: "DISPLAY_ERROR",
        status: status,
        message: message,
        display: display,
        staceTrace: staceTrace,
    };
}

export function resetError() {
    return {
        type: "RESET_ERROR",
    };
}

export function setLanguage(lang) {
    return {
        type: "SET_LANGUAGE",
        language: lang,
    };
}

export function setPermisions(permisions) {
    return {
        type: "SET_PERMISIONS",
        permisions: permisions,
    };
}

/////////////////////////////////////////////////
{
    /*export function setUserMeetingCampaigns(meetingCampaigns) {
  return {
    type: "SET_MEETING_CAMPAIGNS",
    meetingCampaigns: meetingCampaigns,
  };
*/
}

export function setUserMeetingForm(meetingSelectedTss, meetingSR, meetingDisplay, meetingDate, agreedDate) {
    return {
        type: "SET_MEETING_FORM",
        meetingSelectedTss: meetingSelectedTss,
        meetingSR: meetingSR,
        meetingDisplay: meetingDisplay,
        meetingDate: meetingDate,
        agreedDate: agreedDate,
    };
}

/////////////////////////////////////////////////

export function setUserTelemarketingForm(
    telemarketingSelectedTss,
    telemarketingPriority,
    telemarketingQuestion,
    telemarketingDisplay,
    telemarketingDate
) {
    return {
        type: "SET_TELEMARKETING_FORM",
        telemarketingSelectedTss: telemarketingSelectedTss,
        telemarketingPriority: telemarketingPriority,
        telemarketingQuestion: telemarketingQuestion,
        telemarketingDisplay: telemarketingDisplay,
        telemarketingDate: telemarketingDate,
    };
}

/////////////////////////////////////////////////

export function setUserTelesalesForm(telesalesSelectedTss, telesalesDisplay, telesalesDate) {
    return {
        type: "SET_TELESALES_FORM",
        telesalesSelectedTss: telesalesSelectedTss,
        telesalesDisplay: telesalesDisplay,
        telesalesDate: telesalesDate,
    };
}

/////////////////////////////////////////////////

export function setUserRatingsForm(ratingSelectedTss, ratingSR, ratingsDate) {
    return {
        type: "SET_RATINGS_FORM",
        ratingSelectedTss: ratingSelectedTss,
        ratingSR: ratingSR,
        ratingsDate: ratingsDate,
    };
}

/////////////////////////////////////////////////
export function setUserCancelForm(cancelFilter, cancelTIN, cancelCompanyID, cancelSelectedTss, cancelDate) {
    return {
        type: "SET_CANCEL_FORM",
        cancelFilter: cancelFilter,
        cancelTIN: cancelTIN,
        cancelCompanyID: cancelCompanyID,
        cancelSelectedTss: cancelSelectedTss,
        cancelDate: cancelDate,
    };
}
