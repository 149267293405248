import React, { useState, useEffect } from "react";
//Redux
import { connect } from "react-redux";
import SearchAndQueryData from "../../../services/DataQueryController";
import AmaxApiService from "../../../services/AmaxApiService";
import setUserRatingsColumns from "../../../store/actions";
//Antd
import { Button, Table, Descriptions, Select, Checkbox, Input } from "antd";
import {
    InfoCircleTwoTone,
    FileExcelOutlined,
    UpOutlined,
    FileExcelFilled,
    SearchOutlined,
    CloseCircleOutlined,
    SettingFilled,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import HodnoceniSchuzkyDetail from "../Modals/HodnoceniSchuzkyDetail";
import exportReport, { RatingsParse, RatingingParseColumns } from "../exports/CSVExport";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";

const HodnoceniSchuzkyTable = ({ data, loading, query }) => {
    const [storedColumns, setStoredColumns] = useState(
        JSON.parse(localStorage.getItem("ratingColumns")) ? JSON.parse(localStorage.getItem("ratingColumns")) : null
    );
    const [columns, setColumns] = useState(null);

    const [campaigns, setCampaigns] = useState([]);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [displayDetail, setDisplayDetail] = useState(false);
    const [detailId, setDetailId] = useState(null);
    const [layout, setLayout] = useState("vertical");
    const [tableExpandable, setTableExpandable] = useState(false);
    const [last, setLast] = useState(data);
    const [exportVisible, setExportVisible] = useState(false);
    const [columnSettVisible, setColumnSettVisible] = useState(false);
    const [selectedInformation, setSelectedInformation] = useState(false);

    const findCampaigns = async () => {
        let campai = await AmaxApiService.Ratings.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    };

    const paddingRightLayout = {
        style: {
            paddingRight: "10px",
            color: "#0e151a",
            marginTop: "10px",
        },
    };

    ///***FUNKCIONALITY FOR COLUMNS SETTING****
    useEffect(() => {
        if (storedColumns) localStorage.setItem("ratingColumns", JSON.stringify(storedColumns));
    }, [storedColumns]);

    useEffect(() => {
        if (storedColumns) {
            for (let i = 0; i < storedColumns.length; i++) {
                let newColumn = storedColumns;

                switch (storedColumns[i]["title"]) {
                    case t("Meeting Date"):
                        newColumn[i] = colDate;
                        break;
                    case t("Rating Date"):
                        newColumn[i] = colRating;
                        break;
                    case t("Company"):
                        newColumn[i] = colCompany;
                        break;
                    case t("TIN"):
                        newColumn[i] = colTIN;
                        break;
                    case t("Sales Representative"):
                        newColumn[i] = colSR;
                        break;
                    case t("Contact"):
                        newColumn[i] = colContact;
                        break;
                    case t("Detail"):
                        newColumn[i] = colDetail;
                        break;
                }
                setStoredColumns(newColumn);
            }
        }
    }, [data]);

    useEffect(() => {
        setLast(data);
        if (data) console.log(data);
        setRowKeys([]);
        setSearchText("");
        setOldLast(data);
        findCampaigns();
    }, [data]);

    const resizeModal = () => {
        setColumns(
            responsiveWidth({
                xs: xsColumns,
                sm: smColumns,
                md: mdColumns,
                lg: lgColumns,
            })
        );
        setLayout(
            responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            })
        );
    };
    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });

        console.log(data);
    }, []);

    useEffect(() => {
        if (
            columns != null &&
            columns != undefined &&
            columns.length &&
            columns.length != undefined &&
            columns.length != null
        ) {
            {
                /*if (columns.length < 7) {
        setTableExpandable(true);
      } else {
        setTableExpandable(false);
      */
            }
        }
        if (!isChanged) onResize();
    }, [columns]);

    const onResize = () => {
        if (storedColumns) {
            let actualColumnsToChange = [];
            storedColumns.map((oneColumn) => {
                actualColumnsToChange.push(oneColumn["title"]);
            });
            setSelectedItems(actualColumnsToChange.splice(0, actualColumnsToChange.length - 1));
        } else {
            setSelectedItems(
                responsiveWidth({
                    xs: [t("Meeting Date"), t("Company")],
                    sm: [t("Meeting Date"), t("Company"), t("Rating Date"), t("Sales Representative")],
                    md: [t("Meeting Date"), t("Company"), t("Rating Date"), t("TIN"), t("Sales Representative")],
                    lg: [
                        t("Meeting Date"),
                        t("Company"),
                        t("Rating Date"),
                        t("TIN"),
                        t("Contact"),
                        t("Sales Representative"),
                    ],
                })
            );
        }
    };

    const handleDisplayDetail = (record) => {
        setDisplayDetail(true);
        setDetailId(record);
    };

    const closeDetail = () => {
        setDisplayDetail(false);
        setDetailId(null);
    };

    //////////////////////////////////////////////////////////
    const exportTypeAllRecords = () => {
        if (!selectedInformation) {
            exportAll();
        } else {
            exportColumns();
        }
    };

    const exportTypeSelection = () => {
        if (!selectedInformation) {
            exportSelection();
        } else {
            exportColumnsSelection();
        }
    };

    const exportAll = () => {
        const toExport = last ? last : data;
        exportReport(RatingsParse(toExport), "ratings", "amax_portal_ratings", query, campaigns, []);
    };

    const exportColumns = () => {
        const toExport = last ? last : data;
        exportReport(
            RatingingParseColumns(toExport, selectedItems),
            "ratings",
            "amax_portal_ratings",
            query,
            campaigns,
            []
        );
        //setColumns(columns)
    };

    const exportSelection = () => {
        const toExport = last ? last : data;
        if (selectedRowKeys.length > 0) {
            const selectedData = [];
            toExport.map((record) =>
                selectedRowKeys.indexOf(record.MeetingId) >= 0 ? selectedData.push(record) : null
            );
            exportReport(RatingsParse(selectedData), "ratings", "amax_portal_ratings", query, campaigns, []);
        }
    };

    const exportColumnsSelection = () => {
        const toExport = last ? last : data;
        if (selectedRowKeys.length > 0) {
            const selectedData = [];
            toExport.map((record) => (selectedRowKeys.indexOf(record) >= 0 ? selectedData.push(record) : null));
            exportReport(
                RatingingParseColumns(selectedData, selectedItems),
                "ratings",
                "amax_portal_ratings",
                query,
                campaigns,
                []
            );
        }
    };

    //////
    const [searchText, setSearchText] = useState("");
    const [oldLast, setOldLast] = useState(last);
    var newLast = [];

    const handleSearch = () => {
        const dataToSearch = oldLast ? oldLast : data;
        if (dataToSearch) {
            dataToSearch.map((one) => {
                if (
                    one.firm.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.ico.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.SR.toString().toLowerCase().includes(searchText.toLowerCase())
                )
                    newLast.push(one);
            });
        }
        setLast(newLast);
        setPage(1);
        setPageSize(10);
    };

    const resetSearch = () => {
        setLast(oldLast);
        setSearchText("");
    };
    ////

    const openPrevDetail = () => {
        let allData = last ? last : data;

        allData.map((record) => {
            if (record == detailId) {
                if (allData.indexOf(record) != 0) {
                    //setPrev(allData.indexOf(record) - 1)
                    closeDetail();
                    setDetailId(allData[allData.indexOf(record) - 1]);
                    handleDisplayDetail(allData[allData.indexOf(record) - 1], allData[allData.indexOf(record) - 1]);
                }
            }
        });
    };

    const openNextDetail = () => {
        let allData = last ? last : data;

        allData.map((record) => {
            if (record == detailId) {
                if (allData.indexOf(record) != allData.length - 1) {
                    //setPrev(allData.indexOf(record) - 1)
                    closeDetail();
                    setDetailId(allData[allData.indexOf(record) + 1]);
                    handleDisplayDetail(allData[allData.indexOf(record) + 1], allData[allData.indexOf(record) + 1]);
                }
            }
        });
    };

    const changeColumns = (columns) => {
        let newColumns = [];
        columns.map((column) => {
            switch (column) {
                case t("Meeting Date"):
                    newColumns.push(colDate);
                    break;
                case t("Company"):
                    newColumns.push(colCompany);
                    break;
                case t("Rating Date"):
                    newColumns.push(colRating);
                    break;
                case t("TIN"):
                    newColumns.push(colTIN);
                    break;
                case t("Contact"):
                    newColumns.push(colContact);
                    break;
                case t("Sales Representative"):
                    newColumns.push(colSR);
                    break;
            }
        });
        newColumns.push(colDetail);
        setStoredColumns(newColumns);
    };

    const resetChangeColumns = () => {
        //setSelectedColumns(null);
        setIsChanged(false);
        setStoredColumns(
            responsiveWidth({
                xs: xsColumns,
                sm: smColumns,
                md: mdColumns,
                lg: lgColumns,
            })
        );
        setSelectedItems(
            responsiveWidth({
                xs: [t("Meeting Date"), t("Company")],
                sm: [t("Meeting Date"), t("Company"), t("Rating Date"), t("Sales Representative")],
                md: [t("Meeting Date"), t("Company"), t("Rating Date"), t("TIN"), t("Sales Representative")],
                lg: [
                    t("Meeting Date"),
                    t("Company"),
                    t("Rating Date"),
                    t("TIN"),
                    t("Contact"),
                    t("Sales Representative"),
                ],
            })
        );
    };

    //////////////////////COLUMNS SETTINGS/////////////////////
    ///Columns setting for changing table columns
    const colDate = {
        title: t("Meeting Date"),
        dataIndex: "meetingTime",
        sorter: (a, b) => a.meetingTime.localeCompare(b.meetingTime),
        sortDirections: ["descend", "ascend"],
        render: (dateString) => (
            <>
                <span>{moment(dateString).format("DD.MM.YYYY") + " "} </span>
                <span>{moment(dateString).format("HH:mm")}</span>
            </>
        ),
        maxWidth: "200",
    };

    const colCompany = {
        title: t("Company"),
        dataIndex: "firm",
        sorter: (a, b) => a.firm.localeCompare(b.firm),
        sortDirections: ["descend", "ascend"],
        width: "auto",
    };

    const colRating = {
        title: t("Rating Date"),
        dataIndex: "ratingTime",
        sorter: (a, b) => a.ratingTime.localeCompare(b.ratingTime),
        sortDirections: ["descend", "ascend"],
        render: (dateString) =>
            dateString.indexOf("0001-01-01T00:00:00") < 0 ? (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ) : (
                <span>{"-"}</span>
            ),
        maxWidth: "200",
    };

    const colTIN = {
        title: t("TIN"),
        dataIndex: "ico",
        sorter: (a, b) => a.ico - b.ico,
        sortDirections: ["descend", "ascend"],
        width: "5%",
    };

    const colContact = {
        title: t("Contact"),
        dataIndex: "kontakt",
        width: "10%",
    };

    const colSR = {
        title: t("Sales Representative"),
        dataIndex: "SR",
        sorter: (a, b) => a.SR - b.SR,
        sortDirections: ["descend", "ascend"],
        width: "auto",
    };

    const colDetail = {
        title: t("Detail"),
        dataIndex: "details",
        width: "5%",
        render: (text, record) => (
            <Button title={t("Meeting Detail")} onClick={() => handleDisplayDetail(record)}>
                <InfoCircleTwoTone />
            </Button>
        ),
    };

    //////////LARGE SIZE OF TABLE///////////
    const lgColumns = [
        {
            title: t("Meeting Date"),
            dataIndex: "meetingTime",
            sorter: (a, b) => a.meetingTime.localeCompare(b.meetingTime),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "} </span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "15%",
        },
        {
            title: t("Company"),
            dataIndex: "firm",
            sorter: (a, b) => a.firm.localeCompare(b.firm),
            sortDirections: ["descend", "ascend"],
            width: "20%",
        },
        {
            title: t("Rating Date"),
            dataIndex: "ratingTime",
            sorter: (a, b) => a.ratingTime.localeCompare(b.ratingTime),
            sortDirections: ["descend", "ascend"],
            render: (dateString) =>
                dateString.indexOf("0001-01-01T00:00:00") < 0 ? (
                    <>
                        <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                        <span>{moment(dateString).format("HH:mm")}</span>
                    </>
                ) : (
                    <span>{"-"}</span>
                ),
            width: "15%",
        },

        {
            title: t("TIN"),
            dataIndex: "ico",
            sorter: (a, b) => a.ico - b.ico,
            sortDirections: ["descend", "ascend"],
            width: "5%",
        },
        {
            title: t("Contact"),
            dataIndex: "kontakt",
            width: "10%",
        },
        {
            title: t("Sales Representative"),
            dataIndex: "SR",
            sorter: (a, b) => a.SR - b.SR,
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        colDetail,
    ];

    //////////MEDIUM SIZE OF TABLE///////////
    const mdColumns = [
        {
            title: t("Meeting Date"),
            dataIndex: "meetingTime",
            sorter: (a, b) => a.meetingTime.localeCompare(b.meetingTime),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY")}</span>
                    <br />
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "20%",
        },
        {
            title: t("Company"),
            dataIndex: "firm",
            sorter: (a, b) => a.firm.localeCompare(b.firm),
            sortDirections: ["descend", "ascend"],
            width: "20%",
        },

        {
            title: t("Rating Date"),
            dataIndex: "ratingTime",
            sorter: (a, b) => a.ratingTime.localeCompare(b.ratingTime),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY")}</span>
                    <br />
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "20%",
        },
        {
            title: t("TIN"),
            dataIndex: "ico",
            sorter: (a, b) => a.ico - b.ico,
            sortDirections: ["descend", "ascend"],
            width: "5%",
        },
        {
            title: t("Sales Representative"),
            dataIndex: "SR",
            sorter: (a, b) => a.SR - b.SR,
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        colDetail,
    ];

    //////////SMALL SIZE OF TABLE///////////
    const smColumns = [
        {
            title: t("Meeting Date"),
            dataIndex: "meetingTime",
            sorter: (a, b) => a.meetingTime.localeCompare(b.meetingTime),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY")}</span>
                    <br />
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "25%",
        },

        {
            title: t("Company"),
            dataIndex: "firm",
            sorter: (a, b) => a.firm.localeCompare(b.firm),
            sortDirections: ["descend", "ascend"],
            width: "30%",
        },
        {
            title: t("Rating Date"),
            dataIndex: "ratingTime",
            sorter: (a, b) => a.ratingTime.localeCompare(b.ratingTime),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY")}</span>
                    <br />
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "25%",
        },

        {
            title: t("Sales Representative"),
            dataIndex: "SR",
            sorter: (a, b) => a.SR - b.SR,
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        colDetail,
    ];

    //////////EXTRA SMALL SIZE OF TABLE///////////
    const xsColumns = [
        {
            title: t("Meeting Date"),
            dataIndex: "meetingTime",
            sorter: (a, b) => a.meetingTime.localeCompare(b.meetingTime),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY")}</span>
                    <br />
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "25%",
        },
        {
            title: t("Company"),
            dataIndex: "firm",
            sorter: (a, b) => a.firm.localeCompare(b.firm),
            sortDirections: ["descend", "ascend"],
            width: "auto",
        },
        colDetail,
    ];

    ///***FUNKCIONALITY FOR SELECTING RECORDS FROM TABLE****
    const [selectedRowKeys, setRowKeys] = useState([]);
    const onSelectChange = (selectedRowKeys) => {
        setRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            {
                key: "all",
                text: t("All"),
                onSelect: () => {
                    let recordIds = [];
                    const records = last ? last : data ? data : null;
                    if (records) {
                        records.map((one) => {
                            recordIds.push(one.pripadID);
                        });
                    }
                    setRowKeys(recordIds);
                },
            },
            {
                key: "cancel",
                text: t("None"),
                onSelect: () => {
                    setRowKeys([]);
                },
            },
        ],
    };

    const sortForPrevNext = (sorter) => {
        let allData = last ? last : data;
        if (allData) {
            switch (sorter["field"]) {
                case "meetingTime":
                    if (sorter["order"] == "descend")
                        allData.sort((a, b) => b.meetingTime.localeCompare(a.meetingTime));
                    else allData.sort((a, b) => a.meetingTime.localeCompare(b.meetingTime));
                    break;
                case "ratingTime":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.ratingTime.localeCompare(a.ratingTime));
                    else allData.sort((a, b) => a.ratingTime.localeCompare(b.ratingTime));
                    break;
                case "firm":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.firm.localeCompare(a.firm));
                    else allData.sort((a, b) => a.firm.localeCompare(b.firm));
                    break;
                case "SR":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.SR.localeCompare(a.SR));
                    else allData.sort((a, b) => a.SR.localeCompare(b.SR));
                    break;
                case "ico":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.ico.localeCompare(a.ico));
                    else allData.sort((a, b) => a.ico.localeCompare(b.ico));
                    break;
            }
            if (sorter["order"] == undefined) allData.sort((a, b) => a.ratingTime.localeCompare(b.ratingTime));
            setLast(allData);
        }
    };
    ///////Change columns options
    const exportOptions = [
        t("Date of Sale"),
        t("TIN"),
        t("Company"),
        t("ZIP"),
        t("Address"),
        t("Phone"),
        t("Email"),
        t("Web"),
        t("Person"),
        t("Price"),
        t("Product"),
        t("Campaign"),
        t("Questionnaire Note"),
        t("Contact Note"),
        t("Customer note"),
    ];
    const [selectedItems, setSelectedItems] = useState([]);
    const filteredOptions = selectedItems ? exportOptions.filter((o) => !selectedItems.includes(o)) : null;
    const [isChanged, setIsChanged] = useState(false);

    return (
        <>
            <HodnoceniSchuzkyDetail
                closeDialog={() => closeDetail()}
                visible={displayDetail}
                record={detailId}
                openPrevDetail={() => openPrevDetail()}
                openNextDetail={() => openNextDetail()}
            />

            <div className="big-form-switch-div">
                <div className="big-form-switch-button">
                    <button
                        className="multiple-button"
                        onClick={() => {
                            setColumnSettVisible(!columnSettVisible);
                            setExportVisible(false);
                        }}
                    >
                        {columnSettVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Edit columns ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Edit columns ")}
                                <SettingFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>

                    <button
                        className="multiple-button"
                        onClick={() => {
                            setExportVisible(!exportVisible);
                            setColumnSettVisible(false);
                        }}
                    >
                        {exportVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Export ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Export ")}
                                <FileExcelFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>
                </div>

                {columnSettVisible && (
                    <div className="form-question" style={{ paddingTop: "10px" }}>
                        <div style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "30px" }}>
                            <p style={{ marginTop: "10px", color: "#0e151a" }}>
                                {t("To add more columns, click on the white part of the field")}
                            </p>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Choose columns"
                                value={selectedItems}
                                onChange={(e) => {
                                    setSelectedItems(e);
                                    changeColumns(e);
                                    setIsChanged(true);
                                }}
                                style={{
                                    width: "90%",
                                }}
                            >
                                {filteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                            <div style={{ marginTop: "5px" }}>
                                <Button
                                    type="primary"
                                    className="route-button"
                                    icon={<CloseCircleOutlined />}
                                    onClick={resetChangeColumns}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {exportVisible && (
                    <div className="form-question" style={{ paddingTop: "10px" }}>
                        <div style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "30px" }}>
                            <div style={{ marginTop: "5px" }}>
                                <Checkbox
                                    {...paddingRightLayout}
                                    onChange={(e) => setSelectedInformation(e.target.checked)}
                                >
                                    {t("Export visible columns")}
                                </Checkbox>
                                <br />
                                <Button
                                    style={{ marginTop: "22px", width: "256px" }}
                                    disabled={data ? false : true}
                                    onClick={exportTypeAllRecords}
                                    className="yellow-button"
                                    icon={<FileExcelOutlined />}
                                >
                                    {t("Export ALL records")}
                                </Button>
                                <Button
                                    style={{ width: "256px" }}
                                    disabled={data && selectedRowKeys.length > 0 ? false : true}
                                    onClick={exportTypeSelection}
                                    className="yellow-button"
                                    icon={<FileExcelOutlined />}
                                >
                                    {t("Export SELECTED records")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div style={{ backgroundColor: "white" }}>
                <p style={{ marginLeft: "35px", float: "left" }}>
                    {t("Selected")} {selectedRowKeys.length} {t("from")} {last ? last.length : data ? data.length : 0}
                </p>

                <div style={{ float: "right", display: "flex", marginRight: "26px" }}>
                    <Input
                        placeholder={t("Search")}
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        onPressEnter={() => handleSearch()}
                        style={{
                            marginBottom: 8,
                            height: "24.5px",
                            width: "140px",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 25, marginLeft: "5px" }}
                    />
                    <Button
                        icon={<CloseCircleOutlined />}
                        onClick={() => resetSearch()}
                        size="small"
                        style={{ width: 25 }}
                    />
                </div>
            </div>

            <Table
                className="tableClass"
                rowSelection={rowSelection}
                onChange={(pagination, filters, sorter) => {
                    sortForPrevNext(sorter);
                }}
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "30"],
                    size: layout == "horizontal" ? "default" : "small",
                    current: page,
                    onChange: (e) => {
                        setPage(e);
                    },
                    total: last ? last.length : data ? data.length : 0,
                    showTotal: (total) => t("Total: ") + `${total} `,
                    onShowSizeChange: (current, size) => setPage(1),
                }}
                tableLayout="auto"
                dataSource={last ? last : data}
                columns={storedColumns ? storedColumns : columns}
                loading={loading}
                rowKey={(record) => record}
            />
            <div className="btn-group-height"></div>
        </>
    );
};

export default connect(mapStateToProps)(HodnoceniSchuzkyTable);

// Will run every time when state change is detected
function mapStateToProps(state) {
    // If form issued a queue, and 'QUEUED' is detected in state
    // Search and Query data to state
    if (state.SearchQuery.formName == "RATINGS" && state.SearchQuery.query && state.SearchQuery.status == "QUEUED") {
        SearchAndQueryData(4, state.SearchQuery.query);
        return { loading: true };
    }

    // If data are loaded in state ('READY') detected
    // set data to component's props
    if ((state.SearchQuery.formName == "RATINGS") & (state.SearchQuery.status == "READY") && state.SearchQuery.result)
        return { data: state.SearchQuery.result, loading: false, query: state.SearchQuery.query };

    // If no queue detected and no data detected
    // set null to data, and disable loading
    return { data: null, loading: false };
}
