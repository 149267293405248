import React, { useState, useEffect } from "react";
//Redux
import AmaxApiService from "../services/AmaxApiService";
import { resetErrorView } from "../services/ErrorHandler";
//Antd
import { Descriptions, PageHeader, Skeleton, Form, Input, Button, Alert, Table } from "antd";
import { LockOutlined } from "@ant-design/icons";
//Layout
import LayoutWrapper from "../components/layouts/LayoutWrapper";
//Components
import ErrorView from "../components/common/ErrorView";
//Language
import t from "../components/providers/LanguageProvider";
import Column from "antd/lib/table/Column";
//Helpers
import { responsiveWidth } from "../helpers/helpers";

const UserInfoPage = () => {
    const [userData, setUserData] = useState(null);
    const [togglePwd, setTogglePwd] = useState(false);
    const [success, setSuccess] = useState(false);
    const [pwdOk, setPwdOk] = useState(true);
    const [pwdOld, setPwdOld] = useState("");
    const [pwdNew, setPwdNew] = useState("");
    const [pwdNewRepeat, setPwdNewRepeat] = useState("");
    const [visibleChP, setVisibleChP] = useState(false);
    const [layout, setLayout] = useState("vertical");

    /*
     * Handle Input Fields Change -- Set State with appropriate value
     */
    const handleChange = (event) => {
        switch (event.target.id) {
            case "pwdOld":
                setPwdOld(event.target.value);
                break;
            case "pwdNew":
                setPwdNew(event.target.value);
                break;
            case "pwdNewRepeat":
                setPwdNewRepeat(event.target.value);
                break;
        }
    };

    const resizeModal = () => {
        setLayout(
            responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            })
        );
    };

    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });
    }, []);

    /**
     * Runs on form submit, evaluates fields and sets SearchQuery
     * Send request to API
     * If success changes password
     */
    const onFinish = () => {
        resetErrorView();
        let pwds = {
            OldPassword: pwdOld,
            NewPassword: pwdNew,
            ConfirmPassword: pwdNewRepeat,
        };
        var mediumRegex = new RegExp(
            "^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$",
            "g"
        );
        setPwdOk(mediumRegex.test(pwdNew));
        console.log(mediumRegex.test(pwdNew));
        if (mediumRegex.test(pwdNew)) {
            AmaxApiService.Account.ChangePassword(pwds).then((response) => {
                console.log(response);
                if (response.status === 200) {
                    setPwdOld("");
                    setPwdNew("");
                    setPwdNewRepeat("");
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                }
            });
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const getInfo = async () => {
        AmaxApiService.Account.UserInfo().then((response) => {
            console.log(response);
            setUserData(response);
        });
    };

    useEffect(() => {
        resetErrorView();
        getInfo();
    }, []);

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    };

    const formItemControl = {
        style: {
            maxWidth: "300px",
        },
    };

    return (
        <LayoutWrapper>
            <PageHeader
                onBack={() => {
                    window.location = "/";
                }}
                title={t("User Account")}
                extra={[]}
            >
                {!userData ? (
                    <React.Fragment key="react">
                        <Skeleton key="skeleton1" active title="true" />
                        <Skeleton key="skeleton2" active />
                    </React.Fragment>
                ) : (
                    <div key="div">
                        <Descriptions
                            key="list" //for desktop size
                            //className="desktop-user-info"
                            bordered
                            column={2}
                            layout={layout}
                            style={{ textAlign: "left" }}
                        >
                            <Descriptions.Item key="username" span={3} label={t("Username")}>
                                {userData.Username}
                            </Descriptions.Item>
                            <Descriptions.Item key="dispalyName" span={3} label={t("Display Name")}>
                                {userData.DisplayName}
                            </Descriptions.Item>
                            <Descriptions.Item key="firstN" span={3} label={t("First Name")}>
                                {userData.FirstName}
                            </Descriptions.Item>
                            <Descriptions.Item key="lastN" span={3} label={t("Last Name")}>
                                {userData.LastName}
                            </Descriptions.Item>
                            <Descriptions.Item key="email" span={3} label={t("E-mail")}>
                                {userData.Email}
                            </Descriptions.Item>
                        </Descriptions>

                        {/*<Descriptions key="list"//for desktop size
              className="mobile-user-info"
              bordered 
              column={1}
              style={{ textAlign: "left" }}
              >
              <Descriptions.Item key="username" span={1} label={<b>{t("Username")}</b>} />
              <Descriptions.Item key="usernameVal" style={{backgroundColor:"white"}} span={1} label={userData.Username} />
              <Descriptions.Item key="dispalyName" span={1} label={<b>{t("Display Name")}</b>} />
              <Descriptions.Item key="dispalyNameVal" style={{backgroundColor:"white"}} span={1} label={userData.DisplayName} />
              <Descriptions.Item key="firstN" span={1} label={<b>{t("First Name")}</b>} />
              <Descriptions.Item key="firstNVal" style={{backgroundColor:"white"}} span={1} label={userData.FirstName} />
              <Descriptions.Item key="lastN" span={1} label={<b>{t("Last Name")}</b>} />
              <Descriptions.Item key="lastNVal" style={{backgroundColor:"white"}} span={1} label={userData.LastName} />
              <Descriptions.Item key="email" span={1} label={<b>{t("E-mail")}</b>} />
              <Descriptions.Item key="emailVal" style={{backgroundColor:"white"}} span={1} label={userData.Email} />
        </Descriptions>*/}

                        <Button
                            key="changePassword password-form"
                            className="ant-btn-primary"
                            style={{ margin: "20px" }}
                            onClick={() => (setTogglePwd(!togglePwd), setVisibleChP(!visibleChP))}
                        >
                            {visibleChP ? "Skýt formulář" : t("Change Password")}
                        </Button>
                    </div>
                )}
                {togglePwd && (
                    <div className="password-form">
                        <Form key="form" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                            <Form.Item {...formItemLayout}>
                                <Input.Password
                                    {...formItemControl}
                                    type="password"
                                    placeholder={t("Old Password")}
                                    id="pwdOld"
                                    value={pwdOld}
                                    onChange={handleChange}
                                    prefix={<LockOutlined className="form-input-icon" />}
                                ></Input.Password>
                            </Form.Item>
                            <Form.Item {...formItemLayout}>
                                <Input.Password
                                    {...formItemControl}
                                    type="password"
                                    placeholder={t("New Password")}
                                    id="pwdNew"
                                    value={pwdNew}
                                    onChange={handleChange}
                                    prefix={<LockOutlined className="form-input-icon" />}
                                ></Input.Password>
                            </Form.Item>
                            <Form.Item {...formItemLayout}>
                                <Input.Password
                                    {...formItemControl}
                                    type="password"
                                    placeholder={t("New Password Again")}
                                    id="pwdNewRepeat"
                                    value={pwdNewRepeat}
                                    onChange={handleChange}
                                    prefix={<LockOutlined className="form-input-icon" />}
                                ></Input.Password>
                            </Form.Item>
                            <Form.Item {...formItemLayout}>
                                <Button
                                    style={{ backgroundColor: "#fcab06", border: "none" }}
                                    htmlType="submit"
                                    className="ant-btn-secondary"
                                >
                                    {t("Change Password")}
                                </Button>
                            </Form.Item>
                            <div style={{ height: "auto", minHeight: "80px" }}></div>
                            <ErrorView key="error" type="main" />
                        </Form>

                        {!pwdOk && (
                            <Alert
                                key="alert"
                                message={t(
                                    "Password must contain at least 8 characters, with uppercase and lowercase letters, special characters and numbers"
                                )}
                                type="error"
                                showIcon
                            />
                        )}
                        {success && <Alert key="alert" message={t("Password changed")} type="success" showIcon />}
                    </div>
                )}
            </PageHeader>
        </LayoutWrapper>
    );
};

export default UserInfoPage;
