import React, { useState, useEffect } from "react";
//Redux
import AmaxApiService from "../../../services/AmaxApiService";
import handleError from "../../../services/ErrorHandler";
import { defaultAPITime } from "../../../services/DataQueryController";
import { store } from "../../providers/ReduxProvider";
import { resetErrorView } from "../../../services/ErrorHandler";
//Antd
import { Button, Form, Modal, Descriptions, Divider, Input, Radio, Alert, Switch, Select } from "antd";
import { CloseCircleOutlined, PlusCircleOutlined, SendOutlined } from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import ErrorView from "../ErrorView";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//DateRange and DefinedRange
import { DateRange, Calendar } from "react-date-range";
import { addDays, startOfDay } from "date-fns";
// locale for czech calendar
import { cs, enGB } from "date-fns/locale";

const ChangeMeetingDateModal = ({ meetingDetails, visible, closeDialog }) => {
    const [proposedTerms, setProposedTerms] = useState([]);
    const [currentInputType, setCurrentInputType] = useState(false);
    const [MeetingTime, setMeetingTime] = useState("0");
    const [selectedTermType, setSelectedTermType] = useState("0");
    const [userData, setUserData] = useState({ DisplayName: null, Email: null });
    const [modalWidth, setModalWidth] = useState("100%");
    const [layout, setLayout] = useState("vertical");
    const [messageSent, setMessageSent] = useState(false);
    const [errorDisplay, setErrorDisplay] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [Date, setDate] = useState([moment().subtract(3, "months"), moment()]);
    const [state, setState] = useState([
        {
            startDate: addDays(startOfDay(new window.Date()), 1),
            endDate: addDays(startOfDay(new window.Date()), 7),
            key: "selection",
        },
    ]);
    const [Day, setDay] = useState(addDays(startOfDay(new window.Date()), 1));

    const formRangeControl = {
        style: {
            width: "auto",
        },
    };

    const resizeModal = () => {
        setModalWidth(
            responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            })
        );
        setLayout(
            responsiveWidth({
                sm: "horizontal",
                md: "horizontal",
            })
        );
    };

    useEffect(() => {
        setMessageSent(false);
        setProposedTerms([]);
        setSelectedTermType("0");
        setErrorMessage("");
        setErrorDisplay(false);
    }, [meetingDetails]);

    const getInfo = async () => {
        AmaxApiService.Account.UserInfo().then((response) => {
            setUserData(response);
            setName(response.FirstName + " " + response.LastName);
            setEmail(response.Email);
        });
    };

    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });
        getInfo();
        setProposedTerms([]);
    }, []);

    const onFinish = (e) => {
        resetErrorView();
        if (selectedTermType == "1" && proposedTerms.length == 0) {
            setErrorMessage(t("First enter the day or interval of days to move"));
            setErrorDisplay(true);
        } else {
            //e.preventDefault();
            if (validateFields) {
                AmaxApiService.ReportMeetings.SendTermMovingRequest({
                    UserName: name,
                    Email: email,
                    Message: message,
                    MeetingId: meetingDetails.MeetingId,
                    ProposedTerms: proposedTerms,
                    SelectedTermType: selectedTermType,
                }).then(() => {
                    setName("");
                    setEmail("");
                    setMessage("");
                    setMessageSent(true);
                });
            }
            /*console.log("name:", name, "email:", email, "message:", message, "id:", meetingDetails.MeetingId, "p:", proposedTerms, "s:",selectedTermType)
      setName("");
      setEmail("");
      setMessage("");
      setMessageSent(true);*/
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const displayMeetingTime = (timeID) => {
        switch (timeID) {
            case "0":
                return t("Morning");
            case "1":
                return t("Afternoon");
            case "2":
                return t("Whole day");
            case "3":
                return t("Campaign specified");
        }
    };

    const switchType = (e) => {
        setSelectedTermType(e.target.value);
    };

    const switchCurrentInputType = (e) => {
        setCurrentInputType(e);
    };

    const addCurrent = () => {
        if (!validateFields()) return;

        let newTerm = {
            Type: currentInputType ? 1 : 0,
            Time: MeetingTime,
            IntervalFrom: currentInputType ? defaultAPITime : moment(state[0].startDate).format(),
            IntervalTo: currentInputType ? defaultAPITime : moment(state[0].endDate).format(),
            Day: currentInputType ? moment(Day).format() : defaultAPITime,
        };

        setProposedTerms((prevTerms) => [...prevTerms, newTerm]);
    };

    const removeFromProposed = (index) => {
        setProposedTerms((prevTerms) => prevTerms.filter((value, i) => i !== index));
    };

    const validateFields = () => {
        let errs = "";
        if (currentInputType) {
            if (!moment(state[0].startDate).isAfter(moment()) || !moment(state[0].endDate).isAfter(moment())) {
                errs += t("You cannot move a meeting before the current date.");
                console.log(state[0].startDate);
                console.log(state[0].endDate);
            }
        } else {
            // Date is after today's Date
            if (!moment(Day).isAfter(moment())) {
                errs += t("You cannot move a meeting before the current date.");
                console.log(Day);
            }
        }

        if (errs === "") {
            setErrorDisplay(false);
            return true;
        }

        //handleError({ data: { Message: errs }, status: 402 });
        setErrorDisplay(true);
        setErrorMessage(errs);

        return false;
    };

    // const isValidDate = (currentDate) =>
    //   !(
    //     currentDate.isAfter(moment()) &&
    //     // currentDate.isAfter(moment(this.props.meetingDetails.MeetingDate)) &&
    //     currentDate.isBefore(moment().add(13, "days"))
    //   );

    const footer = [
        <Button key="cancel" type="secondary" size="large" onClick={closeDialog}>
            <CloseCircleOutlined />
            {t("Close")}
        </Button>,
    ];

    return (
        <Modal
            width={modalWidth}
            footer={footer}
            title={t("Change meeting date request")}
            visible={visible}
            onCancel={closeDialog}
            style={{ top: "30px" }}
        >
            <Alert
                className="mb-4"
                type="warning"
                showIcon
                message={t("This service will be charged according to the price list")}
            />
            {meetingDetails && (
                <>
                    <Divider orientation="left">
                        <h2 style={{ fontWeight: "600", color: "#48a3d8" }}>{t("Meeting")}</h2>
                    </Divider>
                    <Descriptions bordered style={{ paddingBottom: "15px" }}>
                        <Descriptions.Item span={4} label={t("Meeting ID")}>
                            {meetingDetails.MeetingId}
                        </Descriptions.Item>
                        <Descriptions.Item span={4} label={t("Company Name")}>
                            {meetingDetails.Company}
                        </Descriptions.Item>
                        <Descriptions.Item span={4} label={t("Meeting Date")}>
                            {moment(meetingDetails.MeetingDate).format("DD.MM.YYYY hh:mm")}
                        </Descriptions.Item>
                        <Descriptions.Item span={4} label={t("Meeting Address")}>
                            {meetingDetails.MeetingPlace}
                        </Descriptions.Item>
                    </Descriptions>
                </>
            )}
            {messageSent ? (
                <Alert
                    message={t("The request to move meeting date has been sent")}
                    description={t("Our sales department will contact you shortly")}
                    type="success"
                    showIcon
                />
            ) : (
                <React.Fragment>
                    <Divider orientation="left">
                        <h2 style={{ fontWeight: "600", color: "#48a3d8" }}>{t("Required Term")}</h2>
                    </Divider>
                    {/* --- Type Switch ---  */}

                    <div style={{ marginLeft: "20px" }}>
                        <Radio.Group
                            label={t("Date")}
                            buttonStyle="solid"
                            id="SelectedTermType"
                            defaultValue="0"
                            size="medium"
                            onChange={(e) => switchType(e)}
                            style={{ paddingBottom: "10px" }}
                        >
                            <Radio.Button style={{ width: "137px", textAlign: "center" }} value="0">
                                {t("By TSS")}
                            </Radio.Button>
                            <Radio.Button style={{ width: "137px", textAlign: "center" }} value="1">
                                {t("Manually")}
                            </Radio.Button>
                        </Radio.Group>

                        {/* --- Intervals output ---  */}
                        {selectedTermType == 1 ? (
                            <React.Fragment>
                                <div>
                                    <Switch
                                        style={{ width: "274px" }}
                                        checkedChildren={t("  DAY ")}
                                        unCheckedChildren={t("INTERVAL")}
                                        onChange={(e) => switchCurrentInputType(e)}
                                    />
                                    <br />
                                    <div
                                        style={{
                                            //display: "inline-flex",
                                            flexWrap: "wrap",
                                            gap: "10px",
                                            padding: "10px 0",
                                        }}
                                    >
                                        {currentInputType ? (
                                            /* --- Day input ---  */

                                            <Calendar
                                                {...formRangeControl}
                                                locale={store.getState().Logic.language == "cs" ? cs : enGB}
                                                date={Day}
                                                onChange={(item) => setDay(item)}
                                                color={"#48a3d8"}
                                            />
                                        ) : (
                                            /* --- Interval input ---  */
                                            <DateRange
                                                {...formRangeControl}
                                                locale={store.getState().Logic.language == "cs" ? cs : enGB}
                                                value={Date}
                                                defaultValue={Date}
                                                editableDateInputs={false}
                                                onChange={(item) => setState([item.selection])}
                                                moveRangeOnFirstSelection={false}
                                                ranges={state}
                                                weekStartsOn={1}
                                                rangeColors={["#48a3d8"]}
                                                dateDisplayFormat="d. MMM, yyy"
                                            />
                                        )}
                                        <br />
                                        <br />
                                        <Select
                                            style={{ width: "160px" }}
                                            defaultValue="0"
                                            onChange={(e) => setMeetingTime(e)}
                                        >
                                            <Select.Option value="0">{t("Morning")}</Select.Option>
                                            <Select.Option value="1">{t("Afternoon")}</Select.Option>
                                            <Select.Option value="2">{t("Whole day")}</Select.Option>
                                            <Select.Option value="3">{t("Campaign specified")}</Select.Option>
                                        </Select>
                                        <Button type="primary" onClick={(e) => addCurrent(e)}>
                                            <PlusCircleOutlined />
                                            {t("Add")}
                                        </Button>
                                    </div>
                                </div>
                                <h2 style={{ paddingTop: "10px" }}>{t("Selected terms")}</h2>
                                {proposedTerms.length == 0 && (
                                    <p style={{ paddingTop: "10px" }}>{t("No selected term")}</p>
                                )}

                                {proposedTerms.map((term, index) => (
                                    <>
                                        <Descriptions key={index} layout={layout} className="mobile-change-list">
                                            <Descriptions.Item>
                                                {term.Type
                                                    ? moment(term.Day).format("DD.MM.YYYY")
                                                    : moment(term.IntervalFrom).format("DD.MM.YYYY") +
                                                      " - " +
                                                      moment(term.IntervalTo).format("DD.MM.YYYY")}
                                                {" " + displayMeetingTime(term.Time) + " "}
                                                <Button
                                                    size="small"
                                                    onClick={() => {
                                                        removeFromProposed(index);
                                                    }}
                                                >
                                                    <CloseCircleOutlined />
                                                </Button>
                                            </Descriptions.Item>
                                        </Descriptions>

                                        <Descriptions key={index} layout={layout} className="desktop-change-list">
                                            <Descriptions.Item style={{ width: "100px" }}>
                                                {term.Type
                                                    ? moment(term.Day).format("DD.MM.YYYY")
                                                    : moment(term.IntervalFrom).format("DD.MM.YYYY") +
                                                      " - " +
                                                      moment(term.IntervalTo).format("DD.MM.YYYY")}
                                            </Descriptions.Item>
                                            <Descriptions.Item style={{ width: "100px" }}>
                                                {displayMeetingTime(term.Time)}
                                            </Descriptions.Item>
                                            <Descriptions.Item style={{ width: "100px" }}>
                                                <Button
                                                    onClick={() => {
                                                        removeFromProposed(index);
                                                    }}
                                                >
                                                    <CloseCircleOutlined />
                                                </Button>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </>
                                ))}
                            </React.Fragment>
                        ) : null}
                    </div>

                    <div className="mb-4">{errorDisplay && <ErrorView display="all" message={errorMessage} />}</div>
                    <Form //onSubmit={handleSubmit}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Divider orientation="left">
                            <h2 style={{ fontWeight: "600", color: "#48a3d8" }}>{t("Contact Info")}</h2>
                        </Divider>
                        <Descriptions bordered>
                            <Descriptions.Item
                                span={4}
                                label={t("Name")}
                                name="UserName"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please enter your name"),
                                    },
                                ]}
                                initialValue={userData.DisplayName}
                            >
                                <Input
                                    style={{ height: "40px" }}
                                    disabled={userData ? false : true}
                                    required
                                    type="text"
                                    name="UserName"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item
                                span={4}
                                label={t("Email")}
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please enter your email"),
                                    },
                                ]}
                                initialValue={userData.Email}
                            >
                                <Input
                                    style={{ height: "40px" }}
                                    disabled={userData ? false : true}
                                    required
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Descriptions.Item>

                            <Descriptions.Item
                                span={4}
                                label={t("Note")}
                                name="Message"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please enter your message"),
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    required
                                    rows={4}
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                        <Form.Item>
                            <Button
                                key="submit"
                                //loading={this.state.reclamationSent}
                                type="primary"
                                size="large"
                                style={{ marginTop: "20px", float: "right" }}
                                htmlType="submit"
                                //onClick={(e) => handleSubmit(e)}
                            >
                                <SendOutlined />
                                {t("Send")}
                            </Button>
                        </Form.Item>
                    </Form>
                </React.Fragment>
            )}
        </Modal>
    );
};

export default ChangeMeetingDateModal;
