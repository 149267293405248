import { useState, useEffect } from "react";
import AmaxApiService from "./services/AmaxApiService";
import { store } from "./components/providers/ReduxProvider";
//Router
import { Switch, Route } from "react-router-dom";
//Components
import Loading from "./components/common/Loading";
import LoginGate from "./components/common/LoginGate";
//Pages
import NotFound from "./pages/404";
import NotAuthorized from "./pages/notauthorized";
import Home from "./pages/index";
import ReportSchuzky from "./pages/reportschuzky";
import Telemarketing from "./pages/telemarketing";
import Telesales from "./pages/telesales";
import HodnoceniSchuzky from "./pages/hodnocenischuzky";
import Settings from "./pages/settings";
import Conditions from "./pages/obchodnipodminky";
import User from "./pages/userinfo";
import ResetPassword from "./pages/reset";
import Forgotten from "./pages/forgotten";
import Storno from "./pages/storno";
import Rejected from "./pages/reportodmitnute";
import BlackList from "./pages/blacklist";
//Actions
import { setPermisions } from "./store/actions";

function App() {
    const [loading, setLoading] = useState(true);
    const [permisionResponse, setPermisionResponse] = useState(store.getState().Logic.permisions);

    console.log("App", permisionResponse);
    const getPermision = async () => {
        AmaxApiService.Account.Permission().then((response) => {
            setPermisionResponse(response);
            store.dispatch(setPermisions(response));
        });
    };

    useEffect(() => {
        //loads user permisions if not loaded
        setLoading(false);
        if (!permisionResponse) {
            getPermision();
        }
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <Switch>
            <Route path="/login">
                <LoginGate location="/">
                    <Home />
                </LoginGate>
            </Route>
            <Route exact path="/">
                <Home />
            </Route>

            <Route path="/meetings">
                {permisionResponse ? permisionResponse.ReportMeeting ? <ReportSchuzky /> : <NotAuthorized /> : null}
            </Route>

            <Route path="/telemarketing">
                {permisionResponse ? permisionResponse.Telemarketing ? <Telemarketing /> : <NotAuthorized /> : null}
            </Route>

            <Route path="/telesales">
                {permisionResponse ? permisionResponse.Telesale ? <Telesales /> : <NotAuthorized /> : null}
            </Route>

            <Route path="/hodnocenischuzky">
                {permisionResponse ? permisionResponse.Rating ? <HodnoceniSchuzky /> : <NotAuthorized /> : null}
            </Route>

            <Route path="/cancel">
                {permisionResponse ? permisionResponse.Cancel ? <Storno /> : <NotAuthorized /> : null}
            </Route>

            {/*!!!!!!!!!!!!!!!!!!!!!!!!*/}
            {/*<Route path="/telemarketing">
        <Telemarketing />
      </Route>

      <Route path="/telesales">
       <Telesales />
      </Route>

      <Route path="/hodnocenischuzky">
        <HodnoceniSchuzky />
      </Route>

      <Route path="/cancel" >
        <Storno />
      </Route>*/}
            {/*!!!!!!!!!!!!!!!!!!!!!!!!*/}

            {/*<Route path="/rejected" 
      >
        {true ? <Rejected /> : <NotAuthorized />}
    </Route>*/}

            {/*<Route path="/blacklist"
      >
        {true ? <BlackList /> : <NotAuthorized />}
      </Route>*/}

            <Route path="/obchodnipodminky">
                <Conditions />
            </Route>
            <Route path="/settings">
                <Settings />
            </Route>
            <Route path="/user">
                <User />
            </Route>
            <Route path="/reset">
                <ResetPassword />
            </Route>
            <Route path="/forgotten/:token">
                <Forgotten />
            </Route>
            <Route>
                <NotFound />
            </Route>
        </Switch>
    );
}

export default App;
