import React, { memo, useState, useEffect } from "react";
import AmaxApiService from "../services/AmaxApiService";
import { store } from "../components/providers/ReduxProvider";
//Antd
import { PageHeader, Button, Tooltip } from "antd";
//Layout
import LayoutWrapper from "../components/layouts/LayoutWrapper";
//Components
import TelemarketingForm from "../components/common/forms/TelemarketingForm";
import TelemarketingTable from "../components/common/tables/TelemarketingTable";
import ErrorView from "../components/common/ErrorView";
//Language
import t from "../components/providers/LanguageProvider";

import HelpTelemarketing from "../components/common/Modals/HelpTelemarketing";

const TelemarketingPage = () => {
    const [displayHelp, setDisplayHelp] = useState(false);

    const closeHelp = () => {
        setDisplayHelp(false);
    };

    const handleHelp = () => {
        setDisplayHelp(true);
    };

    return (
        <>
            <HelpTelemarketing key="Help" closeDialog={() => closeHelp()} visible={displayHelp} />
            <LayoutWrapper>
                <PageHeader
                    onBack={() => {
                        window.location = "/";
                    }}
                    title={
                        <>
                            <Tooltip placement="leftBottom" title={t("Help")}>
                                {t("Telemarketing")}
                                <Button
                                    className="mobile-help"
                                    type="secondary"
                                    size="small"
                                    style={{ marginLeft: "10px" }}
                                    onClick={() => handleHelp()}
                                >
                                    ?
                                </Button>
                            </Tooltip>
                        </>
                    }
                    className="ant-page-header-half-radius ant-page-header-boxShadow"
                    extra={[
                        <Tooltip placement="leftBottom" title={t("Help")}>
                            <Button className="desktop-help" size="small" onClick={() => handleHelp()}>
                                ?
                            </Button>
                        </Tooltip>,
                    ]}
                >
                    <TelemarketingForm />
                    <ErrorView type="main" />
                </PageHeader>
                <TelemarketingTable />
            </LayoutWrapper>
        </>
    );
};

export default memo(TelemarketingPage);
