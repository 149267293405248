import React, { useEffect } from "react";
//Redux
import { Provider } from "react-redux";
import initializeStore from "../../store";
//Types
import PropTypes from "prop-types";

export const store = initializeStore();

const ReduxProvider = ({ children }) => {
    useEffect(() => {
        const spinner = window.document.getElementsByClassName("spinner")[0];

        if (spinner && spinner.style.display !== "none") {
            spinner.style.display = "none";
        }
    });

    return <Provider store={store}>{children}</Provider>;
};

ReduxProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]).isRequired,
};

export default ReduxProvider;
