import React from "react";
//Router
import { Link } from "react-router-dom";
//Antd
import { Button, PageHeader } from "antd";
//Layout
import LayoutWrapper from "../components/layouts/LayoutWrapper";
//Language
import t from "../components/providers/LanguageProvider";
//Images
import image from "../images/404.svg";

const Error404Page = () => {
    return (
        <LayoutWrapper>
            <PageHeader
                onBack={() => {
                    window.location = "/";
                }}
                extra={[]}
            >
                <div style={{ paddingBottom: "30px", paddingTop: "20px" }}>
                    <p style={{ fontSize: "24px", paddingBottom: "20px" }}>
                        {t("We apologise, but the page could not be found")}
                    </p>
                    <img style={{ paddingBottom: "40px" }} src={image} alt="404" />
                    <p style={{ fontSize: "20px", paddingBottom: "20px" }}>{t("Try going back to homepage.")}</p>
                    <Button type="primary" size="large">
                        <Link to="/">
                            <span>{t("Home")}</span>
                        </Link>
                    </Button>
                </div>
            </PageHeader>
        </LayoutWrapper>
    );
};

export default Error404Page;
