import React, { memo, useState, useEffect } from "react";
import AmaxApiService from "../../services/AmaxApiService";
import { store } from "../../components/providers/ReduxProvider";
//Types
import PropTypes from "prop-types";
//Redux
import { connect } from "react-redux";
//Router
import { Link, useLocation } from "react-router-dom";
import Footer from "rc-footer";
//Antd
import { Layout, Menu } from "antd";

import {
    ScheduleOutlined,
    MenuOutlined,
    HomeOutlined,
    PhoneOutlined,
    DollarOutlined,
    LikeOutlined,
    CloseCircleOutlined,
    InstagramOutlined,
    FacebookOutlined,
    YoutubeOutlined,
    MailOutlined,
    EnvironmentOutlined,
    ShoppingOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
//Components
import AccountInfo from "../common/AccountInfo";
//Imgs
import Logo from "../../images/logo-only.png";
import amax from "../../images/amax-footer.svg";
import oldPortal from "../../images/oldportal-footer.svg";
import dataPic from "../../images/data-footer.svg";
//Language
import t from "../providers/LanguageProvider";

import { setPermisions } from "../../store/actions";

const MobileLayout = ({ children }) => {
    const location = useLocation();
    const [permisionResponse, setPermisionResponse] = useState(null);

    const [toggle, setToggle] = useState(false);
    const [selectedItem, setSelectedItem] = useState([location.pathname]);

    const { Header, Content } = Layout;

    const getPermision = async () => {
        await AmaxApiService.Account.Permission()
            .then((response) => {
                setPermisionResponse(response);
                store.dispatch(setPermisions(response));
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        //loads user permisions if not loaded
        if (store.getState().Logic.permisions) {
            setPermisionResponse(store.getState().Logic.permisions);
        } else {
            getPermision();
        }
    }, []);

    return (
        <>
            <Layout>
                <div className="mobile-menu">
                    <Header className="mobile-header">
                        <button className="btn-burger" onClick={() => setToggle(!toggle)}>
                            <MenuOutlined />
                        </button>
                        <div className="logo-mobile">
                            <Link to="/">
                                <img src={Logo} alt="ax logo" />
                            </Link>
                        </div>
                        <AccountInfo style={{ position: "absolute" }} />
                    </Header>
                    {permisionResponse ? (
                        <Menu
                            className="nav mobile-nav"
                            mode="inline"
                            defaultSelectedKeys={["/"]}
                            selectedKeys={selectedItem}
                            style={toggle ? { display: "block" } : { display: "none" }}
                        >
                            <Menu.Item>
                                <Link to="/">
                                    <HomeOutlined className="ant-color and-menu-size" />
                                    <span>{t("Home")}</span>
                                </Link>
                            </Menu.Item>

                            {permisionResponse.ReportMeeting && (
                                <Menu.Item>
                                    <Link to="/meetings">
                                        <ScheduleOutlined className="ant-color and-menu-size" />
                                        <span>{t("Report Meetings")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {permisionResponse.Telemarketing && (
                                <Menu.Item>
                                    <Link to="/telemarketing">
                                        <PhoneOutlined className="ant-color and-menu-size" />
                                        <span>{t("Telemarketing")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {permisionResponse.Telesale && (
                                <Menu.Item>
                                    <Link to="/telesales">
                                        <ShoppingOutlined className="ant-color and-menu-size" />
                                        <span>{t("Telesales")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {permisionResponse.Rating && (
                                <Menu.Item>
                                    <Link to="/hodnocenischuzky">
                                        <LikeOutlined className="ant-color and-menu-size" />
                                        <span>{t("Meetings Evaluation")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {permisionResponse.Cancel && (
                                <Menu.Item>
                                    <Link to="/cancel">
                                        <CloseCircleOutlined className="ant-color and-menu-size" />
                                        <span>{t("Cancellation")}</span>
                                    </Link>
                                </Menu.Item>
                            )}
                            {/*<Menu.Item>
            <Link to="/blacklist">
              <DeleteOutlined className="ant-color and-menu-size"/>
              <span>{t("Blacklist")}</span>
            </Link>
          </Menu.Item>
        */}
                        </Menu>
                    ) : (
                        <Menu
                            className="nav mobile-nav"
                            mode="inline"
                            defaultSelectedKeys={["/"]}
                            selectedKeys={selectedItem}
                            style={toggle ? { display: "block" } : { display: "none" }}
                        >
                            <Menu.Item>
                                <Link to="/">
                                    <HomeOutlined className="ant-color and-menu-size" />
                                    <span>{t("Home")}</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    )}
                </div>
                <Layout className="site-layout" style={{ position: "relative", top: "80px" }}>
                    <Content className="site-layout-content">{children}</Content>
                </Layout>
            </Layout>
            <Footer
                className="tablet-version" //small version
                style={{ marginTop: "90px !important" }}
                backgroundColor="#282d32"
                maxColumnsPerRow={2}
                columns={[
                    {
                        items: [
                            {
                                title: (
                                    <div className="bottom-padding">
                                        <Link to="http://amaxsro.cz/">
                                            <img
                                                style={{ marginRight: "10px", height: "14px" }}
                                                src={amax}
                                                alt="amax"
                                            />
                                        </Link>
                                        <Link to="http://portal.amaxsro.cz/">
                                            <img
                                                style={{ paddingRight: "10px", height: "14px" }}
                                                src={oldPortal}
                                                alt="old-portal"
                                            />
                                        </Link>
                                        <Link to="https://data.amaxsro.cz/">
                                            <img
                                                style={{ paddingRight: "10px", height: "14px" }}
                                                src={dataPic}
                                                alt="data"
                                            />
                                        </Link>
                                    </div>
                                ),
                            },
                        ],
                    },
                    {
                        items: [
                            {
                                title: "+420 544 529 959",
                                url: "tel://+420544529959",
                                icon: <PhoneOutlined className="ant-color contact-icons" />,
                            },
                            {
                                title: " amax@amaxsro.cz",
                                url: "mailto:amax@amaxsro.cz",
                                icon: <MailOutlined className="ant-color contact-icons" />,
                            },
                            {
                                title: "Orlí 17, 602 00 Brno",
                                openExternal: true,
                                url: "https://www.google.com/maps/place/Orl%C3%AD+17,+602000+Brno",
                                icon: <EnvironmentOutlined className="ant-color contact-icons" />,
                            },
                        ],
                    },
                    {
                        items: [
                            {
                                title: (
                                    <div className="top-padding">
                                        <Link to="https://www.instagram.com/amax_telemarketing/">
                                            <InstagramOutlined
                                                style={{ paddingRight: "25px" }}
                                                className="ant-color social-icons"
                                            />
                                        </Link>
                                        <Link to="https://www.facebook.com/amaxsro/">
                                            <FacebookOutlined
                                                style={{ paddingRight: "25px" }}
                                                className="ant-color social-icons"
                                            />
                                        </Link>
                                        <Link to="https://www.youtube.com/channel/UCWrSZpw8dwV8fn9uhuhH42Q">
                                            <YoutubeOutlined className="ant-color social-icons" />
                                        </Link>
                                    </div>
                                ),
                            },
                        ],
                    },
                ]}
            />

            <Footer backgroundColor="#282d32" bottom="AMAX, s.r.o. © 2022" />
        </>
    );
};

MobileLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps)(memo(MobileLayout));

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi };
}
