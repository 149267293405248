import React from "react";
//Leaflet
import L from "leaflet";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export default function LeafletMap(props) {
    const tempPosition = [0, -0];

    let DefaultIcon = L.icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [10, 41],
        popupAnchor: [2, -40],
    });

    L.Marker.prototype.options.icon = DefaultIcon;

    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    // Check if enviroment === browser
    // leaflet doesn't check if DOM is avaible
    // hence server-side errors with window not defined
    if (typeof window !== "undefined")
        return (
            <Map
                center={props.position ? props.position : tempPosition}
                zoom={17}
                gestureHandling={true}
                maxZoom={19}
                easeLinearity={0.35}
                attributionControl={true}
            >
                <TileLayer
                    url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
                {props.position ? (
                    <Marker position={props.position}>
                        <Popup>{props.text}</Popup>
                    </Marker>
                ) : null}
            </Map>
        );
    return null;
}
