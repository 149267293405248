import React from "react";
import Chart from "react-apexcharts";

export default class Pie extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            series: this.props.chartSeries,
            options: {
                chart: {
                    width: 200,
                    type: "pie",
                    id: "apexchart-example",
                },
                labels: this.props.chartLabels,
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
        };
    }

    render() {
        return (
            <div>
                <Chart options={this.state.options} series={this.state.series} type="pie" height={250} />
            </div>
        );
    }
}
