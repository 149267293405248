import React, { useState, useEffect } from "react";
//Redux
import { store } from "../../providers/ReduxProvider";
import { setSearchQuery, setUserTelesalesForm } from "../../../store/actions";
import AmaxApiService from "../../../services/AmaxApiService";
import { resetErrorView } from "../../../services/ErrorHandler";
//Antd
import { Button, Form, Select, Radio, Collapse, Popover, Input, Tooltip } from "antd";
import { SearchOutlined, DownOutlined, UpOutlined, FilterFilled, DownCircleTwoTone } from "@ant-design/icons";
//Moment
import moment from "moment";
//Language
import t from "../../providers/LanguageProvider";
//DateRange and DefinedRange
import { DefinedRange, DateRange } from "react-date-range";
import { defineds, customRanges } from "../DateRange";
import { addDays, startOfWeek, getDate, getMonth, getYear, differenceInDays } from "date-fns";
// locale for czech calendar
import { cs, enGB } from "date-fns/locale";

const TelesalesForm = () => {
    const [form] = Form.useForm();

    const [campaigns, setCampaigns] = useState(null);
    const [TssIds, setTssIds] = useState(
        store.getState().Logic.telesalesSelectedTss ? store.getState().Logic.telesalesSelectedTss : []
    );
    const [MeetingType, setMeetingType] = useState("ContactDate");

    const [CancellationStatus, setCancelStatus] = useState(
        store.getState().Logic.telesalesDisplay ? store.getState().Logic.telesalesDisplay : 0
    );

    const [date, setDate] = useState([
        {
            startDate: addDays(startOfWeek(new window.Date()), 1),
            endDate: addDays(startOfWeek(new window.Date()), 7),
            key: "selection",
        },
    ]);
    const [badDateRange, setBadDateRange] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(true);

    const setCampaignsIds = (campaignID) => {
        if ((campaignID == 0 || campaignID.indexOf(0) >= 0) && campaigns) {
            let ids = [];
            campaigns.map((campaign) => {
                ids.push(campaign.CampaignId);
            });
            setTssIds(ids);
        } else {
            setTssIds(campaignID);
        }
    };

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const formCampaignLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "300px",
        },
    };

    const formRangeControl = {
        style: {
            width: "auto",
        },
    };

    /**
     * Evaluate priorities on campaign change
     *
     * @param {Integer} campaignID
     */
    {
        /*const evaluatePriorities = (campaignID) => {
    if (!campaignID) return null;

    // Load priorities (for campain) to state
    AmaxApiService.Telesales.Priorities(campaignID).then((priorities) => {
      setPriorities(priorities);
    });
  */
    }

    /**
     * Runs on form submit, evaluates fields and sets SearchQuery
     *
     * @param {Object} values
     */
    const onFinish = (values) => {
        resetErrorView();
        console.log("Received values of form: ", values);

        setBadDateRange(false);

        if (differenceInDays(date[0].endDate, date[0].startDate) > 91) {
            setBadDateRange(true);
        }
        if (TssIds.length < 1) {
            setSelectedCampaign(false);
        } else {
            values = {
                ...values,
                MeetingType: MeetingType,
                Date: date,
                CancellationStatus: CancellationStatus,
            };
            let searchArray = [];
            TssIds.map((oneTss) => {
                values = { ...values, TssId: oneTss };
                searchArray.push(values);
            });
            console.log("Received values of form after edit: ", searchArray);
            store.dispatch(setSearchQuery("TELESALES", searchArray));
            setVisibleFilter(!visibleFilter);
            setOpenCalendar(false);
            store.dispatch(setUserTelesalesForm(TssIds, CancellationStatus, date));
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    useEffect(() => {
        resetErrorView();

        if (store.getState().Logic.telesalesDate) {
            if (typeof store.getState().Logic.telesalesDate[0].startDate != "string") {
                setDate(store.getState().Logic.telesalesDate);
            } else {
                const storedDate = new Date(store.getState().Logic.telesalesDate[0].startDate);
                const endDate = new Date(store.getState().Logic.telesalesDate[0].endDate);
                setDate([
                    {
                        startDate: storedDate.getTime(),
                        endDate: endDate.getTime(),
                        key: "selection",
                    },
                ]);
            }
        }

        AmaxApiService.Telesales.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (differenceInDays(date[0].endDate, date[0].startDate) > 91) {
            setBadDateRange(true);
        } else {
            setBadDateRange(false);
        }
    }, [date]);

    const [visibleFilter, setVisibleFilter] = useState(true);
    const [visible, setVisible] = useState(false); // visibility of popup range
    const [openCalendar, setOpenCalendar] = useState(false); // visibility of popup range
    const range = customRanges();

    const onCampaignChange = (campaignID) => {
        if (campaignID.indexOf(0) >= 0) {
            let campaignsIds = [];
            campaigns.map((one) => {
                campaignsIds.push(one.CampaignId);
            });
            form.setFieldsValue({ TssId: campaignsIds });
        }
    };

    return (
        <>
            <div>
                <button className="filter-button" onClick={() => setVisibleFilter(!visibleFilter)}>
                    {visibleFilter ? (
                        <h3>
                            {t("Hide Filter ")}
                            <UpOutlined />
                        </h3>
                    ) : (
                        <h3>
                            {t("Filter Telesales ")} <FilterFilled />
                        </h3>
                    )}
                </button>

                {visibleFilter && (
                    <Form
                        form={form}
                        className="form"
                        onChange={(event) => {
                            console.log(event.target.name + ":" + event.target.value);
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            //TssId: TssIds,
                            Date: date,
                            CancellationStatus: CancellationStatus,
                            TssId: TssIds,
                        }}
                    >
                        <div className="form-div">
                            <Form.Item
                                validateStatus={selectedCampaign ? "success" : "error"}
                                {...formCampaignLayout}
                                label={t("Campaign")}
                                name="TssId"
                                rules={[{ required: true, message: t("Please select a Campaign") }]}
                            >
                                <Select
                                    className="campaign-multiple"
                                    maxTagCount={4}
                                    //showArrow={true}
                                    //suffixIcon={<DownCircleTwoTone />}
                                    showSearch
                                    mode="multiple"
                                    allowClear
                                    value={TssIds}
                                    defaultValue={TssIds}
                                    values={TssIds}
                                    placeholder={t("Select Campaigns")}
                                    optionFilterProp="children"
                                    defaultActiveFirstOption
                                    loading={campaigns ? false : true}
                                    disabled={campaigns ? false : true}
                                    onChange={(campaignID) => {
                                        if (campaignID.length == 0) {
                                            setCampaignsIds([]);
                                            setSelectedCampaign(false);
                                        } else {
                                            console.log(campaignID);
                                            setCampaignsIds(campaignID);
                                            setSelectedCampaign(true);
                                            onCampaignChange(campaignID);
                                        }
                                    }}
                                >
                                    <Select.Option value={0} style={{ background: "Gainsboro" }}>
                                        {t("Select all Campaigns")}
                                    </Select.Option>
                                    {campaigns
                                        ? campaigns.map((campaign) => (
                                              <Select.Option
                                                  selected
                                                  key={campaign.CampaignId}
                                                  value={campaign.CampaignId}
                                              >
                                                  {campaign.CampaignName}
                                              </Select.Option>
                                          ))
                                        : null}
                                </Select>
                            </Form.Item>

                            <br />
                            <Form.Item {...formItemLayout} label={t("Display")} name="CancellationStatus">
                                <Radio.Group
                                    className="radio-button-responsive"
                                    buttonStyle="outline"
                                    onChange={(e) => setCancelStatus(e.target.value)}
                                    defaultValue={CancellationStatus}
                                >
                                    <Radio.Button className="radio-button-responsive" selected value={0}>
                                        {t("All")}
                                    </Radio.Button>
                                    <br />
                                    <Radio.Button className="radio-button-responsive" value={1}>
                                        {t("Not Cancelled")}
                                    </Radio.Button>
                                    <br />
                                    <Radio.Button className="radio-button-responsive" value={2}>
                                        {t("Cancelled ")}
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <div className="left-padding">
                            <Form.Item
                                className="form-advanced"
                                {...formItemLayout}
                                label={t("Contact date")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please choose a date"),
                                    },
                                ]}
                            >
                                <div className="left-column-calendar">
                                    <div className="date-range-picker-mobile">
                                        <Popover
                                            trigger="click"
                                            placement="bottom"
                                            arrowPointAtCenter={true}
                                            onClick={() => setVisible(!visible)}
                                            visible={visible}
                                            content={
                                                <div onClick={() => setVisible(!visible)}>
                                                    <DefinedRange
                                                        onChange={(item) => setDate([item.selection])}
                                                        ranges={date}
                                                        inputRanges={[]}
                                                        staticRanges={range}
                                                        rangeColors={["#4897d8"]}
                                                    />
                                                </div>
                                            }
                                        >
                                            <button className="popup">{t("Quick Select")}</button>
                                        </Popover>
                                    </div>

                                    <DateRange
                                        className={badDateRange ? "red" : ""}
                                        {...formRangeControl}
                                        locale={store.getState().Logic.language == "cs" ? cs : enGB}
                                        value={date}
                                        defaultValue={date}
                                        editableDateInputs={false}
                                        onChange={(item) => setDate([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={date}
                                        weekStartsOn={1}
                                        rangeColors={["#48a3d8"]}
                                        dateDisplayFormat="d. MMM, yyy"
                                        minDate={defineds.minDateOfCalendar}
                                        maxDate={defineds.maxDateOfCalendar}
                                    />
                                </div>

                                <div className="right-column-calendar">
                                    <DefinedRange
                                        className="date-range-picker-desktop"
                                        {...formRangeControl}
                                        onChange={(item) => setDate([item.selection])}
                                        ranges={date}
                                        inputRanges={[]}
                                        staticRanges={range}
                                        rangeColors={["#4897d8"]}
                                    />
                                </div>
                            </Form.Item>
                        </div>

                        <Form.Item {...formItemLayout}>
                            {badDateRange && (
                                <h3 style={{ display: "flex", paddingRight: "5px", color: "#DC143C" }}>
                                    {t("Warning! The term must be less than 3 months!")}
                                </h3>
                            )}
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SearchOutlined style={{ paddingRight: "5px" }} />}
                            >
                                <b>{t("Display")}</b>
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </>
    );
};

export default TelesalesForm;
