export default function SearchQuery(
    // Default Initial Store State
    state = {
        formName: null,
        query: null,
        result: null,
        status: null,
    },
    action
) {
    switch (action.type) {
        case "SET_SEARCH_QUERY":
            return Object.assign({}, state, {
                formName: action.formName,
                query: action.query,
                status: "QUEUED",
            });
        case "RESET_SEARCH_QUERY":
            return Object.assign({}, state, {
                query: null,
            });
        case "SET_SEARCH_RESULT":
            return Object.assign({}, state, {
                formName: action.formName,
                status: action.status,
                result: action.data,
            });

        default:
            return state;
    }
}
