export default class StateLoader {
    loadState(initialState = null) {
        try {
            let serializedState = localStorage.getItem("newAmaxPortal:state");

            if (serializedState === null) {
                return this.initializeState(initialState);
            }

            return JSON.parse(serializedState);
        } catch (err) {
            return this.initializeState(initialState);
        }
    }

    saveState(state) {
        try {
            let serializedState = JSON.stringify(
                Object.assign({}, state, {
                    Logic: Object.assign({}, state.Logic, {
                        loading: false,
                        error: false,
                    }),
                    SearchQuery: Object.assign({}, state.SearchQuery, {
                        query: null, // do not save query to local memory
                        result: null, // do not save results in to local memory
                    }),
                })
            );

            localStorage.setItem("newAmaxPortal:state", serializedState);
        } catch (err) {}
    }

    initializeState(initialState) {
        console.log(initialState);
        return initialState;
    }
}
