import React, { useState, useEffect } from "react";
//Router
import { Link } from "react-router-dom";
//Redux
import { store } from "../providers/ReduxProvider";
import { connect } from "react-redux";
import { setDisconnected } from "../../store/actions";
import AmaxApiService from "../../services/AmaxApiService";
//Antd
import { Avatar, Popover, Button, Form, Select } from "antd";
import { UserOutlined, SettingOutlined, LogoutOutlined, BookOutlined } from "@ant-design/icons";

//
//Language
import t from "../providers/LanguageProvider";
//Icons
import czIcon from "../../icons/flagIcons/czech-republic.svg";
import enIcon from "../../icons/flagIcons/united-kingdom.svg";

import { setLanguage } from "../../store/actions";

import manual from "../common/manual/manual.pdf";

const iconStyle = {
    height: "17px",
    display: "inline-block",
    marginRight: "10px",
};

const AccountInfo = ({ AmaxApi, settings }) => {
    const handleButtonClick = () => {
        store.dispatch(setDisconnected());
    };

    const [userData, setUserData] = useState(null);
    const getInfo = async () => {
        AmaxApiService.Account.UserInfo().then((response) => {
            setUserData(response);
        });
    };
    useEffect(() => {
        getInfo();
    }, []);

    const popContent = (
        <div>
            <Link to="/user">
                <p>
                    <UserOutlined />
                    <span style={{ paddingLeft: "10px" }}>{t("Profile")}</span>
                </p>
            </Link>
            <Link to="/settings">
                <p>
                    <SettingOutlined />
                    <span style={{ paddingLeft: "10px" }}>{t("Settings")}</span>
                </p>
            </Link>
            <Link to={manual} target="_blank" download>
                <p>
                    <BookOutlined />
                    <span style={{ paddingLeft: "10px" }}>{t("Manual")}</span>
                </p>
            </Link>
            <Link onClick={handleButtonClick} to="">
                <p>
                    <LogoutOutlined />
                    <span style={{ paddingLeft: "10px" }}>{t("Log out")}</span>
                </p>
            </Link>

            {/*<Select
        onChange={changeLanguage}
        key="Language"
        defaultValue={settings.language}
      >
        <Select.Option value="cs">
          <img src={czIcon} style={iconStyle} />
        </Select.Option>
        <Select.Option value="en">
          <img src={enIcon} style={iconStyle} />
        </Select.Option>
      </Select>*/}
        </div>
    );

    if (AmaxApi.connected) {
        return (
            <>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {userData ? (
                        <div className="user-name-head" style={{ paddingRight: "10px" }}>
                            {userData.FirstName + " " + userData.LastName}
                        </div>
                    ) : (
                        <div></div>
                    )}
                    <Popover trigger="click" placement="leftBottom" content={popContent} title={AmaxApi.user}>
                        <Avatar
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            {AmaxApi.user.slice(0, 1).toUpperCase()}
                        </Avatar>
                    </Popover>
                </div>
            </>
        );
    } else return null;
};

export default connect(mapStateToProps)(AccountInfo);

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi, settings: state.Logic };
}
