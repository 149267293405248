import React, { useState, useEffect } from "react";
//Redux
import { store } from "../../providers/ReduxProvider";
import { setSearchQuery, setUserTelemarketingForm } from "../../../store/actions";
import AmaxApiService from "../../../services/AmaxApiService";
import { resetErrorView } from "../../../services/ErrorHandler";
//Antd
import { Button, Popover, Form, Select, Radio, Collapse } from "antd";
import { SearchOutlined, UpOutlined, DownOutlined, FilterFilled } from "@ant-design/icons";
//Moment
import moment from "moment";
//Language
import t from "../../providers/LanguageProvider";
//DateRange and DefinedRange
import { DefinedRange, DateRange } from "react-date-range";
import { defineds, customRanges } from "../DateRange";
import { addDays, startOfWeek, differenceInDays } from "date-fns";
// locale for czech calendar
import { cs, enGB } from "date-fns/locale";

const TelemarketingForm = () => {
    const [campaigns, setCampaigns] = useState(null);
    const [priorities, setPriorities] = useState(null);
    const [PriorityId, setPriorityId] = useState(
        store.getState().Logic.telemarketingPriority ? store.getState().Logic.telemarketingPriority : 0
    );
    const [TssId, setTssId] = useState(
        store.getState().Logic.telemarketingSelectedTss ? store.getState().Logic.telemarketingSelectedTss : null
    );

    const [MeetingType, setMeetingType] = useState("contactDate");
    //const [ReportedStatus, setReportedStatus] = useState(store.getState().Logic.telemarketingDisplay ? store.getState().Logic.telemarketingDisplay : 2);
    const [ReportedStatus, setReportedStatus] = useState(2);
    const [ContactResultIds, setContactResultIds] = useState(
        store.getState().Logic.telemarketingQuestion ? store.getState().Logic.telemarketingQuestion : 4
    );

    const [badDateRange, setBadDateRange] = useState(false);
    const [date, setDate] = useState([
        {
            startDate: addDays(startOfWeek(new window.Date()), 1),
            endDate: addDays(startOfWeek(new window.Date()), 7),
            key: "selection",
        },
    ]);

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const formItemControl = {
        style: {
            maxWidth: "300px",
        },
    };

    const formRangeControl = {
        style: {
            width: "auto",
        },
    };

    /**
     * Evaluate priorities on campaign change
     *
     * @param {Integer} campaignID
     */
    const evaluatePriorities = (campaignID) => {
        if (!campaignID) return null;

        // Load priorities (for campain) to state
        AmaxApiService.Telemarketing.Priorities(campaignID).then((priorities) => {
            setPriorities(priorities);
        });
    };

    /**
     * Runs on form submit, evaluates fields and sets SearchQuery
     *
     * @param {Object} values
     */
    const onFinish = (values) => {
        resetErrorView();
        setBadDateRange(false);

        if (differenceInDays(date[0].endDate, date[0].startDate) > 91) {
            setBadDateRange(true);
        } else {
            values = {
                ...values,
                MeetingType: MeetingType,
                Date: date,
            };
            if (!values.PriorityId) {
                values = { ...values, PriorityId: PriorityId };
            }
            if (!values.ReportedStatus) {
                values = { ...values, ReportedStatus: ReportedStatus };
            }
            if (!values.ContactResultIds) {
                values = { ...values, ContactResultIds: ContactResultIds };
            }
            console.log("Received values of form after edit: ", values);
            store.dispatch(setSearchQuery("TELEMARKETING", values));
            setVisibleFilter(!visibleFilter);

            store.dispatch(setUserTelemarketingForm(TssId, PriorityId, ContactResultIds, ReportedStatus, date));
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const getUserTelemarketingCampaigns = async () => {
        await AmaxApiService.Telemarketing.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        resetErrorView();
        if (!campaigns) {
            getUserTelemarketingCampaigns();
        }

        if (store.getState().Logic.telemarketingSelectedTss) {
            evaluatePriorities(store.getState().Logic.telemarketingSelectedTss);
        }

        if (store.getState().Logic.telemarketingDate) {
            if (typeof store.getState().Logic.telemarketingDate[0].startDate != "string") {
                setDate(store.getState().Logic.telemarketingDate);
            } else {
                const storedDate = new Date(store.getState().Logic.telemarketingDate[0].startDate);
                const endDate = new Date(store.getState().Logic.telemarketingDate[0].endDate);
                setDate([
                    {
                        startDate: storedDate.getTime(),
                        endDate: endDate.getTime(),
                        key: "selection",
                    },
                ]);
            }
        }
    }, []);

    useEffect(() => {
        if (differenceInDays(date[0].endDate, date[0].startDate) > 91) {
            setBadDateRange(true);
        } else {
            setBadDateRange(false);
        }
    }, [date]);

    const [visibleFilter, setVisibleFilter] = useState(true);
    const [visible, setVisible] = useState(false); // visibility of popup range
    const range = customRanges();

    return (
        <>
            <div>
                <button className="filter-button" onClick={() => setVisibleFilter(!visibleFilter)}>
                    {visibleFilter ? (
                        <h3 style={{ fontWeight: "bold" }}>
                            {t("Hide Filter ")}
                            <UpOutlined />
                        </h3>
                    ) : (
                        <h3>
                            {t("Filter ")} <FilterFilled />
                        </h3>
                    )}
                </button>

                {visibleFilter && (
                    <Form
                        className="form"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            Date: date,
                            TssId: TssId,
                            PriorityId: `${PriorityId}`,
                            MeetingType: `${MeetingType}`,
                            ReportedStatus: `${ReportedStatus}`,
                            ContactResultIds: ContactResultIds,
                        }}
                    >
                        <div className="form-div">
                            <Form.Item
                                {...formItemLayout}
                                label={t("Campaign")}
                                name="TssId"
                                rules={[{ required: true, message: t("Please select a Campaign") }]}
                            >
                                <Select
                                    {...formItemControl}
                                    showSearch
                                    placeholder={t("Campaign")}
                                    optionFilterProp="children"
                                    loading={campaigns ? false : true}
                                    disabled={campaigns ? false : true}
                                    onChange={(campaignID) => {
                                        setPriorities(null);
                                        setTssId(campaignID);
                                        setPriorityId(0);
                                        evaluatePriorities(campaignID);
                                    }}
                                    defaultValue={TssId}
                                    value={TssId}
                                >
                                    {campaigns
                                        ? campaigns.map((campaign) => (
                                              <Select.Option key={campaign.CampaignId} value={campaign.CampaignId}>
                                                  {campaign.CampaignName}
                                              </Select.Option>
                                          ))
                                        : null}
                                </Select>
                            </Form.Item>

                            <div className="responsive-advanced-up sales-label">
                                <div className="sales-label-text">{t("Priority")}</div>
                                <Select
                                    className="sales-label"
                                    loading={priorities ? false : true}
                                    disabled={priorities ? false : true}
                                    value={PriorityId}
                                    defaultValue={PriorityId}
                                    showSearch
                                    placeholder={t("Priority")}
                                    optionFilterProp="children"
                                    onChange={(e) => setPriorityId(e)}
                                    //title={priorities ? "" : t("First select a Campaign")}
                                >
                                    <Select.Option key={0} value={0} default>
                                        {t("Not Selected")}
                                    </Select.Option>

                                    {priorities
                                        ? priorities.map((priority) => (
                                              <Select.Option key={priority.PriorityId} value={priority.PriorityId}>
                                                  {priority.PriorityName}
                                              </Select.Option>
                                          ))
                                        : null}
                                </Select>
                                <div className="sales-label-text"></div>

                                {
                                    <Form.Item {...formItemLayout} label={t("Response")} name="ContactResultIds">
                                        <Radio.Group
                                            {...formItemControl}
                                            buttonStyle="solid"
                                            className="radio-button-responsive"
                                            onChange={(e) => setContactResultIds(e.target.value)}
                                        >
                                            <Radio.Button className="radio-button-responsive" selected value={4}>
                                                {t("Positive response")}
                                            </Radio.Button>
                                            <Radio.Button className="radio-button-responsive" value={7}>
                                                {t("Negative response")}
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                }

                                {/*<Form.Item
              {...formItemLayout}
              label={t("Display")}
              name="ReportedStatus"
          >
            <Radio.Group className="radio-button-responsive"
              {...formItemControl}
              buttonStyle="outline"
              onChange={(e) => setReportedStatus(e.target.value)}
            >
              <Radio.Button className="radio-button-responsive" selected value="2">{t("All")}</Radio.Button><br />
              <Radio.Button className="radio-button-responsive" value="0">{t("Reported")}</Radio.Button><br />
              <Radio.Button className="radio-button-responsive" value="1">{t("Not-Reported")}</Radio.Button>
            </Radio.Group>
          </Form.Item>*/}
                            </div>
                        </div>

                        <div className="left-padding">
                            <Form.Item
                                className="form-advanced"
                                {...formItemLayout}
                                label={t("Contact date")}
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please choose a date"),
                                    },
                                ]}
                            >
                                <div className="left-column-calendar">
                                    <div className="date-range-picker-mobile">
                                        <Popover
                                            trigger="click"
                                            placement="bottom"
                                            arrowPointAtCenter={true}
                                            onClick={() => setVisible(!visible)}
                                            visible={visible}
                                            content={
                                                <div onClick={() => setVisible(!visible)}>
                                                    <DefinedRange
                                                        onChange={(item) => setDate([item.selection])}
                                                        ranges={date}
                                                        inputRanges={[]}
                                                        staticRanges={range}
                                                        rangeColors={["#4897d8"]}
                                                    />
                                                </div>
                                            }
                                        >
                                            <button className="popup">{t("Quick Select")}</button>
                                        </Popover>
                                    </div>

                                    <DateRange
                                        className={badDateRange ? "red" : ""}
                                        {...formRangeControl}
                                        locale={store.getState().Logic.language == "cs" ? cs : enGB}
                                        value={date}
                                        defaultValue={date}
                                        editableDateInputs={false}
                                        onChange={(item) => setDate([item.selection])}
                                        moveRangeOnFirstSelection={false}
                                        ranges={date}
                                        weekStartsOn={1}
                                        rangeColors={["#48a3d8"]}
                                        dateDisplayFormat="d. MMM, yyy"
                                        minDate={defineds.minDateOfCalendar}
                                        maxDate={defineds.maxDateOfCalendar}
                                    />
                                </div>

                                <div className="right-column-calendar">
                                    <DefinedRange
                                        className="date-range-picker-desktop"
                                        {...formRangeControl}
                                        onChange={(item) => setDate([item.selection])}
                                        ranges={date}
                                        inputRanges={[]}
                                        staticRanges={range}
                                        rangeColors={["#4897d8"]}
                                    />
                                </div>
                            </Form.Item>
                        </div>

                        <Collapse className="responsive-advanced-down" bordered={false}>
                            <Collapse.Panel header={t("Advanced")} key="1">
                                <div className="form-part">
                                    <Form.Item
                                        {...formItemLayout}
                                        label={t("Priority")}
                                        name="PriorityId"
                                        rules={[{ required: true, message: t("Please choose priority") }]}
                                    >
                                        <Select
                                            className="sales-label"
                                            loading={priorities ? false : true}
                                            disabled={priorities ? false : true}
                                            value={PriorityId}
                                            showSearch
                                            placeholder={t("Priority")}
                                            optionFilterProp="children"
                                            onChange={(e) => setPriorityId(e)}
                                            title={priorities ? "" : t("First select a Campaign")}
                                        >
                                            <Select.Option key={0} value={0} default>
                                                {t("Not Selected")}
                                            </Select.Option>

                                            {priorities !== null
                                                ? priorities.map((priority) => (
                                                      <Select.Option
                                                          key={priority.PriorityId}
                                                          value={priority.PriorityId}
                                                      >
                                                          {priority.PriorityName}
                                                      </Select.Option>
                                                  ))
                                                : null}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item {...formItemLayout} label={t("Response")} name="ContactResultIds">
                                        <Radio.Group
                                            {...formItemControl}
                                            buttonStyle="solid"
                                            className="radio-button-responsive"
                                            onChange={(e) => setContactResultIds(e.target.value)}
                                        >
                                            <Radio.Button className="radio-button-responsive" selected value={4}>
                                                {t("Positive response")}
                                            </Radio.Button>
                                            <Radio.Button className="radio-button-responsive" value={7}>
                                                {t("Negative response")}
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Collapse.Panel>
                        </Collapse>

                        <Form.Item {...formItemLayout}>
                            {badDateRange && (
                                <h3 style={{ display: "flex", paddingRight: "5px", color: "#DC143C" }}>
                                    {t("Warning! The term must be less than 3 months!")}
                                </h3>
                            )}
                            <Button
                                type="primary"
                                htmlType="submit"
                                icon={<SearchOutlined style={{ paddingRight: "5px" }} />}
                            >
                                <b>{t("Display")}</b>
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </>
    );
};

export default TelemarketingForm;
