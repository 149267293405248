import React, { useState, useEffect } from "react";
//Redux
import { store } from "../../providers/ReduxProvider";
import { setSearchQuery, setUserCancelForm } from "../../../store/actions";
import AmaxApiService from "../../../services/AmaxApiService";
import { resetErrorView } from "../../../services/ErrorHandler";
//Antd
import { Button, Form, Select, Radio, Popover, Input, Tooltip, Checkbox } from "antd";
import { SearchOutlined, DownOutlined, UpOutlined, FilterFilled } from "@ant-design/icons";
//Language
import t from "../../providers/LanguageProvider";
//DateRange and DefinedRange
import { DefinedRange, DateRange } from "react-date-range";
import { defineds, customRanges } from "../DateRange";
import { addDays, startOfWeek, getDate, getMonth, getYear, differenceInDays } from "date-fns";
// locale for czech calendar
import { cs, enGB } from "date-fns/locale";

const StornoForm = () => {
    const [campaigns, setCampaigns] = useState(null);
    const [TssId, setTssId] = useState(null);
    const [IdCompany, setIdCompany] = useState(
        store.getState().Logic.cancelCompanyID ? store.getState().Logic.cancelCompanyID : null
    );
    const [UniqueIdentificator, setUniqueIdentificator] = useState(
        store.getState().Logic.cancelTIN ? store.getState().Logic.cancelTIN : null
    );
    const [FilteredStatus, setFilteredStatus] = useState(
        store.getState().Logic.cancelFilter ? store.getState().Logic.cancelFilter : "1"
    );
    const [CheckedCampaign, setCheckedCampaign] = useState(false);
    const [CheckedDate, setCheckedDate] = useState(false);

    const [firstDate, setFirstDate] = useState(addDays(startOfWeek(new window.Date()), 1));
    const [secondDate, setSecondDate] = useState(addDays(startOfWeek(new window.Date()), 7));
    const [Date, setDate] = useState([
        {
            startDate: addDays(startOfWeek(new window.Date()), 1),
            endDate: addDays(startOfWeek(new window.Date()), 7),
            key: "selection",
        },
    ]);

    const inputDate =
        getDate(Date[0].startDate) +
        ". " +
        (getMonth(Date[0].startDate) + 1) +
        ". " +
        getYear(Date[0].startDate) +
        " ~ " +
        getDate(Date[0].endDate) +
        ". " +
        (getMonth(Date[0].endDate) + 1) +
        ". " +
        getYear(Date[0].endDate);

    const inputLayout = {
        style: {
            height: "30px",
            maxWidth: "300px",
        },
    };

    const formItemLayout = {
        style: {
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            maxWidth: "600px",
        },
    };

    const formItemControl = {
        style: {
            maxWidth: "300px",
        },
    };

    const formRangeControl = {
        style: {
            width: "auto",
        },
    };

    /**
     * Runs on form submit, evaluates fields and sets SearchQuery
     *
     * @param {Object} values
     */
    const onFinish = (values) => {
        resetErrorView();
        console.log("Received values of form: ", values);
        values = {
            ...values,
            Date: Date,
        };
        if (!values.FilteredStatus) {
            values = { ...values, FilteredStatus: FilteredStatus };
        }
        if (!values.FilterCampaign) {
            values = { ...values, FilterCampaign: CheckedCampaign };
        }
        if (!values.FilterDate) {
            values = { ...values, FilterDate: CheckedDate };
        }
        if (!values.IdCompany) {
            values = { ...values, IdCompany: IdCompany };
        }
        if (!values.UniqueIdentificator) {
            values = { ...values, UniqueIdentificator: UniqueIdentificator };
        }
        console.log("Received values of form after edit: ", values);
        store.dispatch(setSearchQuery("CANCEL", values));
        setVisibleFilter(!visibleFilter);
        setOpenCalendar(false);

        store.dispatch(setUserCancelForm(FilteredStatus, UniqueIdentificator, IdCompany, TssId, Date));
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    useEffect(() => {
        resetErrorView();

        if (store.getState().Logic.cancelDate && typeof store.getState().Logic.cancelDate[0].startDate != "string") {
            setDate(store.getState().Logic.cancelDate);
        }

        //Load campaigns to state after component mount
        AmaxApiService.Cancel.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    }, []);

    const onCheckedCampaign = (e) => {
        setCheckedCampaign(!CheckedCampaign);
        if (!e.target.checked) {
            setTssId(null);
        }
    };

    const onCheckedDate = () => {
        setCheckedDate(!CheckedDate);

        if (CheckedDate) {
            setOpenCalendar(false);
        }
    };

    const [visibleFilter, setVisibleFilter] = useState(true);
    const [visible, setVisible] = useState(false); // visibility of popup range
    const [openCalendar, setOpenCalendar] = useState(false); // visibility of popup range
    const range = customRanges();

    const onMobileDateChange = (item) => {
        setDate([item.selection]);
    };

    return (
        <>
            <div>
                <button className="filter-button" onClick={() => setVisibleFilter(!visibleFilter)}>
                    {visibleFilter ? (
                        <h3>
                            {t("Hide Filter ")}
                            <UpOutlined />
                        </h3>
                    ) : (
                        <h3>
                            {t("Filter ")} <FilterFilled />
                        </h3>
                    )}
                </button>

                {visibleFilter && (
                    <Form
                        className="form"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        initialValues={{
                            TssId: TssId,
                            Date: Date,
                            FilteredStatus: FilteredStatus,
                            IdCompany: IdCompany,
                            UniqueIdentificator: UniqueIdentificator,
                        }}
                    >
                        <div className="form-div">
                            <Form.Item {...formItemLayout} label={t("Filter by")} name="FilteredStatus">
                                <Radio.Group
                                    buttonStyle="outline"
                                    onChange={(e) => {
                                        setFilteredStatus(e.target.value);
                                        setUniqueIdentificator(null);
                                        setIdCompany(null);
                                    }}
                                >
                                    <Radio.Button style={{ width: "120px" }} selected value="1">
                                        {t("TIN")}
                                    </Radio.Button>
                                    <Radio.Button style={{ width: "120px" }} value="0">
                                        {t("Company ID")}
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {FilteredStatus == "0" ? (
                                <Form.Item
                                    {...formItemLayout}
                                    label={t("Company ID")}
                                    name="IdCompany"
                                    rules={[{ required: true, message: t("Please enter Company ID") }]}
                                >
                                    <Input
                                        {...inputLayout}
                                        style={{ marginTop: "4px", height: "37px", maxWidth: "240px" }}
                                        value={IdCompany}
                                        defaultValue={IdCompany}
                                        onChange={(e) => setIdCompany(e.target.value)}
                                        type="number"
                                    />
                                </Form.Item>
                            ) : (
                                <Form.Item
                                    {...formItemLayout}
                                    label={t("TIN")}
                                    name="UniqueIdentificator"
                                    rules={[{ required: true, message: t("Please enter TIN") }]}
                                >
                                    <Input
                                        {...inputLayout}
                                        style={{ marginTop: "4px", height: "37px", maxWidth: "240px" }}
                                        value={UniqueIdentificator}
                                        defaultValue={UniqueIdentificator}
                                        onChange={(e) => setUniqueIdentificator(e.target.value)}
                                        type="number"
                                    />
                                </Form.Item>
                            )}
                        </div>

                        <div className="left-padding">
                            <Form.Item
                                className="form-advanced"
                                {...formItemLayout}
                                label={
                                    <div>
                                        <Checkbox
                                            defaultChecked={CheckedCampaign}
                                            checked={CheckedCampaign}
                                            onChange={onCheckedCampaign}
                                        >
                                            {" "}
                                        </Checkbox>
                                        {t("Campaign")}
                                    </div>
                                }
                                name="TssId"
                                rules={
                                    CheckedCampaign
                                        ? [{ required: true, message: t("Please select a campaign") }]
                                        : [{ required: false }]
                                }
                            >
                                <Select
                                    {...formItemControl}
                                    showSearch
                                    defaultValue={TssId}
                                    placeholder={t("Campaign")}
                                    optionFilterProp="children"
                                    disabled={CheckedCampaign ? false : true}
                                    onChange={(campaignID) => {
                                        setTssId(campaignID);
                                    }}
                                >
                                    <Select.Option value="0" default disabled>
                                        {t("Select a campaign")}
                                    </Select.Option>
                                    {campaigns
                                        ? campaigns.map((campaign) => (
                                              <Select.Option key={campaign.CampaignId} value={campaign.CampaignId}>
                                                  {campaign.CampaignName}
                                              </Select.Option>
                                          ))
                                        : null}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                {...formItemLayout}
                                label={
                                    <div>
                                        <Checkbox defaultChecked={CheckedDate} onChange={onCheckedDate}>
                                            {" "}
                                        </Checkbox>
                                        {t("Conclusion Date")}
                                    </div>
                                }
                            >
                                <Input.Group>
                                    <Input
                                        {...inputLayout}
                                        disabled={CheckedDate ? false : true}
                                        value={inputDate}
                                        onClick={() => (CheckedDate ? setOpenCalendar(!openCalendar) : null)}
                                        addonAfter={
                                            openCalendar ? (
                                                <UpOutlined
                                                    onClick={() =>
                                                        CheckedDate ? setOpenCalendar(!openCalendar) : null
                                                    }
                                                />
                                            ) : (
                                                <DownOutlined
                                                    onClick={() =>
                                                        CheckedDate ? setOpenCalendar(!openCalendar) : null
                                                    }
                                                />
                                            )
                                        }
                                    />
                                </Input.Group>
                            </Form.Item>

                            {openCalendar && (
                                <div>
                                    <Form.Item {...formItemLayout} name="Date">
                                        <div className="left-column-calendar">
                                            <div className="date-range-picker-mobile">
                                                {/* <Popover
                                                    trigger="click"
                                                    placement="bottom"
                                                    arrowPointAtCenter={true}
                                                    onClick={() => setVisible(!visible)}
                                                    visible={visible && openCalendar}
                                                    content={
                                                        <div onClick={() => setVisible(!visible)}>
                                                            <DefinedRange
                                                                onChange={(item) => setDate([item.selection])}
                                                                ranges={Date}
                                                                inputRanges={[]}
                                                                staticRanges={range}
                                                                rangeColors={["#4897d8"]}
                                                            />
                                                        </div>
                                                    }
                                                >
                                                    <button className="popup">{t("Quick Select")}</button>
                                                </Popover> */}

                                                <Popover
                                                    trigger="click"
                                                    placement="bottom"
                                                    arrowPointAtCenter={true}
                                                    onClick={() => setVisible(!visible)}
                                                    visible={visible}
                                                    content={
                                                        <div onClick={() => setVisible(false)}>
                                                            <DefinedRange
                                                                onChange={onMobileDateChange}
                                                                ranges={Date}
                                                                inputRanges={[]}
                                                                staticRanges={range}
                                                                rangeColors={["#4897d8"]}
                                                            />
                                                        </div>
                                                    }
                                                >
                                                    <button className="popup">{t("Quick Select")}</button>
                                                </Popover>
                                            </div>

                                            <DateRange
                                                {...formRangeControl}
                                                locale={store.getState().Logic.language == "cs" ? cs : enGB}
                                                value={Date}
                                                defaultValue={Date}
                                                editableDateInputs={false}
                                                onChange={(item) => {
                                                    setDate([item.selection]);
                                                    setFirstDate(item.selection.startDate);
                                                    setSecondDate(item.selection.endDate);
                                                }}
                                                moveRangeOnFirstSelection={false}
                                                ranges={Date}
                                                weekStartsOn={1}
                                                rangeColors={["#48a3d8"]}
                                                dateDisplayFormat="d. MMM yyy"
                                            />
                                            <DefinedRange
                                                className="date-range-picker-desktop"
                                                {...formRangeControl}
                                                onChange={(item) => {
                                                    setDate([item.selection]);
                                                    setFirstDate(item.selection.startDate);
                                                    setSecondDate(item.selection.endDate);
                                                }}
                                                ranges={Date}
                                                inputRanges={[]}
                                                staticRanges={range}
                                                rangeColors={["#4897d8"]}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                            )}

                            <br />
                        </div>
                        <Form.Item {...formItemLayout}>
                            <Button type="primary" htmlType="submit">
                                <SearchOutlined />
                                <b>{t("Display")}</b>
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </div>
        </>
    );
};

export default StornoForm;
