import React from "react";
import AmaxApiService from "../../services/AmaxApiService";
import { store } from "../../components/providers/ReduxProvider";
import { setPermisions } from "../../store/actions";
//Router
import { useLocation } from "react-router-dom";
//Redux
import { connect } from "react-redux";
import { isValidLogin } from "./AccountFunctions";
//Pages
import LoginPage from "../../pages/login";
import ResetPassword from "../../pages/reset";
import Forgotten from "../../pages/forgotten";

const LoginGate = ({ AmaxApi, children }) => {
    const location = useLocation();

    const getPermision = () => {
        AmaxApiService.Account.Permission()
            .then((response) => {
                store.dispatch(setPermisions(response));
            })
            .catch((err) => console.log(err));
    };

    if (!isValidLogin(AmaxApi)) {
        if (location.pathname === "/reset") {
            return <ResetPassword />;
        } else if (location.pathname.substring(0, 11) === "/forgotten/") {
            return <Forgotten />;
        } else {
            return <LoginPage />;
        }
    }
    getPermision();
    if (AmaxApi.connected) return children;
    else return null;
};

export default connect(mapStateToProps)(LoginGate);

function mapStateToProps(state) {
    return { AmaxApi: state.AmaxApi };
}
