import React from "react";
//Redux
import handleError from "../../../services/ErrorHandler";
import AmaxApiService from "../../../services/AmaxApiService";
//Antd
import { Button, Radio, Modal, Descriptions, Divider, Input, Form, Alert } from "antd";
//Router
import { Link } from "react-router-dom";
//Moment
import moment from "moment";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";
//icons
import {
    CloseCircleOutlined,
    SendOutlined,
    CalendarOutlined,
    EnvironmentOutlined,
    HomeOutlined,
} from "@ant-design/icons";

const headings = {
    style: {
        fontWeight: "600",
        color: "#48a3d8",
        paddingLeft: "15px",
        paddingTop: "10px",
    },
};

class MeetingReclamationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: { DisplayName: null, Email: null },
            reasonOther: false,
            name: "",
            email: "",
            note: "",
            reclamationSent: false,
            modalWidth: "100%",
        };
    }

    resizeModal() {
        this.setState({
            modalWidth: responsiveWidth({
                xs: "95vw",
                sm: "80vw",
                md: "75vw",
                lg: "900px",
            }),
            layout: responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            }),
        });
    }

    async getInfo() {
        AmaxApiService.Account.UserInfo().then((response) => {
            this.setState({
                userData: response,
                name: response.FirstName + " " + response.LastName,
                email: response.Email,
            });
        });
    }

    componentDidMount() {
        this.resizeModal();
        window.addEventListener("resize", () => {
            this.resizeModal();
        });
        this.getInfo();
    }

    componentWillReceiveProps(newProps) {
        this.setState({ reclamationSent: false });
    }

    /**
     * Handle form submit and sends api request
     * @todo make form "noninteractive" after submit
     */
    onFinish = (values) => {
        console.log("Received values of form: ", values);
        values = {
            ...values,
            UserName: this.state.name,
            Email: this.state.email,
            Message: this.state.note,
        };
        console.log("Received values of form after edit: ", values);

        AmaxApiService.ReportMeetings.SendReclamationRequest(
            Object.assign({}, values, {
                MeetingId: this.props.meetingDetails.MeetingId,
            })
        ).then(() => {
            this.setState({
                reclamationSent: true,
                name: "",
                email: "",
                note: "",
                reasonOther: false,
            });
        });
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    render() {
        const radioStyle = {
            display: "inline-block",
            width: "100%",
            height: "30px",
            padding: "10px 5px",
            lineHeight: "30px",
            whiteSpace: "normal",
            color: "#0e151a",
        };

        const footer = [
            <Button key="cancel" type="secondary" size="large" onClick={this.props.closeDialog}>
                <CloseCircleOutlined />
                {t("Close")}
            </Button>,

            // <Button
            //   key="submit"
            //   loading={this.state.reclamationSent}
            //   onClick={this.onFinish}
            //   type="primary"
            //   size="large"
            //   style={{ float: "right" }}
            //   htmlType="submit"
            // >
            //   {t("Send")}
            // </Button>,
        ];

        return (
            <Modal
                width={this.state.modalWidth}
                footer={footer}
                title={t("Meeting complaint")}
                visible={this.props.visible}
                onCancel={this.props.closeDialog}
                style={{ top: "30px" }}
            >
                {this.props.meetingDetails && (
                    <>
                        {moment().isAfter(moment(this.props.meetingDetails.MeetingDate).add(48, "h")) ? (
                            <Alert
                                message={t(
                                    "The meeting complaint deadline (2 working days from the date of the meeting) has already expired!"
                                )}
                                type="error"
                                description={
                                    <Link to="ObchodniPodminky" target="_blank">
                                        {t("Zobrazit obchodní podmínky")}
                                    </Link>
                                }
                                showIcon
                            />
                        ) : (
                            <Alert
                                message={t("Schůzku nyní můžete reklamovat (do 2 pracovních dnů od data schůzky)")}
                                type="success"
                                description={
                                    <Link to="ObchodniPodminky" target="_blank">
                                        {t("Zobrazit obchodní podmínky")}
                                    </Link>
                                }
                                showIcon
                            />
                        )}

                        <h2 {...headings}>{t("Meeting")}</h2>

                        <div className="mobile-card" style={{ paddingLeft: "16px" }}>
                            <div class="column-mobile">
                                <div style={{ display: "flex", width: "26px" }}>
                                    <p style={{ paddingRight: "14px", paddingTop: "2px", fontSize: "14px" }}>{`${t(
                                        "ID"
                                    )}: `}</p>
                                    <p style={{ paddingLeft: "8px" }}>
                                        {this.props.meetingDetails.MeetingId
                                            ? this.props.meetingDetails.MeetingId
                                            : "-"}
                                    </p>
                                </div>

                                <div style={{ display: "flex" }}>
                                    <HomeOutlined className="detail-icon" />
                                    <div>{this.props.meetingDetails.Company}</div>
                                </div>
                                <br />

                                <div className="mobile-card-div">
                                    <CalendarOutlined className="detail-icon" />
                                    <div>
                                        {moment(this.props.meetingDetails.MeetingDate).format("DD.MM.YYYY hh:mm")}
                                    </div>
                                </div>

                                <div className="mobile-card-div">
                                    <EnvironmentOutlined className="detail-icon" />
                                    <div style={{ color: "#0e151a", paddingTop: "4px" }}>
                                        {this.props.meetingDetails.MeetingPlace}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* ///// DESKTOP VERSION ////// */}
                        <div class="desktop-card" style={{ paddingLeft: "20px" }}>
                            <div className="row">
                                <div class="column">
                                    <div style={{ display: "flex" }}>
                                        <p style={{ paddingRight: "14px", fontSize: "14px", paddingTop: "2px" }}>{`${t(
                                            "ID"
                                        )}: `}</p>
                                        <p style={{ paddingLeft: "8px" }}>
                                            {this.props.meetingDetails.MeetingId
                                                ? this.props.meetingDetails.MeetingId
                                                : "-"}
                                        </p>
                                    </div>

                                    <div style={{ display: "flex" }}>
                                        <HomeOutlined className="detail-icon" />
                                        <p style={{ paddingLeft: "4px", paddingTop: "1px" }}>
                                            {this.props.meetingDetails.Company}
                                        </p>
                                    </div>
                                </div>

                                <div style={{ display: "flex", paddingTop: "2px", paddingLeft: "4px" }}>
                                    <CalendarOutlined className="detail-icon" />
                                    <div style={{ paddingLeft: "4px", color: "#0e151a", paddingTop: "2px" }}>
                                        {moment(this.props.meetingDetails.MeetingDate).format("DD.MM.YYYY hh:mm")}
                                    </div>
                                </div>

                                <div style={{ display: "flex", paddingTop: "20px", paddingLeft: "4px" }}>
                                    <EnvironmentOutlined className="detail-icon" />
                                    <div style={{ paddingTop: "2px", paddingLeft: "5px", color: "#0e151a" }}>
                                        {this.props.meetingDetails.MeetingPlace}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                    </>
                )}
                {this.state.reclamationSent ? (
                    <Alert
                        message={t("Complaint sent successfully")}
                        description={t("Our sales department will contact you shortly")}
                        type="success"
                        showIcon
                    />
                ) : (
                    <Form
                        onChange={(event) => {
                            console.log(event.target.name + ":" + event.target.value);
                        }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <h2 {...headings}>{t("Reason for Reclamation")}</h2>
                        <Form.Item
                            name="Reason"
                            rules={[
                                {
                                    required: true,
                                    message: t("Please select a reason"),
                                },
                            ]}
                        >
                            <Radio.Group
                                style={{ paddingBottom: "15px", paddingLeft: "16px" }}
                                onChange={(e) => {
                                    console.log(this.state.reasonOther);
                                    if (e.target.value === 6) {
                                        if (!this.state.reasonOther) this.setState({ reasonOther: true });
                                    } else if (this.state.reasonOther) this.setState({ reasonOther: false });
                                }}
                            >
                                <Radio style={radioStyle} value={0}>
                                    {t("The meeting was arranged with an incompetent person.")}
                                </Radio>
                                <Radio style={radioStyle} value={1}>
                                    {t("The meeting was canceled prior to the meeting by the client by telephone.")}
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    {t("The meeting location address was not verified")}
                                </Radio>
                                <Radio style={radioStyle} value={3}>
                                    {t("The date, time, and location do not match the specified campaign criteria")}
                                </Radio>
                                <Radio style={radioStyle} value={4}>
                                    {t("Insufficient time to travel.")}
                                </Radio>
                                <Radio style={radioStyle} value={5}>
                                    {t(
                                        "The meeting was arranged to a company that does not match the campaign criteria."
                                    )}
                                </Radio>
                                <Radio style={radioStyle} value={6}>
                                    {t("Other")}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            style={{ display: this.state.reasonOther ? "inherit" : "none" }}
                            name="OtherReason"
                            rules={[
                                {
                                    required: this.state.reasonOther,
                                    message: t("Please enter a reason"),
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <h2 {...headings}>{t("Contact Info")}</h2>

                        <Descriptions bordered className="detail-question" layout={this.state.layout}>
                            <Descriptions.Item
                                span={4}
                                label={t("Name")}
                                name="UserName"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please enter your name"),
                                    },
                                ]}
                                initialValue={this.state.userData.DisplayName}
                            >
                                <Input
                                    style={{ height: "40px" }}
                                    disabled={this.state.userData ? false : true}
                                    required
                                    type="text"
                                    name="UserName"
                                    value={this.state.name}
                                    onChange={(e) => {
                                        console.log(e);
                                        this.setState({ name: e.target.value });
                                    }}
                                />
                            </Descriptions.Item>

                            <Descriptions.Item
                                span={4}
                                label={t("Email")}
                                name="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: t("Please enter your email"),
                                    },
                                ]}
                            >
                                <Input
                                    style={{ height: "40px" }}
                                    disabled={this.state.userData ? false : true}
                                    required
                                    type="email"
                                    name="email"
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value })}
                                />
                            </Descriptions.Item>

                            <Descriptions.Item
                                span={4}
                                label={t("Note")}
                                name="Message"
                                rules={[{ required: true, message: t("Please enter your message") }]}
                            >
                                <Input.TextArea
                                    required
                                    rows={4}
                                    name="message"
                                    value={this.state.note}
                                    onChange={(e) => this.setState({ note: e.target.value })}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                        <Form.Item>
                            <Button
                                disabled={
                                    this.props.meetingDetails
                                        ? moment().isAfter(moment(this.props.meetingDetails.MeetingDate).add(48, "h"))
                                        : true
                                }
                                key="submit"
                                loading={this.state.reclamationSent}
                                type="primary"
                                size="large"
                                style={{ marginTop: "20px", float: "right" }}
                                htmlType="submit"
                            >
                                <SendOutlined />
                                {t("Send")}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        );
    }
}
export default MeetingReclamationModal;
