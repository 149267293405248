import React, { useState, useEffect } from "react";
//Redux
import { connect } from "react-redux";
import SearchAndQueryData from "../../../services/DataQueryController";
import AmaxApiService from "../../../services/AmaxApiService";
//Antd
import "antd/dist/antd.css";
import { Button, Table, Input, Checkbox, Select, Tabs } from "antd";
import {
    InfoCircleTwoTone,
    FileExcelOutlined,
    CarFilled,
    SearchOutlined,
    UpOutlined,
    CloseCircleOutlined,
    FileExcelFilled,
    SettingFilled,
} from "@ant-design/icons";
//Moment
import moment from "moment";
//Components
import SchuzkaDetail from "../Modals/SchuzkaDetail";
import exportReport, { MeetingReportsParse, MeetingReportsParseColumns } from "../exports/CSVExport";
//Helpers
import { responsiveWidth } from "../../../helpers/helpers";
//Language
import t from "../../providers/LanguageProvider";

const ReportSchuzkyTable = ({ data, loading, query }) => {
    const [storedColumns, setStoredColumns] = useState(
        JSON.parse(localStorage.getItem("meetingColumns")) ? JSON.parse(localStorage.getItem("meetingColumns")) : null
    );
    const [columns, setColumns] = useState(null);

    const [campaigns, setCampaigns] = useState([]);

    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [displayDetail, setDisplayDetail] = useState(false);
    const [detailId, setDetailId] = useState(null);

    const [selectedColumns, setSelectedColumns] = useState(null);
    const [layout, setLayout] = useState("vertical");
    const [tableExpandable, setTableExpandable] = useState(false);
    const [searchValue, setSearchValue] = useState(" ");
    const [isChecked, setIsChecked] = useState(false);
    const [selected, setSelected] = useState(false);
    const [routeVisible, setRouteVisible] = useState(false);
    const [columnSettVisible, setColumnSettVisible] = useState(false);
    const [exportVisible, setExportVisible] = useState(false);
    const [selectedInformation, setSelectedInformation] = useState(false);
    const [inputRouteValidate, setInputRouteValidate] = useState(false);
    const [searchText, setSearchText] = useState("");

    const [last, setLast] = useState(data);
    const [oldold, setOldLast] = useState(last ? last : data);

    ///***FUNKCIONALITY FOR COLUMNS SETTING****
    useEffect(() => {
        if (storedColumns) localStorage.setItem("meetingColumns", JSON.stringify(storedColumns));
    }, [storedColumns]);

    useEffect(() => {
        if (storedColumns) {
            for (let i = 0; i < storedColumns.length; i++) {
                let newColumn = storedColumns;

                switch (storedColumns[i]["title"]) {
                    case t("Meeting Date"):
                        newColumn[i] = colMeeting;
                        break;
                    case t("Meeting Agreed Date"):
                        newColumn[i] = colAgreed;
                        break;
                    case t("Company"):
                        newColumn[i] = colCompany;
                        break;
                    case t("TIN"):
                        newColumn[i] = colTIN;
                        break;
                    case t("Address"):
                        newColumn[i] = colAddress;
                        break;
                    case t("Sales Representative"):
                        newColumn[i] = colSR;
                        break;
                    case t("Person"):
                        newColumn[i] = colPerson;
                        break;
                    case t("Detail"):
                        newColumn[i] = colDetail;
                        break;
                }
                setStoredColumns(newColumn);
            }
        }
    }, [data]);

    ///***FUNKCIONALITY FOR SELECTING RECORDS FROM TABLE****
    const [selectedRowKeys, setRowKeys] = useState([]);
    const onSelectChange = (selectedRowKeys) => {
        setRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            {
                key: "all",
                text: t("All"),
                onSelect: () => {
                    let recordIds = [];
                    if (data) {
                        data.map((one) => {
                            recordIds.push(one.MeetingId);
                        });
                    }
                    setRowKeys(recordIds);
                },
            },
            {
                key: "cancel",
                text: t("None"),
                onSelect: () => {
                    setRowKeys([]);
                },
            },
        ],
    };

    ///////////////////////////////////////////

    const findCampaigns = async () => {
        let campai = await AmaxApiService.ReportMeetings.CampaignsByUser()
            .then((campaigns) => {
                setCampaigns(campaigns);
            })
            .catch((err) => console.log(err));
    };

    const inputLayout = {
        style: {
            height: "26px",
            marginRight: "8px",
            marginBottom: "8px",
            paddingRight: "10px",
            minWidth: "160px",
            maxWidth: "360px",
            //width: "auto",
        },
    };

    const routeDivLayout = {
        style: {
            maxWidth: "1000px",
            paddingBottom: "10px",
            paddingTop: "10px",
            margin: "auto",
        },
    };

    const paddingRightLayout = {
        style: {
            paddingRight: "10px",
            color: "#0e151a",
            marginTop: "10px",
        },
    };

    const resizeModal = () => {
        {
            setColumns(
                responsiveWidth({
                    xs: xsColumns,
                    sm: smColumns,
                    md: mdColumns,
                    lg: lgColumns,
                })
            );
        }
        setLayout(
            responsiveWidth({
                sm: "vertical",
                md: "horizontal",
            })
        );
    };

    const onResize = () => {
        if (storedColumns) {
            let actualColumnsToChange = [];
            storedColumns.map((oneColumn) => {
                actualColumnsToChange.push(oneColumn["title"]);
            });
            setSelectedItems(actualColumnsToChange.splice(0, actualColumnsToChange.length - 1));
        } else {
            setSelectedItems(
                responsiveWidth({
                    xs: [t("Meeting Date"), t("Company")],
                    sm: [t("Meeting Date"), t("Company"), t("Address")],
                    md: [t("Meeting Date"), t("Company"), t("TIN"), t("Address"), t("Sales Representative")],
                    lg: [
                        t("Meeting Date"),
                        t("Company"),
                        t("TIN"),
                        t("Person"),
                        t("Address"),
                        t("Sales Representative"),
                        t("Phone"),
                    ],
                })
            );
        }
    };

    useEffect(() => {
        resizeModal();
        window.addEventListener("resize", () => {
            resizeModal();
        });
    }, []);

    useEffect(() => {
        if (
            columns != null &&
            columns != undefined &&
            columns.length &&
            columns.length != undefined &&
            columns.length != null
        ) {
            {
                /*if (columns.length < 14) {
        setTableExpandable(true);
      } else {
        setTableExpandable(false);
      */
            }
        }
        if (!isChanged) onResize();
    }, [columns]);

    const handleDisplayDetail = (meetingID) => {
        setDisplayDetail(true);
        setDetailId(meetingID);
    };

    const closeDetail = () => {
        setDisplayDetail(false);
        setDetailId(null);
    };

    const exportTypeAllRecords = () => {
        if (!selectedInformation) {
            exportAll();
        } else {
            exportColumns();
        }
    };

    const exportTypeSelection = () => {
        if (!selectedInformation) {
            exportSelection();
        } else {
            exportColumnsSelection();
        }
    };

    const exportAll = () => {
        const toExport = last ? last : data;
        exportReport(MeetingReportsParse(toExport), "report", "amax_portal_report", query, campaigns, []);
    };

    const exportColumns = () => {
        const toExport = last ? last : data;
        exportReport(
            MeetingReportsParseColumns(toExport, selectedItems),
            "report",
            "amax_portal_report",
            query,
            campaigns,
            []
        );
        //setColumns(columns)
    };

    const exportSelection = () => {
        const toExport = last ? last : data;
        if (selectedRowKeys.length > 0) {
            const selectedData = [];
            toExport.map((record) =>
                selectedRowKeys.indexOf(record.MeetingId) >= 0 ? selectedData.push(record) : null
            );
            exportReport(MeetingReportsParse(selectedData), "report", "amax_portal_report", query, campaigns, []);
        }
    };

    const exportColumnsSelection = () => {
        const toExport = last ? last : data;
        if (selectedRowKeys.length > 0) {
            const selectedData = [];
            toExport.map((record) =>
                selectedRowKeys.indexOf(record.MeetingId) >= 0 ? selectedData.push(record) : null
            );
            exportReport(
                MeetingReportsParseColumns(selectedData, selectedItems),
                "report",
                "amax_portal_report",
                query,
                campaigns,
                []
            );
        }
    };

    function onCheckboxChange(e) {
        setIsChecked(e.target.checked);
        setSearchValue(" ");
        setInputRouteValidate(e.target.checked);
    }

    const makeURLForMap = () => {
        if (isChecked && (searchValue == " " || searchValue == "")) {
            setInputRouteValidate(true);
        } else {
            //setInputRouteValidate(true)
            setSelected(true);
            if (selectedRowKeys.length > 0 && !inputRouteValidate) {
                setSelected(false);
                let URL = "https://www.google.com/maps/dir/";
                if (isChecked) {
                    URL += searchValue.replaceAll(" ", "+") + "/";
                }
                data.map((record) => {
                    if (selectedRowKeys.indexOf(record.MeetingId) >= 0) {
                        let houseNumber = record.Address.match(/\d+/)[0];
                        const streetWithNumber = record.Address.replace("č.p. ", "");
                        let street = streetWithNumber.replace(" " + houseNumber, "");

                        const twoNumbers = record.Address.match("/");
                        if (twoNumbers) {
                            houseNumber = record.Address.match(/\/\d+/)[0].match(/\d+/)[0];
                            street = street.replace("/", "").replace(houseNumber, "");
                        }

                        const wsStreet = street.replaceAll(" ", "");
                        const wsTown = record.Town.replaceAll(" ", "");
                        const numberAndStreet =
                            wsStreet == wsTown || houseNumber == wsStreet ? houseNumber : houseNumber + "%2F" + street;

                        URL += record.Town + "+" + numberAndStreet + ",+" + record.ZIP + "+" + record.Town + "/";
                        URL = URL.replaceAll(" ", "+");

                        //URL += (record.Town).replace("  ", "+") + "," + (record.Address).replace("č.p. ", "").replace("  ", "+").replace(" ", "+") + "," + (record.ZIP).replace("  ", "+") +"/"
                    }
                });
                window.open(
                    URL,
                    "_blank" // <- This is what makes it open in a new window.
                );
            }
        }
    };

    const openPrevDetail = () => {
        let allData = last ? last : data;

        allData.map((record) => {
            if (record.MeetingId == detailId) {
                if (allData.indexOf(record) != 0) {
                    //setPrev(allData.indexOf(record) - 1)
                    closeDetail();
                    setDetailId(allData[allData.indexOf(record) - 1].MeetingId);
                    handleDisplayDetail(
                        allData[allData.indexOf(record) - 1].MeetingId,
                        allData[allData.indexOf(record) - 1]
                    );
                }
            }
        });
    };

    const openNextDetail = () => {
        let allData = last ? last : data;

        allData.map((record) => {
            if (record.MeetingId == detailId) {
                if (allData.indexOf(record) != allData.length - 1) {
                    //setPrev(allData.indexOf(record) - 1)
                    closeDetail();
                    setDetailId(allData[allData.indexOf(record) + 1].MeetingId);
                    handleDisplayDetail(
                        allData[allData.indexOf(record) + 1].MeetingId,
                        allData[allData.indexOf(record) + 1]
                    );
                }
            }
        });
    };

    //////
    var oldLast = [];

    const handleSearch = () => {
        setLast(oldold);
        const dataToSearch = oldold ? oldold : data;
        if (dataToSearch) {
            dataToSearch.map((one) => {
                if (
                    one.Company.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.TIN.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.Person.toString().toLowerCase().includes(searchText.toLowerCase()) ||
                    one.SR.toString().toLowerCase().includes(searchText.toLowerCase())
                )
                    oldLast.push(one);
            });
        }
        setLast(oldLast);
        setPage(1);
        setPageSize(10);
    };

    const resetSearch = () => {
        setLast(oldold);
        setSearchText("");
    };
    ////

    const resetChangeColumns = () => {
        //setSelectedItems([])
        //setSelectedColumns(null)
        //setStoredColumns(lgColumns)
        setStoredColumns(
            responsiveWidth({
                xs: xsColumns,
                sm: smColumns,
                md: mdColumns,
                lg: lgColumns,
            })
        );

        setIsChanged(false);
        setSelectedItems(
            responsiveWidth({
                xs: [t("Meeting Date"), t("Company")],
                sm: [t("Meeting Date"), t("Company"), t("Address")],
                md: [t("Meeting Date"), t("Company"), t("TIN"), t("Address"), t("Sales Representative")],
                lg: [
                    t("Meeting Date"),
                    t("Company"),
                    t("TIN"),
                    t("Person"),
                    t("Address"),
                    t("Sales Representative"),
                    t("Phone"),
                ],
            })
        );
    };

    const changeColumns = (columns) => {
        let newColumns = [];
        columns.map((column) => {
            switch (column) {
                case t("Meeting Date"):
                    newColumns.push(colMeeting);
                    break;
                case t("Meeting Agreed Date"):
                    newColumns.push(colAgreed);
                    break;
                case t("Sales Representative"):
                    newColumns.push(colSR);
                    break;
                case t("TIN"):
                    newColumns.push(colTIN);
                    break;
                case t("Person"):
                    newColumns.push(colPerson);
                    break;
                case t("Function"):
                    newColumns.push(colFunction);
                    break;
                case t("Company"):
                    newColumns.push(colCompany);
                    break;
                case t("Phone"):
                    newColumns.push(colPhone);
                    break;
                case t("Address"):
                    newColumns.push(colAddress);
                    break;
                case t("ZIP"):
                    newColumns.push(colZIP);
                    break;
                case t("Note"):
                    newColumns.push(colNote);
                    break;
                case t("Original Id"):
                    newColumns.push(colOriginalId);
                    break;
                case t("Sales Representative's Note"):
                    newColumns.push(colSRNote);
                    break;
            }
        });
        newColumns.push(colDetail);
        setStoredColumns(newColumns);
    };

    /////////COLUMNS SETTINGS///////////////
    const colMeeting = {
        title: t("Meeting Date"),
        dataIndex: "MeetingDate",
        key: "MeetingDate",
        sorter: (a, b) => a.MeetingDate.localeCompare(b.MeetingDate),
        sortDirections: ["descend", "ascend"],
        render: (dateString) => (
            <>
                <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                <span>{moment(dateString).format("HH:mm")}</span>
            </>
        ),
        maxWidth: "200",
    };

    const colAgreed = {
        title: t("Meeting Agreed Date"),
        dataIndex: "AgreedDate",
        key: "AgreedDate",
        sorter: (a, b) => a.AgreedDate.localeCompare(b.AgreedDate),
        sortDirections: ["descend", "ascend"],
        render: (dateString) => (
            <>
                <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                <span>{moment(dateString).format("HH:mm")}</span>
            </>
        ),
        maxWidth: "200",
    };

    const colCompany = {
        title: t("Company"),
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.localeCompare(b.Company),
        sortDirections: ["descend", "ascend"],
        width: "15%",
    };

    const colTIN = {
        title: t("TIN"),
        dataIndex: "TIN",
        key: "TIN",
        sorter: (a, b) => a.TIN - b.TIN,
        sortDirections: ["descend", "ascend"],
        width: "5%",
    };

    const colPerson = {
        title: t("Person"),
        dataIndex: "Person",
        key: "Person",
        sorter: (a, b) => a.Person.localeCompare(b.Person),
        sortDirections: ["descend", "ascend"],
        width: "auto",
    };

    const colAddress = {
        title: t("Address"),
        dataIndex: "Address",
        key: "Address",
        render: (text, record) => record.Address + ", " + record.Town,
        width: "auto",
    };

    const colZIP = {
        title: t("ZIP"),
        dataIndex: "ZIP",
        key: "ZIP",
        width: "auto",
    };

    const colSR = {
        title: t("Sales Representative"),
        dataIndex: "SR",
        key: "SR",
        sorter: (a, b) => a.SR.localeCompare(b.SR),
        sortDirections: ["descend", "ascend"],
        width: "auto",
    };

    const colPhone = {
        title: t("Phone"),
        dataIndex: "PersonPhone",
        key: "PersonPhone",
        width: "auto",
    };

    const colFunction = {
        title: t("Function"),
        dataIndex: "Function",
        key: "Function",
        width: "auto",
    };

    const colOriginalId = {
        title: t("Original Id"),
        dataIndex: "OriginalId",
        key: "OriginalId",
        width: "auto",
    };

    const colNote = {
        title: t("Note"),
        dataIndex: "Note",
        key: "Note",
        width: "auto",
    };

    const colSRNote = {
        title: t("Sales Representative's Note"),
        dataIndex: "SRNote",
        key: "SRNote",
        width: "auto",
    };

    const colDetail = {
        title: t("Detail"),
        dataIndex: "details",
        width: "5%",
        render: (text, record) => (
            <Button title={t("Meeting Detail")} onClick={() => handleDisplayDetail(record.MeetingId)}>
                <InfoCircleTwoTone />
            </Button>
        ),
        width: "77px",
    };

    //////////LARGE SIZE OF TABLE///////////
    const lgColumns = [
        {
            title: t("Meeting Date"),
            dataIndex: "MeetingDate",
            key: "MeetingDate",
            sorter: (a, b) => a.MeetingDate.localeCompare(b.MeetingDate),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "10%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            key: "Company",
            sorter: (a, b) => a.Company.localeCompare(b.Company),
            sortDirections: ["descend", "ascend"],
            width: "15%",
        },
        {
            title: t("TIN"),
            dataIndex: "TIN",
            key: "TIN",
            sorter: (a, b) => a.TIN - b.TIN,
            sortDirections: ["descend", "ascend"],
            width: "5%",
        },
        {
            title: t("Person"),
            dataIndex: "Person",
            key: "Person",
            sorter: (a, b) => a.Person.localeCompare(b.Person),
            sortDirections: ["descend", "ascend"],
            width: "15%",
        },
        {
            title: t("Address"),
            dataIndex: "Address",
            key: "Address",
            render: (text, record) => record.Address + ", " + record.Town,
            width: "auto",
        },
        {
            title: t("Sales Representative"),
            sorter: (a, b) => a.SR.localeCompare(b.SR),
            sortDirections: ["descend", "ascend"],
            dataIndex: "SR",
            key: "SR",
            width: "15%",
        },
        {
            title: t("Phone"),
            dataIndex: "PersonPhone",
            key: "PersonPhone",
            width: "10%",
        },
        colDetail,
    ];

    const mdColumns = [
        {
            title: t("Meeting Date"),
            dataIndex: "MeetingDate",
            key: "MeetingDate",
            sorter: (a, b) => a.MeetingDate.localeCompare(b.MeetingDate),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "20%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            key: "Company",
            sorter: (a, b) => a.Company.localeCompare(b.Company),
            sortDirections: ["descend", "ascend"],
            width: "25%",
        },
        {
            title: t("TIN"),
            dataIndex: "TIN",
            key: "TIN",
            sorter: (a, b) => a.TIN - b.TIN,
            sortDirections: ["descend", "ascend"],
            width: "5%",
        },
        {
            title: t("Address"),
            dataIndex: "Address",
            key: "Address",
            render: (text, record) => record.Address + ", " + record.Town,
            width: "25%",
        },
        {
            title: t("Sales Representative"),
            sorter: (a, b) => a.SR.localeCompare(b.SR),
            sortDirections: ["descend", "ascend"],
            dataIndex: "SR",
            key: "SR",
            width: "auto",
        },
        colDetail,
    ];

    const smColumns = [
        {
            title: t("Meeting Date"),
            dataIndex: "MeetingDate",
            key: "MeetingDate",
            sorter: (a, b) => a.MeetingDate.localeCompare(b.MeetingDate),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "25%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            key: "Company",
            sorter: (a, b) => a.Company.localeCompare(b.Company),
            sortDirections: ["descend", "ascend"],
            width: "30%",
        },
        {
            title: t("Address"),
            dataIndex: "Address",
            key: "Address",
            render: (text, record) => record.Address + ", " + record.Town,
            width: "auto",
        },
        colDetail,
    ];
    const xsColumns = [
        {
            title: t("Meeting Date"),
            dataIndex: "MeetingDate",
            key: "MeetingDate",
            sorter: (a, b) => a.MeetingDate.localeCompare(b.MeetingDate),
            sortDirections: ["descend", "ascend"],
            render: (dateString) => (
                <>
                    <span>{moment(dateString).format("DD.MM.YYYY") + " "}</span>
                    <span>{moment(dateString).format("HH:mm")}</span>
                </>
            ),
            width: "30%",
        },
        {
            title: t("Company"),
            dataIndex: "Company",
            key: "Company",
            sorter: (a, b) => a.Company.localeCompare(b.Company),
            sortDirections: ["descend", "ascend"],
            width: "40%",
        },
        colDetail,
    ];

    ///////////////////////////////////////////

    const exportOptions = [
        t("Meeting Date"),
        t("Meeting Agreed Date"),
        t("Sales Representative"),
        t("TIN"),
        t("Person"),
        t("Function"),
        t("Company"),
        t("Phone"),
        t("Address"),
        t("ZIP"),
        t("Note"),
        t("Original Id"),
        t("Sales Representative's Note"),
    ];
    const [selectedItems, setSelectedItems] = useState([]);
    const filteredOptions = selectedItems ? exportOptions.filter((o) => !selectedItems.includes(o)) : null;
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setRowKeys([]);
        setLast(data);
        setPage(1);
        setSearchText("");
        setOldLast(data);
        findCampaigns();
    }, [data]);

    const sortForPrevNext = (sorter) => {
        let allData = last ? last : data;
        if (allData) {
            switch (sorter["field"]) {
                case "MeetingDate":
                    if (sorter["order"] == "descend")
                        allData.sort((a, b) => b.MeetingDate.localeCompare(a.MeetingDate));
                    else allData.sort((a, b) => a.MeetingDate.localeCompare(b.MeetingDate));
                    break;
                case "AgreedDate":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.AgreedDate.localeCompare(a.AgreedDate));
                    else allData.sort((a, b) => a.AgreedDate.localeCompare(b.AgreedDate));
                    break;
                case "Company":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.Company.localeCompare(a.Company));
                    else allData.sort((a, b) => a.Company.localeCompare(b.Company));
                    break;
                case "TIN":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.TIN.localeCompare(a.TIN));
                    else allData.sort((a, b) => a.TIN.localeCompare(b.TIN));
                    break;
                case "Person":
                    if (sorter["order"] == "descend")
                        allData.sort((a, b) => (a.Person && b.Person ? b.Person.localeCompare(a.Person) : null));
                    else allData.sort((a, b) => (a.Person && b.Person ? a.Person.localeCompare(b.Person) : null));
                    break;
                case "SR":
                    if (sorter["order"] == "descend") allData.sort((a, b) => b.SR.localeCompare(a.SR));
                    else allData.sort((a, b) => a.SR.localeCompare(b.SR));
                    break;
            }
            if (sorter["order"] == undefined) allData.sort((a, b) => a.MeetingDate.localeCompare(b.MeetingDate));
            setLast(allData);
        }
    };

    return (
        <>
            <SchuzkaDetail
                key="SchuzkaDetail"
                closeDialog={() => closeDetail()}
                visible={displayDetail}
                meetingId={detailId}
                openPrevDetail={() => openPrevDetail()}
                openNextDetail={() => openNextDetail()}
            />

            <div className="big-form-switch-div">
                <div className="big-form-switch-button">
                    <button
                        className="multiple-button"
                        onClick={() => {
                            setRouteVisible(!routeVisible);
                            setColumnSettVisible(false);
                            setExportVisible(false);
                        }}
                    >
                        {routeVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Search Route ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Search Route ")} <CarFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>

                    <button
                        className="multiple-button"
                        onClick={() => {
                            setColumnSettVisible(!columnSettVisible);
                            setRouteVisible(false);
                            setExportVisible(false);
                        }}
                    >
                        {columnSettVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Edit columns ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Edit columns ")}
                                <SettingFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>

                    <button
                        className="multiple-button"
                        onClick={() => {
                            setExportVisible(!exportVisible);
                            setColumnSettVisible(false);
                            setRouteVisible(false);
                        }}
                    >
                        {exportVisible ? (
                            <h3 style={{ fontWeight: "bold" }}>
                                {t("Export meetings ")}
                                <UpOutlined />
                            </h3>
                        ) : (
                            <h3>
                                {t("Export meetings ")}
                                <FileExcelFilled style={{ paddingLeft: "2px" }} />
                            </h3>
                        )}
                    </button>
                </div>

                {routeVisible && (
                    <div {...routeDivLayout}>
                        <div style={{ display: "flex", margin: "auto" }}>
                            <Checkbox
                                style={{ margin: "auto", paddingTop: "14px", paddingBottom: "10px" }}
                                onChange={onCheckboxChange}
                                defaultChecked={isChecked}
                            >
                                {t("Search from the starting position")}
                            </Checkbox>
                        </div>
                        {isChecked && (
                            <>
                                <p>
                                    Zadejte adresu ve tvaru "<b>{t("Street number, ZIP Town")}</b>"
                                </p>
                                <Input
                                    {...inputLayout}
                                    type="text"
                                    value={searchValue}
                                    placeholder={t("Street number, ZIP Town")}
                                    onChange={(e) => {
                                        setSearchValue(e.target.value);
                                        setInputRouteValidate(false);
                                    }}
                                />
                                {inputRouteValidate ? (
                                    <p style={{ textAlign: "center", color: "red" }}>
                                        Nejprve vyplňte adresu, nebo odšktněte "Hledat od výchozí polohy"
                                    </p>
                                ) : (
                                    <br />
                                )}
                            </>
                        )}
                        <Button className="route-button" onClick={makeURLForMap} icon={<SearchOutlined />}>
                            {t("Find Route")}
                        </Button>

                        {selectedRowKeys.length == 0 && selected && (
                            <p style={{ textAlign: "center", color: "red" }}>
                                {t("First select meetings from the table")}
                            </p>
                        )}
                        <p
                            style={{
                                padding: "16px 8px",
                                paddingBottom: "0",
                                fontSize: "15px",
                                fontWeight: "550",
                                color: "#0e151a",
                            }}
                        >
                            {t(
                                "The route will be displayed in a new tab, if this has not happened, please enable the browser to open new windows."
                            )}
                        </p>
                        <p style={{ padding: "0px 8px", fontSize: "15px", color: "#0e151a" }}>
                            {t(
                                "The displayed route may not be the most optimal, you can adjust it in Google Maps by changing the order of places"
                            )}
                        </p>
                    </div>
                )}

                {columnSettVisible && (
                    <div className="form-question" style={{ paddingTop: "10px" }}>
                        <div style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "30px" }}>
                            <p style={{ marginTop: "10px", color: "#0e151a" }}>
                                {t("To add more columns, click on the white part of the field")}
                            </p>
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Choose columns"
                                value={selectedItems}
                                onChange={(e) => {
                                    setSelectedItems(e);
                                    changeColumns(e);
                                    setIsChanged(true);
                                }}
                                style={{
                                    width: "90%",
                                }}
                            >
                                {filteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                        {item}
                                    </Select.Option>
                                ))}
                            </Select>
                            <div style={{ marginTop: "5px" }}>
                                <Button
                                    type="primary"
                                    className="route-button"
                                    onClick={resetChangeColumns}
                                    icon={<CloseCircleOutlined />}
                                >
                                    {t("Reset")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}

                {exportVisible && (
                    <div className="form-question">
                        <div style={{ backgroundColor: "white", paddingLeft: "20px", paddingRight: "30px" }}>
                            <div style={{ marginTop: "5px" }}>
                                <Checkbox
                                    {...paddingRightLayout}
                                    onChange={(e) => setSelectedInformation(e.target.checked)}
                                >
                                    {t("Export visible columns")}
                                </Checkbox>
                                <br />
                                <Button
                                    style={{ marginTop: "22px", width: "256px" }}
                                    disabled={data ? false : true}
                                    className="yellow-button"
                                    onClick={exportTypeAllRecords}
                                    icon={<FileExcelOutlined />}
                                >
                                    {t("Export ALL records")}
                                </Button>
                                <Button
                                    style={{ width: "256px" }}
                                    disabled={data && selectedRowKeys.length > 0 ? false : true}
                                    className="yellow-button"
                                    onClick={exportTypeSelection}
                                    icon={<FileExcelOutlined />}
                                >
                                    {t("Export SELECTED records")}
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div style={{ backgroundColor: "white" }}>
                <p style={{ marginLeft: "35px", float: "left" }}>
                    {t("Selected")} {selectedRowKeys.length} {t("from")} {last ? last.length : data ? data.length : 0}
                </p>

                <div style={{ float: "right", display: "flex", marginRight: "26px" }}>
                    <Input
                        placeholder={t("Search")}
                        value={searchText}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                        }}
                        onPressEnter={() => handleSearch()}
                        style={{
                            marginBottom: 8,
                            height: "24.5px",
                            width: "140px",
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => handleSearch()}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 25, marginLeft: "5px" }}
                    />
                    <Button
                        icon={<CloseCircleOutlined />}
                        onClick={() => resetSearch()}
                        size="small"
                        style={{ width: 25 }}
                    />
                </div>
            </div>

            <Table
                key="SchuzkaTable2"
                pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ["5", "10", "20", "30"],
                    size: layout == "horizontal" ? "default" : "small",
                    current: page,
                    onChange: (e) => {
                        setPage(e);
                    },
                    total: last ? last.length : data ? data.length : 0,
                    showTotal: (total) => t("Total: ") + `${total} `,
                    onShowSizeChange: (current, size) => setPage(1),
                }}
                rowSelection={rowSelection}
                className="tableClass"
                tableLayout="auto"
                dataSource={last ? last : data}
                columns={storedColumns ? storedColumns : columns}
                //columns={columns}
                loading={loading}
                rowKey={(record) => record.MeetingId}
                onChange={(pagination, filters, sorter) => {
                    sortForPrevNext(sorter);
                }}
            />

            <div className="btn-group-height"></div>
        </>
    );
};

export default connect(mapStateToProps)(ReportSchuzkyTable);

// Will run every time state change is detected
function mapStateToProps(state) {
    // If form issued a queue, and 'QUEUED' is detected in state
    // Search and Query data to state
    if (
        state.SearchQuery.formName == "REPORT_SCHUZKY" &&
        state.SearchQuery.query &&
        state.SearchQuery.status == "QUEUED"
    ) {
        switch (state.SearchQuery.query.MeetingType) {
            //Account
            case "BothDates":
                SearchAndQueryData(1, state.SearchQuery.query);
                return { loading: true };
            //Schůzky podle data sjednani i domluveni
            case "MeetingDates":
                SearchAndQueryData(7, state.SearchQuery.query);
                return { loading: true };
            //Schůzky podle data sjednani i domluveni
            case "AgreedDates":
                SearchAndQueryData(8, state.SearchQuery.query);
                return { loading: true };
        }
    }
    // If data are loaded in state ('READY') detected
    // set data to component's props
    if (
        (state.SearchQuery.formName == "REPORT_SCHUZKY") & (state.SearchQuery.status == "READY") &&
        state.SearchQuery.result
    )
        return { data: state.SearchQuery.result, loading: false, query: state.SearchQuery.query };

    // If no queue detected and no data detected
    // set null to data, and disable loading
    return { data: null, loading: false };
}
